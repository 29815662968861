import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import CalendarInputDate from 'src/components/inputs/Calendars/CalendarInputDate';
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import { clearAssistatText, addAssistantText } from '../../../../../../../generic/store/action';
import useMachine from '../../../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../../../generic/hooks/useTranslate';
import TemplateWizard from '../../../../../../../generic/templates/Modal/TemplateWizard';
import SubmitButton from '../../../../../../../generic/components/Buttons/SubmitButton';
import TemplateLabel from '../../../../../../../generic/components/Inputs/LabelInput';
import Button from '../../../../../../../generic/components/Buttons/Button';
import ControllerInput from '../../../../../../../generic/components/Inputs/ReactHookForm/ControllerInput';
import { StructureChartFormData } from '../../../types';
import classes from './classes.module.scss';

function UploadStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.UploadStep ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = (values: StructureChartFormData) => {
    next(values);
  };

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.STRUCTURE_CHART.TOOLTIP')));

    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    document: Yup
      .object()
      .nullable()
      .required(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.STRUCTURE_CHART.REQUIRED')),
    date: Yup
      .date()
      .nullable()
      .typeError(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.STRUCTURE_CHART.REQUIRED_DATE'))
      .required()
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.STRUCTURE_CHART.TITLE'),
          skeleton: null,
          subTitle: translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.STRUCTURE_CHART.SUBTITLE'),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel
            label={translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.STRUCTURE_CHART.FIELD_DOCUMENT')}
            isRequired
            variant="top"
          >
            <ControllerInput
              render={FileUploaderInput}
              name="document"
              defaultlabel=""
              className={classes.fileLoad}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.STRUCTURE_CHART.FIELD_DATE')}
            isRequired
          >
            <ControllerInput
              render={CalendarInputDate}
              placeholder="dd/mm/yyyy"
              name="date"
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default UploadStep;

