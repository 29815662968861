import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import TextAreaInput from "src/components/inputs/Textarea/TextareaInput";
import classes from "./classes.module.scss";
import { useLD } from 'src/providers/LDProvider';

function ReviewCorporateStakeholderPepFormStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  const initialValues = context?.ReviewPEP ?? context.initialData.PEP;

  const { formValues } = initialValues;
  const [isPEP, setIsPEP] = useState(formValues?.isPEP ?? false);

  const stakeholderName: string = context.initialData.stakeholder.fullName;

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    dispatch(
      addAssistantText(
        isPaymentProcessingRemoved ?
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.TOOLTIP_PAYMENT_REMOVAL"
        )
        :
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.TOOLTIP"
        ),
        "alert"
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.PEP.TOOLTIP"
        ),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const options = [
    {
      value: false,
      label: "No, they aren't",
    },
    {
      value: true,
      label: "Yes, they are",
    },
  ];

  const schema = Yup.object().shape({
    isPEP: Yup.boolean().required("This field is required").nullable(),
    descriptionOfPEP: Yup.lazy(() => {
      if (isPEP)
        return Yup.string()
          .required("This field is required")
          .typeError("This field is required");
      return Yup.string().nullable().typeError("This field is required");
    }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.PEP.TITLE"
          ),
          subTitle: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.SUBTITLE",
            { stakeholderName }
          ),
          skeleton: null,
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>{translate("BUTTON_NEXT")}</SubmitButton>,
        }}
      >
        <TemplateLabel
          isRequired={true}
          label={`Are any directors, managers, or shareholders of ${stakeholderName} a PEP, a family member of a PEP or a close associate of a PEP?`}
          renderPosition="row"
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            name="isPEP"
            size="size1"
            options={options}
            classNameButton={classes.heightCheckboxButtonSimple}
            onChange={(value) => setIsPEP(value)}
          />
        </TemplateLabel>
        {isPEP && (
          <TemplateLabel
            isRequired={true}
            renderPosition="row"
            label="Who is a PEP, what is their role and what makes them a PEP?"
          >
            <ControllerInput
              render={TextAreaInput}
              name="descriptionOfPEP"
              placeholder="Type here"
              rows={8}
            />
          </TemplateLabel>
        )}
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCorporateStakeholderPepFormStep;
