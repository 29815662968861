import * as Yup from "yup";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Button from "src/modules/generic/components/Buttons/Button";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import { Constants } from "src/v1/utils/constants";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import Form from "src/components/forms/Form/Form";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import TextInput from "src/components/inputs/Text/TextInput";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { AnalizerInputWithBanner } from "src/components/inputs/AnalizerInputWithBanner";
import useStakeholderFieldsAnalizer from "src/components/forms/modules/startups/hooks/useStakeholderFieldsAnalizer";
import RadioInput from "src/components/inputs/Radio/RadioInput";
import ClaraCountryAutocomplete from "src/v1/components/Form/ClaraCountryAutocomplete/ClaraCountrySelector";
import { useLD } from 'src/providers/LDProvider';

export default function ReviewPersonalDetailConfirmDetailForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const [countryNationality, setCountryNationality] = useState();
  const { initialData } = context;
  const dispatch = useDispatch();

  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  const listTitle = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Ms", value: "Ms" },
    { label: "Dr", value: "Dr" },
    { label: "Hon", value: "Hon" },
    { label: "Reverend", value: "Reverend" },
    { label: "Very Reverend", value: "Very Reverend" },
    { label: "Senator", value: "Senator" },
  ];

  const initialValues = useMemo(() => {
    let result = context?.ReviewPersonalDetailConfirmDetailForm ?? initialData?.stakeholder;
    const code = _.get(result, "nationality.code", "");
    result.nationality = code;
    return result;
  }, [context, initialData]);

  const residenceCountry = _.get(initialValues, "address.country.code")
    ? _.get(initialValues, "address.country.code").toLowerCase()
    : "";

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => {
    next({ ...values, countryNationality });
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        isPaymentProcessingRemoved
          ? translate("MODULES.RENEWALS_ADGM.COMMON.ADDITIONAL_FEES_PAYMENT_REMOVAL"):
        translate("MODULES.RENEWALS_ADGM.COMMON.ADDITIONAL_FEES"),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    title: Yup.string()
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
    fullName: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    profession: Yup.string()
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    phoneNumber: Yup.string()
      .nullable()
      .required("This field is required")
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    nationality: Yup.string().nullable().required("This field is required"),
    hasNationalities: Yup.boolean().required("This field is required").typeError("This field is required"),
    nationalities: Yup.mixed().when('hasNationalities', {
      is: true,
      then: Yup.array().of(Yup.string()).min(1, translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      otherwise: Yup.array().of(Yup.string()).nullable(),
    }),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_EMAIL"),
        excludeEmptyString: true,
      })
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    dateOfBirth: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
    countryOfBirth: Yup.string().required("This field is required").typeError("This field is required").nullable(),
    cityOfBirth: Yup.string().required("This field is required").nullable(),
  });

  const { config } = useStakeholderFieldsAnalizer(initialValues, {}, "Next");

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm their details",
          subTitle: `Review details for ${initialValues.fullName}`,
        }}
        buttons={{
          submit: (
            <SubmitButton>{translate("MODULES.CLARA.BTN_NEXT")}</SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <AnalizerInputWithBanner
          component={SelectInput}
          controllerInputProps={{
            name: "title",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
            list: listTitle,
          }}
          field={config.title}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "fullName",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.fullName}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "arabicName",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.arabicName}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "previousName",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.previousName}
        />
        <AnalizerInputWithBanner
          component={CountrySelector}
          controllerInputProps={{
            name: "nationality",
            placeholder: translate("MODULES.CLARA.SELECT_COUNTRY"),
            callbakcs: {
              updateValues: (v) => setCountryNationality(v) 
            }
          }}
          field={config.nationality}
        />
        <AnalizerInputWithBanner
          component={RadioInput}
          controllerInputProps={{
            name: 'hasNationalities'
          }}
          field={config.hasNationalities}
        />
        <AnalizerInputWithBanner
          component={ClaraCountryAutocomplete}
          controllerInputProps={{
            name: "nationalities",
            placeholder: translate("MODULES.CLARA.SELECT_ALL_THAT_APPLY")
          }}
          field={config.nationalities}
        />
        <AnalizerInputWithBanner
          component={CalendarInputDate}
          controllerInputProps={{
            name: "dateOfBirth",
            placeholder: translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT"),
          }}
          field={config.dateOfBirth}
        />
        <AnalizerInputWithBanner
          component={CountrySelector}
          controllerInputProps={{
            name: "countryOfBirth",
            placeholder: translate("MODULES.CLARA.SELECT_COUNTRY")
          }}
          field={config.countryOfBirth}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "cityOfBirth",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT")
          }}
          field={config.cityOfBirth}
        />
        <TemplateLabel label="Profession" isRequired={true}>
          <ControllerInput
            name="profession"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "email",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.email}
        />
        <AnalizerInputWithBanner
          component={PhoneInput}
          controllerInputProps={{
            name: "phoneNumber",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
            defaultCountry: residenceCountry,
            disableDropdown: false,
          }}
          field={config.phoneNumber}
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}
