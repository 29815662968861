import React, { FC, useMemo, useRef } from "react";
import * as Yup from "yup";
import _ from "lodash";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import useSession from "../../../session/hooks/useSession";
import {StakeholderBO} from "../../core/type/startup/stakeholder";
import classes from "./Form.module.scss";
import useGetFormationCompanyDirectors from '../graphql/queries/useGetFormationDirectors';
export interface Stakeholder {
  value: StakeholderBO | string;
  label: string;
}
interface FormSelectsDirectorProps extends FormProps {
  groupCompanyId?: string;
}

const schema = Yup.object().shape({
  director: Yup.object().required("Required"),
});

const FormSelectsDirector: FC<FormSelectsDirectorProps> = ({
  initialValues,
  groupCompanyId,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormSelectsDirectorProps) => {
  const { startupId } = useSession();
  const { data } = useGetFormationCompanyDirectors({ variables: { startupId,groupCompanyId } });
  const refForm = useRef();

  const stakeholders: Stakeholder[] = useMemo(() => {
    const filterIndividual = _.filter(_.get(data,'formationData.directors'), (st) => st.isAnEntity === false);
    const result = _.map(filterIndividual, (st) => ({ value: st, label: st.fullName }));
    return result;
  },[JSON.stringify(data)]);

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            isMultiple={false}
            defaultValue={[]}
            name="director"
            options={stakeholders}
            className={classes.checkboxMultiple}
            classNameButton={classes.buttonCheckBox}
          />
        </Template>
      </Form>
    </>
  );
};

export default FormSelectsDirector;
