import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCategoriesTitles } from '../../../../../hooks/common/useCategoriesTitles';
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../generic/hooks/useTranslate';
import Form from "../../Forms/FormSelectCreationDocument";
import { clearAssistatText } from "../../../../generic/store/action";

const Step1: FC<StepProps> = () => {
  const CATEGORIES_TITLES = useCategoriesTitles();

  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();
  const dispatch = useDispatch();
  const { paramsData } = state.context;

  useEffect(() => {
    dispatch(clearAssistatText());
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const handleCompleteSubmit = async values => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  return (
    <>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: `Do they have a ${CATEGORIES_TITLES[paramsData.type]}?`,
        subTitle: translate(`MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_SUBTITLE`)
      }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </>
  );
}

export default Step1;
