import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import EditProfileModal from "src/components/modules/clara/startups/individualStakeholder/Modals/EditProfile/EditProfile";
import EmptyState from "src/modules/generic/components/EmptyState";
import Link from "src/components/text/Link/Link";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import { RoleAccess } from "src/v1/components";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import classes from "../DetailsTab.module.scss";
import { Stack } from '@mui/material';

function GeneralDetailsPanel({
  data,
  lock,
  jurisdictions,
  isManagedByClara,
  isEmptyState,
}) {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const [itemsCol1, setItemsCol1] = useState([]);
  const [itemsCol2, setItemsCol2] = useState([]);

  const handleViewDocumentDetails = () => {
    let cvIndex = _.findIndex(_.get(data, "documents"), (doc) => {
      return _.get(doc, "file.id") === _.get(data, "curriculumVitae.id");
    });
    dispatch({
      type: "GO_TO",
      page: Constants.PAGES[`viewDocumentUploaded`],
      params: {
        id: _.get(data, `documents[${cvIndex}].id`),
        status: "Uploaded",
      },
    });
  };

  const getCVName = (data) => {
    const CVId = _.get(data, "curriculumVitae.id", "");
    if (CVId !== "") {
      const documents = _.get(data, "documents", []);
      const CVname = _.find(documents, (doc) => {
        return _.get(doc, "file.id") === CVId;
      });
      return _.get(CVname, "name", "");
    }
  };

  const generalDetailsButton = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        disabled={lock}
        variant="card"
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          openModal(EditProfileModal, {
            handleCloseModal: closeModal,
            isManagedByClara,
            jurisdictions: jurisdictions,
          });
        }}
      />
    </RoleAccess>
  );

  const stakeholderClass = useMemo(() => {
    if (!data) return null;
    return new Stakeholder(data);
  }, [data]);

  useEffect(() => {
    let newItemsCol1 = [];
    let newItemsCol2 = [];
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_TITLE"),
      value: _.get(data, "title"),
      valuePrefix: stakeholderClass?.getFieldInMatter("title") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("title"),
    });
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_FORMER_NAMES"),
      value: _.get(data, "previousName", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter("previousName") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("previousName"),
    });
    if (_.includes(jurisdictions, "ADGM")) {
      newItemsCol1.push({
        label: translate("STAKEHOLDER_VIEW_TAB_LABEL_ARABIC_NAME"),
        value: _.get(data, "arabicName", "-"),
        valuePrefix: stakeholderClass?.getFieldInMatter("arabicName") ? (
          <PincasIcon />
        ) : null,
        disabledField: stakeholderClass?.getFieldInMatter("arabicName"),
      });
    }
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_NATIONALITY"),
      value: _.get(data, "nationality.name"),
      valuePrefix: stakeholderClass?.getFieldInMatter("nationality") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("nationality"),
    });
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_OTHER_NATIONALITIES"),
      value: (
        <Stack gap={1}>
          {_.isEmpty(data?.nationalities?.countries) ? (
            "-"
          ) : (
            _.map(data?.nationalities?.countries, (country) => (
              <div key={country?.country?.code}>
                {country?.country?.name}
              </div>
            ))
          )}
        </Stack>
      ),
      valuePrefix: stakeholderClass?.getFieldInMatter("nationalities") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("nationalities"),
    });
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_DATE_OF_BIRTH"),
      value: _.isEmpty(data?.dateOfBirth)
        ? "-"
        : moment(_.get(data, "dateOfBirth", "")).format("DD MMM YYYY"),
      valuePrefix: stakeholderClass?.getFieldInMatter("dateOfBirth") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("dateOfBirth"),
    });
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_COUNTRY_OF_BIRTH"),
      value: _.get(data, "birthAddress.country.name"),
      valuePrefix: stakeholderClass?.getFieldInMatter("birthAddress.country") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("birthAddress.country"),
    });
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_CITY_OF_BIRTH"),
      value: _.get(data, "birthAddress.city"),
      valuePrefix: stakeholderClass?.getFieldInMatter("birthAddress.city") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("birthAddress.city"),
    });
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_PASSPORT_NUMBER"),
      value: _.get(data, "passportNumber"),
      valuePrefix: stakeholderClass?.getFieldInMatter("passportNumber") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("passportNumber"),
    });
    newItemsCol2.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_EMAIL"),
      value: _.get(data, "email"),
      valuePrefix: stakeholderClass?.getFieldInMatter("email") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("email"),
    });
    newItemsCol2.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_TEL"),
      value: _.get(data, "phoneNumber"),
      valuePrefix: stakeholderClass?.getFieldInMatter("phoneNumber") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("phoneNumber"),
    });
    newItemsCol2.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_PROFESSION"),
      value: _.get(data, "profession"),
      valuePrefix: stakeholderClass?.getFieldInMatter("profession") ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter("profession"),
    });
    newItemsCol2.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_LINKEDIN"),
      valuePrefix: stakeholderClass?.getFieldInMatter("linkedinURL") ? (
        <PincasIcon />
      ) : null,
      value: (() => {
        const originalURL = _.get(data, "linkedinURL");
        const cutURL = originalURL ? originalURL.split("?")[0] : "";
        return cutURL ? (
          <Link target="_blank" href={cutURL}>
            {cutURL}
          </Link>
        ) : (
          "-"
        );
      })(),
    });
    newItemsCol2.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_CV"),
      value: (
        <label
          className={classes.labelDocument}
          onClick={handleViewDocumentDetails}
        >
          {getCVName(data)}
        </label>
      ),
    });
    setItemsCol1(newItemsCol1);
    setItemsCol2(newItemsCol2);
  }, [data]);

  return (
    <>
      <DetailsTabTitle title="General details" buttons={generalDetailsButton} />
      {isEmptyState ? (
        <EmptyState
          title={
            lock
              ? translate("DETAILS_EMPTY_STATE_VIEWER")
              : translate("DETAILS_EMPTY_STATE_USER")
          }
          iconType={lock ? "Locked" : "SAFE"}
        />
      ) : (
        <TwoColumnsViewer itemsCol1={itemsCol1} itemsCol2={itemsCol2} />
      )}
    </>
  );
}

GeneralDetailsPanel.propTypes = {
  data: PropTypes.any,
  lock: PropTypes.bool,
  isManagedByClara: PropTypes.bool,
};

export default GeneralDetailsPanel;
