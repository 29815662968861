import _ from "lodash";
import { useDispatch } from "react-redux";
import useModal from 'src/modules/generic/hooks/useModal';
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import EconomicSubstanceModal from "src/views/modules/startups/renewal/cayman/modals/EconomicSubstanceModal";
import UploadAnnualAccountsModal from "src/views/modules/startups/renewal/cayman/modals/UploadAnnualAccountsModal";
import ReviewCompanyDetailsModal from "src/views/modules/startups/renewal/adgm/modals/ReviewCompanyDetailsModal";
import ReviewAddressesModal from "src/views/modules/startups/renewal/adgm/modals/ReviewAddressesModal";
import ReviewDirectorsModal from "src/views/modules/startups/renewal/adgm/modals/ReviewDirectorsModal";
import ReviewDataProtectionContact from "src/modules/startup/GroupCompany/ADGM/renewals/ReviewDataProtectionContact";
import ReviewADGMDesignatedContact from "src/modules/startup/GroupCompany/ADGM/renewals/ReviewADGMDesignatedContact";
import ReviewAuthorisedSignatory from "src/views/modules/startups/renewal/adgm/modals/ReviewAuthorisedSignatory";
import ReviewAndConfirmCapTableModal from "src/views/modules/startups/renewal/adgm/modals/ReviewAndConfirmCapTableModal";
import ReviewDataProtectionDeclaration from "src/modules/startup/GroupCompany/ADGM/renewals/ReviewDataProtectionDeclaration";
import ModalPassport from "src/modules/startup/Stakeholder/Passport";
import StakeholderExpiredDocumentModal from "src/views/modules/startups/renewal/adgm/modals/StakeholderExpiredDocumentModal";
import ModalProofAddress from "src/modules/startup/Stakeholder/ProofOfAddress/ModalProofAddress";
import ModalEmiratesID from "src/modules/startup/Stakeholder/ADGM/EmiratesID/ModalEmiratesID";
import ModalUAEResidenceVisa from "src/modules/startup/Stakeholder/ADGM/UAEResidenceVisa/ModalUAEResidenceVisa";
import ModalPEP from 'src/modules/startup/Stakeholder/PEP';
import ModalSourcesOfFunds from 'src/modules/startup/Stakeholder/ADGM/SourceOfFunds';
import ModalSourceOfFundsKY from 'src/modules/startup/Stakeholder/Cayman/SourceOfFunds';
import ModalProfessionalHistory from 'src/modules/startup/Stakeholder/ProfessionallHistory';
import ModalUIDStamp from "src/modules/startup/Stakeholder/ADGM/UIDStamp/ModalUIDStamp";
import ModalUAEEntryStamp from "src/modules/startup/Stakeholder/ADGM/UAEEntryStamp/ModalUAEEntryStamp";
import ModalAddressAndFamilyContact from "src/modules/startup/Stakeholder/ADGM/AddressAndFamilyContact/ModalAddressAndFamilyContact";
import ModalGCCResidentID from "src/modules/startup/Stakeholder/ADGM/GCCResidentID/ModalGCCResidentID";
import ModalNationalID from "src/modules/startup/Stakeholder/ADGM/NationalID/ModalGCCResidentID";
import StakeholderCorporateDetailsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/StakeholderCorporateDetailsModal";
import CompanyDirectorsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/CompanyDirectorsModal";
import CorporateSignatoryModal from "src/modules/startup/Stakeholder/EntityStructures/CorporateSignatoryModal";
import PEPModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/PEPModal";
import SourceOfFundsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/SourceOfFundsModal";
import CertificateOfIncorporationModal from "src/modules/startup/Stakeholder/EntityStructures/CertificateOfIncorporationModal";
import RegistersOrCertificateOfIncumbencyModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/RegistersOrCertificateOfIncumbencyModal";
import OfficialDocumentModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/OfficialDocumentModal";
import ArticlesOfAssociationModal from "src/modules/startup/Stakeholder/EntityStructures/Cayman/ArticlesOfAssociationModal";
import RegistersModal from "src/modules/startup/Stakeholder/EntityStructures/Cayman/RegistersModal";
import LimitedPartnershipDetailsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedPartnership/LimitedPartnershipDetailsModal";
import CertificateOfGoodStandingModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedPartnership/CertificateOfGoodStandingModal";
import RegOfPartnersOrLimPartnershipAgreeModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedPartnership/RegOfPartnersOrLimPartnershipAgreeModal";
import CertificateOfRegistrationModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/Trust/CertificateOfRegistrationModal";
import TrustDeedModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/Trust/TrustDeedModal";
import ModalPersonalInfoCorporate from 'src/modules/startup/Stakeholder/PersonalInfoCorporate/ModalPersonalInfoCorporate';
import ModalAddAddresses from "src/modules/startup/Stakeholder/AddAddresses/ModalAddAddresses";
import ModalSetUp from 'src/modules/startup/GroupCompany/ADGM/SetUp';
import ModalConfirmShareholdersDirectors from 'src/modules/startup/GroupCompany/ADGM/ConfirmShareholdersDirectors';
import ModalAssetsAndSources from 'src/modules/startup/GroupCompany/ADGM/AssetsAndSources';
import ModalAuthorisedSignatory from "src/modules/startup/GroupCompany/ADGM/AuhorisedSignatoryModal";
import LeaseDetailsModal from "src/modules/startup/onBoarding/LeaseDetailsModal";
import BusinessPlanModal from "src/modules/startup/onBoarding/BusinessPlanModal";
import ModalBankingAuthorities from 'src/modules/startup/GroupCompany/ADGM/BankingAuthority';
import ModalAccountingRecords from 'src/modules/startup/Stakeholder/ADGM/AccountingRecords/ModalAccountingRecords';
import ModalDataProtectionContact from 'src/modules/startup/Stakeholder/ADGM/DataProtectionContact/DataProtectionContact';
import ModalShareCapital from 'src/modules/startup/GroupCompany/ShareCapital';
import ModalShareCapitalDelaware from "src/modules/startup/GroupCompany/DELAWARE/ShareCapital";
import DocumentReview from 'src/modules/startup/GroupCompany/DocumentReview/ModalDocumentReview';
import ModalNatureOfBusiness from 'src/modules/startup/GroupCompany/KY/NatureOfBusiness';
import ModalPlaceOfBusiness from 'src/modules/startup/Stakeholder/PlaceOfBusiness';
import ModalCorporatePosition from 'src/modules/startup/Stakeholder/CorporatePosition';
import AddFormationCompanyModal from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal";
import CreateCompanyModal from "src/modules/startup/onBoarding/CreateCompanyModal";
import modalTopCo from "src/modules/accelerators/modals/SelectToCop/index";
import { Constants } from "src/v1/utils/constants.js";
import { goToPage } from "src/store/actions/page";
import ModalDetailCompany from "src/modules/startup/GroupCompany/Details/ModalDetailCompany";
import ModalConstitutionalDocuments from "src/modules/clara/components/Accelerator/ConstitutionalDocuments";
import ModalCompleteStakeholders from "src/modules/clara/components/Accelerator/CompleteStakeholders";
import ModalQuestionnaire from "src/modules/accelerators/modals/questionnaire";
import AddFounderServicesAgrementModal from "src/modules/startup/AddFounderServicesAgrementModal";
import AddFounderAgrementModal from "src/modules/startup/AddFounderAgrementModal";
import ModalBoardResolution from "src/modules/accelerators/modals/boardResolution";
import ModalConfirmDocuments from "src/modules/accelerators/modals/ConfirmDocumentsModal";
import AddEquityTaskModal from "src/modules/startup/equity/AddEquityTask";
import ModalRemoveTask from "src/modules/startup/core/components/modals/DeleteTaskModal";
import { ModalConvertibleDoc, ModalEmployeeDoc, ModalIpAndGenerate } from "src/modules/startup/Documents";
import ModalAddShare from 'src/modules/startup/equity/stakeholder/share/modals/AddSharesModal';
import ModalShareOptions from "src/modules/startup/Stakeholder/ShareOptions/ModalShareOptions";
import ModalAddConvertible from 'src/modules/startup/equity/stakeholder/convertible/modals/AddConvertibleModal';
import ModalAddWarrant from 'src/modules/startup/equity/stakeholder/warrants/modals/AddWarrantModal';
import ModalNotices from 'src/modules/startup/Stakeholder/Notices/ModalNotices';
import ModalAuthorisedSignatories from 'src/modules/startup/Stakeholder/AuthorisedSignatories/ModalAuthorisedSignatories';
import ModalDirector from "src/modules/startup/onBoarding/roles/director/modal/ModalDirector";
import ModalFounder from "src/modules/startup/onBoarding/roles/founder/modal/ModalFounder";
import ModalEmployee from "src/modules/startup/onBoarding/roles/employee/modal/ModalEmployee";
import ModalRemoveEssential from "src/modules/map/modals/ModalRemoveEssential";
import ModalConsultant from "src/modules/startup/onBoarding/roles/consultant/modal/ModalConsultant";
import ModalAdvisor from "src/modules/startup/onBoarding/roles/advisor/modal/ModalAdvisor";
import ModalInvestor from "src/modules/startup/onBoarding/roles/investor/modal/ModalInvestor";
import DirectorDelawareModal from "src/modules/startup/onBoarding/roles/DirectorDelawareModal";
import DirectorV2Modal from "src/modules/startup/onBoarding/roles/DirectorV2Modal";
import ShareholderDelawareModal from "src/modules/startup/onBoarding/roles/ShareholderDelawareModal";
import ShareholderV2Modal from "src/modules/startup/onBoarding/roles/ShareholderV2Modal";
import PayFormationBundleModal from "src/modules/startup/PayFormationBundleModal";
import ModalRecipientForNotices from "src/modules/startup/GroupCompany/RecipientForNotices";
import StructureChartModal from '../../../../../../modules/startup/Stakeholder/EntityStructures/StructureChartModal';
import StartRenewalProcessModal from "../../../renewal/adgm/modals/StartRenewalProcessModal";
import StartRenewalModal from "../../../renewal/cayman/modals/StartRenewalModal";
import ModalSubmitApplication from "src/modules/clara/components/Accelerator/SubmitApplication";
import useFunctionsTaskRenewal from "./useFunctionsTaskRenewal";
import useFunctionsTaskEquity from "./useFunctionTasksEquity";
import useFunctionTasksDocument from "./useFunctionsTaskDocument";
import ModalSIPDoc from "src/modules/startup/Documents/Sip/ModalSIPDoc";
import { useCallback, useMemo } from "react";
import ModalSubmittedFormation from "src/modules/startup/GroupCompany/ADGM/SubmittedFormation/ModalSubmittedFormation";
import MailingAddressModal from "src/modules/startup/Stakeholder/EntityStructures/Cayman/MailingAddressModal";
import PersonalInfoModal from "src/modules/startup/Stakeholder/PersonalInfoModal";
import ImmediateParentsModal from "src/views/modules/startups/renewal/cayman/modals/ImmediateParentsModal";
import ConfirmUltimateParentModal from "src/views/modules/startups/renewal/cayman/modals/ConfirmUltimateParentModal";
import BeneficialOwnersModal from "src/views/modules/startups/renewal/cayman/modals/BeneficialOwnersModal";
import ConfirmRenewalInformationModal from "src/views/modules/startups/renewal/cayman/modals/ConfirmRenewalInformationModal";
import NatureOfUnderlyingBusinessModal from "src/modules/startup/Stakeholder/EntityStructures/Cayman/NatureOfUnderlyingBusinessModal";
import PurposeOfEntityModal from "src/modules/startup/Stakeholder/EntityStructures/Cayman/PurposeOfEntityModal";
import ModalOtherNationalityInfo from "src/modules/startup/Stakeholder/OtherNationalityInfo";
import { useLD } from "src/providers/LDProvider";

const useFunctionsTask = ({ openPublish }) => {
  const functionsRenewal = useFunctionsTaskRenewal();
  const functionsEquity = useFunctionsTaskEquity({ openPublish });
  const functionsDocument = useFunctionTasksDocument();
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const { isFormationGoal } = useBusinessRules();
  const { featureFlags } = useLD();

  const handleAddGroupCompany = useCallback(() => {
    if (isFormationGoal()) {
      openModal(
        AddFormationCompanyModal,
        {},
        'MODAL_TASK_ADD_COMPANIES_GET_READY_FORMATION'
      );
    } else {
      openModal(
        CreateCompanyModal,
        {},
        'MODAL_TASK_ADD_COMPANIES_GET_READY'
      );
    }
  }, []);

  const getProductCodeByJurisdictionType = (jurisdictionType) => {
    switch (jurisdictionType) {
      case "CAYMAN":
        return Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART;
      case "DELAWARE":
        return Constants.MODULES.CHECKOUT.PRODUCTS.DELAWARE_SHOPPING_CART;
      case "ADGM":
        return Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART
      default:
        return null
    }
  }

  const handleViewMore = useCallback((params) => {
    goToPage(dispatch, Constants.PAGES.viewGroupCompany, { id: params.company.id, tab: "details" });
  }, []);

  const handleViewMoreStakeholder = useCallback((params) => {
    if (params.stakeholder.isAnEntity) {
      goToPage(dispatch, Constants.PAGES.viewStakeholderCompany, {
        id: params.stakeholder.id,
        tab: "details",
      });
    } else {
      goToPage(dispatch, Constants.PAGES.viewStakeholderIndividual, {
        id: params.stakeholder.id,
        tab: "details",
      });
    }
  }, []);

  const handleShareCapitalModal = useCallback((params) => {
    const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
    let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
    if (params?.jurisdiction === 'ADGM' || params?.jurisdiction === 'CAYMAN') {
      openModal(
        ModalShareCapital,
        {
          paramsMutation: {
            id: idGroupCompany,
            jurisdictionType: params?.jurisdiction
          }
        },
        "MODAL_TASK_SHARE_CAPITAL"
      );
    } else if (params?.jurisdiction === 'DELAWARE') {
      openModal(
        ModalShareCapitalDelaware,
        {
          paramsMutation: {
            id: idGroupCompany,
            jurisdictionType: params?.jurisdiction
          }
        },
        "MODAL_TASK_SHARE_CAPITAL"
      );
    } else {
      openModal(
        ModalShareCapital,
        {
          paramsMutation: {
            id: idGroupCompany,
            jurisdictionType: params?.jurisdiction
          }
        },
        "MODAL_TASK_SHARE_CAPITAL"
      );
    }
  }, [])

  const handlePayAdgm = useCallback((groupCompanyId, jurisdiction, callback) => {
    openModal(PayFormationBundleModal, {
      initialValues: {
        groupCompanyId: groupCompanyId,
        productCode: Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART,
        needFirstContact: true,
        disabledPayCart: false,
        isPincasSubmit: false,
      }
    }, 'MODAL_PAY_ADGM_SHOPPING_CART');
  }, [])

  const handlePayPincas = useCallback((groupCompanyId, jurisdiction, callback) => {
    openModal(PayFormationBundleModal, {
      initialValues: {
        groupCompanyId, callback,
        productCode: getProductCodeByJurisdictionType(jurisdiction),
        needFirstContact: true,
        disabledPayCart: false,
        isPincasSubmit: false,
      }
    }, 'MODAL_PAY_CAYMAN_SHOPPING_CART');
  }, []);

  const dictionary = useMemo(() => ({
    "TASK_PASSPORT_OTHER_NATIONALITIES_IN_FORMATION": {
      onClick: (params) => {
        openModal(
          ModalOtherNationalityInfo,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              country:params?.additionalParams[0].country
            }
          })
      }
    },
    "TASK_RENEWAL_KY_REVIEW_ECONOMIC_SUBSTANCE_NOTIFICATION": {
      onClick: (params) => {
        openModal(
          EconomicSubstanceModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id,
            },
          },
          "MODAL_TASK_RENEWAL_KY_REVIEW_ECONOMIC_SUBSTANCE_NOTIFICATION"
        );
      },
    },
    "TASK_RENEWAL_KY_UPLOAD_FINANCIAL_ACCOUNTS": {
      onClick: (params) => {
        openModal(
          UploadAnnualAccountsModal,
          {
            ...params,
            initialValues: {
              groupCompanyId: params?.company?.id,
            },
          },
          "MODAL_UPLOAD_FINANCIAL_ACCOUNTS"
        );
      },
    },
    "TASK_RENEWAL_KY_PAY": {
      onClick: (params) => {
        openModal(PayFormationBundleModal, {
          initialValues: {
            groupCompanyId: params?.groupCompanyId,
            productCode: Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART,
            needFirstContact: true,
            disabledPayCart: false,
            isPincasSubmit: false,
          }
        })
      },
    },
    "TASK_RENEWAL_KY_SUBMIT": {
      onClick: (params) => {
        openModal(PayFormationBundleModal, {
          initialValues: {
            groupCompanyId: params?.groupCompanyId,
            productCode: Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART,
            needFirstContact: true,
            disabledPayCart: false,
            isPincasSubmit: !featureFlags['payment-process-removal-pincas-and-renewals'],
            isPincasSubmitPaymentRemoved: featureFlags['payment-process-removal-pincas-and-renewals'],
          }
        })
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_COMPANY_DETAILS": {
      onClick: (params) => {
        openModal(
          ReviewCompanyDetailsModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_COMPANY_DETAILS"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_ADDRESSES": {
      onClick: (params) => {
        openModal(
          ReviewAddressesModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_ADDRESSES"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DIRECTORS": {
      onClick: (params) => {
        openModal(
          ReviewDirectorsModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_DIRECTORS"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DATA_PROTECTION_CONTACT": {
      onClick: (params) => {
        openModal(
          ReviewDataProtectionContact,
          {
            ...params,
            initialValues: {
              groupCompanyId: params?.company?.id
            },
          },
          "MODAL_REVIEW_DATA_PROTECTION_CONTACT"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DESIGNATED_CONTACT": {
      onClick: (params) => {
        openModal(
          ReviewADGMDesignatedContact,
          {
            ...params,
            initialValues: {
              groupCompanyId: params?.company?.id
            },
          },
          "MODAL_REVIEW_ADGM_DESIGNATED_CONTACT"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_AUTHORISED_SIGNATORY": {
      onClick: (params) => {
        openModal(
          ReviewAuthorisedSignatory,
          {
            initialValues: {
              groupCompanyId: params?.company?.id
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_AUTHORISED_SIGNATORY"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_CAP_TABLE": {
      onClick: (params) => {
        openModal(
          ReviewAndConfirmCapTableModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_CAP_TABLE"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DATA_PROTECTION_DECLARATION": {
      onClick: (params) => {
        openModal(
          ReviewDataProtectionDeclaration,
          {
            ...params,
            initialValues: {
              groupCompanyId: params?.company?.id
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_REVIEW_DATA_PROTECTION_DECLARATION"
        );
      },
    },
    "TASK_IDENTITY_DOCUMENT_CHECK_EXP_DATE": {
      onClick: (params) => {
        const identityDocument = _.find(params?.additionalParams, (addParam) => addParam.entityType === "IdentityDocument");
        openModal(
          StakeholderExpiredDocumentModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName,
              documentId: identityDocument?.entityId,
              document: identityDocument?.name,
              documentType: identityDocument?.type,
              country: {
                code: identityDocument?.country?.code,
                name: identityDocument?.country?.name,
              },
              ...params,
            },
          },
          "TASK_IDENTITY_DOCUMENT_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_PASSPORT_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {
            initialValues: {
              // idToSave: dataStakeholder?.getStakeholder?.id,
              // name: dataStakeholder?.getStakeholder?.fullName,
            },
          },
          "MODAL_TASK_PASSPORT_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_EMIRATES_ID_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalEmiratesID,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_EMIRATES_ID_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_UAE_RESIDENCE_VISA_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalUAEResidenceVisa,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
              documentTypeOld: "UID_OLDPASSPORT_DOCUMENT",
              documentType: "UID_STAMP_DOCUMENT"
            },
          },
          "MODAL_TASK_UAE_RESIDENCE_VISA_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_EMIRATES_ID_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalEmiratesID,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_EMIRATES_ID_KYC_INC"
        );
      },
    },
    "TASK_UAE_RESIDENCE_VISA_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalUAEResidenceVisa,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
              documentTypeOld: "UID_OLDPASSPORT_DOCUMENT",
              documentType: "UID_STAMP_DOCUMENT"
            }
          },
          "MODAL_TASK_UAE_RESIDENCE_VISA_KYC_INC"
        );
      },
    },
    "TASK_UID_STAMP_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalUIDStamp,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_UID_STAMP_KYC_INC"
        );
      },
    },
    "TASK_UAE_ENTRY_STAMP_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalUAEEntryStamp,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId
            }
          },
          "MODAL_TASK_UAE_ENTRY_STAMP_KYC_INC"
        );
      },
    },
    "TASK_ADDRESS_FAMILY_CONTACT_PAGE_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalAddressAndFamilyContact,
          {},
          "MODAL_TASK_ADDRESS_FAMILY_CONTACT_PAGE_KYC_INC"
        );
      },
    },
    "TASK_GCC_RESIDENT_ID_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalGCCResidentID,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId
            }
          },
          "MODAL_TASK_GCC_RESIDENT_ID_KYC_INC"
        );
      },
    },
    "TASK_NATIONAL_ID_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalNationalID,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId
            }
          },
          "MODAL_TASK_NATIONAL_ID_KYC_INC"
        );
      },
    },
    "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(ModalPassport, {
          initialValues: {
            // idToSave: params.stakeholderId ?? "",
          }
        }, 'MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_CHECK_EXP_DATE');
      },
    },
    "TASK_REGISTER_OF_DIRECTORS_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_REGISTER_OF_DIRECTORS_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_REGISTER_OF_MEMBERS_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_REGISTER_OF_MEMBERS_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_CERTIFICATE_OF_INCUMBENCY_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_CERTIFICATE_OF_INCUMBENCY_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_CERTIFICATE_OF_GOOD_STANDING_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_CERTIFICATE_OF_GOOD_STANDING_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_TRADE_LICENSE_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_TRADE_LICENSE_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_COMMERCIAL_LICENSE_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_COMMERCIAL_LICENSE_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_REGISTRY_EXTRACT_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_REGISTRY_EXTRACT_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_REGISTER_OF_PARTNERS_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_REGISTER_OF_PARTNERS_CHECK_EXP_DATE"
        );
      },
    },
    "TASK_LIMITED_PARTNERSHIP_AGREEMENT_CHECK_EXP_DATE": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {},
          "MODAL_TASK_LIMITED_PARTNERSHIP_AGREEMENT_CHECK_EXP_DATE"
        );
      },
    },
    "ASK_COMPANY_DETAILS_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          StakeholderCorporateDetailsModal,
          {
            initialValues: {
              // stakeholderId: idStakeholder,
              // stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', '')
            }
          },
          "MODAL_ASK_COMPANY_DETAILS_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_PASSPORT_LBS_KYC_INC": {
      onClick: (params) => {
        // const individualToFind = params?.additionalParams[0]?.entityId ?? null;
        // if (individualToFind !== null) {
        //   const individualData = _.find(dataStakeholder?.getStakeholder?.directors, (individual) => individual.id === individualToFind);
        openModal(ModalPassport, {
          initialValues: {
            // idToSave: individualData?.id,
            // name: individualData?.fullName
          }
        }, 'MODAL_TASK_PASSPORT_LBS_KYC_INC');
        // }
      },
    },
    "TASK_PEP_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          PEPModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_PEP_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_SOURCE_OF_FUNDS_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          SourceOfFundsModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_SOURCE_OF_FUNDS_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          RegistersOrCertificateOfIncumbencyModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_COMPANY_DETAILS_LP_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          LimitedPartnershipDetailsModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_COMPANY_DETAILS_LP_CORP_KYC_INC"
        );
      },
    },
    "TASK_CORPORATE_SIGNATORY_LP_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          CorporateSignatoryModal,
          {
            initialValues: {
              // stakeholderId: idStakeholder,
              // stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
            }
          },
          "MODAL_TASK_CORPORATE_SIGNATORY_LP_CORP_KYC_INC"
        );
      },
    },
    "TASK_CORPORATE_SIGNATORY_LP_CORP": {
      onClick: (params) => {
        openModal(
          CorporateSignatoryModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName,
            }
          },
          "MODAL_TASK_CORPORATE_SIGNATORY_LP_CORP"
        );
      },
    },
    "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP_KYC_INC": {
      onClick: (params) => {
        const filterAuthSign = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "AuthorizedSignatory");
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: filterAuthSign[0]?.entityId,
              name: filterAuthSign[0]?.name,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP_KYC_INC"
        );
      },
    },
    "TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          CertificateOfIncorporationModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP_KYC_INC"
        );
      },
    },
    "TASK_STRUCTURE_CHART_LP_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          StructureChartModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_STRUCTURE_CHART_LP_CORP_KYC_INC"
        );
      },
    },
    "TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          CertificateOfGoodStandingModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP_KYC_INC"
        );
      },
    },
    "TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          RegOfPartnersOrLimPartnershipAgreeModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP_KYC_INC"
        );
      },
    },
    "TASK_CORPORATE_SIGNATORY_TD_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          CorporateSignatoryModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_CORPORATE_SIGNATORY_TD_CORP_KYC_INC"
        );
      },
    },
    "TASK_CORPORATE_SIGNATORY_TD_CORP": {
      onClick: (params) => {
        openModal(
          CorporateSignatoryModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_CORPORATE_SIGNATORY_TD_CORP"
        );
      },
    },
    "TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          CertificateOfRegistrationModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId
            }
          },
          "MODAL_TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP_KYC_INC"
        );
      },
    },
    "TASK_STRUCTURE_CHART_TD_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          StructureChartModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId
            }
          },
          "MODAL_TASK_STRUCTURE_CHART_TD_CORP_KYC_INC"
        );
      },
    },
    "TASK_TRUST_DEED_TD_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          TrustDeedModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId
            }
          },
          "MODAL_TASK_TRUST_DEED_TD_CORP_KYC_INC"
        );
      },
    },
    "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP_KYC_INC": {
      onClick: (params) => {
        const filterAuthSign = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "AuthorizedSignatory");
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: filterAuthSign[0]?.entityId,
              name: filterAuthSign[0]?.name,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP_KYC_INC"
        );
      },
    },
    "TASK_PASSPORT_CORPORATE_DIRECTOR_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: params?.additionalParams[0]?.entityId,
              name: params?.additionalParams[0]?.name,
              jurisdictionType: params?.jurisdiction,
              needToUpdateNacionality: true,
              isForNationalityPassport: !!params?.additionalParams[0]?.nationality,
              isNationalityInProgress: params?.additionalParams[0]?.nationalityInProgress
            }
          },
          "MODAL_TASK_PASSPORT_CORPORATE_DIRECTOR_KYC_INC"
        );
      },
    },
    "TASK_UAE_ENTRY_STAMP_SEC_SIGNER": {
      onClick: (params) => {
        openModal(
          ModalUAEEntryStamp,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId
            }
          },
          "MODAL_TASK_UAE_ENTRY_STAMP_SEC_SIGNER"
        );
      },
    },
    "TASK_RENEWAL_ADGM_OTHER_PASSPORTS": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: params?.stakeholderId,
              name: params?.stakeholderName,
              isRenewal: true,
              jurisdictionType: params?.jurisdiction,
              isOldPassport: true,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_OTHER_PASSPORTS"
        );
      },
    },
    "TASK_ADD_PERSONAL_INFORMATION": {
      onClick: (params) => {
        openModal(
          PersonalInfoModal, {
            initialValues: {
              stakeholderId: params?.stakeholderId
            }
          }, "TASK_ADD_PERSONAL_INFORMATION"
        );
      },
    },
    "TASK_ADDRESSES": {
      onClick: (params) => {
        openModal(
          ModalAddAddresses,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_ADDRESSES"
        );
      },
    },
    "TASK_PEP": {
      onClick: (params) => {
        openModal(
          ModalPEP,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_PEP"
        );
      },
    },
    "TASK_SOURCE_OF_FUNDS_ADGM": {
      onClick: (params) => {
        openModal(
          ModalSourcesOfFunds,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
              jurisdictionType: "ADGM"
            }
          },
          "MODAL_TASK_SOURCE_OF_FUNDS_ADGM"
        );
      },
    },
    "TASK_SOURCE_OF_FUNDS_CAYMAN": {
      onClick: (params) => {
        openModal(
          ModalSourceOfFundsKY,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
              jurisdictionType: "CAYMAN"
            }
          },
          "MODAL_TASK_SOURCE_OF_FUNDS_CAYMAN"
        );
      },
    },
    "TASK_SOURCE_OF_FUNDS_DIFC": {
      onClick: (params) => {
        openModal(
          ModalSourcesOfFunds,
          {},
          "MODAL_TASK_SOURCE_OF_FUNDS_DIFC"
        );
      },
    },
    "TASK_SOURCE_OF_FUNDS_GENERIC_BUILD": {
      onClick: (params) => {
        openModal(
          ModalSourcesOfFunds,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_SOURCE_OF_FUNDS_GENERIC_BUILD"
        );
      },
    },
    "TASK_PROOF_OF_ADDRESS": {
      onClick: (params) => {
        openModal(
          ModalProofAddress,
          {
            paramsMutation: {
              idGroupCompany: params?.groupCompanyId,
              stakeholderId: params?.stakeholderId,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_PROOF_OF_ADDRESS"
        );
      },
    },
    "TASK_PASSPORT": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: params?.stakeholderId,
              jurisdictionType: params?.jurisdiction,
              isForNationalityPassport:true
            },
          },
          "MODAL_TASK_PASSPORT"
        );
      },
    },
    "TASK_PROFESSIONAL_BACKGROUND": {
      onClick: (params) => {
        openModal(
          ModalProfessionalHistory,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_PROFESSIONAL_BACKGROUND"
        );
      },
    },
    "TASK_EMIRATES_ID": {
      onClick: (params) => {
        openModal(
          ModalEmiratesID,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_EMIRATES_ID"
        );
      },
    },
    "TASK_UAE_RESIDENCE_VISA": {
      onClick: (params) => {
        openModal(
          ModalUAEResidenceVisa,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
              documentTypeOld: "UID_OLDPASSPORT_DOCUMENT",
              documentType: "UID_STAMP_DOCUMENT"
            }
          },
          "MODAL_TASK_UAE_RESIDENCE_VISA"
        );
      },
    },
    "TASK_UID_STAMP": {
      onClick: (params) => {
        openModal(
          ModalUIDStamp,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_UID_STAMP"
        );
      },
    },
    "TASK_UAE_ENTRY_STAMP": {
      onClick: (params) => {
        openModal(
          ModalUAEEntryStamp,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_UAE_ENTRY_STAMP"
        );
      },
    },
    "TASK_ADDRESS_FAMILY_CONTACT_PAGE": {
      onClick: (params) => {
        openModal(
          ModalAddressAndFamilyContact,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_ADDRESS_FAMILY_CONTACT_PAGE"
        );
      },
    },
    "TASK_GCC_RESIDENT_ID": {
      onClick: (params) => {
        openModal(
          ModalGCCResidentID,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_GCC_RESIDENT_ID"
        );
      },
    },
    "TASK_NATIONAL_ID": {
      onClick: (params) => {
        openModal(
          ModalNationalID,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_NATIONAL_ID"
        );
      },
    },
    "TASK_COMPANY_DETAILS_LBS_CORP": {
      onClick: (params) => {
        openModal(
          StakeholderCorporateDetailsModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_COMPANY_DETAILS_LBS_CORP"
        );
      },
    },
    "TASK_COMPANY_DIRECTORS_LBS_CORP": {
      onClick: (params) => {
        openModal(
          CompanyDirectorsModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
              // directors: _.get(dataStakeholder, 'getStakeholder.directors', [])
            }
          },
          "MODAL_TASK_COMPANY_DIRECTORS_LBS_CORP"
        );
      },
    },
    "TASK_CORPORATE_SIGNATORY_LBS_CORP": {
      onClick: (params) => {
        openModal(
          CorporateSignatoryModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_CORPORATE_SIGNATORY_LBS_CORP"
        );
      },
    },
    "TASK_PEP_LBS_CORP": {
      onClick: (params) => {
        openModal(
          PEPModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_PEP_LBS_CORP"
        );
      },
    },
    "TASK_SOURCE_OF_FUNDS_LBS_CORP": {
      onClick: (params) => {
        openModal(
          SourceOfFundsModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_SOURCE_OF_FUNDS_LBS_CORP"
        );
      },
    },
    "TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP": {
      onClick: (params) => {
        openModal(
          CertificateOfIncorporationModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP"
        );
      },
    },
    "TASK_STRUCTURE_CHART_LBS_CORP": {
      onClick: (params) => {
        openModal(
          StructureChartModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_STRUCTURE_CHART_LBS_CORP"
        );
      },
    },
    "TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP": {
      onClick: (params) => {
        openModal(
          RegistersOrCertificateOfIncumbencyModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP"
        );
      },
    },
    "TASK_OFFICIAL_DOCUMENT_LBS_CORP": {
      onClick: (params) => {
        openModal(
          OfficialDocumentModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_OFFICIAL_DOCUMENT_LBS_CORP"
        );
      },
    },
    "TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP": {
      onClick: (params) => {
        openModal(
          ArticlesOfAssociationModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP"
        );
      },
    },
    "TASK_REGISTERS_LBS_CORP": {
      onClick: (params) => {
        openModal(
          RegistersModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_REGISTERS_LBS_CORP"
        );
      },
    },
    "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LBS_CORP": {
      onClick: (params) => {
        const filterAuthSign = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "AuthorizedSignatory");
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: filterAuthSign[0]?.entityId,
              name: filterAuthSign[0]?.name,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LBS_CORP"
        );
      },
    },
    "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP": {
      onClick: (params) => {
        const filterAuthSign = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "AuthorizedSignatory");
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: filterAuthSign[0]?.entityId,
              name: filterAuthSign[0]?.name,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP"
        );
      },
    },
    "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP": {
      onClick: (params) => {
        const filterAuthSign = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "AuthorizedSignatory");
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: filterAuthSign[0]?.entityId,
              name: filterAuthSign[0]?.name,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP"
        );
      },
    },
    "TASK_PASSPORT_LBS": {
      onClick: (params) => {
        openModal(ModalPassport, {
          initialValues: {
            idToSave: params?.additionalParams[0]?.entityId,
            name: params?.additionalParams[0]?.name,
            jurisdictionType: params?.jurisdiction,
            needToUpdateNacionality: true
          }
        }, 'MODAL_TASK_PASSPORT_LBS');
      },
    },
    "TASK_COMPANY_DETAILS_LP_CORP": {
      onClick: (params) => {
        openModal(
          LimitedPartnershipDetailsModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_COMPANY_DETAILS_LP_CORP"
        );
      },
    },
    "TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP": {
      onClick: (params) => {
        openModal(
          CertificateOfIncorporationModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP"
        );
      },
    },
    "TASK_STRUCTURE_CHART_LP_CORP": {
      onClick: (params) => {
        openModal(
          StructureChartModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_STRUCTURE_CHART_LP_CORP"
        );
      },
    },
    "TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP": {
      onClick: (params) => {
        openModal(
          CertificateOfGoodStandingModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP"
        );
      },
    },
    "TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP": {
      onClick: (params) => {
        openModal(
          RegOfPartnersOrLimPartnershipAgreeModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP"
        );
      },
    },
    "TASK_TRUST_DEED_TD_CORP": {
      onClick: (params) => {
        openModal(
          TrustDeedModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_TRUST_DEED_TD_CORP"
        );
      },
    },
    "TASK_STRUCTURE_CHART_TD_CORP": {
      onClick: (params) => {
        openModal(
          StructureChartModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_STRUCTURE_CHART_TD_CORP"
        );
      },
    },
    "TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP": {
      onClick: (params) => {
        openModal(
          CertificateOfRegistrationModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP"
        );
      },
    },
    "TASK_COMPANY_SET_UP": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          ModalSetUp,
          {
            paramsMutation: {
              idGroupCompany: idGroupCompany
            }
          },
          "MODAL_TASK_COMPANY_SET_UP"
        );
      },
    },
    "TASK_CONFIRM_DIRECTORS_AND_SHAREHOLDERS": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          ModalConfirmShareholdersDirectors,
          {
            paramsMutation: {
              idGroupCompany: idGroupCompany,
              useThisJurisdiction: params?.jurisdiction
            }
          },
          "MODAL_TASK_CONFIRM_DIRECTORS_AND_SHAREHOLDERS"
        );
      },
    },
    "TASK_NATURE_OF_UNDERLYING_BUSINESS": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          NatureOfUnderlyingBusinessModal,
          {
            initialValues: {
              idGroupCompany: idGroupCompany,
            }
          },
          "MODAL_TASK_CONFIRM_DIRECTORS_AND_SHAREHOLDERS"
        );
      },
    },
    "TASK_ASSETS_AND_REVENUE": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          ModalAssetsAndSources,
          {
            paramsMutation: {
              idGroupCompany: idGroupCompany
            }
          },
          "MODAL_TASK_ASSETS_AND_REVENUE"
        );
      },
    },
    "TASK_ADGM_AUTHORISED_SIGNATORY": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          ModalAuthorisedSignatory,
          {
            initialValues: {
              idGroupCompany: idGroupCompany
            }
          },
          "MODAL_TASK_ADGM_AUTHORISED_SIGNATORY"
        );
      },
    },
    "TASK_LEASE_DETAILS": {
      onClick: (params) => {
        openModal(
          LeaseDetailsModal,
          {
            initialValues: {
              idGroupCompany: params?.company?.id
            }
          },
          "MODAL_TASK_LEASE_DETAILS"
        );
      },
    },
    "TASK_ADGM_BUSINESS_PLAN": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          BusinessPlanModal,
          {
            initialValues: {
              idGroupCompany
            }
          },
          "MODAL_TASK_ADGM_BUSINESS_PLAN"
        );
      },
    },
    "TASK_BANKING_AUTHORITY": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          ModalBankingAuthorities,
          {
            paramsMutation: {
              id: idGroupCompany
            }
          },
          "MODAL_TASK_BANKING_AUTHORITY"
        );
      },
    },
    "TASK_ACCOUNTING_RECORDS": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          ModalAccountingRecords,
          {
            paramsMutation: {
              id: idGroupCompany
            }
          },
          "MODAL_TASK_ACCOUNTING_RECORDS"
        );
      },
    },
    "TASK_DATA_PROTECTION_CONTACT": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          ModalDataProtectionContact,
          {
            paramsMutation: {
              id: idGroupCompany
            }
          },
          "MODAL_TASK_DATA_PROTECTION_CONTACT"
        );
      },
    },
    "TASK_SHARE_CAPITAL": {
      onClick: (params) => {
        handleShareCapitalModal(params);
      },
    },
    "TASK_DOC_REVIEW_RES_OF_INC_SHAREHOLDER": {
      onClick: (params) => {
        const document = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "Document");
        openModal(
          DocumentReview,
          {
            paramsMutation: {
              documentId: document[0].entityId
            }
          },
          "MODAL_TASK_DOC_REVIEW_RES_OF_INC_SHAREHOLDER"
        );
      },
    },
    "TASK_DOC_REVIEW_BUSINESS_PLAN": {
      onClick: (params) => {
        const document = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "Document");
        openModal(
          DocumentReview,
          {
            paramsMutation: {
              documentId: document[0].entityId
            }
          },
          "MODAL_TASK_DOC_REVIEW_BUSINESS_PLAN"
        );
      },
    },
    "TASK_DOC_REVIEW_DATA_PROTECTION_QUESTIONNAIRE": {
      onClick: (params) => {
        const document = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "Document");
        openModal(
          DocumentReview,
          {
            paramsMutation: {
              documentId: document[0].entityId
            }
          },
          "MODAL_TASK_DOC_REVIEW_DATA_PROTECTION_QUESTIONNAIRE"
        );
      },
    },
    "TASK_DOC_REVIEW_CORPORATE_SHAREHOLDER_RESOLUTION": {
      onClick: (params) => {
        openModal(
          DocumentReview,
          {
            paramsMutation: {
              // documentId: task.additionalParams[1].entityId
            }
          },
          "MODAL_TASK_DOC_REVIEW_CORPORATE_SHAREHOLDER_RESOLUTION"
        );
      },
    },
    "TASK_MAILING_ADDRESS": {
      onClick: (params) => {
        openModal(MailingAddressModal, {
          initialValues: {
            id: params?.company?.id
          }
        }, 'MODAL_MAILING_ADDRESS');
      },
    },
    "TASK_PURPOSE_OF_ENTITY": {
      onClick: (params) => {
        openModal(PurposeOfEntityModal, {
          initialValues: {
            id: params?.company?.id
          }
        }, 'MODAL_PURPOSE_OF_ENTITY');
      },
    },
    "TASK_NATURE_OF_BUSINESS": {
      onClick: (params) => {
        const formationCompany = _.find(params?.additionalParams, (addParam) => addParam.entityType === "FormationCompany");
        let idGroupCompany = formationCompany?.entityId ?? params?.company?.id ?? params?.groupCompanyId;
        openModal(
          ModalNatureOfBusiness,
          {
            paramsMutation: {
              mode: 'TASK',
              initialValues: {
                groupCompanyId: idGroupCompany
              }
            }
          },
          "MODAL_TASK_NATURE_OF_BUSINESS"
        );
      },
    },
    "TASK_PLACE_OF_BUSINESS": {
      onClick: (params) => {
        openModal(
          ModalPlaceOfBusiness,
          {
            isEdit: false,
            initialValues: {
              id: params?.company?.id
            }
          },
          "MODAL_TASK_PLACE_OF_BUSINESS"
        );
      },
    },
    "TASK_CORPORATE_POSITIONS": {
      onClick: (params) => {
        openModal(
          ModalCorporatePosition,
          {
            isEdit: false,
            initialValues: {
              id: params?.company?.id
            }
          },
          "MODAL_TASK_CORPORATE_POSITIONS"
        );
      },
    },
    // "TASK_RECIPIENT_FOR_NOTICE": {
    // },
    "FORMATION_ADD_COMPANY": {
      onClick: () => {
        openModal(
          AddFormationCompanyModal,
          {},
          'MODAL_TASK_ADD_COMPANIES_GET_READY_FORMATION'
        );
      }
    },
    "INCORPORATED_ADD_COMPANY": {
      onClick: () => {
        openModal(
          CreateCompanyModal,
          {},
          'MODAL_TASK_ADD_COMPANIES_GET_READY_FORMATION'
        );
      }
    },
    "TASK_ADD_COMPANIES_GET_READY": {
      onClick: handleAddGroupCompany,
    },
    // "TASK_ADD_COMPANIES_FOR_DOCS": {
    // },
    "TASK_ADD_COMPANIES_ACCELERATOR": {
      onClick: handleAddGroupCompany,
    },
    "TASK_SELECT_TOPCO_ACCELERATOR": {
      onClick: (params) => {
        openModal(
          modalTopCo,
          {},
          "MODAL_TASK_SELECT_TOPCO_ACCELERATOR"
        );
      },
    },
    "TASK_ADD_COMPANY_INFORMATION_ACCELERATOR": {
      onClick: (params) => {
        openModal(
          ModalDetailCompany,
          {
            paramsMutation: {
              groupCompanyId: params?.additionalParams[0]?.entityId
            },
          },
          "MODAL_TASK_ADD_COMPANY_INFORMATION_ACCELERATOR"
        );
      },
    },
    "TASK_ADD_CONSTITUTIONAL_DOCUMENTS_ACCELERATOR": {
      onClick: (params) => {
        openModal(
          ModalConstitutionalDocuments,
          {
            initialValues: {
              groupCompanyId: params?.additionalParams[0]?.entityId
            },
          },
          "MODAL_TASK_ADD_CONSTITUTIONAL_DOCUMENTS_ACCELERATOR"
        );
      },
    },
    // "TASK_ADD_COMPANY_EQUITY_ACCELERATOR":{
    //   onClick: (params) => {
    //     openModal(
    //       ModalConstitutionalDocuments,
    //       {},
    //       "MODAL_TASK_ADD_COMPANY_EQUITY_ACCELERATOR"
    //     );
    //   },
    // },
    "TASK_ADD_EQUITY_POSITIONS": {
      onClick: (params) => {
        openModal(
          AddEquityTaskModal,
          {},
          "MODAL_TASK_ADD_EQUITY_POSITIONS"
        );
      },
      onDelete: (params) => {
        openModal(
          ModalRemoveTask,
          {
            registeredTaskData: {
              // entityId: idStakeholder,
              taskCode: 'TASK_ADD_EQUITY_POSITIONS_DELETE'
            }
          }
        );
      },
    },
    "TASK_ADD_FOUNDERS_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalIpAndGenerate,
          {
            paramsMutation: {
              ...params,
              type: "FOUNDER_SERVICES_AGREEMENT"
            }
          },
          "MODAL_TASK_ADD_FOUNDERS_AGREEMENT"
        );
      },
    },
    "TASK_ADD_IP_ASSIGNMENT_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalIpAndGenerate,
          {
            paramsMutation: {
              ...params,
              type: "IP_ASSIGNMENT"
            }
          },
          "MODAL_TASK_ADD_IP_ASSIGNMENT_AGREEMENT"
        );
      },
    },
    "TASK_ADD_SHARES": {
      onClick: (params) => {
        openModal(
          ModalAddShare,
          {
            ...params,
            initialValues: {
              // id: params.stakeholderId,
              type: 'stakeholder'
            }
          },
          "MODAL_TASK_ADD_SHARES"
        );
      },
    },
    "TASK_ADD_SHARES_OPTIONS": {
      onClick: (params) => {
        openModal(
          ModalShareOptions,
          {
            initialValues: {
              ...params,
              // stakeholderId: idStakeholder
            }
          },
          "MODAL_TASK_ADD_SHARES_OPTIONS"
        );
      },
    },
    "TASK_ADD_CONVERTIBLES": {
      onClick: (params) => {
        openModal(
          ModalAddConvertible,
          {
            initialValues: {
              // id: params.stakeholderId,
              type: 'stakeholder'
            }
          },
          "MODAL_TASK_ADD_CONVERTIBLES"
        );
      },
    },
    "TASK_ADD_WARRANTS": {
      onClick: (params) => {
        openModal(
          ModalAddWarrant,
          {
            initialValues: {
              // id: params.stakeholderId,
              type: 'stakeholder'
            }
          },
          "MODAL_TASK_ADD_WARRANTS"
        );
      },
    },
    "TASK_ADD_EMPLOYEE_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalEmployeeDoc,
          {
            paramsMutation: {
              ...params,
              type: 'EMPLOYMENT_AGREEMENT'
            }
          },
          "MODAL_TASK_ADD_EMPLOYEE_AGREEMENT"
        );
      },
    },
    "TASK_ADD_CONSULTANT_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalIpAndGenerate,
          {
            paramsMutation: {
              ...params,
              type: "CONSULTANT_AGREEMENT"
            }
          },
          "MODAL_TASK_ADD_CONSULTANT_AGREEMENT"
        );
      },
    },
    "TASK_ADD_ADVISOR_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalIpAndGenerate,
          {
            paramsMutation: {
              ...params,
              type: "ADVISOR_AGREEMENT"
            }
          },
          "MODAL_TASK_ADD_ADVISOR_AGREEMENT"
        );
      },
    },
    "TASK_ADD_SHARE_OPTION_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalIpAndGenerate,
          {
            paramsMutation: {
              ...params,
              type: "GRANT_AGREEMENT"
            }
          },
          "MODAL_TASK_ADD_SHARE_OPTION_AGREEMENT"
        );
      },
    },
    "TASK_ADD_CONVERTIBLE_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalConvertibleDoc,
          {
            paramsMutation: {
              ...params,
            }
          },
          "MODAL_TASK_ADD_CONVERTIBLE_AGREEMENT"
        );
      },
    },
    "TASK_ADD_SHARE_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalIpAndGenerate,
          {
            paramsMutation: {
              ...params,
              type: "REGISTER_OF_SHARES"
            }
          },
          "MODAL_TASK_ADD_SHARE_AGREEMENT"
        );
      },
    },
    "TASK_ADD_WARRANT_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalIpAndGenerate,
          {
            paramsMutation: {
              ...params,
              type: "WARRANT_AGREEMENT"
            }
          },
          "MODAL_TASK_ADD_WARRANT_AGREEMENT"
        );
      },
    },
    'TASK_COMPLETE_STAKEHOLDERS_ACCELERATOR': {
      onClick: (params) => {
        openModal(
          ModalCompleteStakeholders,
          {
            ...params
          },
          "MODAL_TASK_COMPLETE_STAKEHOLDERS_ACCELERATOR"
        );
      },
    },
    'TASK_COMPLETE_QUESTIONNAIRE_ACCELERATOR': {
      onClick: (params) => {
        openModal(
          ModalQuestionnaire,
          {
            ...params
          },
          "MODAL_TASK_COMPLETE_QUESTIONNAIRE_ACCELERATOR"
        );
      },
    },
    'TASK_ADD_OR_GENERATE_1_FOUNDER_AGREEMENT_ACCELERATOR': {
      onClick: (params) => {
        openModal(
          AddFounderServicesAgrementModal,
          {
            ...params
          },
          "MODAL_TASK_ADD_OR_GENERATE_1_FOUNDER_AGREEMENT_ACCELERATOR"
        );
      },
    },
    'TASK_ADD_OR_GENERATE_N_FOUNDERS_AGREEMENT_ACCELERATOR': {
      onClick: (params) => {
        openModal(
          AddFounderAgrementModal,
          {},
          "MODAL_TASK_ADD_OR_GENERATE_N_FOUNDERS_AGREEMENT_ACCELERATOR"
        );
      },
    },
    'TASK_ADD_OR_GENERATE_BOARD_RESOLUTION_ACCELERATOR': {
      onClick: (params) => {
        openModal(
          ModalBoardResolution,
          {
            ...params
          },
          "MODAL_TASK_ADD_OR_GENERATE_BOARD_RESOLUTION_ACCELERATOR"
        );
      },
    },
    'TASK_CONFIRM_DOCUMENTS_ACCELERATOR': {
      onClick: (params) => {
        openModal(
          ModalConfirmDocuments,
          {
            ...params
          },
          "MODAL_TASK_CONFIRM_DOCUMENTS_ACCELERATOR"
        );
      },
    },
    'TASK_ADD_DIRECTORS_GET_READY': {
      onClick: (params) => {
        openModal(
          ModalDirector,
          {
            ...params
          },
          "MODAL_TASK_ADD_DIRECTORS_GET_READY"
        );
      },
    },
    'TASK_ADD_FOUNDERS_GET_READY': {
      onClick: (params) => {
        openModal(
          ModalFounder,
          {
            ...params
          },
          "MODAL_TASK_ADD_FOUNDERS_GET_READY"
        );
      },
    },
    'TASK_ADD_EMPLOYEES_GET_READY': {
      onClick: (params) => {
        openModal(
          ModalEmployee,
          {
            ...params
          },
          "MODAL_TASK_ADD_EMPLOYEES_GET_READY"
        );
      },
      onDelete: (params) => {
        openModal(
          ModalRemoveEssential,
          {
            role: 'EMPLOYEE'
          },
          "MODAL_TASK_ADD_EMPLOYEES_GET_READY_DELETE"
        );
      },
    },
    'TASK_ADD_CONSULTANTS_GET_READY': {
      onClick: (params) => {
        openModal(
          ModalConsultant,
          {
            ...params
          },
          "MODAL_TASK_ADD_CONSULTANTS_GET_READY"
        );
      },
      onDelete: (params) => {
        openModal(
          ModalRemoveEssential,
          {
            role: 'CONSULTANT'
          },
          "MODAL_TASK_ADD_CONSULTANTS_GET_READY_DELETE"
        );
      },
    },
    'TASK_ADD_ADVISORS_GET_READY': {
      onClick: (params) => {
        openModal(
          ModalAdvisor,
          {
            ...params
          },
          "MODAL_TASK_ADD_ADVISORS_GET_READY"
        );
      },
      onDelete: (params) => {
        openModal(
          ModalRemoveEssential,
          {
            role: 'ADVISOR'
          },
          "MODAL_TASK_ADD_ADVISORS_GET_READY_DELETE"
        );
      },
    },
    'TASK_ADD_INVESTORS_GET_READY': {
      onClick: (params) => {
        openModal(
          ModalInvestor,
          {
            ...params
          },
          "MODAL_TASK_ADD_INVESTORS_GET_READY"
        );
      },
      onDelete: (params) => {
        openModal(
          ModalRemoveEssential,
          {
            role: 'INVESTOR'
          },
          "MODAL_TASK_ADD_INVESTORS_GET_READY_DELETE"
        );
      },
    },
    'TASK_PENDING_GROUP_COMPANY_FORMATION': {
      onClick: (params) => handleViewMore(params),
    },
    'TASK_PENDING_GROUP_COMPANY_INCORPORATED': {
      onClick: (params) => handleViewMore(params),
    },
    'TASK_PENDING_GROUP_COMPANY_INCORPORATED_ACCELERATOR': {
      onClick: (params) => handleViewMore(params),
    },
    'ADGM_RENEWAL_GROUP_COMPANY_TASKS': {
      onClick: (params) => handleViewMore(params),
    },
    'TASK_PENDING_STAKEHOLDER_INCORPORATED': {
      onClick: (params) => handleViewMoreStakeholder(params),
    },
    'TASK_PENDING_STAKEHOLDER_INCORPORATED_ACCELERATOR': {
      onClick: (params) => handleViewMoreStakeholder(params),
    },
    'TASK_PENDING_STAKEHOLDER_FORMATION': {
      onClick: (params) => handleViewMoreStakeholder(params),
    },
    'ADGM_RENEWAL_STAKEHOLDERS_TASKS': {
      onClick: (params) => handleViewMoreStakeholder(params),
    },
    'TASK_ADD_COMPANY_FORMATION': {
      onClick: handleAddGroupCompany,
    },
    // 'TASK_ADD_DIRECTORS_FORMATION': {
    //   onClick: (params) => {
    //     if (params?.company?.jurisdictionType === "DELAWARE") {
    //       return openModal(
    //         DirectorDelawareModal,
    //         {
    //           initialValues: {
    //             company: params?.company
    //           }
    //         },
    //         "MODAL_TASK_ADD_DIRECTORS_FORMATION_DELAWARE"
    //       );
    //     } else {
    //       return openModal(
    //         DirectorV2Modal,
    //         {
    //           initialValues: {
    //             company: params?.company
    //           }
    //         },
    //         "MODAL_TASK_ADD_DIRECTORS_FORMATION_V2"
    //       );
    //     }
    //   },
    // },
    'TASK_ADD_DIRECTORS_DELAWARE_BUY_REFER': {
      onClick: (params) => {
        return openModal(
          DirectorDelawareModal,
          {
            initialValues: {
              company: params?.company
            }
          },
          "MODAL_TASK_ADD_DIRECTORS_FORMATION_DELAWARE"
        );
      },
    },
    'TASK_ADD_DIRECTORS_ADGM_BUY_REFER': {
      onClick: (params) => {
        return openModal(
          DirectorV2Modal,
          {
            initialValues: {
              company: params?.company
            }
          },
          "MODAL_TASK_ADD_DIRECTORS_FORMATION_V2"
        );
      },
    },
    // 'TASK_ADD_SHAREHOLDERS_FORMATION': {
    //   onClick: (params) => {
    //     if (params?.company?.jurisdictionType === "DELAWARE") {
    //       return openModal(
    //         ShareholderDelawareModal,
    //         {
    //           initialValues: {
    //             company: params?.company
    //           }
    //         },
    //         "MODAL_TASK_ADD_SHAREHOLDERS_FORMATION_DELAWARE"
    //       );
    //     } else {
    //       return openModal(
    //         ShareholderV2Modal,
    //         {
    //           initialValues: {
    //             company: params?.company
    //           }
    //         },
    //         "MODAL_TASK_ADD_SHAREHOLDERS_FORMATION_V2"
    //       );
    //     }
    //   },
    // },
    'TASK_ADD_SHAREHOLDERS_DELAWARE_BUY_REFER': {
      onClick: (params) => {
        return openModal(
          ShareholderDelawareModal,
          {
            initialValues: {
              company: params?.company
            }
          },
          "MODAL_TASK_ADD_SHAREHOLDERS_FORMATION_DELAWARE"
        );
      }
    },
    'TASK_ADD_SHAREHOLDERS_ADGM_BUY_REFER': {
      onClick: (params) => {
        return openModal(
          ShareholderV2Modal,
          {
            initialValues: {
              company: params?.company
            }
          },
          "MODAL_TASK_ADD_SHAREHOLDERS_FORMATION_V2"
        );
      }
    },
    'TASK_ADD_BUY_PACKAGE_BUY_REFER': {
      onClick: (params) => {
        openModal(
          PayFormationBundleModal,
          {
            initialValues: {
              groupCompanyId: params?.groupCompanyId
            }
          },
          "MODAL_TASK_ADD_BUY_PACKAGE_BUY_REFER"
        );
      },
    },
    'TASK_ADD_SUBMIT': {
      onClick: (params) => {
        openModal(
          ModalSubmittedFormation,
          {
            paramsMutation: {
              groupCompanyId: params?.groupCompanyId
            }
          },
          "MODAL_TASK_ADD_SUBMIT"
        );
      },
    },
    'TASK_ADD_DIRECTORS_KY_BUY_REFER': {
      onClick: (params) => {
        return openModal(
          DirectorV2Modal,
          {
            initialValues: {
              company: params?.company
            }
          },
          "MODAL_TASK_ADD_DIRECTORS_KY_BUY_REFER"
        );
      },
    },
    'TASK_ADD_SHAREHOLDERS_KY_BUY_REFER': {
      onClick: (params) => {
        return openModal(
          ShareholderV2Modal,
          {
            initialValues: {
              company: params?.company
            }
          },
          "MODAL_TASK_ADD_SHAREHOLDERS_KY_BUY_REFER"
        );
      },
    },
    'TASK_RECIPIENT_FOR_NOTICES': {
      onClick: (params) => openModal(ModalRecipientForNotices, {
        initialValues: {
          id: params?.company?.id
        }
      }, "MODAL_RECIPIENT_FOR_NOTICES"),
    },
    START_RENEWAL_PROCESS: {
      onClick: (params, callback) => {
        const jurisdiction = params?.company?.jurisdictionType;
        if (jurisdiction === "ADGM") {
          openModal(
            StartRenewalProcessModal,
            {
              initialValues: {
                company: {
                  id: params?.company?.id,
                  name: params?.company?.name,
                  licenceType: params?.company?.companyType,
                  callback
                },
              },
            },
            "MODAL_START_ADGM_RENEWAL"
          );
        } else if (jurisdiction === "CAYMAN") {
          openModal(
            StartRenewalModal,
            {
              initialValues: {
                company: params?.company,
                callback
              },
            },
            "MODAL_START_RENEWAL"
          );
        }
      },
    },
    "TASK_ONLY_PINCAS_PAY": {
      onClick: (params) => {
        handlePayPincas(params?.groupCompanyId, params?.jurisdiction);
      },
    },
    "TASK_ONLY_PINCAS_SUBMIT": {
      onClick: (params) => {
        openModal(PayFormationBundleModal, {
          initialValues: {
            groupCompanyId: params?.groupCompanyId,
            productCode: getProductCodeByJurisdictionType(params?.jurisdiction),
            needFirstContact: true,
            disabledPayCart: false,
            isPincasSubmit: !featureFlags['payment-process-removal-pincas-and-renewals'],
            isPincasSubmitPaymentRemoved: featureFlags['payment-process-removal-pincas-and-renewals'],
          }
        }, 'MODAL_TASK_ONLY_PINCAS_SUBMIT');
      }
    },
    "TASK_RENEWAL_ADGM_PAY": {
      onClick: (params) => {
        handlePayAdgm(params?.groupCompanyId, params?.jurisdiction);
      }
    },
    "TASK_RENEWAL_ADGM_SUBMIT": {
      onClick: (params) => {
        openModal(PayFormationBundleModal, {
          initialValues: {
            groupCompanyId: params?.groupCompanyId,
            productCode: Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART,
            needFirstContact: true,
            disabledPayCart: false,
            isPincasSubmit: !featureFlags['payment-process-removal-pincas-and-renewals'],
            isPincasSubmitPaymentRemoved: featureFlags['payment-process-removal-pincas-and-renewals'],
          }
        }, 'MODAL_TASK_RENEWAL_ADGM_SUBMIT');
      }
    },
    "TASK_ADD_PERSONAL_INFORMATION_KYC_INC": {
      onClick: (params) => {
        openModal(
          PersonalInfoModal, {
            initialValues: {
              stakeholderId: params?.stakeholderId
            }
          }, "MODAL_TASK_ADD_PERSONAL_INFORMATION_KYC_INC"
        );
      },
    },
    "TASK_PEP_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalPEP,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_PEP_KYC_INC"
        );
      },
    },
    "TASK_SOURCE_OF_FUNDS_ADGM_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalSourcesOfFunds,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_SOURCE_OF_FUNDS_ADGM_KYC_INC"
        );
      },
    },
    "TASK_PROOF_OF_ADDRESS_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalProofAddress,
          {
            paramsMutation: {
              idGroupCompany: params?.groupCompanyId,
              stakeholderId: params?.stakeholderId,
              jurisdictionType: params?.additionalParams ? (params?.additionalParams[0]?.jurisdictionType || params?.jurisdiction) : params?.jurisdiction
            }
          },
          "MODAL_TASK_SOURCE_OF_FUNDS_CAYMAN_KYC_INC"
        );
      },
    },
    "TASK_PASSPORT_KYC_INC": {
      onClick: (params) => {
        let defaultNationality = params?.additionalParams[0]?.nationality;
        if (params?.additionalParams[0].nationalityInProgress) {
          defaultNationality = params?.additionalParams[0].nationalityInProgress;
        }
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: params?.stakeholderId,
              jurisdictionType: params?.jurisdiction,
              isForNationalityPassport: params?.jurisdiction === "CAYMAN",
              defaultNationality: defaultNationality
            },
          },
          "MODAL_TASK_PASSPORT"
        );
      },
    },
    "TASK_PROFESSIONAL_BACKGROUND_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalProfessionalHistory,
          {
            paramsMutation: {
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_PROFESSIONAL_BACKGROUND_KYC_INC"
        );
      },
    },
    "TASK_SOURCE_OF_FUNDS_CAYMAN_KYC_INC": {
      onClick: (params) => {
        openModal(
          ModalSourceOfFundsKY,
          {
            paramsMutation: {
              jurisdictionType: params?.jurisdiction,
              stakeholderId: params?.stakeholderId,
            }
          },
          "MODAL_TASK_SOURCE_OF_FUNDS_CAYMAN_KYC_INC"
        );
      },
    },
    "TASK_ADD_STAKEHOLDER_CORPORATE_NOTICES": {
      onClick: (params) => {
        openModal(
          ModalNotices,
          {
            paramsMutation: params
          },
          "MODAL_TASK_ADD_STAKEHOLDER_CORPORATE_NOTICES"
        );
      },
    },
    "TASK_ADD_STAKEHOLDER_CORPORATE_INFORMATION": {
      onClick: (params) => {
        openModal(
          ModalPersonalInfoCorporate,
          {
            paramsMutation: params
          },
          "MODAL_TASK_ADD_STAKEHOLDER_CORPORATE_INFORMATION"
        );
      },
    },
    "TASK_ADD_AUTHORISED_SIGNATORIES": {
      onClick: (params) => {
        openModal(
          ModalAuthorisedSignatories,
          {
            paramsMutation: params
          },
          "MODAL_TASK_ADD_AUTHORISED_SIGNATORIES"
        );
      },
    },
    "TASK_COMPANY_DETAILS_LBS_CORP_KYC_INC": {
      onClick: (params) => openModal(StakeholderCorporateDetailsModal, {
        initialValues: {
          stakeholderId: params?.stakeholderId,
          stakeholderName: params?.stakeholderName
        }
      }, 'MODAL_TASK_COMPANY_DETAILS_LBS_CORP_KYC_INC')
    },
    "TASK_COMPANY_DIRECTORS_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          CompanyDirectorsModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_COMPANY_DIRECTORS_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          CorporateSignatoryModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          CertificateOfIncorporationModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_STRUCTURE_CHART_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          StructureChartModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_STRUCTURE_CHART_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_OFFICIAL_DOCUMENT_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          OfficialDocumentModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_OFFICIAL_DOCUMENT_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          ArticlesOfAssociationModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_REGISTERS_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        openModal(
          RegistersModal,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName
            }
          },
          "MODAL_TASK_REGISTERS_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC": {
      onClick: (params) => {
        const filterAuthSign = _.filter(params?.additionalParams, (addParam) => addParam.entityType === "AuthorizedSignatory");
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: filterAuthSign[0]?.entityId,
              name: filterAuthSign[0]?.name,
              jurisdictionType: params?.jurisdiction
            }
          },
          "MODAL_TASK_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC"
        );
      },
    },
    "TASK_ADD_DIRECTORS_ACCELERATOR": {
      onClick: (params) => {
      },
    },
    "TASK_ADD_SUBMIT_ACCELERATOR": {
      onClick: (params) => {
        openModal(
          ModalSubmitApplication,
          {},
          "MODAL_TASK_ADD_SUBMIT_ACCELERATOR"
        );
      },
    },
    "TASK_ADD_SHARE_INCENTIVE_PLAN_AGREEMENT": {
      onClick: (params) => {
        openModal(
          ModalSIPDoc,
          { paramsMutation: { groupCompanyId: params?.company?.id, ...params } },
          "MODAL_UNDER_CONTRUCTION"
        );
      }
    },
    "TASK_RENEWAL_KY_CONFIRM_INMEDIATE_PARENTS": {
      onClick: (params) => {
        openModal(
          ImmediateParentsModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id
            }
          },
          "MODAL_TASK_RENEWAL_KY_CONFIRM_INMEDIATE_PARENTS"
        );
      },
    },
    "TASK_RENEWAL_KY_CONFIRM_ULTIMATE_PARENTS": {
      onClick: (params) => {
        openModal(
          ConfirmUltimateParentModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id,
            },
          },
          "MODAL_TASK_RENEWAL_KY_CONFIRM_ULTIMATE_PARENTS"
        );
      },
    },
    "TASK_RENEWAL_KY_CONFIRM_ULTIMATE_BENEFICIAL_OWNERS": {
      onClick: (params) => {
        openModal(
          BeneficialOwnersModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id,
            },
          },
          "MODAL_TASK_RENEWAL_KY_CONFIRM_ULTIMATE_BENEFICIAL_OWNERS"
        );
      },
    },
    "TASK_RENEWAL_KY_CONFIRM_RENEWAL_INFORMATION": {
      onClick: (params) => {
        openModal(
          ConfirmRenewalInformationModal,
          {
            initialValues: {
              groupCompanyId: params?.company?.id,
            }
          },
          "MODAL_TASK_RENEWAL_KY_CONFIRM_RENEWAL_INFORMATION"
        );
      },
    },
  }), []);

  const dictionary2 = useMemo(() => {
    return {
      ...dictionary,
      ...functionsRenewal,
      ...functionsEquity,
      ...functionsDocument
    }
  }, []);

  return dictionary2;
}


export default useFunctionsTask;
