import React, {FC} from 'react';
import { StepProps } from '../../../../../../../generic/components/Wizard/Step';
import useCustomMachine from '../../../../../../../generic/hooks/useCustomMachine';
import Button from '../../../../../../../generic/components/Buttons/Button';
import useTranslate from '../../../../../../../generic/hooks/useTranslate';
import ModalTourTemplate from '../../../../../../../../components/templates/ModalTourTemplate';
import image from '../../../../../../../../images/Whats-next.svg'
import { useLD } from 'src/providers/LDProvider';
import './classes.module.scss';
/**
 - Engage Concierge Done Form
 **/
const WhatsNextStep: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { featureFlags } = useLD();
  const isPaymentProcessingRemoved = featureFlags['payment-processing-removal'];
  const { next, state, prev } = useCustomMachine();
  const { steps, actualStep } = state.context.paramsData;

  const handlePrevious = () => {
    prev();
  }

  const handleNext = () => {
    next();
  }

  return (
    <>
      <ModalTourTemplate
        title={
          isPaymentProcessingRemoved
            ? translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP_PAYMENT_REMOVED.TITLE')
            : translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP.TITLE')
        }
        subtitle={
          isPaymentProcessingRemoved
            ? translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP_PAYMENT_REMOVED.SUBTITLE')
            : translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP.SUBTITLE')

        }
        image={<img src={image} />}
        buttons={[
          <Button onClick={handlePrevious} variant="secondary">
            {isPaymentProcessingRemoved
              ? translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP_PAYMENT_REMOVED.BUTTON_PREVIOUS')
              : translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP.BUTTON_PREVIOUS')}
          </Button>,
          <Button onClick={handleNext} variant="primary">
            {isPaymentProcessingRemoved
              ? translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP_PAYMENT_REMOVED.BUTTON_NEXT')
              : translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP.BUTTON_NEXT')}
          </Button>]}
        actualStep={actualStep}
        subtitleVariant={isPaymentProcessingRemoved ? 'l' : 'xxl'}
        steps={steps}
      />
    </>
  )
}

export default WhatsNextStep;
