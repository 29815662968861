import { useMemo } from 'react';
import us from '../../langs/us';
import { useLD } from '../../providers/LDProvider';

const { CATEGORIES_TITLES } = us;

export function useCategoriesTitles() {
  const { featureFlags } = useLD();
  const isEnabled = featureFlags['individual-corporate-kyc-document-subcategories'];

  return useMemo(() => {
    if (isEnabled) {
      return {
        ...CATEGORIES_TITLES,
        kycDocuments: CATEGORIES_TITLES.kycDocumentsIndividual,
      }
    }
    return CATEGORIES_TITLES;
  }, [isEnabled]);
}
