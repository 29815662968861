import Machine from "../../../../generic/components/Machine/MachineClass";
import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";

const confirmDirectorsShareholders = (data): boolean => 
  data.confirm_directors_shareholders_form.isAllShareholdersAndDirectorsConfirmed;

const jurisdictionCheckers = {
  ADGM: (paramsData) => paramsData.useThisJurisdiction === "ADGM",
  CAYMAN: (paramsData) => paramsData.useThisJurisdiction === "CAYMAN",
};

const continueConfirm = ({ data, paramsData }): boolean => {
  const isJurisdictionValid = jurisdictionCheckers[paramsData.useThisJurisdiction];
  return isJurisdictionValid 
    ? confirmDirectorsShareholders(data) && isJurisdictionValid(paramsData)
    : false;
};

const notContinueConfirm = ({ data, paramsData }): boolean => !continueConfirm({ data, paramsData });

const machineDefinition = {
  initial: "confirm_directors_shareholders_form",
  states: {
    confirm_directors_shareholders_form: {
      on: {
        NEXT: { target: "loading_confirm", actions: "setContext" },
        CANCEL: "finish_confirm_directors_shareholders",
      },
    },
    loading_confirm: {
      always: [
        { cond: "notContinueConfirm", target: "finish_confirm_directors_shareholders" },
        { cond: "continueConfirm", target: "select_director_share_certificate" },
      ],
    },
    select_director_share_certificate: {
      on: {
        NEXT: { target: "loading_confirm_next", actions: "setContext" },
        PREVIOUS: "confirm_directors_shareholders_form",
        CANCEL: "finish_confirm_directors_shareholders",
      },
    },
    loading_confirm_next: {
      always: [
        { cond: "isADGMJurisdiction", target: "select_adgm_designated_contact_form" },
        { cond: "isCAYMANJurisdiction", target: "finish_confirm_directors_shareholders" },
        { cond: "notContinueConfirm", target: "finish_confirm_directors_shareholders" },
      ],
    },
    select_adgm_designated_contact_form: {
      on: {
        NEXT: { target: "finish_confirm_directors_shareholders", actions: "setContext" },
        PREVIOUS: "select_director_share_certificate",
        CANCEL: "finish_confirm_directors_shareholders",
      },
    },
    finish_confirm_directors_shareholders: {
      type: "final",
      entry: "final",
    },
    onDone: {
      actions: "stopMachine"
    },
  },
};

const options = {
  guards: {
    continueConfirm,
    notContinueConfirm,
    isADGMJurisdiction: ({ paramsData }) => jurisdictionCheckers.ADGM(paramsData),
    isCAYMANJurisdiction: ({ paramsData }) => jurisdictionCheckers.CAYMAN(paramsData),
  },
};

const steps = {
  confirm_directors_shareholders_form: FirstStep,
  select_director_share_certificate: SecondStep,
  select_adgm_designated_contact_form: ThirdStep,
};

class MachineConfirmShareholdersDirectors extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }

  getParamsData({ paramsMutation }: { paramsMutation: object }) {
    return { ...paramsMutation };
  }

  getInitialValues({ initialValues = {} }: { initialValues: unknown }) {
    return initialValues;
  }
}

export default MachineConfirmShareholdersDirectors;
