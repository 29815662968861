import { createMachine } from "xstate";

const machine = createMachine({
  id: "PayFormationBundle",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "EvaluateCart",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    EvaluateCart: {
      always: [
        {
          cond: "isPincasSubmit",
          target: "ThanksForYourPaymentPincasFormStep",
        },
        {
          cond: "isPincas",
          target: "getCart",
        },
        {
          cond: "isNoPincas",
          target: "EvaluateProductCode",
        },
      ],
    },
    getCart: {
      invoke: {
        src: "getCart",
        id: "getCart",
        onDone: [
          {
            target: "CartStep",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    CartStep: {
      on: {
        NEXT: [
          {
            cond: "isPincasSubmitPaymentRemoved",
            target: "ThanksForYourPaymentPincasFormStep",
            actions: ["setContextAfterStep"],
          },
          {
            target: "EvaluateProductCode",
            actions: ["setContextAfterStep"],
          },

        ],
        PREV: {
          target: "getCart",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateProductCode: {
      always: [
        {
          cond: "hasProductCode",
          target: "getProduct",
        },
        {
          cond: "noHasProductCode",
          target: "getProducts",
        },
      ],
    },
    getProductByProductCode: {
      invoke: {
        src: "getProductByProductCode",
        id: "getProductByProductCode",
        onDone: [
          {
            target: "getStakeholders",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    getProducts: {
      invoke: {
        src: "getProducts",
        id: "getProducts",
        onDone: [
          {
            target: "EvaluateAlreadyPaid",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    EvaluateAlreadyPaid: {
      always: [
        {
          cond: "isAlreadyPaid",
          target: "EvaluateThanks",
        },
        {
          target: "EvaluateSaveWithScale",
        },
      ],
    },
    EvaluateSaveWithScale: {
      always: [
        {
          cond: "hasDisabledBundle",
          target: "getProduct",
        },
        {
          cond: "isADGM_SPV_Formation",
          target: "SaveWithScaleADGM_SPVFormStep",
        },
        {
          cond: "isCaymanFormation",
          target: "SaveWithScaleCaymanFormStep",
        },
        {
          cond: "isDelawareFormation",
          target: "SaveWithScaleDelawareFormStep",
        },
      ],
    },
    SaveWithScaleADGM_SPVFormStep: {
      on: {
        NEXT: {
          target: "getProduct",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SaveWithScaleCaymanFormStep: {
      on: {
        NEXT: {
          target: "getProduct",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SaveWithScaleDelawareFormStep: {
      on: {
        NEXT: {
          target: "getProduct",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    getProduct: {
      invoke: {
        src: "getProduct",
        id: "getProduct",
        onDone: [
          {
            target: "getSource",
            actions: ["setContextAfterInvoke"],
          },
        ],
        onError: [
          {
            target: "EvaluateSaveWithScale",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    getSource: {
      invoke: {
        src: "getSource",
        id: "getSource",
        onDone: [
          {
            target: "getStakeholders",
            actions: ["setContextAfterInvoke"],
          },
        ],
        onError: [
          {
            target: "EvaluateSaveWithScale",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    getStakeholders: {
      invoke: {
        src: "getStakeholders",
        id: "getStakeholders",
        onDone: [
          {
            target: "ChooseAPayeeFormStep",
            actions: ["setContextAfterInvoke"],
          },
        ],
        onError: [
          {
            target: "EvaluateSaveWithScale",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    ChooseAPayeeFormStep: {
      on: {
        PREV_RENEWAL: {
          target: "CartStep",
          actions: ["setContextAfterStep"],
        },
        PREV: {
          target: "EvaluateSaveWithScale",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "PaymentFormStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    PaymentFormStep: {
      on: {
        PREV: {
          target: "ChooseAPayeeFormStep",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "EvaluateThanks",
          actions: ["setContextAfterStep", "removeErrorCard"],
        },
        GO_BANK_TRANSFER: {
          target: "BankInformation",
          actions: ["setContextAfterStep", "removeErrorCard"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    
    UploadDocumentTransfer: {
      on: {
        PREV: {
          target: "EvaluateBankTransferStep",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "EvaluateThanks",
          actions: ["setContextAfterStep", "removeErrorCard"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
 
    EvaluateThanks: {
      always: [
        {
          cond: "isPincas",
          target: "ThanksForYourPaymentPincasFormStep",
        },
        {
          cond: "isADGM_TSL_Formation",
          target: "ThanksForYourPaymentADGMFormStep",
        },
        {
          cond: "isADGM_SPV_Formation",
          target: "ThanksForYourPaymentADGMFormStep",
        },
        {
          cond: "isCaymanFormation",
          target: "ThanksForYourPaymentCaymanFormStep",
        },
        {
          cond: "isDelawareFormation",
          target: "ThanksForYourPaymentDelawareFormStep",
        },
      ],
    },
    // EvaluateSubmit: {
    //   always: [
    //     {
    //       cond: "isPincas",
    //       target: "ThanksForYourPaymentPincasFormStep",
    //     },
    //     {
    //       cond: "isADGM_TSL_Formation",
    //       target: "ThanksForYourPaymentADGMFormStep",
    //     },
    //     {
    //       cond: "isADGM_SPV_Formation",
    //       target: "ThanksForYourPaymentADGMFormStep",
    //     },
    //     {
    //       cond: "isCaymanFormation",
    //       target: "ThanksForYourPaymentCaymanFormStep",
    //     },
    //     {
    //       cond: "isDelawareFormation",
    //       target: "ThanksForYourPaymentDelawareFormStep",
    //     },
    //   ],
    // },
    PreSubmitADGMFormStep: {
      on: {
        NEXT: {
          target: "SubmitADGMFormStep",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SubmitADGMFormStep: {
      on: {
        PREV: {
          target: "PreSubmitADGMFormStep",
        },
        NEXT: {
          target: "SubmitingADGMStep",
        },
      },
    },
    ThanksForYourPaymentPincasFormStep: {
      on: {
        NEXT: {
          target: "SubmitingPincasStep",
        },
      },
    },
    ThanksForYourPaymentCaymanFormStep: {
      on: {
        NEXT: {
          target: "SubmitingADGMStep",
        },
      },
    },
    ThanksForYourPaymentDelawareFormStep: {
      on: {
        NEXT: {
          target: "SubmitingADGMStep",
        },
      },
    },
    ThanksForYourPaymentADGMFormStep: {
      on: {
        NEXT: {
          target: "SubmitingADGMStep",
        },
      },
    },
    SubmitingPincasStep: {
      invoke: {
        src: "SubmitingPincasStep",
        id: "SubmitingPincasStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "EvaluateThanks",
          },
        ],
      },
    },
    SubmitingADGMStep: {
      invoke: {
        src: "SubmitingADGMStep",
        id: "SubmitingADGMStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "EvaluateThanks",
          },
        ],
      },
    },
    PayTimeOverdue: {
      on: {
        NEXT: {
          target: "PaymentFormStep",
        },
        PREV: {
          target: "PaymentFormStep",
        },
      },
    },
    BankInformation:{
      invoke: {
        src: "getBankInformation",
        id: "getBankInformation",
        onDone: [
          {
            target: "EvaluateBankTransferStep",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    EvaluateBankTransferStep:{
      always: [
        {
          cond: "isADGM",
          target: "ADGMBankTransferFormStep",
        },
        {
          cond: "isCayman",
          target: "CaymanBankTransferFormStep",
        },
        
      ],
    },
    ADGMBankTransferFormStep: {
      on: {
        NEXT: {
          target: "UploadDocumentTransfer",
        },
        PREV: {
          target: "PaymentFormStep",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    CaymanBankTransferFormStep: {
      on: {
        NEXT: {
          target: "UploadDocumentTransfer",
        },
        PREV: {
          target: "PaymentFormStep",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;
