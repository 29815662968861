import { createMachine } from "xstate";

const machine = createMachine({
  id: 'EditDesignatedContact',
  initial: 'InitialData',
  states: {
    InitialData: {
      invoke: {
        src: 'initialData',
        id: 'initialData',
        onDone: [
          {
            target: 'pincasData',
            actions: ['setContextAfterInvoke']
          }
        ],
        onError: [
          {
            target: 'cancel'
          }
        ]
      }
    },
    pincasData: {
      invoke: {
        src: "pincasData",
        id: "pincasData",
        onDone: [
          {
            target: "SelectDesignatedContact",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    SelectDesignatedContact: {
      on: {
        NEXT: {
          target: 'SaveNewDesignatedContact',
          actions: ['setContextAfterStep']
        },
        CANCEL: {
          target: 'finish'
        }
      }
    },
    error: {
      on: {
        PREV: {
          target: 'SelectDesignatedContact'
        }
      }
    },
    SaveNewDesignatedContact: {
      invoke: {
        src: 'saveNewDesignatedContact',
        id: 'saveNewDesignatedContact',
        onDone: [
          {
              target: 'finish'
          }
        ],
        onError: [
          {
              target: 'finish'
          }
        ]
      }
    },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
});

export default machine;
