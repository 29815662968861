import _ from 'lodash'
import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useCategoriesTitles } from '../../../../../hooks/common/useCategoriesTitles';
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import useTranslate from '../../../../generic/hooks/useTranslate'
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useSession from '../../../../session/hooks/useSession'
import useUpsertConvertible from "../../../equity/graphql/mutation/upsertConvertible"
import Form from "../../Forms/FormConvertibleDoc"

const Step2: FC<StepProps> = () => {
    const CATEGORIES_TITLES = useCategoriesTitles();

    const { translate } = useTranslate();
    const { next, send, prev, state } = useCustomMachine();

    const {upsertConvertible}=useUpsertConvertible()

    const { startupId } = useSession();

    const { id = '' } = useParams<any>()

    /*Function*/

    const { type, groupCompanyId, stakeholderId ,equityId} = state.context.paramsData;

    const valuesParties = React.useMemo(() => {
        const values = {}
        groupCompanyId && _.assign(values, { groupCompanies: [{ groupCompany: groupCompanyId }] });
        if (id && !stakeholderId) {
            _.assign(values, { groupCompanies: [{ groupCompany: id }] });
            return values;
        }
        _.assign(values, { parties: [id || stakeholderId] });

        return values;
    }, []);

    const handleCompleteSubmit = async values => {

        const convertibleData = {
            id: equityId,
            groupCompany: groupCompanyId,
            documents: {uploadedFiles: [{...values.file, title: values.title}]}
        }
        await upsertConvertible({startupId, convertibleData, owner: {type: "stakeholder", id: stakeholderId}})

        next(values);
    }
    const handleCancel = async values => {
        send("CANCEL");
    }

    const handlePrev = () => prev();


    return (
        <React.Fragment>
            <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
                title: 'Now for a few more details...',
                subTitle: type ? `Add the ${CATEGORIES_TITLES[type]}` : 'Select Type Document'
            }}
                buttons={{
                    cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
                    previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
                }}>
            </Form>

        </React.Fragment>
    )
}

export default Step2;
