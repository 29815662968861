import { createMachine } from 'xstate';

const machine = createMachine({
  id: 'StructureChart',
  initial: 'initialData',
  states: {
    initialData: {
      invoke: {
        src: 'initialData',
        id: 'initialData',
        onDone: [
          {
            target: 'UploadStep',
            actions: ['setContextAfterInvoke'],
          },
        ],
      },
    },
    UploadStep: {
      on: {
        NEXT: {
          target: 'SaveStep',
          actions: ['setContextAfterStep'],
        },
        CANCEL: {
          target: 'cancel',
        },
      },
    },
    SaveStep: {
      invoke: {
        src: 'SaveStep',
        id: 'SaveStep',
        onDone: [
          {
            target: 'finish',
          },
        ],
        onError: [
          {
            target: 'initialData',
          },
        ],
      },
    },
    cancel: {
      entry: 'cancelMachine',
      type: 'final',
    },
    finish: {
      entry: 'stopMachine',
      type: 'final',
    },
  },
});

export default machine;
