import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_CONFIRM_DESIGNATED_CONTACT = `
mutation upsertRenewalData(
    $startupId: ID!
    $groupCompanyId: ID!
	$renewalData: RenewalDataInput
  ) {
    upsertRenewalData(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      renewalData: $renewalData
    ) {
      id
  }
}
`;

const useUpdateDesignatedContact = () => {
    const {startupId} = useSession();
    const [mutation] = useMutation(
        UPSERT_CONFIRM_DESIGNATED_CONTACT,
        { showSuccessNotification: true }
    ); 

    const save = async (groupCompanyId) =>{
        const data = {
            variables:{
                startupId,
                groupCompanyId,
                renewalData:{
                    companyTasksDetails:{
                        isDesignatedContactConfirmed:true
                    }
                }
            }
        }        
        const submitData = await mutation(data);

        return submitData;
    };

    return save;
};

export default useUpdateDesignatedContact;
