import { useMemo } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';
import useUploadBulkDocuments from '../../../../graphql/mutation/useUploadBulkDocuments';
import useSession from "../../../../../../session/hooks/useSession";
import { StructureChartFormData, StructureChartInitialData } from '../../types';

type SaveStepContext = {
  UploadStep: StructureChartFormData;
  initialData: StructureChartInitialData;
};

function useServices(initialValues: StructureChartInitialData) {
  const { id } = useParams<{ id: string }>();
  const { startupId } = useSession();
  const { mutation: uploadDocuments } = useUploadBulkDocuments();

  return useMemo(() => ({
    initialData: () => {
      return Promise.resolve(initialValues);
    },
    SaveStep: (context: SaveStepContext) => {
      const file = _.get(context, 'UploadStep.document', '');
      const documents = [
        {
          title: file.name,
          file: file,
          type: 'STRUCTURE_CHART',
          parties: [context?.initialData?.stakeholderId ?? id],
          status: 'Uploaded',
          subCategories: ['constitutionalDocuments'],
          executedOnDate: moment(_.get(context, 'UploadStep.date')).format('YYYY-MM-DD'),
        },
      ];

      return uploadDocuments({ variables: {startupId, documents: documents} })
    },

  }), [id, startupId, uploadDocuments, initialValues]);
}

export default useServices;
