import { useMemo } from "react";
import * as Yup from "yup";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { Constants } from "src/v1/utils";
import startCase from "lodash/startCase";
import { useLD } from 'src/providers/LDProvider';

export type FieldConfig = {
  show: boolean;
  disabled: boolean;
  hasPincas: boolean;
  bannerTitle: string;
  bannerLogicType: "touched" | "values";
  isRequired: boolean;
  label: string;
};

type FieldAnalysisResult = {
  config: {
    [key: string]: FieldConfig;
  };
  schema: object;
};
function useGroupCompanyDetailsFieldsAnalyzer(
  groupCompany,
  pincasPrices
): FieldAnalysisResult {
  const { translate } = useTranslate();

    const ldProvider = useLD()
    const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];
    
  const config = useMemo(() => {
    const groupCompanyClass = new GroupCompany(groupCompany);
    const currency = groupCompanyClass.getCurrency();

    return {
      isHoldingCompany: {
        show: true,
        disabled: false,
        hasPincas: null,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: false,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_IS_TOP_CO"),
        schema: Yup.boolean().nullable(),
      },
      name: {
        show: true,
        disabled: false,
        hasPincas:
          groupCompanyClass.isManagedByClara() &&
          (groupCompanyClass.isADGM() || groupCompanyClass.isCayman()),
        bannerTitle: isPaymentProcessingRemoved ? translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1_PAYMENT_REMOVAL") : translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1", {
          currency,
          price: pincasPrices?.CHANGE_COMPANY_NAME?.value ?? 0,
          button: "Save changes",
        }),
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_NAME"),
        schema: Yup.string().required("This field is required").nullable(),
      },
      nameBecameEffectiveOn: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: false,
        label: translate(
          "VIEW_COMPANY_TAB_LABEL_DETAILS_NAME_BECAME_EFFECTIVE_ON"
        ),
        schema: Yup.string()
          .matches(Constants.REGEXP.DATE, {
            message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
            excludeEmptyString: true,
          })
          .typeError("This field is required"),
      },
      previousName: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: false,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_PREVIOUS_NAME"),
        schema: Yup.string().nullable(),
      },
      country: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: true,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COUNTRY"),
        schema: Yup.string().required("This field is required").nullable(),
      },
      jurisdiction: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: true,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_JURISDICTION"),
        schema: Yup.string().required("This field is required").nullable(),
      },
      type: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: true,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_TYPE"),
        schema: Yup.string().required("Required").nullable(),
      },
      licenseType: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: true,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_LICENCE_TYPE"),
        schema: Yup.string().required("This field is required").nullable(),
      },
      companyStatus: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: true,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_STATUS"),
        schema: Yup.string().required("This field is required").nullable(),
      },
      registeredNumber: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: true,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_REGISTERED_NUMBER"),
        schema: Yup.string().required("This field is required").nullable(),
      },
      incorporation_date: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: true,
        label: translate("VIEW_COMPANY_LABEL_INCORPORATION_DATE"),
        schema: Yup.string()
          .matches(Constants.REGEXP.DATE, {
            message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
            excludeEmptyString: true,
          })
          .typeError("This field is required"),
      },
      dissolution_date: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        label: translate("VIEW_COMPANY_LABEL_DISSOLUTION_DATE"),
        schema: Yup.string()
          .matches(Constants.REGEXP.DATE, {
            message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
            excludeEmptyString: true,
          })
          .nullable().notRequired(),
      },
      transactionCurrency: {
        show: true,
        disabled: groupCompanyClass.isManagedByClara(),
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: true,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_CURRENCY"),
        schema: Yup.string().required("This field is required").nullable(),
      },
      authorisedShareCapital: {
        show: true,
        disabled: false,
        hasPincas:
          groupCompanyClass.isManagedByClara() &&
          (groupCompanyClass.isADGM() || groupCompanyClass.isCayman()),
          bannerTitle: isPaymentProcessingRemoved ? translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1_PAYMENT_REMOVAL") : translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1", {
            currency,
          price:
            pincasPrices?.CHANGE_COMPANY_AUTHORIZED_SHARE_CAPITAL?.value ?? 0,
          button: "Save changes",
        }),
        bannerLogicType: "touched",
        isRequired: true,
        label: translate(
          "VIEW_COMPANY_TAB_LABEL_DETAILS_AUTHORISED_SHARE_CAPITAL"
        ),
        schema: Yup.number()
          .required("This field is required")
          .nullable()
          .typeError("This field is required"),
      },
      linkedinURL: {
        show: true,
        disabled: false,
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: null,
        isRequired: false,
        label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE"),
        schema: Yup.string().matches(Constants.REGEXP.URL_PROTOCOL, {
          message: "Please enter a valid URL. eg:'https://...' or 'http://... '",
          excludeEmptyString: true,
        }).nullable()
      },
    };
  }, [groupCompany, translate]);

  const schema = useMemo(() => {
    const s = {};

    Object.keys(config).forEach((key) => {
      if (config[key].show && config[key].schema) {
        s[key] = config[key].schema;
      }
    });

    return Yup.object().shape(s);
  }, [config]);

  return { config: config as { [key: string]: FieldConfig }, schema };
}


export default useGroupCompanyDetailsFieldsAnalyzer;
