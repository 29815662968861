import React, { useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import FormConfirmShareholdersDirectors from "../../../Forms/FormConfirmShareholdersDirectors";
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import { useDispatch } from "react-redux";

const FirstStep = () => {

  const { translate } = useTranslate();
  const { next, send, paramsData } = useCustomMachine();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.FIRST_STEP.TOOLTIP')
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  };

  const handleClose = () => {
    send('CANCEL');
  }

  return (
    <FormConfirmShareholdersDirectors
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      addNoText={true}
      propsTemplate={{
        title: translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.FIRST_STEP.TITLE'),
        subTitle: translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.FIRST_STEP.SUBTITLE')
      }}
      buttons={{
        cancel: <Button onClick={handleClose} variant="secondary">{translate('BUTTON_CANCEL')}</Button>
      }}
    />
  )
}

export default FirstStep;
