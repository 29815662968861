
import React from 'react';
import { Stack } from '@mui/material';
import { Divider } from '@material-ui/core';
import Typography from 'src/modules/generic/components/Texts/Typography';
import _ from 'lodash';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import ButtonIcon from 'src/modules/generic/components/Buttons/ButtonIcon';
import Money from 'src/components/text/Text/Money';
import cls from "src/modules/startup/PayFormationBundleModal/steps/CartStep/CartStep.module.scss";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import { useLD } from 'src/providers/LDProvider';

function ADGMCartItems({ categories, loadingIds, handleDeleteItem, totalGovernmentFee, showFee }) {
  
  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  const { translate } = useTranslate();
  const { context } = useMachine();

  const getTitleKey = (category) => {
    return (isPaymentProcessingRemoved && !translate(`MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}_PAYMENT_REMOVAL`).includes("MODULES.STARTUP")) ? 
      `MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}_PAYMENT_REMOVAL` : 
      `MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}`;
  };

  const getItemKey = (category, type) => {
    if (type === 'COMPANY_STRIKE_OFF') {
      const actualJurisdiction = context?.initialData?.company?.jurisdictionType;
      return (isPaymentProcessingRemoved && !translate(`MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}_ITEM_${type}_${actualJurisdiction}_PAYMENT_REMOVAL`).includes("MODULES.STARTUP")) ?
        `MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}_ITEM_${type}_${actualJurisdiction}_PAYMENT_REMOVAL` :
        `MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}_ITEM_${type}_${actualJurisdiction}`;
    } else {
      return (isPaymentProcessingRemoved && !translate(`MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}_ITEM_${type}_PAYMENT_REMOVAL`).includes("MODULES.STARTUP")) ?
        `MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}_ITEM_${type}_PAYMENT_REMOVAL` :
        `MODULES.STARTUP.MODAL_PAYMENT_PINCAS_CATEGORY_${category}_ITEM_${type}`;
    }
  }

  return (
    <>
      <Stack spacing={2}>
        {_.map(categories, (category, index) => {
          return (
            <>
              {category?.showTitle && !isPaymentProcessingRemoved && (
                <Stack spacing={2} justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
                  <Stack spacing={1} justifyContent={"flex-start"} alignItems={"center"} direction={"row"} sx={{ maxWidth: "900px" }}>
                    <Typography variant={"h5"} weight='semibold'>
                      {translate(getTitleKey(category?.code), category?.params)}
                    </Typography>
                    {category?.delete && (
                      <ButtonIcon
                        className={cls.button}
                        size={'1rem'}
                        icon={'Delete-bin'}
                        variant='flat'
                        children={undefined}
                        loading={(category?.items?.length ?? 0) > 0 && loadingIds.includes(category?.items[0].id)}
                        onClick={(event) => {
                          handleDeleteItem(category)
                        }}
                      />
                    )}
                </Stack>
                  {category?.showTotal && (
                  isPaymentProcessingRemoved ? null : 
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      spacing={0.5}
                    >
                      <span style={{ fontSize: "12px" }}>USD</span>
                      <Typography variant={"body"} weight={"extrabold"} className={cls.MoneyValue}>
                        <Money value={category?.total?.value} showDecimals={true} noShowPrefix/>
                      </Typography>
                    </Stack>
                  )}
                </Stack>
  
              )}
              {_.map(category?.items ?? [], (item, index) => {
                return (
                  <>
                    <Stack spacing={2} justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
                      <Stack spacing={1} justifyContent={"flex-start"} alignItems={"top"} direction={"row"} sx={{ maxWidth: "900px" }}>
                      {item.delete && (
                          <ButtonIcon
                            className={cls.button}
                            size={'1rem'}
                            icon={'Delete-bin'}
                            variant='flat'
                            loading={loadingIds.includes(item?.id)}
                            onClick={(event) => {
                              handleDeleteItem(category, item)
                            }}
                          />
                        )}
                        {isPaymentProcessingRemoved ?
                          item.variant === "item" && <Typography variant={"body"} >{translate(getItemKey(category.code, item.type), item.params)}</Typography> :
                          item.variant === "item" && <Typography variant={"h5"} weight='semibold'>{translate(getItemKey(category.code, item.type), item.params)}</Typography>
                        }
                        {
                          item.variant === "subitem" && <Typography variant={"body"}>{translate(getItemKey(category.code, item.type), item.params)}</Typography>
                       }
                       
                      </Stack>
                      {item?.showTotal && (
                        isPaymentProcessingRemoved ? null : 
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          spacing={0.5}
                        >
                          <span style={{ fontSize: "12px" }}>USD</span>
                          <Typography variant={"body"} weight={"extrabold"} className={cls.MoneyValue}>
                            <Money value={item.amount} noShowPrefix={true} showDecimals={true} />
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Divider />
                  </>
                )
              })}
            </>
          )
        })}
        {showFee && !isPaymentProcessingRemoved && (
          <Stack spacing={2} justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
            <Stack spacing={1} justifyContent={"flex-start"} alignItems={"center"} direction={"row"} sx={{ maxWidth: "900px" }}>
              <Typography variant={"h5"} weight='semibold'>ADGM Fee</Typography>
            </Stack>
              <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={0.5}
            >
              <span style={{ fontSize: "12px"}}>USD</span>
              <Typography variant={"body"} weight={"extrabold"} className={cls.MoneyValue}>
                <Money value={totalGovernmentFee} noShowPrefix={true} showDecimals={true} />
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
}
export default ADGMCartItems;
