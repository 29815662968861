const FormationDataDirectors = `
fragment FormationDataDirectors on FormationData{
  directors{
    id
    fullName
    isAnEntity
  }
}`
const FormationData = `
fragment FormationDataFields on FormationData {
  sharesData{
    id
    shareType
    shareNominalPrice
    sharesAuthorised
    shareholders{
      id
      shareholder{
        id
        fullName
        isAnEntity
      }
      shareIssuances{
        sharePrice{
          amount
          currency
        }
        quantity
        percentage
        isPaid
        shareClass
      }
    }
  }
  directorsAndShareholdersCompleted
  specialMeaning
  tslType
  isAwaitingFunds
  isUsingNominee
  arabicTranslation
  affiliated
  businessActivity{
    activity
    operationCompany
    operatingCompany{
      id
      name
      linkedinURL
      country{
        code
        name
      } 
      linkedinURL
      jurisdiction
      jurisdictionType
    }
  }
  commercialLicense {
    id
    name
    type {
      code
    }
    file {
      name
      id
    }
  }
  hasAssetsAndRevenue
  notYetFormedCompanyName
  hasShareInIncorporated
  hasShareInCompanyFormed
  sharesInIncorporatedCompanies{
    id
    name
    avatar
  }
  gccNationalOrUaeResident
  authorisedSignatories {
    id
    fullName
  }
  address {
    street
    zipCode
    country {
      code
    }
  }
  bankInformation {
    id
    authorisedSignatories {
      id
      fullName
    }
    address {
      street
      zipCode
      city
      state
      country {
        code
        name
        }
    }
    requireToSignJointly
  }
  stateRegion
  dataProtectionContact {
    id
    fullName
  }
  designatedContact {
    id
    fullName
  }
  placeOfBusiness {
    state
    city
  }
  corporatePositions {
    id
    fullName
    avatar
  }
}
`;

const GroupCompanyRequiredFields = `
  fragment GroupCompanyRequiredFields on GroupCompany{
  id
}
`;

const PositionRequiredFields = `
  fragment PositionRequiredFields on Position{
  code
}
`;

export { GroupCompanyRequiredFields, PositionRequiredFields, FormationData,FormationDataDirectors };
