import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import classes from '../classes.module.scss';
import { useLD } from 'src/providers/LDProvider';
/**
* Description Step: For nominee workflow, you have to choose if you are 
* going to use a clara's nominee authorised signatory.
* @returns 
*/
function AddNomineeAuthorisedSignatoryStep() {

  const { context, cancel, send, state } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const ldProvider = useLD();
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  const initialValues = useMemo(() => {
    return context?.AddNomineeAuthorisedSignatoryStep ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    const nextStep = values?.wantClarasNominee
    send({
      type: nextStep,
      payload: { idState: state.value, values: values }
    });
  }

  useEffect(() => {
    dispatch(addAssistantText(`To use this service, you must appoint a second Authorised Signatory. This person doesn't need to be a GCC Resident or National, they only need to have entered the UAE in the last two years.`))
    dispatch(addAssistantText("<b>Why do I need to appoint a secondary Authorised Signatory?</b> Click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6230840-can-i-use-a-nominee-to-be-my-adgm-authorised-signatory\' >here</a> to read more about the use of Clara’s nominee service.<br><br><b>Who can be the secondary Authorised Signatory?</b> You can choose anyone for this role, provided they are of good standing and have entered the UAE in the past two years. They do not need to be a director or shareholder. They will need to provide their personal information, identification documents and proof of entry into the UAE in the form of a UAE entry stamp in their passport or confirm the date they entered through e-gates."))
    !isPaymentProcessingRemoved && dispatch(addAssistantText('if you decide to opt out of this service, your total at checkout will be updated accordingly.'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    wantClarasNominee: Yup.string().nullable().required('Required'),
  })

  const options: Array<CheckboxButtonOptions<string, any>> = [
    {
      value: 'YES',
      label: 'Yes, I do'
    },
    {
      value: 'NO',
      label: 'No, I don’t'
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: `Please confirm you want to use Clara's Nominee Authorised Signatory Service`,
          skeleton: null,
          subTitle: 'ADGM Authorised Signatory',
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <ControllerInput
          as={CheckboxButtonSimple}
          name='wantClarasNominee'
          variant='col_1'
          options={options}
          className={classes.CheckboxButton}
          classNameButton={classes.buttonCheckBox}
        />
      </TemplateWizard>
    </Form>
  );
}

export default AddNomineeAuthorisedSignatoryStep;

