import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";
export const UPSERT_COMPANY_ADDRESSES = `
mutation updateDesignatedContact(
    $startupId: String
    $groupCompanyId: String
	  $groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateGroupCompanyAddresses = () => {
  const [mutation] = useMutation(
    UPSERT_COMPANY_ADDRESSES,
      { showSuccessNotification: true }
  ); 
  const { startupId } = useSession();
  const updateDesignatedContact = (groupCompanyId, designatedContact) => {
    const groupCompany = {
      keyPeople: {
        designatedContact
      }
    };
    return mutation({ variables: { startupId, groupCompanyId, groupCompany } });
  }

  return updateDesignatedContact;
};

export default useUpdateGroupCompanyAddresses;
