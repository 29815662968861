import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import * as Yup from "yup";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { useLD } from 'src/providers/LDProvider';
import { is } from "date-fns/locale";
export default function ReviewAddressesHoldingCompanyStepForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];
  
  const initialValues = useMemo(() => {
    let formattedValues = _.cloneDeep(context?.initialData?.groupCompany);

    if (formattedValues?.registeredAddress?.country?.code) {
      formattedValues.registeredAddress.country =
        formattedValues?.registeredAddress?.country?.code;
    }

    if (formattedValues?.mailingAddress?.country?.code) {
      formattedValues.mailingAddress.country =
        formattedValues?.mailingAddress?.country?.code;
    } else {
      delete formattedValues.mailingAddress;
    }

    if (formattedValues?.locationAccountingRecordsAddress?.country?.code) {
      formattedValues.locationAccountingRecordsAddress.country =
        formattedValues?.locationAccountingRecordsAddress?.country?.code;
    } else {
      delete formattedValues.locationAccountingRecordsAddress;
    }
    return formattedValues;
  }, [context]);

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    // TODO ADD LINK URL
    dispatch(
      addAssistantText(
        isPaymentProcessingRemoved ?
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_1_PAYMENT_REMOVAL") :
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_1"),
        "alert"
      )
    );
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_2")
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    mailingAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    locationAccountingRecordsAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm the company addresses",
          subTitle: "Confirm company addresses",
        }}
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_CONFIRM")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <AddressInput
          name="registeredAddress"
          label={{
            country: translate("MODULES.CLARA.ADDRESS_REGISTERED_LABEL"),
          }}
          zipCodeOptional
          isRequired
          disabled
        />
        <div>&nbsp;</div>
        <AddressInput
          name="mailingAddress"
          label={{ country: "Mailing address" }}
          banner={true}
          bannerTitle={
            isPaymentProcessingRemoved ?
            translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1_PAYMENT_REMOVAL") :
            translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1", {
            currency: "USD",
            price:
              context?.pincasData?.pincasData
                ?.CHANGE_COMPANY_LOCATION_COMPANY_RECORDS_ADDRESS?.value ?? 0,
            button: "Next",
          })}
          zipCodeOptional
          stateOptional
          isRequired
        />
        <div>&nbsp;</div>
        <AddressInput
          name="locationAccountingRecordsAddress"
          label={{ country: "Location of accounting records" }}
          zipCodeOptional
          stateOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}
