import React, { FC, useRef, useState } from "react";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import Form from "../../../../components/forms/Form/FormL";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../generic/templates/Modal";
import classes from "./Form.module.scss";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import useValidations from "src/modules/generic/forms/useValidations";
import { HiddenFieldForm } from "src/components/forms/contents";
import ClaraList from "src/modules/generic/components/Lists/ClaraList";
import useTranslate from "src/modules/generic/hooks/useTranslate";

interface Props extends FormProps {
  designatedContact: any;
}

const FormADGMDesignatedContact: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  designatedContact = {},
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {
  const refForm = useRef();
  const { addField, fieldType, fieldConfigurations } = useValidations();
  const { object, boolean } = fieldType;
  const { isRequired, nullable } = fieldConfigurations;
  const { translate } = useTranslate();
  const schema = addField(
    object(),
    "isChange",
    nullable(isRequired(boolean()))
  );

  const [isChange, setIsChange] = useState();

  const options = [
    {
      value: true,
      label: translate("TEXT_NO"),
    },
    {
      value: false,
      label: translate("TEXT_YES"),
    },
  ];

  return (
    <>
      <Form
        defaultValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: (
              <SubmitButton {...buttonSubmitProps}>
                {isChange ? translate("BUTTON_NEXT") : translate("BUTTON_CONFIRM")}
              </SubmitButton>
            ),
          }}
          props={propsTemplate}
        >
          <Typography component="p" variant="h5">
            {translate("MODULES.RENEWALS_ADGM.REVIEW_ADGM_DESIGNATED_CONTACT.REVIEW_STEP.DESCRIPTION")}
          </Typography>
          <ClaraList
            items={designatedContact ? [{name: designatedContact?.fullName ?? ""}] : []}
          ></ClaraList>
          <TemplateLabel
            label={translate("MODULES.RENEWALS_ADGM.REVIEW_ADGM_DESIGNATED_CONTACT.REVIEW_STEP.INPUT_LABEL")}
            isRequired
            variant="top"
          >
            <ControllerInput
              render={CheckboxButtonSimple}
              name="isChange"
              options={options}
              onChange={(value) => {
                setIsChange(value);
              }}
              isMultiple={false}
              classNameButton={classes.buttonCheckBox}
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <HiddenFieldForm
              fieldsWatch="isChange"
              conditionHideCallback={(value) => {
                if (typeof value === "boolean") {
                  return value;
                }
              }}
            >
              <Typography component="p" variant="h4" fontWeight="semibold">
                {translate("MODULES.RENEWALS_ADGM.REVIEW_ADGM_DESIGNATED_CONTACT.REVIEW_STEP.INPUT_HELPER_TEXT")}
              </Typography>
            </HiddenFieldForm>
          </TemplateLabel>
          <div>&nbsp;</div>
        </Template>
      </Form>
    </>
  );
};

export default FormADGMDesignatedContact;
