import React, { FC, useEffect } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import Button from 'src/modules/generic/components/Buttons/Button';
import FormSelectDesignatedContact from 'src/modules/startup/GroupCompany/Forms/FormSelectADGMDesignatedContact';
import { StepProps } from 'src/modules/generic/components/Wizard/Step';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { useLD } from 'src/providers/LDProvider';
const SelectDesignatedContact: FC<StepProps> = () => {
  const { next, send, context } = useMachine();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const  designatedContact = context?.initialData?.designatedContact;
  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];
  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        isPaymentProcessingRemoved ?
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADGM_DESIGNATED_CONTACT.TOOLTIP_1_PAYMENT_REMOVAL")
        :
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADGM_DESIGNATED_CONTACT.TOOLTIP_1"), "alert"
      )
    );
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADGM_DESIGNATED_CONTACT.TOOLTIP_2")
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  return (
    <>
      <FormSelectDesignatedContact
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        showDesignatedContact={true}
        designatedContact={designatedContact}
        propsTemplate={{
          title: translate("VIEW_COMPANY_TAB_KEY_PEOPLE_ADGM_DESIGNATED_CONTACT_EDIT")
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>
        }}
      />
    </>
  );
}

export default SelectDesignatedContact;
