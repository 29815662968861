import * as Yup from 'yup';
import { default as React, useEffect, useMemo } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import Button from 'src/modules/generic/components/Buttons/Button';
import Form from 'src/components/forms/Form/Form';
import { Stack } from '@mui/material';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateWizardInfo from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardInfo";
import  Typography  from 'src/modules/generic/components/Texts/Typography';
import classModule from "../classes.module.scss"
import reactImage from "../../../../../../../../../../images/Group2022.png";
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import Link from '@mui/material/Link';
import { useLD } from 'src/providers/LDProvider';

function WhatHappensHoldingCompanyStepForm() {

  const { next, context, cancel, setAction } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];
  
  const Paragraph = () => {
    return <div className={classModule.Paragraph}>
      <Typography component='p' weight='bold' variant='bodyModal'>
        {translate('MODULES.RENEWALS_ADGM.WHATHAPPENS_HOLDING_COMPANY.STEP_1.CONTENT_1')}
      </Typography>
      <Typography component='p' variant='bodyModal'>
        <span dangerouslySetInnerHTML={{__html: translate('MODULES.RENEWALS_ADGM.WHATHAPPENS_HOLDING_COMPANY.STEP_1.CONTENT_2')}}></span>
      </Typography>
      {isPaymentProcessingRemoved ? null :
      <>
      <Typography component='p' weight='bold' variant='bodyModal'>
        {translate('MODULES.RENEWALS_ADGM.WHATHAPPENS_HOLDING_COMPANY.STEP_1.CONTENT_3')}
      </Typography> 
      <Typography component='p' variant='bodyModal'>
        <span dangerouslySetInnerHTML={{__html: translate('MODULES.RENEWALS_ADGM.WHATHAPPENS_HOLDING_COMPANY.STEP_1.CONTENT_4', {currency: "USD", amount: "3,300", amountFees: "1,500"})}}></span>
      </Typography> 
      <Typography component='p' variant='bodyModal'>
        {translate('MODULES.RENEWALS_ADGM.WHATHAPPENS_HOLDING_COMPANY.STEP_1.CONTENT_5')}
      </Typography></> }
    </div>
  }

  const initialValues = useMemo(() => {
    return context?.WHATHAPPENS_HOLDING_COMPANYTechLicenceStepForm ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  const handleCloseCompany = (values = {}) => {
    setAction({ action: 'GO_CLOSE_COMPANY', payload: values });
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS_ADGM.WHATHAPPENS_HOLDING_COMPANY.STEP_1.TOOLTIP_1')))
    dispatch(addAssistantText(translate('MODULES.RENEWALS_ADGM.WHATHAPPENS_HOLDING_COMPANY.STEP_1.TOOLTIP_2')))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({});

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizardInfo
        props={{
          title: translate('MODULES.RENEWALS_ADGM.COMMON.TITLE'),
          subTitle: translate('MODULES.RENEWALS_ADGM.COMMON.SUBTITLE'),
          image: reactImage,
          isRenewalImage: true,
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} >{translate('MODULES.RENEWALS_ADGM.COMMON.BTN_START')}</SubmitButton>,
        }}
      >
        <>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Paragraph />
            <Typography component='p' variant='s'>
              {translate('MODULES.RENEWALS_ADGM.COMMON.CLICK')}
              <Link onClick={handleCloseCompany}>{translate('MODULES.RENEWALS_ADGM.COMMON.HERE')}</Link>
              {translate('MODULES.RENEWALS_ADGM.COMMON.WANT_CLOSE')}
            </Typography>
          </Stack>
        </>
        <div>&nbsp;</div>
      </TemplateWizardInfo>
    </Form>
  );
}

export default WhatHappensHoldingCompanyStepForm;

