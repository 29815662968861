import Machine from "../../../../generic/components/Machine/MachineClass";
import { Step1 } from "./Steps";

const machineDefinition = {
  id: "ADGM_desgnated_contact",
  initial: "initialStatus",
  states: {
    initialStatus: {
      on: {
        NEXT: { target: "finish_designated_contact", actions: "setContext" },
        CANCEL: "finish_designated_contact",
      },
    },
    finish_designated_contact: {
      type: "final",
      entry: "final",
    },
    onDone: {
      actions: "stopMachine",
    },
  },
};

export default class MachineDesignatedContact extends Machine {
  constructor() {
    super(machineDefinition, {}, { initialStatus: Step1 });
  }

  getParamsData = (values) => values;
}
