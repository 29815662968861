import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_DESIGNATED_CONTACT = `
mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
	$groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateDesignatedContact = () => {
    const {startupId} = useSession();
    const [mutation] = useMutation(
        UPSERT_DESIGNATED_CONTACT,
        { showSuccessNotification: true }
    ); 
    
    const save = async (groupCompanyId,id) =>{
        const data = {
            variables:{
                startupId,
                groupCompanyId,
                groupCompany:{
                    keyPeople:{
                        designatedContact:id 
                    }
                }
            }
        }        
        const submitData = await mutation(data);

        return submitData;
    };

    return save;
};

export default useUpdateDesignatedContact;
