import React, { useEffect, useMemo } from "react";
import { addAssistantText, clearAssistatText } from "../../../../../../modules/generic/store/action";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useSession from "../../../../../session/hooks/useSession";
import FormCreateDesignatedContact from "../../../Forms/FormCreateDesignatedContact";
import useUpsertFormationCompany from "../../../graphql/mutations/useUpsertFormationCompany";
import useGetFormationCompanyDirectors from 'src/modules/startup/GroupCompany/graphql/queries/useGetFormationDirectors';
import _ from 'lodash';

const ThirdStep = () => {

  const { id: _id } = useParams<{ id: string }>();
  const { translate } = useTranslate();
  const { next, send, paramsData } = useCustomMachine();
  const { startupId } = useSession();
  const [mutation] = useUpsertFormationCompany({}, {});
  const dispatch = useDispatch();
  const id =  paramsData?.idGroupCompany ?? _id;
  const { data } = useGetFormationCompanyDirectors({ variables: { startupId, groupCompanyId: id } });

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.THIRD_STEP.TOOLTIP')
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    const variables = {
      groupCompanyId: id,
      startupId,
      groupCompanyData: {
        formationData: {
          directorsAndShareholdersCompleted: true,
          directorToSignShareCertificate: data?.formationData?.directors[0].id,
          designatedContact: values.designatedContact,
        }
      }
    };
    await mutation(variables);
    next(values);
  };

  const handleClose = () => {
    send('CANCEL');
  };

  const handlePrevious = () => {
    send("PREVIOUS");
  };

  return (
    <FormCreateDesignatedContact
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      groupCompanyId={id}
      stakeholders={[]}
      designatedContact={[]}
      propsTemplate={{
        title: translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.THIRD_STEP.TITLE'),
        subTitle: translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.THIRD_STEP.SUBTITLE'),
      }}
      buttons={{
        cancel: <Button onClick={handleClose} variant="secondary">{translate("BUTTON_CANCEL")}</Button>,
        previous: <Button onClick={handlePrevious}>{translate("BUTTON_PREVIOUS")}</Button>,
      }}
    />
  )
}

export default ThirdStep;
