import { ReviewADGMDesignatedContact, SelectNewADGMDesignatedContact } from "../steps";
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';

const useSteps = () => {
  return {
    InitialData: {
      render: TemplateWizardSkeletonMachine
    },
    pincasData: {
      render: TemplateWizardSkeletonMachine
    },
    CheckDesignatedContact: {
      render: TemplateWizardSkeletonMachine
    },
    SaveNewDesignatedContact: {
      render: TemplateWizardSkeletonMachine
    },
    SaveNoNewDesignatedContact: {
      render: TemplateWizardSkeletonMachine
    },
    ReviewDesignatedContact: {
      render: ReviewADGMDesignatedContact
    },
    SelectDesignatedContact: {
      render: SelectNewADGMDesignatedContact
    }
  }
}

export default useSteps;
