import React from 'react';
import Wizard from '../../../../../generic/components/Wizard/v2';
import { StructureChartInitialData } from '../types';
import useServices from './hooks/useServices';
import useSteps from './hooks/useSteps';
import machine from './machine';

type Props = {
  initialValues: StructureChartInitialData;
  onFinish: () => void;
  onCancel: () => void;
};

function StructureChartWizard({ initialValues, onFinish, onCancel }: Props) {
  const steps = useSteps();
  const services = useServices(initialValues);

  return (
    <Wizard
      machine={machine}
      steps={steps}
      services={services}
      onStopMachine={onFinish}
      onCancelMachine={onCancel}
    />
  );
}

export default StructureChartWizard;
