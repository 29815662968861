const CompanyProfileFragment = `fragment CompanyProfile on GroupCompany {
  name
  avatar
  updatedAt
  title
  dissolution_date
  incorporation_date
  hasInvestment
  linkedinURL
  type
  companyType
  previousName
  nameBecameEffectiveOn
  authorisedShareCapital
  postIncorporation {
  	nextRenewalDate
  	renewalDeadlineDate
  	strikeOffDate
  }
  licenseType
  requestBasket {
    requestItems {
      id
      type
      category
      isPaid
      status
      entity {
        ... on StakeHolder {
          id
          description: fullName
        }
        ... on GroupCompany {
          id
          description: name
        }
      }
      values
      product {
        id
        code
        name
        value
        vat
        items {
          code
          name
          description
          value
          subTotal
          vat
          vatPercent
        }
      }
    }
  }
  dataProtectionDeclaration {
    dataBeingProcessed
    countriesPersonalDataTransferred
    conditionsApplyingTransfer
    reasonsDataBeingProcessed
    mechanismInPlaceForProtection
    reasonsProcessingSensitiveData
    sensitiveDataBeingProcessed
    isPersonalDataTransferred {
      description
      question
    }
    isSensitiveDataProcessed {
      description
      question
    }
    othersSensitivePersonalData
    otherDataProcessed
    registrationExpiryDate
  }
  keyPeople {
    directors {
      id
      avatar
      fullName
      email
    }
    useClaraNominee
    authorisedSignatories {
      id
      avatar
      fullName
      email
    }
    dataProtectionContact {
      id
      avatar
      fullName
      email
    }
    designatedContact {
      id
      avatar
      fullName
      email
    }
    bankAuthorityInformation {
      accountOpening
      requireToSignJointly
      address {street city state country {code name} zipCode}
      authorisedSignatories {
        id
        fullName
        email
        avatar
      }
    }
  }
  leaseDetails {
    leaseStart
    leaseEnd
    registrationNumber
  }
  businessActivities {
    id
    activity
    relevantActivities
    articlesOfAssociationDate
    articlesOfAssociationType
    companyArticlesEntrenched
    confirmationStatementLastFiled,
    activityType
    activitySection
  }
  companyStatus
  isHoldingCompany
  jurisdiction
  jurisdictionType
  country {
    name
    code
  }
  registeredNumber
  registeredAddress {
    street
    city
    state
    country {
      name
      code
      alpha3
    }
    zipCode
  }
  mailingAddress {
    type
    useRegisteredAddress
    street
    city
    state
    country {
      name
      code
      alpha3
    }
    zipCode
  }
  locationCompanyRecordsAddress {
    street
    city
    state
    country {
      name
      code
      alpha3
    }
    zipCode
  }
  locationAccountingRecordsAddress {
    street
    city
    state
    country {
      name
      code
      alpha3
    }
    zipCode
  }
  kind
  nextFinancialYearEnd
  authorisedShareCapital
  managedByClara
  businessActivities {
    relevantActivities
    articlesOfAssociation {
      id
      name
    }
    articlesOfAssociationType
  }
    purposeOfEntity {
      code
      description
    }
}`;
const AdditionalAddressFragment = `fragment AdditionalCompanyAddress on GroupCompany {
   recipientNotice {
   	fullName
   	email
    id
   	useRegisteredAddress
   	address {
		street
		city
		state
		country {
		  code
		  name
		  alpha3
		}
    	zipCode
    }
  }
  workingAddress {
    street
    city
    state
    country {
      code
      name
      alpha3
    }
    zipCode
  }
}`;
const CompanyDocumentsFragment = `fragment CompanyDocuments on GroupCompany {
  documents {
  kind
  params
  freemiumValid
  relatedDocuments{
  id
  name
  type{
  code
  }
  stakeholders{
  id
  fullName
  }
  }
    name
    id
    parties
    executedOnDate
    kind
    terms {
      key
      value
    }
    status
    type {
      label
      code
    }
    groupCompanies {
      groupCompany {
        id
        avatar
        name
      }
      signedBy{
      id
      }
    }
    stakeholders {
      id
      avatar
      fullName
    }
    subCategories
    file {
      id
      name
      size
      type
    }
  }
}`;

const GET_COMPANY_DETAILS_QUERY = `query companyDetailsView($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    isLocked
    isActive
    ...CompanyProfile
    hasSameWorkingAddress
    ...AdditionalCompanyAddress
    statusOnMatters {
      isLocked
      isRenewal
      onFields
    },
    certificateOfIncorporation {
      id
      name
      size
      type
    }
    businessActivities{
      relevantActivities
      articlesOfAssociation{
        id
        name
      }
      articlesOfAssociationType
    }
    assetsAndRevenue {
      sharesInIncorporatedCompanies {
          id
          name
          avatar
      }
      isSharesInNotYetFormedCompanies {
        description 
        question
      }
    }
    articlesOfAssociation
    ...CompanyDocuments
    transactionCurrency
    directors {
      id
      avatar
      isAnEntity
      roles {
        ...RoleFields
      }
      fullName
    }
    extraCurrencies
    assignee {
      isADGM
      isBanking
      isDataProtection
      isDesignatedContact
      stakeholder {
        id
        fullName
        avatar
        isAnEntity
      }
    }
    formationData {
      ...FormationDataFields
    }
  }
}`;
const GET_COMPANY_DETAILS_NAME = `query companyDetailsView($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
  }
}`;

export {
  CompanyProfileFragment,
  AdditionalAddressFragment,
  CompanyDocumentsFragment,
  GET_COMPANY_DETAILS_QUERY,
  GET_COMPANY_DETAILS_NAME,
};
