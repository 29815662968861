import React, { FC, useMemo } from "react";
import classesModule from "./classes.module.scss";
import ColorType from "./../../../types/colors"
import  _ from "lodash"
export interface TypographyProps {
      children: React.ReactNode,
      className? : string
      component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span' | 'p' | 'div' | 'label'|'subTitle',
      variant?: 'xxxl' | 'xxl' | 'l' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body' | 'm' | 's' | 'xs' | 'xxs' | 'xxxs' | 'xbody' | 'xxl_2' | 'title' | 'dash'|'subTitle'|'label' |'onboarding-great' | "bodyLineThrough" ,
      weight?: 'extrabold'  |'bold' | 'regular' | 'semibold' | 'light',
      color?:  ColorType
      fontWeight?: 'bold' | 'regular' | 'semibold' | 'light',
}
/**
* Copmponent to write simple text
*
* sketch: https://www.sketch.com/s/5b7b0651-3168-42eb-b600-fb1938b67b0b/a/v8xmaGQ
*
*/
const Typography: FC<TypographyProps> = ({ children, variant,  component = 'span',weight,color,className,fontWeight }) => {
  const mappers = { 
            h1 : {variant: "h1", weight: 'regular', color: 'black'}, 
            h2 : {variant: "h2", weight: 'regular', color: 'black'},
            h3 : {variant: "h3", weight: 'regular', color: 'black'},
            h4 : {variant: "h4", weight: 'regular', color: 'black'},
            h5 : {variant: "h5", weight: 'regular', color: 'black'},
            label : {variant: "body", weight: 'regular', color: 'black'},
            subTitle:{variant:'p',weight: 'regular', color: 'black'}
  } 
  const {variantLocal,weightLocal,colorLocal} = useMemo(()=>{
    const mapElement= mappers[component];
    const variantLocal = variant || _.get(mapElement,"variant",'s');
    const colorLocal = color || _.get(mapElement,"color",'black');
    const weightLocal= weight || _.get(mapElement,"weight",'regular');
    
    return {variantLocal,weightLocal,colorLocal}
  },[variant,weight,color,component])
  
  return (
    <React.Fragment>
      {React.createElement(component,{children ,className:`${classesModule.Typography}  ${classesModule[fontWeight]} ${classesModule[variantLocal]}  ${classesModule[weightLocal]}  ${classesModule[`text-color-${colorLocal}`]} ${className}`})}
    </React.Fragment>
  );
}

export default Typography;