import React, { FC, useMemo, useRef } from "react";
import * as Yup from "yup";
import _ from "lodash";
import Form from "../../../../../../components/forms/Form/Form";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import useSession from "../../../../../../modules/session/hooks/useSession";
import CheckboxButtonSimple from "../../../../../../modules/generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useGetStakeholders from "../../../../../../hooks/services/modules/stakeholders/useGetStakeholders";

interface FormSelectDesignatedContactProps extends FormProps {
  groupCompanyId?: string;
}

const schema = Yup.object().shape({
  designatedContact: Yup.string().required("Required"),
});

const FormSelectDesignatedContact: FC<FormSelectDesignatedContactProps> = ({
  initialValues,
  groupCompanyId,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormSelectDesignatedContactProps) => {
  const { startupId } = useSession();
  const refForm = useRef();

  const { data: dataStakeholders } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId,
      filters: { forADGM: true, checkIsFounder: true },
    },
  });

  const options = React.useMemo(() => {
    const filterOnlyIndividuals = _.filter(dataStakeholders, (stakeholder) => stakeholder.isAnEntity === false);
    let result = [];
    _.forEach(filterOnlyIndividuals, (stakeholder) => {
      let newItem = {
        label: stakeholder.fullName,
        value: stakeholder.id,
      };
      result.push(newItem);
    });
    return result;
  }, [JSON.stringify(dataStakeholders)])

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            name='designatedContact'
            isMultiple={false}
            defaultValue={[]}
            options={options}
            size={"md"}
          />
        </Template>
      </Form>
    </>
  );
};

export default FormSelectDesignatedContact;
