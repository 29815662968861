import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { getURLApi } from 'src/utils/urlHelper';
import { ILDProvider } from './types';
import useSession from 'src/modules/session/hooks/useSession';

const LDContext = createContext < ILDProvider | null > (null);

export const useLD = () => useContext(LDContext);

export const LDProvider = ({ children }: any) => {
  const [featureFlags, setFeatureFlags] = useState < any > (
    {}
  );
  const { session } = useSession();

  const fetchFeatureFlags = async () => {
    const headers = {
      'launch-darkly-key': process.env.REACT_APP_LAUNCHDARKLY_HEADER_KEY
    }
    if (session.token) {
      headers['authorization'] = session.token
    }
    try {
      const response = await axios.get(`${getURLApi()}/get-all-flags`, {
        headers: headers
      });
      setFeatureFlags(response.data.result);
    } catch (error) {
      console.error('Failed to fetch feature flags:', error);
    }
  };

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  const value = {
    featureFlags,
    setFeatureFlags,
    fetchFeatureFlags
  };

  return <LDContext.Provider value={value}>{children}</LDContext.Provider>;
};
