import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useModal from 'src/modules/generic/hooks/useModal';
import ModalAssistant from 'src/modules/generic/components/Modal/ModalAssistant';
import { clearAssistatText } from 'src/modules/generic/store/action';
import Wizard from 'src/modules/generic/components/Wizard/v2';
import useGuards from './wizard/hooks/useGuards';
import useSteps from './wizard/hooks/useSteps';
import useServices from './wizard/hooks/useServices';
import { actions } from './wizard/hooks/useActions';
import machine from './wizard/machine';

interface ModalEditDesignatedContactProps {
  initialValues: { groupCompanyId: string },
  onClose(): void,
  children: ReactNode,
  open: boolean,
}

const ModalEditDesignatedContact: FC<ModalEditDesignatedContactProps> = ({ children, open: openParam = false, initialValues, ...props }: ModalEditDesignatedContactProps) => {
  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const guards = useGuards();
  const steps = useSteps();
  const services = useServices(initialValues);

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleClose = () => {
    onClose();
    setOpen(false);
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal();
  }, []);

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleClose}
        onCancelMachine={handleClose}
      />
    </ModalAssistant>
  );
}

export default ModalEditDesignatedContact;
