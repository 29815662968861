import { useCallback, useMemo } from 'react';
import { useLD } from '../providers/LDProvider';

/**
 * If the document is of the given type, allow download of that document if feature flag is on.
 */
export function useAllowDownloadForDocumentType() {
  const { featureFlags } = useLD();
  const isEnabled = featureFlags['csp-allow-download-of-register-of-shares-and-members-documents'];

  return useCallback((documentType: string) => {
    if (isEnabled) {
      return [
        'ADGM_REGISTER_OF_MEMBERS_DOCUMENT',
        'CAYMAN_REGISTER_OF_MEMBERS',
        'REGISTER_OF_MEMBERS',
        'REGISTER_OF_SHARES'
      ].includes(documentType);
    }

    return false;
  }, [isEnabled]);
}
