import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classesModule from "../classes.module.scss";
import Typography from "src/modules/generic/components/Texts/Typography";
import ListItem from 'src/modules/generic/components/Lists/ListItem'
import { useLD } from 'src/providers/LDProvider';

function FormDelawareAgree() {

  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
      
  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-processing-removal'];
  
  const initialValues = useMemo(() => {
    return context?.FormDelawareAgree ?? {}
  }, [context]);
  
  const handleCancel = (values) => {
    cancel();
  }
  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }
  
  useEffect( ()=>{
    dispatch(
      addAssistantText(
        translate(isPaymentProcessingRemoved ? 'MODULES.ONBOARDINGCOMPANY.TOOLTIP_ACCEPT_TERMS_DELAWARE_1_PAYMENTREMOVAL': 'MODULES.ONBOARDINGCOMPANY.TOOLTIP_ACCEPT_TERMS_DELAWARE_1')
      )
    );
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])
  
  const schema = Yup.object().shape({
    accept: Yup.boolean().required("Required"),
  });

  const options = [
    {
      value: false,
      label: "No, I don’t agree to the above requirements",
      componentProps:{
        variant: "h4"
      }
    },
    {
      value: true,
      label: "Yes, I agree to the above requirements",
      componentProps:{
        variant: "h4"
      }
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={{
          title: 'Form in Delaware',
          subTitle: "Let's form!",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <Typography component='h4'>
            You can form a company in Delaware using the Clara platform. To do so, you will first need to agree to the following requirements:
          </Typography>
          <ListItem
            color='black'
            item='The company formed will be a C-Corporation (or “C-Corp”).'
            customClass={false}
            typographyProps={
              { component: "body" }
            }
            noBottomMargin={true}
          />
          <ListItem
            color='black'
            item='You can only form through the platform using natural persons as shareholders and directors (i.e. no corporate entities).'
            customClass={false}
            typographyProps={
              { component: "body" }
            }
            noBottomMargin={true}
          />
          <ControllerInput
            as={CheckboxButtonSimple}
            className={classesModule.CheckboxButton}
            classNameButton={classesModule.buttonCheckBoxSize}
            name="accept"
            variant="col_1"
            size={"size2"}
            options={options}
            onChange={(value) => {}}
          />
        </>
      </TemplateWizard>
    </Form>
  );
}

export default FormDelawareAgree;

