import { SelectDesignatedContact } from "../steps"
import  TemplateWizardSkeletonMachine  from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';

const useSteps = () => {
  return {
    InitialData:{
      render:TemplateWizardSkeletonMachine
    },
    pincasData: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveNewDesignatedContact:{
      render:TemplateWizardSkeletonMachine
    },
    SelectDesignatedContact:{
      render:SelectDesignatedContact
    }
  }
}

export default useSteps;
