/* eslint-disable import/no-anonymous-default-export */
import moduleAccelerators from "./../modules/accelerators/langs";
import moduleAssistanceV4 from "./../modules/assistanceV4/langs";
import moduleCheckout from "./../modules/checkout/langs";
import moduleClara from "./../modules/clara/lang";
import moduleCompleteStakeholder from "../modules/clara/components/Accelerator/CompleteStakeholders/langs";
import moduleConstitutionalDocuments from "../modules/clara/components/Accelerator/ConstitutionalDocuments/langs";
import moduleDocuments from "./../modules/documents/langs";
import moduleDocumentsStartup from "../modules/startup/Documents/langs";
import moduleEquity from "../modules/startup/equity/langs/index";
import moduleFormation from "./../modules/formation/langs";
import moduleGeneric from "./../modules/generic/langs";
import moduleGroupCompany from "../modules/startup/GroupCompany/langs";
import moduleIndividualStakeholder from "./../modules/stakeholder/lang";
import moduleMap from "./../modules/map/langs";
import moduleMapModals from "../modules/startup/Map/langs";
import moduleMessages from "../components/modules/clara/startups/messages/langs";
import moduleOnBoardingFormsStartup from "./../modules/startup/onBoarding/core/forms/langs/index";
import moduleOnBoardingStepsStartup from "../modules/startup/onBoarding/core/wizards/steps/langs/index";
import moduleOnboardingCompany from "./../modules/startup/onBoarding/company/langs";
import moduleOnboardingEmployee from "./../modules/startup/onBoarding/roles/employee/langs";
import moduleOnboardingRoles from "./../modules/startup/onBoarding/roles/langs";
import moduleRenewalsADGM from "src/views/modules/startups/renewal/adgm/langs";
import moduleRenewalsKY from "src/views/modules/startups/renewal/cayman/langs";
import moduleStakeholder from "../modules/startup/Stakeholder/langs";
import moduleStartup from "./../modules/startup/langs";
import moduleSubmitApplication from "../modules/clara/components/Accelerator/SubmitApplication/langs";

export default {
  SUBSCRIBE: "Subscribe",
  UNSUBSCRIBE: "Unsubscribed",
  ROL_FOUNDER: "Founder",
  ROL_CONSULTANT: "Consultant",
  ROL_EMPLOYEE: "Employee",
  ROL_ADVISOR: "Advisor",
  GROUP_COMPANY: "groupCompany",

  ASSISTANCE_TITLE: "Hi {{firstName}}",
  ASSISTANCE_QUESTION_TITLE: "What would you like to do today?",
  ASSISTANCE_BUTTON_ADD_NEW_REMARK: "<b>Add</b> New Remark",
  ASSISTANCE_BUTTON_ADD_COMPANY: "Add <b>Company</b>",
  ASSISTANCE_BUTTON_TEAM_MEMBER: "Add <b>Team Member</b>",
  ASSISTANCE_BUTTON_ADD_INVESTOR: "Add <b>Investor</b>",
  ASSISTANCE_BUTTON_ADD_EQUITY: "Add <b>Equity Position</b>",
  ASSISTANCE_BUTTON_CREATE_DOCUMENT: "Create <b>Document</b>",
  ASSISTANCE_BUTTON_ADD_DOCUMENT: "Add <b>Document</b>",
  ASSISTANCE_BUTTON_DOWNLOAD_ALL_DOCUMENT: "Download all <b>Documents</b>",
  ASSISTANCE_BUTTON_DOWNLOAD_CAP_TABLE_MODELLING_DOCUMENT:
    "Download <b>Cap Table</b>",
  ASSISTANCE_BUTTON_ADD_REMARK: "Add <b>Remark</b>",
  ASSISTANCE_BUTTON_SHARE_PROFILE: "<b>Share</b> Clara Profile",
  ASSISTANCE_BUTTON_CONTACT_PARTNERS: "<b>Contact</b> Clara Partners",
  ASSISTANCE_BUTTON_CONTACT_SUPPORT: "<b>Contact</b> Clara Support",
  ASSISTANCE_HEALTHCHECK_COMMENTS: "{{cant}} New Comments",
  ASSISTANCE_HEALTHCHECK_RED: "{{cant}} Red Remarks",
  ASSISTANCE_HEALTHCHECK_AMBER: "{{cant}} Amber Remarks",
  ASSISTANCE_HEALTHCHECK_GREEN: "{{cant}} Green Remarks",
  ASSISTANCE_HEALTHCHECK_UNKNOWN: "{{cant}} Neutral Remarks",
  ASSISTANCE_HEALTHCHECK_NOTIFICATION_SUCCESS: "Health Check executed",
  ASSISTANCE_BUTTON_STARTUP_ADD_CARD: "Edit/Add <b>Card</b>",
  ASSISTANCE_BUTTON_STARTUP_EDIT_PROFILE: "Edit <b>Profile</b>",
  ASSISTANCE_BUTTON_STARTUP_SHARE_PROFILE: "Share <b>Profile</b>",
  ASSISTANCE_BUTTON_STARTUP_EDIT_SUBSCRIPTION: "Edit <b>Subscription</b>",
  ASSISTANCE_BUTTON_STARTUP_CANCEL_SUBSCRIPTION: "Cancel <b>Subscription</b>",
  ASSISTANCE_BUTTON_STARTUP_DOWNLOAD: "Download all <b>Invoices</b>",
  ONBOARD_TEAMMEMBERS_TITLE: "Onboard {{rol}} # {{number}} ",
  ASSISTANCE_READ_MORE: "Read more",
  ONBOARD_TEAMMEMBERS_TEXT:
    "The next step in onboarding your Startup is to onboard your Team Members. Clara will now ask you to enter some details about the Team Member.",
  ONBOARD_INVESTOR_TITLE: "Onboard your {{rol}} # {{number}} ",
  ONBOARD_INVESTOR_TEXT:
    "The next step in onboarding your Startup is to onboard your Investors. Clara will now ask you to enter some details about the Investor.",
  ONBOARD_POSITION_FIRST: "first",
  ONBOARD_POSITION_NEXT: "next",
  ONBOARD_POSITION_LAST: "last",

  ONBOARD_MODAL_FINISH_CONTENT:
    "<b>Good job on adding all your startup details {{firstName}}.</b><br/> View, add and edit this info whenever you need, directly from the map. From cap tables to company health checks, ready to see what else you can do on Clara?",
  ONBOARD_MODAL_FINISH_TITLE: "You’re off to a great start(up)!",
  ONBOARD_MODAL_FINISH_BUTTON: "Let’s go!",
  CHAT_ADDRES_EXAMPLE_TEXT:
    "Office 2406, 24th Floor, Al Sila Tower, Abu Dhabi Global Market Square, PO Box 128666, Al Maryah Island*",

  VALIDATION_CHAT_FIELD_REQUIRED: "This field is required",
  VALIDATION_CHAT_MAX_LENGTH: "Max Length {{max}}",
  VALIDATION_CHAT_MIN_LENGTH: "Min Length {{min}}",
  VALIDATION_CHAT_MIN_NUMBER: "Min num {{min}}",
  VALIDATION_CHAT_MAX_NUMBER: "Max num {{min}}",
  VALIDATION_CHAT_NUMBER: "The field is numeric",
  VALIDATION_STAKEHOLDER_NAME_REQUIRED: "Name is required",
  VALIDATION_STAKEHOLDER_ROLES_REQUIRED: "You need add one role at least",
  VALIDATION_STAKEHOLDER_ROLES_INVESTOR:
    "An investor must also be a Shareholder, Convertive or Warrant Holder.",

  IP_ASSIGNMENT: "IP Assignment Agreement",
  SAFE: "Simple Agreement for Future Equity",
  CONVERTIBLE_NOTE: "Convertible Loan Note",
  ADVISOR_AGREEMENT: "Advisor Agreement",
  EMPLOYMENT_AGREEMENT: "Employment Agreement",
  EMPLOYMENT_AGREEMENT_ADGM: "Employment Agreement - ADGM",
  EMPLOYMENT_AGREEMENT_DIFC: "Employment Agreement - DIFC",
  EMPLOYMENT_AGREEMENT_UK: "Employment Agreement - UK",
  CONSULTANT_AGREEMENT: "Consultancy Agreement",
  FOUNDERS_TERM_SHEET: "Founders Term Sheet",
  KISS: "Keep It Simple Security",
  FOUNDER_SERVICES_AGREEMENT: "Founder Services Agreement",
  GRANT_AGREEMENT: "Grant Agreement(SIP)",
  SHARE_INCENTIVE_PLAN: "Share Incentive Plan",
  RESTRICTIVE_COVENANT_AGREEMENT: "Non-Compete Agreement",
  DOCTYPE_LOADING_TEXT: "Your requested document is being loaded",

  LOADING: "Loading...",
  MORE_ACTIONS: "More Actions",
  MENU_VIEW_AUDIT_LOGS: "Audit Logs",
  MENU_ACTIVITY_LOGS: "Activity log",
  MENU_VIEW_MY_ACCOUNT: "My account",
  MENU_VIEW_STARTUP_PROFILE: "This profile",
  MENU_VIEW_ACCELERATORS: "Accelerators",
  MENU_VIEW_MANAGE_SUBSCRIPTION: "Manage subscription",
  MENU_VIEW_VIEW_INVOICES: "View invoices",
  MENU_ADD_NEW_STARTUP: "Add New Startup",
  MENU_SHARE_PROFILE: "Share Profile",
  MENU_VIEW_KITCHEN_SINK: "Kitchen Sink",
  MENU_ENVIRONMENT_CONFIG: "Versions",

  MOBILIE_TITLE: "Mobile Coming Soon",
  MOBILIE_TEXT:
    "Please visit app.clara.co on desktop for the best user experience.",
  MOBILIE_CONTACT: "If you have any questions, ",
  MOBILIE_CONTACT_US: "Contact Us",

  MODAL_LOADING_PLEASE_WAIT: "Please wait...",
  MODAL_LOADING_FILE_GENERATED: "Your Clara Report is being generated",

  USER_TABLE_TITLE: "Users",
  USER_TABLE_MODAL_TITLE_ADD: "Add User",
  USER_TABLE_MODAL_TITLE_EDIT: "Edit User",
  USER_TABLE_BUTTON_ADD: "New User",
  USER_TABLE_FIELDS_TITLE_FIRSTNAME: "First names",
  USER_TABLE_FIELDS_TITLE_LASTNAME: "Last name",
  USER_TABLE_FIELDS_TITLE_EMAIL: "EMAIL",
  USER_TABLE_FIELDS_TITLE_STARTUPS_COUNT: "Cant. Startups",
  USER_TABLE_FIELDS_TITLE_ROLE: "Role",
  USER_TABLE_FIELDS_TITLE_ACTIVE: "Status",
  USER_TABLE_FIELDS_DELETE_DISABLED_USER: "Deactive User",
  USER_TABLE_FIELDS_DELETE_ENABLED_USER: "Active User",
  USER_TABLE_FIELDS_DELETE_DISABLED_WARNING_USER: "Deactive User",
  USER_TABLE_FIELDS_DELETE_ENABLED_WARNING_USER: "Active User",
  USER_STATUS_ACTIVE: "Active",
  USER_STATUS_DISABLED: "Deactive",
  USER_FROM_ADD_EDIT_USER:
    "You can change user details and access from this form",
  USER_TABLE_FIELDS_ACTIVE_DEACTIVE_USER: "Active / Deactive User",
  USER_NOTIFICATION_SUCCES_CREATED: "User Successfully created",
  USER_NOTIFICATION_SUCCES_UPDATED: "User Successfully updated",
  USER_NOTIFICATION_STATUS_CHANGED: "Status Successfully changed",

  //KYC
  KYC_TITLE_FORM_INDIVIDUAL_FORM_ADD: "Individual details",
  KYC_TITLE_FORM_COMPANY_FORM_ADD: "Company details",
  KYC_TITLE_FORM_THIRD_PARTY_FORM_ADD: "Service provider details",
  KYC_ENTITY_DIRECTORS_MANAGERS_FIELD_VIEW: "Directors and managers",
  KYC_TITLE_FORM_INDIVIDUAL_FORM_EDIT: "Edit individual",
  KYC_TITLE_FORM_COMPANY_FORM_EDIT: "Edit company",
  KYC_TITLE_FORM_THIRD_PARTY_FORM_EDIT: "Edit third party",
  KYC_FINALIZE_ENGAGEMENT_BUTTON: "Finalise engagement",
  KYC_ADD_INDIVIDUAL_FORM: "Individual added successfully",
  KYC_ADD_COMPANY_FORM: "Company added successfully",
  KYC_TABLE_DOCUMENT_DELETE_ENABLED_WARNING_USER:
    "This action cannot be undone",
  KYC_SURNAME_FIELD_VIEW: "Surname",
  KYC_NATIONALITY_FIELD_VIEW: "Nationality",
  KYC_CONTACT_NUMBER_FIELD_VIEW: "Contact number",
  KYC_PASSPORT_DOC_FIELD_VIEW: "Passport",
  KYC_CERTIFICATE_COMERCIAL_LICENSE_FIELD:
    "Certificate of Incorporation or Commercial Licence",
  KYC_PASSPORT_NUMBER_FIELD_VIEW: "Passport number",
  KYC_PASSPORT_EXPIRY_DATE_FIELD_VIEW: "Passport expiry date",
  KYC_CURRICULUM_VITAE_FIELD_VIEW: "Curriculum Vitae",
  KYC_PASSPORT_HELD_DIFFERENT_COUNTRY_FIELD_VIEW:
    "Passport held from a different Country?",
  KYC_ENTITY_POLITICALLY_EXPOSED_FIELD_VIEW:
    "Is the entity politically exposed?",
  KYC_INTELLECTUAL_PROP: "What assets will the company hold",
  KYC_ENTITY_POLITICALLY_EXPOSED_DESCRIPTION_FIELD_VIEW:
    "Please describe why you are a politically exposed person",
  KYC_ENTITY_POLITICALLY_EXPOSED__FIELD_VIEW: "Politically exposed person",
  KYC_ENTITY_SOURCE_WEALTH_FIELD_VIEW: "Source of wealth",
  KYC_ENTITY_JOB_TITLE_FIELD_VIEW: "Job title",
  KYC_ENTITY_SOURCE_FUNDS_FIELD_VIEW: "Source of funds",
  KYC_COMPANY_NAME_FIELD_VIEW: "Company name",
  KYC_COMPANY_OVERVIEW_FIELD_VIEW: "Company overview",
  KYC_COMPANY_OTHER_ADDRESS_FIELD_VIEW: "Other addresses",
  KYC_AUTHORITY_FIELD_VIEW: "Authority",
  KYC_AUTHORITY_JUSTIFICATION_FIELD_VIEW: "Authority justification",
  KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW: "Email address",
  KYC_ENTITY_FAMILY_POLITICALLY_EXPOSED_FIELD_VIEW:
    "Is the entity a family member of a PEP",
  KYC_ENTITY_ASSOCIATE_POLITICALLY_EXPOSED_FIELD_VIEW:
    "Is the entity a close associate of a PEP",
  KYC_COMPANY_CRN_NUMBER_FIELD_VIEW: "CRN number",
  KYC_COMPANY_PRINCIPAL_BUSINESS_PLACE_FIELD_VIEW:
    "Is the principal place of business the same  address as above",
  KYC_COMPANY_TRN_NUMBER_FIELD_VIEW: "TRN number",
  KYC_COMPANY_COUNTRY_INCORPORATION_FIELD_VIEW: "Country of incorporation",
  KYC_COMPANY_JURISDICTION_INCORPORATION_FIELD_VIEW:
    "Jurisdiction of incorporation",
  KYC_COMPANY_ALTERNATIVE_NAME_FIELD_VIEW: "Alternative trading names",
  KYC_BIRTH_DATE_FIELD_VIEW: "Date of birth",
  KYC_REGISTERED_ADDRESS_FIELD_VIEW: "Registered address",
  KYC_CONTACT_FULL_NAME: "Contact's full name",
  KYC_CONTACT_PHONE_NUMBER: "Contact's phone number",
  KYC_CONTACT_EMAIL_ADDRESS_FIELD_VIEW: "Contact's email address",
  KYC_OCCUPATION_FIELD_VIEW: "Occupation",
  KYC_TITLE_FORM_INDIVIDUAL_FORM: "Personal details",
  KYC_AUTHORITY_SIGNATORY_TITLE: "Authority to be signatory",
  KYC_CONTACT_DETAILS_TITLE: "Contact details",
  KYC_MEMORANDUM_FIELD: "Memorandum & Articles of Association",
  KYC_SIGNATORY_DETAILS_TITLE: "Signatory details",
  KYC_FINANCE_CLIENT_CONTRACT_TITLE: "Finance and invoicing client contact",
  KYC_KEY_CLIENT_CONTRACT_TITLE: "Key client contact",
  KYC_RESIDENTIAL_ADDRESS_TITLE: "Residential address",
  KYC_KEY_DOCS_TITLE: "Key company documents",
  KYC_TITLE_FORM_COMPANY_FORM: "Update  company",
  KYC_DOC_SHOW_DIRECTOR_FIELD: "Document showing register of directors",
  KYC_DOC_SHOW_SHARE_FIELD: "Document showing register of shareholders",

  // labels and  Placeholders
  AUDITLOGS_TITLE: "Audit Log",
  AUDIT_LOG_HEADER: "User History",
  AUDITLOGS_FILTER: "Filters",
  AUDITLOGS_FILTER_BUTTON: "Filter",
  AUDITLOGS_FILTER_INFO: "Select Filters to search data in the time line",
  AUDITLOGS_TITLE_TIME_LINE: "Time Line",
  AUDITLOGS_FILTERS_LABEL_STARTUPS: "Startups",
  AUDITLOGS_FILTERS_LABEL_STAKEHOLDERS: "Stakeholders",
  AUDITLOGS_FILTERS_LABEL_ACTIONS: "Actions",
  AUDITLOGS_FILTERS_LABEL_DATEFROM: "Date From",
  AUDITLOGS_FILTERS_LABEL_DATETO: "Date To",
  AUDITLOGS_FILTERS_LABEL_FILTER: "Filter",
  AUDITLOGS_TRANSACTIONS_ACTIONS: "Actions",
  AUDITLOGS_TRANSACTIONS_STARTUPS: "Startups",
  AUDITLOGS_TRANSACTIONS_GROUP_COMPANIES: "Group Companies",
  AUDITLOGS_TRANSACTIONS_STAKEHOLDERS: "Stakeholders",
  AUDITLOGS_TRANSACTIONS_PLACEHOLDER_ACTIONS: "Select actions from the list...",
  AUDITLOGS_TRANSACTIONS_PLACEHOLDER_STARTUPS: "Select from the list...",
  AUDITLOGS_TRANSACTIONS_PLACEHOLDER_STAKEHOLDERS:
    "Select stakeholder from the list...",
  AUDITLOGS_RANGES_TODAY: "Today",
  AUDITLOGS_RANGES_YESTERDAY: "Yesterday",
  AUDITLOGS_RANGES_LASTWEEK: "Last Week",
  AUDITLOGS_RANGES_LASTMONTH: "Last Month",
  AUDITLOGS_RANGES_OLDEST: "Others",

  //label and placeholders  payments
  CARD_IN_USE:
    "This card is attached to your scale subscription and can not be removed unless you change the card in edit subscription.",
  INPUT_CARD_CVC_PLACEHOLDER: "CVC",
  INPUT_CARD_NUMBER_PLACEHOLDER: "1234 1234 1234 1234",
  INPUT_CARD_EXPIRED_PLACEHOLDER: "mm/yy",
  PAYMENT_CARD_SELECT_PACLEHOLDER: "Select from list",
  PAYMENT_CARD_SELECT_LABEL: "Select card",
  PAYMENT_CARD_DETAILS_LABEL: "Card details",
  PAYMENT_CARD_NAME_LABEL: "Name on card",
  PAYMENT_CARD_NAME_PLACEHOLDER: "Type here",
  PAYMENT_CARD_TITLE: "Add card details",
  PAYMENT_CARD_TITLE_BILLING_ADDRESS: "Add billing address",
  PAYMENT_CARD_COUNTRY_LABEL: "Country",
  PAYMENT_CARD_COUNTRY_PLACEHOLDER: "Please select",
  PAYMENT_CARD_ADDRESS_LABEL: "Address",
  PAYMENT_CARD_ADDRESS1_PLACEHOLDER: "Line 1",
  PAYMENT_CARD_ADDRESS2_PLACEHOLDER: "Line 2",
  PAYMENT_CARD_REGION_PLACEHOLDER: "State / Region",
  PAYMENT_CARD_ZIPCODE_PLACEHOLDER: "Postcode / Zip Code",
  PAYMENT_CARD_CITY_LABEL: "City",
  PAYMENT_CARD_CITY_PLACEHOLDER: "City / Town",
  PAYMENT_CARD_FOOTER:
    "By confirming your subscription, you allow Clara to charge your card for this payment and future payments in accordance with their terms.",

  PAYMENT_SUCCESS_FREE_VAT: "I am paying on behalf of a VAT registered company",
  PAYMENT_ERROR_CARD: "Your card was declined",
  PAYMENT_COUPON_INVALID: "Coupon invalid",
  PAYMENT_COUPON_EXPIRED: "Coupon expired",
  AUDITLOGS_TITLE_TIME_MATTER_LINE: "Time Line",
  SUSCRIPTION_MODAL_CONTINUE_BUTTON: "Continue with Start",
  SUSCRIPTION_MODAL_GENERATE_DOCUMENT_TITLE: "Generate, sign and store online",
  SUSCRIPTION_MODAL_GENERATE_DOCUMENT_SUBTITLE: "Generate your documents",
  SUSCRIPTION_MODAL_GENERATE_DOCUMENT_CONTENT: `
    <p>Clara's fully integrated document generation feature pulls together key startup contracts to protect your business and incentivise your team.</p>
	<p>As a Start subscriber, you can generate up to <b>3</b> contracts and then sign them through our integrated DocuSign and automatically store and categorise them under your Document section.</p>
	<p>Upgrade to Scale for <b>unlimited</b> document generation, access to Clara's premium startup contracts, edit capabilities and as many digital signatures through DocuSign as you need.</p>
    `,
  SUSCRIPTION_MODAL_GENERATE_DOCUMENT_BUTTON: "Start Generating Documents",
  SUSCRIPTION_MODAL_HEALTH_CHECK_TITLE: "Scan, solve and safeguard",
  SUSCRIPTION_MODAL_HEALTH_CHECK_SUBTITLE: "Health Check your startup",
  SUSCRIPTION_MODAL_HEALTH_CHECK_CONTENT: `
  <p><b>Health Check</b> scans your startup's details, documents and structure, then highlights key issues for you to address to help you get ready for investment or getting into an accelerator programme.</p>
  <p>Ready to go? As a <b>Start</b> subscriber, you can run three full Health Checks.</p>
  <p>Want to stay in tip top shape? Upgrade to <b>Scale</b> to have Clara run Health Checks automatically in the background.</p>
      `,
  SUSCRIPTION_MODAL_HEALTH_CHECK_BUTTON: "Run Health Check",
  SUSCRIPTION_MODAL_DOCUMENT_DETAIL_TITLE: "Why can't I see this document?",
  SUSCRIPTION_MODAL_DOCUMENT_DETAIL_CONTENT: `
  <p>You have reached the <b>limit</b> of <b>50</b> documents in Clara with your current <b>"Start" subscription</b>. Firstly don't worry your documents are safe and will not be deleted from you Clara profile.</p>
  <p>To regain access to this document, you can remove other unused documents or upgrade to our <b>"Scale" subscription</b>, which has <b>no limitations</b>.</p>
  <p>If you do not see the upgrade button below, then please contact the Clara profile owner.</p>`,
  SUSCRIPTION_MODAL_DOCUMENT_SUBTITLE: "Store your documents",
  SUSCRIPTION_MODAL_DOCUMENT_TITLE: "Store, categorise and keep track",
  SUSCRIPTION_MODAL_DOCUMENT_CONTENT: `
  <p>Clara helps you keep everything in order by storing and categorising your documents - from contracts to constitutional documents and investor updates.</p>
  <p>As a Start subscriber, you can upload up to <b>50</b> documents.</p>
  <p>Need more space? Upgrade to Scale to upload <b>unlimited</b> documents</p>
      `,
  SUSCRIPTION_MODAL_DOCUMENT_BUTTON: "Start Adding Documents",

  SUSCRIPTION_MODAL_SHARE_PROFILE_SUBTITLE: "Share your profile",
  SUSCRIPTION_MODAL_SHARE_PROFILE_TITLE: "Invite, share and share again",
  SUSCRIPTION_MODAL_SHARE_PROFILE_CONTENT: `
  <p>Get your startup out there, with profile shares. Invite investors, lawyers and stakeholders to view your profile's information, whenever you're ready.</p>
  <p>As a Start subscriber, you can share your profile up to <b>three</b> times.</p>
  <p>Need to share with more people? Scale up to share your profile as many times as you need.</p>
      `,
  SUSCRIPTION_MODAL_SHARE_PROFILE_BUTTON: "Start Sharing",
  SHARE_STARTUP_PROFILE_TITLE: `Share {{{startupName}}}'s profile`,
  // incorporation portal

  INCORPORATION_PORTAL_TABLE_COLUMN_SIGNATORIES: `{{signatories}} Signatorie(s) / {{signed}} Signed`,
  INCORPORATION_PORTAL_MODAL_CHANGED_ASSIGN_TO_AGENT: `Assign to    Agent {{fullName}} for Internal Review?`,
  INCORPORATION_PORTAL_MODAL_CHANGED_ASSIGN_TO_AGENT_SUCCES: ` Agent  {{fullName}} has been assign {{docName}}`,
  INCORPORATION_PORTAL_MODAL_CHANGED_NUMBER_APPLICATION:
    "ADGM Portal Application number",
  INCORPORATION_PORTAL_MODAL_CHANGED_NUMBER_APPLICATION_DISCLAIMER:
    "<b>Warning:</b> When you update the ADGM number, all documents that contain this will be regenerated. Except those who were replaced manually.",
  INCORPORATION_PORTAL_MODAL_REGENERATE_ADGM_DOCUMENTS:
    "Regenerate ADGM Documents",
  INCORPORATION_PORTAL_MODAL_REGENERATE_ADGM_DOCUMENTS_DISCLAIMER:
    "This action cannot be undone.",
  INCORPORATION_PORTAL_MODAL_REGENERATE_ADGM_DOCUMENTS_QUESTION:
    "Are you sure you want to regenerate and overwrite all ADGM documents?",
  INCORPORATION_PORTAL_MODAL_CANCEL_ENVELOPE: "Void Envelope",
  INCORPORATION_PORTAL_MODAL_CANCEL_ENVELOPE_LABEL:
    "By voiding this envelope, recipients can no longer view or sign it. Recipient will receive an email notification, which includes your reason for voiding the document",
  INCORPORATION_PORTAL_MODAL_CANCEL_ENVELOPE_NOTE:
    "<b>Note:</b> Once the envelope is voided, if the recipient has not signed the envelope it will no longer be accessible. If a recipient has signed the envelope, they will be able to access it but there will be a 'Void' watermark on all of the pages. This action cannot be undone.",
  INCORPORATION_PORTAL_MODAL_CANCEL_ENVELOPE_DISCLAIMER:
    "<b>Warning:</b> This action cannot be undone.",
  INCORPORATION_PORTAL_MODAL_ADGM_BUNDLE:
    "Your ADGM bundle has been generated, click below to download.",
  INCORPORATION_PORTAL_MODAL_INCORPORATION_PACK_CREATION_TITLE:
    "Welcome pack creation",
  INCORPORATION_PORTAL_MODAL_INCORPORATION_PACK_CREATION_CERTIFICATE_OF_INCORPORATION:
    "Upload the certificate of incorporation:",
  INCORPORATION_PORTAL_MODAL_INCORPORATION_PACK_COMERCIAL_LICENCE:
    "Upload the commercial licence:",
  INCORPORATION_PORTAL_MODAL_REPLACE_DOCUMENT_TITLE: "Replace Document",
  INCORPORATION_PORTAL_MODAL_REPLACE_DOCUMENT_CURRENT_DOCUMENT:
    "Current document:",
  INCORPORATION_PORTAL_MODAL_REPLACE_DOCUMENT_NEW_DOCUMENT: "New document:",
  //mutations in app

  AUDITLOGS_TRANSACTIONS_GETDDREPORT: "Get Ddr Report",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETECOMMENT:
    "Delete Comment on {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_GETFILEBYID: "Get File on {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_LOGIN: "logged in ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_GENERATEAGREEMENT:
    "Generate Agreement on {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_REQUESTSIGNATURES:
    "Request Signatures on {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_GETPREVIEWAGREEMENTDOCUMENT:
    "Preview Document on {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_LOGOUT: "logged out ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETEDRAFTDOCUMENT:
    "Remove an  Document Draft on {{startupName}}",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_CREATEDOCUMENTS:
    "Created Document on {{startupName}}",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETEDOCUMENT:
    "Remove an  Document  on {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_UPLOADNEWFILE: "Upload Document ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTSCOPING: "updated Scoping ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTGROUPCOMPANY: "Upserting Company ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_SENDHEALTHCHECKREQUEST:
    "sent a health check request on {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTDOCUMENT: "added an document on",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTCOMPONENTSTAKEHOLDER:
    "added an stakeholder on {{startupName}}",

  AUDITLOGS_TRANSACTIONS_CHANGEPASSWORD: "updated his password ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTISSUE:
    "added ad Remark on {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETEISSUE:
    "Remove an Remark in {{startupName}} ",
  AUDITLOGS_TRANSACTIONS_FORGOTPASS: "executed forgot password ",
  AUDITLOGS_TRANSACTIONS_SIGNUP: "signed up",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETEGROUPCOMPANY:
    "Remove an Group Company on {{startupName}} ",
  GQL_MUTATION_SINGED_DOCUMENT_CHANGED:
    "{{documentName}} changed {{key}} to {{change}} on {{startupName}}",
  AUDITLOGS_TRANSACTIONS_REQUESTSIGNATURES:
    "Request Signature on {{startupName}}",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_SENDREQUESTSIGNATURESCLIENTAPPROVAL:
    "sent request signature to client",

  // time line matter
  AUDITLOGS_TRANSACTIONS_CREATE_MATTER: "has created this matter",
  AUDITLOGS_TRANSACTIONS_SEND_CLIENT_SCOPING: "send {{to}} for Scoping",
  AUDITLOGS_TRANSACTIONS_SUBMIT_SCOPING: "submitted Scoping",
  AUDITLOGS_TRANSACTIONS_SEND_KYC_TO_CLIENT:
    " accepted the proposal and now started KYC (Individual or Company)",
  AUDITLOGS_TRANSACTIONS_SUBMITS_KYC: "submitted KYC",
  AUDITLOGS_TRANSACTIONS_SEND_QUESTIONNAIRE_TO_CLIENT:
    "send {{to}} to Questionnaire",
  AUDITLOGS_TRANSACTIONS_COMPLETE_QUESTIONNAIRE: "submitted the questionnaire",
  AUDITLOGS_TRANSACTIONS_GENERATE_ADGM_DOCUMENTS: "generated ADGM documents",
  AUDITLOGS_TRANSACTIONS_REGENERATE_ADGM_DOCUMENTS:
    "regenerated ADGM documents",
  AUDITLOGS_TRANSACTIONS_APPROVES_DOCUMENTS:
    "approved document {{documentName}}",
  AUDITLOGS_TRANSACTIONS_DECLINED_DOCUMENT:
    "declined document {{documentName}}",
  AUDITLOGS_TRANSACTIONS_REPLACED_DOCUMENT:
    "replaced document {{documentName}}",
  AUDITLOGS_TRANSACTIONS_ASSINGS_TO_SSA_FOR_REVIEW:
    " assigned the matter to {{to}}  for review",
  AUDITLOGS_TRANSACTIONS_ASSINGS_TO_SA_FOR_REVIEW:
    "assigned the matter to {{to}}  for review",
  AUDITLOGS_TRANSACTIONS_SUBMITS_DOCUMENTS_FOR_SIGNATURE:
    "request signatures  for all ADGM Documents ",
  AUDITLOGS_TRANSACTIONS_GENERATE_ADGM_BUNDLE: "generated ADGM bundle",
  AUDITLOGS_TRANSACTIONS_CONFIRMS_ADGM_SUBMISSION: " confirmed ADGM submission",
  AUDITLOGS_TRANSACTIONS_CREATE_INCORPORATION_PACK:
    " created the incorporation Pack",
  AUDITLOGS_TRANSACTIONS_SEND_INCORPORATION_PACK:
    " send the incorporation Pack to {{to}}",
  AUDITLOGS_TRANSACTIONS_SEND_TO_CLIENT_TO_REVIEW:
    " send Documents to {{to}} for review",
  AUDITLOGS_TRANSACTIONS_SEND_PROPOSAL: "send Proposal to {{to}}",
  AUDITLOGS_TRANSACTIONS_PROPOSAL_PAY: "paid Proposal",
  AUDITLOGS_TRANSACTIONS_PROPOSAL_DECLINED: "declaine the Proposal",
  AUDITLOGS_TRANSACTIONS_SEND_ENGAGEMENT: "send Engagement to  {{to}}",
  AUDITLOGS_TRANSACTIONS_ENGAGEMENT_PAY: "paid Engagement",
  AUDITLOGS_TRANSACTIONS_ENGAGEMENT_DECLINED: "declaine the Engagement",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_SENDREQUESTREVIEWCLIENTAPPROVAL:
    "send request review to client",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_APPROVEDOCUMENTINCORPORATION:
    "approved document incorporatation",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_ASSIGNTOAGENT:
    "incorporation assign to agent",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DECLINEDOCUMENTINCORPORATION:
    "incorporation assign to agent",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTINCORPORATION:
    "update incorporation",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTQUESTIONNAIRE:
    "update questionnaire",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTCOMPONENTSHAREINCENTIVEPLAN:
    "update share incentive plan",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTKYCINDIVIDUALDRAFTFLOW:
    "Updated KYC individual Flow",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_SENDEMAILENGAGEMENT:
    "Sent engagement notification email",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTCOMPONENTDIRECTORORSHAREHOLDER:
    "updated Director or Shareholder",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_APPROVEPROPOSAL: "Approved proposal",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTKYCENTITYDRAFTFLOW:
    "Updated Entity in KYC flow",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTQUESTIONNAIREINDIVIDUALDRAFTFLOW:
    "Updated questionnaire in Individual flow",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_SENDQUESTIONNAIREEMAIL:
    "Questionnaire notification email sent",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_SETASPAIDENGAGEMENT:
    "Set engagement Paid",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTKYCCOMPANYDRAFTFLOW:
    "Updated Company in KYC flow",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_CONFIRMADGMSUBMISSION:
    "confirmed ADGM submission",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTCOMPONENTQUESTIONNAIREDIRECTOR:
    "updated questionnaire director",

  //audit-share-profile
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_SETUSERACCESSTOASTARTUP:
    "SET ACCESS TO STARTUP",
  //in list Filter Actions
  AUDITLOGS_TRANSACTIONS_LOGIN_LIST: "login",
  AUDITLOGS_TRANSACTIONS_LOGOUT_LIST: "logout",
  AUDITLOGS_TRANSACTIONS_GETDDREPORT_LIST: "Get Ddr Report",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETECOMMENT_LIST: "Remove Document",
  AUDITLOGS_TRANSACTIONS_GETFILEBYID_LIST: "Get File",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_GENERATEAGREEMENT_LIST:
    "Agreement Generate",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_REQUESTSIGNATURES_LIST:
    "Request Signatures",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_GETPREVIEWAGREEMENTDOCUMENT_LIST:
    "Get Preview Agreement",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETEDRAFTDOCUMENT_LIST:
    "Remove Draft Document",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_CREATEDOCUMENTS_LIST: "Created Document",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETEDOCUMENT_LIST: "Remove Document",
  AUDITLOGS_TRANSACTIONS_UPLOADNEWFILE_LIST: "Upload New File",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTSCOPING_LIST: "Add Scoping",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_SENDHEALTHCHECKREQUEST_LIST:
    "Sent Healt Check Request",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTDOCUMENT_LIST: "Add Document",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTCOMPONENTSTAKEHOLDER_LIST:
    "Add Stakeholder",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPDATEKYCTHIRDPARTYDRAFTFLOW:
    "Updated KYC flow",
  AUDITLOGS_TRANSACTIONS_CHANGEPASSWORD_LIST: "Changed Password",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_UPSERTISSUE_LIST: "Add Remark",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETEISSUE_LIST: "Remove Remark",
  AUDITLOGS_TRANSACTIONS_FORGOTPASS_LIST: "Forgot Password",
  AUDITLOGS_TRANSACTIONS_GQL_MUTATION_DELETEGROUPCOMPANY_LIST:
    "Remove Group Company",
  AUDITLOGS_TRANSACTIONS_GETPREVIEWAGREEMENTDOCUMENT_LIST: "Preview Agreement",
  AUDITLOGS_TRANSACTIONS_SIGNUP_LIST: "Sign up ",
  AUDITLOGS_TRANSACTIONS_GENERATEAGREEMENT_LIST: "Generate Document",
  AUDITLOGS_TRANSACTIONS_REQUESTSIGNATURES_LIST: "Request Signatures",
  CHAT_FROM_SUB_CATEGORIES_COMPANY: "Company",
  CHAT_FROM_SUB_CATEGORIES_THIRD_PARTY: "Third Party",
  NOTIFICATION_SUCCESS: "Updated Successfully",
  NOTIFICATION_SUCCESS_EXERCISE_NOTICE:
    "The Exercise notice for {{{agreementNameGrant}}} - {{vestingDate}} has been sent to {{{params.participant.fullName}}} for them to sign",
  NOTIFICATION_SUCCESS_CONVERTED_SHARES:
    "{{vestingDate}} for {{{name}}} has been converted to share",
  NOTIFICATION_SUCCESS_DELETED: "{{name}} has been deleted",
  NOTIFICATION_SUCCESS_KYC_INDIVIDUAL: "Individual updated successfully",
  NOTIFICATION_SUCCESS_VOID_DOCUMENT: "{{name}} has been successfully voided",
  NOTIFICATION_SUCCESS_RESEND_DOCUMENT:
    "{{{name}}} has successfully been resent",
  NOTIFICATION_SUCCESS_TERMINATED: "{{name}} has been Terminated",
  NOTIFICATION_SUCCESS_GETSIGNATURES_DOCUMENT:
    "{{{name}}} has been successfully sent out for Signing",
  NOTIFICATION_SUCCESS_SHARING_PROFILE:
    "An invite has been {{sentType}} to {{email}}",
  NOTIFICATION_BAD: "Update failed",
  NOTIFICATION_SUCCESS_SEND_MAIL: "Email sent successfully",
  LAST_UPDATED: "Last updated ",
  TEXT_ALL: "All companies",
  TEXT_EDIT: "Edit",
  TEXT_UPDATE: "Update",
  TEXT_REMOVE: "Remove",
  TEXT_SIGN_UP: "Join Clara",
  TEXT_LOG_IN: "Log in",
  TEXT_LAST_UPDATE: "Last updated",
  TEXT_CREATED_BY: "Created by",
  TEXT_CLARA_PLATFORM: "Clara Platform",
  TEXT_NEXT: "Next",
  TEXT_CONFIRM: "Confirm",
  TEXT_YES: "Yes",
  TEXT_NO: "No",
  TEXT_LATER: "Later",
  TEXT_UNPAID: "Unpaid",
  TEXT_PAID: "Paid",
  TEXT_PLEASE_WAIT: "Please wait...",
  TEXT_PROCESSING: "Processing",
  HOLDING_COMPANY: "Holding Company SPV",
  FOUNDATION: "Foundation",
  TECH_STARTUP: "Tech Startup",
  OPERATING_COMPANY: "Operating Company",
  OTHER: "Other",
  ABU_DHABI_MARKET: "ADGM(Abu Dhabi Global Market)",
  1: "JAN",
  2: "FEB",
  3: "MAR",
  4: "APR",
  5: "MAY",
  6: "JUN",
  7: "JUL",
  8: "AUG",
  9: "SEP",
  10: "OCT",
  11: "NOV",
  12: "DEC",
  //Questionary
  QUESTIONNAIRE_TITLE: "Questionnaire",
  QUESTIONNAIRE_TSL: "Tech Licence questionnaire",
  QUESTIONNAIRE_INDIVIDUAL_FORM_TITLE: "Individual Details",
  QUESTIONNAIRE_COMPANY_FORM_TITLE: "Company Details",
  QUESTIONNAIRE: " ",
  QUESTIONNAIRE_THIRD_PARTY_INDIVIDUAL_QUESTIONNAIRE_FORM_TITEL:
    "Third Party Details",
  QUESTIONARY_FORM_COMPANY_NAME_HOLDING: "Holding",
  QUESTIONARY_FORM_COMPANY_NAME_HOLDING_TEXT: "{{companyName}} Holding",
  QUESTIONARY_FORM_COMPANY_NAME_HOLDING_PLACEHOLDER: "Proposed Company Name...",
  QUESTIONARY_FORM_COMPANY_NAME_HOLDINGS: "Holdings",
  QUESTIONARY_FORM_COMPANY_NAME_HOLDINGS_TEXT: "{{companyName}} Holdings",
  QUESTIONARY_FORM_COMPANY_NAME_SPV: "SPV",
  QUESTIONARY_FORM_COMPANY_NAME_SPV_TEXT: "{{companyName}} SPV",
  QUESTIONARY_FORM_COMPANY_NAME_LIMITED: "Limited",
  QUESTIONARY_FORM_COMPANY_NAME_LIMITED_TEXT:
    "{{companyName}} {{holdingName}} Limited",
  QUESTIONARY_FORM_COMPANY_NAME_LTD: "LTD",
  QUESTIONARY_FORM_COMPANY_NAME_LTD_TEXT: "{{companyName}} {{holdingName}} LTD",
  QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER: "Ltd",
  QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER_TEXT:
    "{{companyName}} {{holdingName}} Ltd",
  QUESTIONARY_FORM_COMPANY_DETAILS_QUESTION1:
    "Does the proposed Company Name have a special meaning or is it a shortening, abbreviation or acronym?",
  QUESTIONARY_FORM_COMPANY_DETAILS_SPECIAL_MEANING_PLACEHOLDER:
    "Describe in detail the meaning of the proposed Company Name...",
  QUESTIONARY_FORM_COMPANY_DETAILS_SPECIAL_MEANING_TOOLTIP:
    "If the proposed name is not a common English word or is a shortening, abbreviation or acronym, the incorporation application must include information explaining the meaning behind the name. Failure to provide this information can delay the incorporation application.",
  QUESTIONARY_FORM_COMPANY_DETAILS_QUESTION2:
    "Will you provide an Arabic translation of your proposed Company Name?",
  QUESTIONARY_FORM_COMPANY_DETAILS_ARABIC_COMPANY_NAME_PLACEHOLDER:
    "Please copy and paste the Company Name in Arabic...",
  QUESTIONARY_FORM_COMPANY_DETAILS_ARABIC_TOOLTIP:
    "An Arabic translation is optional but if you provide one, ensure it is the full translation, including the suffix e.g. 'Holding Limited'. If you do not provide an Arabic translation, the ADGM Registration Authority will impose a transliterated one.",
  QUESTIONARY_FORM_COMPANY_DETAILS_ARABIC_NOTE:
    "<b>Please note:</b><br/>ADGM will provide a transliterated version on the Company's behalf that will not be open to amendment",
  QUESTIONARY_FORM_COMPANY_DETAILS_QUESTION3:
    "Are you affiliated with an existing company in any country that uses the same or similar name?",
  QUESTIONARY_FORM_COMPANY_DETAILS_AFFILIATION_TOOLTIP:
    "Where a new incorporation is affiliated with an existing company anywhere in the world that has a similar or same name, we must provide a letter of consent to use the name signed by the shareholders of that company. We will prepare this consent letter for you. If obtaining getting the shareholders’ signatures will be difficult, we suggest selecting a different name for your Company.",
  QUESTIONARY_FORM_COMPANY_DETAILS_AFFILIATED_PLACEHOLDER:
    "Full legal name of the company...",
  QUESTIONARY_FORM_COMPANY_DETAILS_LABEL_AFFILIATED_LEGALNAME:
    "Company Name as on Certificate of Incorporation",
  QUESTIONARY_FORM_COMPANY_DETAILS_LABEL_AFFILIATED_FILE:
    "Document confirming shareholders of affiliated company (This document must be dated or certified within the last 2 months):",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY:
    "Intellectual property",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_ALREADY_COMPANY:
    "Shares in a company already incorporated",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY:
    "Shares in a company to be established",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_OTHER: "Other",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY_NOTE: " ",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY_STATE_WHY_PLACEHOLDER:
    "Please state why...",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY_FILE_LABEL:
    "Upload any supporting documentation",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY_NOTE:
    "Please confirm that the company will be set up within 6 months of the ADGM SPV being registered",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY_ADGM_NO:
    "ADGM requires an undertaking to be submitted with the incorporation application confirming that the SPV will fulfill its purpose within 6 months of being incorporated. Please only refer to the jurisdictions where this timeframe is possible",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY_COUNTRIES_LIST_LABEL:
    "Please list the country or countries where the new entities will be established. If an entity is being established in the UAE, please specify whether this will be onshore, or list the releative freezone.",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY_COUNTRIES_LIST_PLACHEHOLDER:
    "Where will these companies be established",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY_SERVICES_LABEL:
    "Please state what services or goodsthe new companies will supply and where they will supply them",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY_SERVICES_PLACEHOLDER:
    "Type here...",
  QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY_OTHER_PLACEHOLDER:
    "Describe in detail the asset that the SPV will hold.",
  QUESTIONARY_ARTICLES_ASSOCIATION_QUESTION_UPLOAD:
    "Would you like to upload yours now?",
  QUESTIONARY_AUTHORIZED_SIGNATORIES_UAE_TITLE:
    "Select at least one <b>UAE Resident</b> or <b>GCC National</b>",
  QUESTIONARY_AUTHORIZED_SIGNATORIES_OTHER_TITLE:
    "Select any other Authorised Signatories",
  QUESTIONARY_AUTHORIZED_SIGNATORIES_ADD_INDIVIDUAL: "Add Individual",
  QUESTIONARY_AUTHORIZED_SIGNATORIES_ADDITIONAL_SIGNATORIES:
    "Additional Signatories",
  QUESTIONARY_AUTHORIZED_SIGNATORIES_TITLE: "Authorised Signatories",
  QUESTIONARY_AUTHORIZED_SIGNATORIES_BUTTON_NASS:
    "Nominne Authorised Signatory Service",
  QUESTIONARY_AUTHORIZED_SIGNATORIES_BUTTON_SFCI:
    "Select from current Individuals",
  QUESTIONARY_DATA_PROTECTION_BUTTON: "Add Individual",

  QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_SHAREHOLDERS: "Shareholders",
  QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_DIRECTORS: "Directors",
  QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_COMPANIES: "Companies",
  QUESTIONARY_SIGNATORIES_OFFICE_AGREEMENT_TEXT:
    "Signatories for Registered Office Agreement",
  QUESTIONARY_BENEFICIAL_OWNERS_TEXT_DIRECTORS: "Beneficial Owners",
  QUESTIONARY_SHAREHOLDER_TEXT: "Shareholders",
  QUESTIONARY_SHAREHOLDER_DIRECTORS_BUTTON_INDIVIDUAL: "Individual",
  QUESTIONARY_SHAREHOLDER_BUTTON_INDIVIDUAL_EDIT: "Add Individual",
  QUESTIONARY_SHAREHOLDER_BUTTON_COMPANY_EDIT: "Add Company",
  QUESTIONARY_SHAREHOLDER_DIRECTORS_TITLE_BUTTONS: "Add new Director",
  QUESTIONARY_BENEFICIAL_OWNER_TITLE_BUTTONS: "Add new Beneficial Owner",
  QUESTIONARY_SHAREHOLDER_SHAREHOLDER_TITLE_BUTTONS: "Add new Shareholder",
  QUESTIONARY_SHAREHOLDER_DIRECTORS_BUTTON_COMPANY: "Company",
  QUESTIONARY_ADDITIONAL_SIGNATURE_BUTTON: "Add Individual",
  QUESTIONARY_BENEFICIAL_OWNERS_BUTTON: "Add another Individual",
  QUESTIONARY_SHARE_CAPITAL_BUTTON_DEFAULT:
    "I want to use the recommended setup",
  QUESTIONARY_SHARE_CAPITAL_BUTTON_CUSTOM: "No, I wish to set my own amount",
  QUESTIONARY_SHARE_CAPITAL_BUTTON_SHARES: "Par Value of Shares (USD)",
  QUESTIONARY_SHARE_CAPITAL_BUTTON_AUTHORISED_SHARES:
    "Number of Authorised Shares:",
  QUESTIONARY_SHARE_CAPITAL_BUTTON_LIMIT_AUTH_SHARES:
    "Authorised Share Capital is the maximum value of all the shares (calculated against par value) that the Directors are authorised to issue.",
  QUESTIONARY_SHARE_CAPITAL_BUTTON_LIMIT_PAR_VALUES:
    "The Par Value is the face value of the Shares, not the market value. It is the lowest value that the Shares may be issued at and is often set at a nominal rate as low as USD 0.0001.",
  QUESTIONARY_SHARE_CAPITAL_BUTTON_REQUIERED_SHARE_CAPITAL:
    "<label>Required share capital</label> <b>(USD) {{amount}}</b>",
  QUESTIONARY_SHARES_ISSUES_TITLE_N_SHARES: "No: of shares",
  QUESTIONARY_SHARES_ISSUES_TITLE_PAID_UNPAID: "Paid/Unpaid",
  QUESTIONARY_SHARES_ISSUES_AUTHORIZED_SHARES: "Authorised Shares:",
  QUESTIONARY_SHARES_ISSUES_TOTAL_LEFT: "Total Left:",
  QUESTIONARY_SHARES_ISSUES_ERROR: "You don’t have enough available shares...",
  QUESTIONNAIRE_TAB_NAME_DIRECTORS: "Directors",
  QUESTIONNAIRE_TAB_NAME_SHAREHOLDERS: "Shareholders",
  QUESTIONNAIRE_TAB_NAME_COMPANYDETAILS: "Company Details",
  QUESTIONNAIRE_TAB_NAME_SIGNATORIES: "Signatories",
  QUESTIONNAIRE_TAB_NAME_DATACONTROLLER: "Data Controller",
  QUESTIONNAIRE_TAB_NAME_BENEFICIALOWNERS: "Beneficial Owners",
  QUESTIONNAIRE_TAB_NAME_BANKINGSERVICES: "Banking Services",
  QUESTIONNAIRE_TAB_NAME_SHARESDATA: "Shareholders",
  QUESTIONNAIRE_TAB_NAME_AGREEMENTSDATA: "Agreements",
  QUESTIONNAIRE_FIELD_COMPANY_TYPE: "Company type",
  QUESTIONNAIRE_FIELD_LEGAL_ENTITY: "Entity legal structure",
  QUESTIONNAIRE_FIELD_COMPANY_SUFFIX: "Company name suffix",
  QUESTIONNAIRE_FIELD_COMPANY_ABBREVIATIONS:
    "Company name special meanings abbreviations or acronyms?",
  QUESTIONNAIRE_FIELD_ARABIC_NAME: "Additional Arabic name",
  QUESTIONNAIRE_FIELD_SIMILAR_COMPANY_NAME_AFFILIATION:
    "Affiliation with any company in any jurisdiction that uses a similar name?",
  QUESTIONNAIRE_FIELD_HOLDING_ASSETS: "What assets will the company hold?",
  QUESTIONNAIRE_FIELD_WHY_INTELLECTUAL_PROPERTY:
    "Reason for holding Intellectual Property",
  QUESTIONNAIRE_FILE_NAME_SUPPORTING_DOCUMENTATION: "Supporting Documentation",
  QUESTIONNAIRE_FIELD_ARTICLES_ASSOCIATION: "ADGM Articles of Association",
  QUESTIONNAIRE_FIELD_SERVICES_COMPANY:
    "What services or goods the new companies will supply and where they will supply them",
  QUESTIONNAIRE_FIELD_COMPANY_SET_UP_ADGM:
    "Will the company be set up within 6 months of the ADGM SPV being registered",
  QUESTIONNAIRE_REGISTERED_OFFICE_AND_SERIVICES:
    "Please add the contact details for your witness below.",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_ALREADY_INCORPORATED_BUTTON:
    "Shares in a company already incorporated",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_TOBE_INCORPORATED_BUTTON:
    "Shares in a company to be incorporated",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_INTELLECTUAL_PROPERTY_BUTTON:
    "Intellectual Property",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_OTHER_ASSET_BUTTON: "Other Assets",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_PRINCIPAL_TITLE:
    "Other assets being added to the Holding Company?",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_ALREADY_INCORPORATED_LABEL:
    "If your Company will, within 6 months of its incorporation, hold shares in any Company which has already been incorporated, please enter the jurisdiction of incorporation of each Company.",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_TOBE_INCORPORATED_LABEL:
    "If your Company will, within 6 months of its incorporation, hold shares in any Company which has not been incorporated at today’s date, please enter the jurisdiction of incorporation of each Company.",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_INTELLECTUAL_PROPERTY_LABEL:
    "Please describe the Intellectual Property that the Company will own, including whether the Intellectual Property is registered or unregistered and who owns it.",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_INTELLECTUAL_PROPERTY_TYPE_LABEL:
    " Please select the type of Intellectual Property the Company will own:",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_ALREADY_INCORPORATED_PLACEHOLDER:
    "Enter the jurisdiction of incorporation of each company...",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_OTHER_LABEL:
    "Please describe any other assets your Company will hold, including where these assets are located.",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_TOBE_INCORPORATED_PLACEHOLDER:
    "Enter the jurisdiction in which each company will be incorporated...",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_INTELLECTUAL_PROPERTY_PLACEHOLDER:
    "Describe the Intellectual Property...",
  CHAT_SCOPING_HOLDING_COMPANY_SPV_OTHER_ASSET_PLACEHOLDERS: "Other Assets",
  CHAT_DECLARE_PROVIDE_GENUINE_INFORMATION_MODAL_TEXT:
    "<p>To complete the onboarding process and proceed to incorporation of the ADGM entity, we must verify the information and documents you have provided.<br/><br/>If the information and documents provided are correct, click <b>OK</b> and then select <b>Yes</b>.<br/><br/>If you wish to cancel this matter, click <b>Cancel Matter</b>.</p>",
  CHAT_DECLARE_PROVIDE_GENUINE_INFORMATION_MODAL_TITLE: "Declaration",
  CHAT_DECLARE_PROVIDE_GENUINE_INFORMATION_MODAL_BUTTON_CANCEL: "Cancel Matter",
  CHAT_DECLARE_PROVIDE_GENUINE_INFORMATION_MODAL_BUTTON_OK: "Ok",
  TEXT_BUTTON_SINGLE: "Single",
  TEXT_BUTTON_JOINT: "Joint",
  TEXT_NUMBER_OF_COMPANIES: "Number of companies",
  KYC_ENTITY_PEP_INDIVIDUALS_BUTTON: "Add PEP",
  KYC_ENTITY_PEP_INDIVIDUALS_TEXT_PEP: "PEPs",
  KYC_ENTITY_EDIT_MEMBERS_TEXT_COMPANY: "Company",
  KYC_ENTITY_EDIT_MEMBERS_TEXT_DIRECTORS_MANAGERS: "Directors / Managers",
  KYC_ENTITY_EDIT_MEMBERS_TEXT_ADDITIONAL_SHAREHOLDERS: "Shareholders",
  //PORTAL DOCUMENTS
  CLIENT_REVIEW_UPPER_TITLE: "Client Review",
  CLIENT_APPROVAL_TITLE: "Client approval of documents",
  CLIENT_APPROVAL_SUBTITLE: "The client needs to approve the following forms",
  APPROVAL_TITLE: "Approval",
  MAIN_CLIENT: "Main Client",
  REQUEST_APPROVAL: "Request Approval",
  REQUEST_SIGNATURES: "Send Incorporation Bundle",
  REQUEST_WELCOME_PACK: "Send Welcome Pack",
  APPROVAL_FINAL_DOCUMENTS: "Approval of final documents.",
  LABEL_DOCUMENT_DECLINE_ISSUES: "Comments",
  TITLEL_DOCUMENT_DECLINE: "Consent to Act",
  SIGNATORIES_UPPER_TITLE: "ADGM documents",
  SIGNATORIES_SUBTITLE: "The client needs to DocuSign the following forms",
  SIGNERS_TITLE: "Client Signers",
  SIGNATORIES_LEFT_SUBTITLE: "Client Sign off",
  SIGNATORIES_LEFT_TITLE: "DocuSign of all ADGM Documents",

  INCORPORATION_PACK_CLIENT: "Client",
  INCORPORATION_PACK_CLIENT_PRIMARY: "Primary Client",
  INCORPORATION_PACK_SIGNATORIES_LEFT_SUBTITLE: "Clara Formations",
  INCORPORATION_PACK_SIGNATORIES_LEFT_TITLE:
    "Incorporation Documents and Welcome Pack",
  INCORPORATION_PACK_UPPER_TITLE: "Incorporation Documents",
  INCORPORATION_PACK_SUBTITLE: "Copies of the final Legal Documents",
  INCORPORATION_PACK_SERVICE_MANAGER_TITLE:
    "Sign Registered Office / Servicce Agreements",
  INCORPORATION_PACK_SERVICE_MANAGER_SUBTITLE:
    "The service agent manager to sign Registered Office and Service Agreement",
  INCORPORATION_PACK_SERVICE_MANAGER_DOCUMENT_CLIENT_TITLE:
    "Document for Client to Wet Sign and Return",
  INCORPORATION_PACK_SERVICE_MANAGER_DOCUMENT_CLIENT_SUBTITLE:
    "The client needs to approve the following forms",
  INCORPORATION_PACK_SERVICE_MANAGER_DOCUMENT_DOCUSIGN_TITLE:
    "Documents for Client to Sign in DocuSign",
  INCORPORATION_PACK_SERVICE_MANAGER_DOCUMENT_DOCUSIGN_SUBTITLE:
    "The client needs to approve the following forms",
  INCORPORATION_VIEW_PROFILES_BUTTON: "View Documents",
  SERVICE_AGENT_INCORPORATION_PACK: "Service Agent Manger",

  //SUBMIT SCREENS
  SCOPING_FORM_TITLE: "Scoping",
  SCOPING_FORM_TITLE_SUBMITTED: "Scoping Submitted",
  SCOPING_FIRST_TITLE_SUBMITTED:
    "Thank you! <br />Your responses have been submitted.",
  SCOPING_FIRST_TITLE: "Understanding your needs and requirements",
  SCOPING_FIRST_TEXT:
    "Before we can get moving on getting your new entity setup we need to ask you a few simple questions.",
  SCOPING_FIRST_TEXT_SUBMITTED: "You will soon receive our proposal by email.",
  SCOPING_SECOND_TEXT:
    "Don’t worry it will only take a few minutes to complete.",
  SCOPING_SECOND_TEXT_SUBMITTED: " ",
  SCOPING_SUBMITTED_STATUS_BUTTON: "Status",
  SCOPING_SUBMITTED_FORMATION_STATUS_BUTTON: "Formation Status",
  SCOPING_SUBMITTED_ACADEMY_BUTTON: "Academy",
  SCOPING_PROPOSAL_VIEW_TEXT: "View Proposal",
  //SCOPING DISAGREE
  SCOPING_DISAGREE_FORM_TITLE: "Clara Formations",
  SCOPING_DISAGREE_FIRST_TITLE: "Sorry it's us, not you!",
  SCOPING_DISAGREE_FIRST_TEXT:
    "We are sorry we can't support your request right now!",
  SCOPING_DISAGREE_SECOND_TEXT:
    "We are always adding new features to Clara so please check back at another time.",
  SCOPING_DISAGREE_BUTTON_NEXT_TEXT: "Done",
  QUESTIONNAIRE_FORM_TITLE: "Clara Hub",
  QUESTIONNAIRE_FIRST_TITLE_SUBMITTED:
    "You’re all done. Sit back whilst we review the details",
  QUESTIONNAIRE_FIRST_TITLE: "Thank you for paying our invoice.",
  QUESTIONNAIRE_FIRST_TEXT:
    "We will now collect the rest of the information and documents that we need to prepare the incorporation documents and application with ADGM.",
  QUESTIONNAIRE_FIRST_TEXT_SUBMITTED:
    "You will get an email confirming our receipt of the submitted information.",
  QUESTIONNAIRE_SECOND_TEXT: "",
  QUESTIONNAIRE_SECOND_TEXT_SUBMITTED:
    "Please note that if we have any questions we will reach out to the person you set as the primary contact.",

  KYC_FORM_TITLE_SUBMITTED: "KYC has been submitted.",
  KYC_FORM_INDIVIDUAL_TITLE: "Individual KYC",
  KYC_FORM_COMPANY_TITLE: "Company KYC",
  KYC_FIRST_TITLE_SUBMITTED:
    "<p>Thank you! Your responses have been submitted.​</p>",
  KYC_FIRST_TITLE: "Time for us to get to know each other.",
  KYC_FIRST_TEXT_SUBMITTED:
    "Our team will review your responses and contact you shortly.",
  KYC_FIRST_TEXT:
    "Before we can start work, we need some more information about our client. Click Get Started to start our onboarding questionnaire.",
  KYC_SECOND_TEXT_SUBMITTED: " ",
  KYC_SECOND_TEXT:
    "You will need information and documents relating to the person or company who will be our client (e.g. passport copy, visa, proof of residential address, constitutional documents and registers) and the persons who will be the Directors and Shareholders of your new Company.",

  QUESTIONNAIRE_FORM_TITLE_SUBMITTED: "Questionnaire has been submitted.",

  INCORPORATION_FORM_TITLE: "Clara Formations",
  INCORPORATION_FIRST_TITLE_SUBMITTED: "We have your submitted documents...",
  INCORPORATION_FIRST_TITLE: "You have documents ready to review",
  INCORPORATION_FIRST_TEXT: "Resolution of Incorporating Shareholder(s)",
  INCORPORATION_SECOND_TEXT: "Business Plan",

  CANCEL_SUBMIT_TITLE: "The request to cancel your matter has been submitted",
  CANCEL_SUBMIT_FIRST_TEXT:
    "Please allow for up to 24hours for this matter to be closed",
  CANCEL_SUBMIT_SECOND_TEXT:
    "If you received this message and you did not want to cancel your matter please email formations@clara.co",

  SCOPING_INTRO_TITLE: "Clara Formations",
  SCOPING_INTRO_LINE1:
    "To use our <b>ADGM</b> company incorporation service, you must meet the following <b>criteria</b>:",
  SCOPING_INTRO_LINE2:
    "Your <b>Company</b> will be incorporated with the <b>ADGM Model Articles of Association</b> and <b>Ordinary Shares</b>.<sup>1</sup>",
  SCOPING_INTRO_LINE3:
    "You must have either a <b>UAE Resident</b> or <b>GCC National</b> who can be the <b>Authorised Signatory</b> for your Company or you can use our <b>Nominee Service</b>.<sup>2</sup>",
  SCOPING_INTRO_LINE4:
    "Your <b>Company</b> must be able to show an adequate connection to the <b>GCC</b> by satisfying one of the following:<br/><br/>The <b>Company</b> will primarily hold <b>assets</b> in the <b>GCC</b>.<br/>The majority of the <b>Company’s Directors</b> are <b>resident</b> in the <b>GCC</b><br/>The majority of the <b>Company’s</b> shares will be held by <b>Shareholders</b> resident in the <b>GCC</b>.",
  TEXT_WHAT_IS_THIS: "What is this?",
  TEXT_SCOPING_CONTINUE_SUBTITLE: "Company KYC",
  TEXT_SCOPING_CONTINUE_TITLE: "Were you in the middle of something?",
  TEXT_STARTUP_DRAFT_CONTINUE_TITLE: "Were you in the middle of something?",
  TEXT_STARTUP_DRAFT_CONTINUE_TEXT:
    "Your previous session has been saved. Please make a selection below to continue where you left off or reset your progress.",
  TEXT_SCOPING_CONTINUE_TEXT:
    "Your previous session has been saved. Please make a selection below to continue where you left off or reset your progress.",
  PROPOSAL_FORM_TITLE: "Clara Formations",
  PROPOSAL_FORM_TITLE_FIRST_TITLE_SUBMITTED: "ADGM SPV Proposal",
  PROPOSAL_FIRST_TEXT:
    "Click on View Proposal to see your Proposal for incorporation of an ADGM SPV.",
  PROPOSAL_SECOND_TEXT:
    "You can approve the Proposal, request changes to it or cancel the process.",
  ENGAGEMENT_FORM_TITLE: "Clara Formations",
  ENGAGEMENT_FORM_TITLE_FIRST_TITLE_SUBMITTED: "ADGM SPV Incorporation",
  ENGAGEMENT_FIRST_TEXT:
    "Congratulations! You have now completed the KYC process.",
  ENGAGEMENT_SECOND_TEXT:
    "You can now finalise our engagement and pay your invoice by clicking on the button below.",
  PROPOSAL_APPROVE_FORM_TITLE: "Clara Formations",
  PROPOSAL_APPROVE_FORM_TITLE_FIRST_TITLE_SUBMITTED:
    "Thank you!<br/>We have received your approval.",
  PROPOSAL_APPROVE_FIRST_TEXT:
    "Please check your email to start our onboarding process.",
  PROPOSAL_APPROVE_SECOND_TEXT:
    "You will need information and documents relating to the person or company who will be our client (e.g. passport copy, visa, proof of residential address, constitutional documents and registers) and the persons who will be the Directors and Shareholders of your new Company.",
  PROPOSAL_DECLINE_FORM_TITLE: "Clara Formations",
  PROPOSAL_DECLINE_FORM_TITLE_FIRST_TITLE_SUBMITTED:
    "Thank you!<br/>We have received your feedback...",
  PROPOSAL_DECLINE_FIRST_TEXT:
    "Our team will review your comments and contact you shortly.",
  PROPOSAL_DECLINE_SECOND_TEXT: "",
  ENGAGEMENT_APPROVE_FORM_TITLE: "Clara Formations",
  ENGAGEMENT_APPROVE_FORM_TITLE_FIRST_TITLE_SUBMITTED:
    "Thank you for your invoice payment...",
  ENGAGEMENT_APPROVE_FIRST_TEXT:
    "Once we have confirmed receipt of the payment, you will receive the link to our final questionnaire to collect the remainder of the information we need to prepare the incorporation documents for your new ADGM entity.",
  ENGAGEMENT_APPROVE_SECOND_TEXT: "",
  ENGAGEMENT_DECLINE_FORM_TITLE: "Clara Formations",
  ENGAGEMENT_DECLINE_FORM_TITLE_FIRST_TITLE_SUBMITTED:
    "Thank you! We have received your feedback...",
  ENGAGEMENT_DECLINE_FIRST_TEXT:
    "Our team will review your comments and contact you shortly.",
  ENGAGEMENT_DECLINE_SECOND_TEXT: "",
  PROPOSAL_DECLINE_PLACEHOLDER:
    "Confirm you no longer want to proceed or describe the changes you want to the proposal e.g. 'remove the certificate of incumbency' or 'there will now be 5 shareholders on incorporation’...",
  TITLE_MODAL_PAID_ENGAGEMENT: "Payment Options",
  SUBTITLE_MODAL_PAID_ENGAGEMENT:
    "You will have two ways to pay when you select Pay invoice below:",
  TEXT_IN_MODAL_PAID_ENGAGEMENT: `<br/>You will have two ways to pay when you select Pay invoice below:  <br/><br/><strong>Online:</strong> <br/>Use our online payment process. This is fast and secure, along with supporting all major card types. <br/><br/> <strong>Bank Transfer:</strong> <br/>Please email us at formations@clara.co for our bank details, please include the matter number shown at the top of your invoice as your reference number when you complete the transfer so we can track your payment. You can pay in either USD or AED. <br/><br/> <strong>Once you have made the payment, please return to this page and click on "I Have Paid" so we can make sure your formation is not delayed.</strong>`,
  TEXT_IN_MODAL_PAID_ENGAGEMENT_ONLINE_TITLE: `<strong>Online:</strong>`,
  TEXT_IN_MODAL_PAID_ENGAGEMENT_ONLINE_OVERVIEW: `<br/>Use our online payment process. This is fast and secure, along with supporting all major card types.`,
  TEXT_IN_MODAL_PAID_ENGAGEMENT_TRANSFER: `<strong>Bank Transfer:</strong> <br/>Our banking details are shown on the invoice, we will require you to quote the invoice number as you reference when completing the transfer so we can track your payment. You can pay in either USD or AED.`,
  ENGAGEMENT_MODAL_BANK_TRANSFER_TITLE: "Bank Transfer",
  ENGAGEMENT_MODAL_BANK_TRANSFER_SUBTITLE:
    "Below are the details for paying your invoice.",
  ENGAGEMENT_MODAL_BANK_TRANSFER_INVOICE_TITLE: "<strong>Invoice:</strong>",
  ENGAGEMENT_MODAL_BANK_TRANSFER_INVOICE_NUMBER: "<br/>{{invoiceNumber}}-CF",
  ENGAGEMENT_MODAL_BANK_TRANSFER_OVERVIEW:
    "In accordance with our engagement terms, bank charges must be paid by the client. Amount payable in AED equivalent at the fixed exchange rate of USD1:AED3.6725. Please quote the invoice number and client name on the payment reference.",
  // BUTTONS
  BUTTON_APPLY: "Apply",
  BUTTON_CANCEL: "Cancel",
  BUTTON_NEXT: "Next",
  BUTTON_PREVIOUS: "Previous",
  BUTTON_CONFIRM: "Confirm",
  BUTTON_APPROVE: "Approve",
  BUTTON_DECLINE: "Decline",
  BUTTON_TERMINATE: "Terminate",
  BUTTON_VIEW: "View",
  BUTTON_SUBMIT: "Submit",
  BUTTON_UPDATE: "Update",
  BUTTON_AGREE: "Agree",
  BUTTON_START: "Start",
  BUTTON_UPGRADE: "Upgrade",
  BUTTON_SUBSCRIPTION: "Subscription",
  BUTTON_SUBSCRIBE: "Subscribe",
  BUTTON_GET_STARTED: "Get Started",
  BUTTON_DISAGREE: "Disagree",
  BUTTON_DISMISS: "Dismiss",
  BUTTON_REMIND_LATER: "Remind me later",
  BUTTON_CONTINUE_OPERATION: "Continue {{operation}}",
  BUTTON_RESTART_OPERATION: "Restart {{operation}}",
  BUTTON_SWITCH_STARTUP: "My profiles",
  BUTTON_MY_EQUITY: "My equity",
  BUTTON_PAID: "Pay Invoice",
  BUTTON_NOW: "Pay Now",
  BUTTON_DOWNLOAD_INVOICE: "Download Invoice",
  BUTTON_BANK_TRANSFER: "Bank Transfer",
  BUTTON_BACK: "Back",
  BUTTON_HAVE_PAID: "I Have Paid",
  BUTTON_SAVING: "Saving...",
  BUTTON_PROCESSING: "Processing...",
  BUTTON_DELETING: "Deleting...",
  BUTTON_TERMINATING: "Terminating...",
  BUTTON_RUN_HEALTH_CHECK: "Run health check",
  BUTTON_SAVE_AND_CLOSE: "Save and Close",
  BUTTON_VIEW_PROFILE: "Go to profile",
  BUTTON_GO_MAP: "Go to the map",
  BUTTON_LOGOUT: "Log out",
  BUTTON_VOID: "Void",
  BUTTON_COMPLETE_DETAILS: "Complete details",
  /* Refactor*/
  //METADATA
  META_HEAD_TITLE_DEFAULT: "Clara | Your Startup's Legal OS",
  META_HEAD_TITLE_SWITCH_STARTUP: "Clara | Switch Matters",
  META_HEAD_TITLE_MY_ACCOUNT: "Clara | My Account",
  META_HEAD_TITLE_404: "Clara | Oops! 404",
  META_HEAD_TITLE_403: "Clara | Oops! 403",
  //TITLE
  TITLE_UPLOAD_IMAGE: "Upload Image",
  //BREADCRUMS
  BREADCRUMS_MY_ACCOUNT: "{{fullName}}",
  BREADCRUMS_VIEW_GROUP_COMPANY: "{{fullName}}",
  BREADCRUMS_VIEW_STAKEHOLDER_INDIVIDUAL: "{{fullName}}",
  BREADCRUMS_VIEW_STAKEHOLDER_COMPANY: "{{fullName}}",
  BREADCRUMS_VIEW_STARTUP: "{{fullName}}",
  BREADCRUMS_VIEW_AUDIT_LOGS: "Audit Log",
  BREADCRUMS_GENERATE_DOCUMENT: "Generate documents",
  BREADCRUMS_MESSAGES: "Messages",
  BREADCRUMS_SUBSCRIPTIONS: "Subscriptions",
  BREADCRUMS_ACCELERATORS: "Accelerators",
  BREADCRUMS_INVOICES: "Invoices",
  BREADCRUMS_DASHBOARD: "Dashboard",
  BREADCRUMS_DOCUMENTS: "{{fullName}}",
  BREADCRUMS_DOCUMENTS_LIST: "Documents",
  BREADCRUMS_CAP_TABLE: "Cap Table",
  BREADCRUMS_HEALTH_CHECK: "Health Check",
  //LAYOUTS
  LAYOUT_CLARA_FOOOTER_CLARA_ACADEMY: "Clara Academy",
  //COMPONETS

  //TEXT GENERIC
  GENERIC_PLACEHOLDER_DESCRIPTION: "Search or select from a list...",
  GENERIC_PLACEHOLDER_SEARCH: "Search",
  GENERIC_BUTTON_ADD_GROUP: "Add",
  GENERIC_BUTTON_CLOSE: "Close",
  GENERIC_BUTTON_SUBMIT: "Submit",
  GENERIC_BUTTON_CANCEL: "Cancel",
  GENERIC_BUTTON_PREVIOUS: "Previous",
  GENERIC_BUTTON_CONFIRM: "Confirm",
  GENERIC_BUTTON_SELECT: "Select",
  GENERIC_BUTTON_REMOVE_GROUP: "Remove",
  GENERIC_EMPTY_STATE: "Click ‘Edit’ to start adding information",
  GENERIC_EMPTY_STATE_ASSISTANCE: "You’re up to date on<br>all your tasks!",
  GENERIC_NOTIFICATION_ERROR:
    "Oops, something’s gone wrong. Please check your internet connection and reload the page",
  GENERIC_NOTIFICATION_SUCCESS: "Your business data has been successfully updated.",
  ADD_COMPANY_NOTIFICATION_SUCCESS:
    "The company has been added successfully to the map.",
  EDIT_COMPANY_NOTIFICATION_SUCCESS:
    "The company's details have been successfully edited.",
  EDIT_INDIVIDUAL_STAKEHOLDER_NOTIFICATION_SUCCESS:
    "The stakeholder's details have been successfully edited.",
  GENERIC_PERMISSION_ERROR: "You don’t have permissions to execute that action",
  GENERIC_NO_ADD: "No, don’t add",
  GENERIC_ADD: "Yes, add another",
  //VIEW_MISSING
  VIEW_MISSING_PAGE_TITLE: "Oops! 404",
  VIEW_MISSING_PAGE_SUBTITLE:
    "We can't seem to find the page you're looking for",
  //VIEW_ERROR
  VIEW_ERROR_PAGE_TITLE: "Something’s gone wrong, but we’re on it!",
  VIEW_ERROR_PAGE_MESSAGE:
    "Let’s get you back in {{leftTime}} <br/> Please bear with us while we redirect you.",
  //VIEW_FORBIDDEN
  VIEW_FORBIDDEN_PAGE_TITLE: "Oops! 403",
  VIEW_FORBIDDEN_PAGE_SUBTITLE: "You can't acces to this seccion",
  //VIEW_CHOOSE_DOCUMENT
  VIEW_CHOOSE_DOCUMENT_TITLE: "What type of document would you like to create?",
  //FORM_HEALCHECK_ADD
  FORM_HEALCHECK_ADD_TITLE: "Enter Remark Details",
  FORM_HEALCHECK_ADD_SUBTITLE: " ",
  FORM_HEALCHECK_ADD_LABEL_CATEGORY: "Choose Remark Category:",
  FORM_HEALTH_CHECK_ADD_PLACEHOLDER_CATEGORY: "Select from a list...",
  FORM_HEALCHECK_ADD_LABEL_SUBCATEGORY: "Choose Sub Category:",
  FORM_HEALCHECK_ADD_PLACEHOLDER_SUBCATEGORY: "Select from a list...",
  FORM_HEALCHECK_ADD_LABEL_URGENCY: "Choose Remark Status:",
  FORM_HEALCHECK_ADD_LABEL_TITLE: "Remark Name:",
  FORM_HEALCHECK_ADD_PLACEHOLDER_TITLE: "Remark Name...",
  FORM_HEALCHECK_ADD_LABEL_DESCRIPTION: "Remark Note:",
  FORM_HEALCHECK_ADD_PLACEHOLDER_DESCRIPTION: "Remark Note...",
  FORM_HEALCHECK_ADD_LABEL_GROUPCOMPANY: "Company #{{number}}",
  FORM_HEALCHECK_ADD_LABEL_STAKEHOLDER: "Stakeholder #{{number}}",
  FORM_HEALCHECK_ADD_LABEL_DOCUMENT: "Document #{{number}}",
  FORM_HEALCHECK_ADD_LABEL_ADD_GROUPCOMPANY: "Add Group Company",
  FORM_HEALCHECK_ADD_LABEL_EDIT_PROFILE: "Add Stakeholder",
  FORM_HEALCHECK_ADD_LABEL_ADD_DOCUMENT: "Add Document",

  //FORM_UPLOAD_DOCUMENT_ADD
  FORM_UPLOAD_DOCUMENT_SUBTITLE: "Let’s add a Document to your Group Company",
  FORM_UPLOAD_DOCUMENT_FORM_UPLOADER_LABEL: "Upload your Document",
  FORM_UPLOAD_DOCUMENT_LABEL_CATEGORY: "Category:",
  FORM_UPLOAD_DOCUMENT_LABEL_SUB_CATEGORY: "Sub-Category:",
  FORM_UPLOAD_DOCUMENT_LABEL_AGREEMENT_NAME: "Document Name (optional)",
  FORM_UPLOAD_DOCUMENT_LABEL_DOCUMENT_TYPE: "Document Type",
  //DATA
  ISSUE_STATUS_RED: "Red",
  ISSUE_STATUS_AMBER: "Amber",
  ISSUE_STATUS_GREEN: "Green",
  ISSUE_STATUS_UNKNOWN: "Unknown",
  ROL_USER_USER_OWNER: "Owner",
  ROL_USER_USER_MANAGER: "Manager",
  ROL_USER_USER_EDITOR: "Editor",
  ROL_USER_USER_VIEWER: "Viewer",
  ROL_USER_USER_COLLABORATOR: "Collaborator",
  ROL_USER_USER_INACTIVE: "No Role",
  ROL_SUPERADMIN: "Superadmin",
  ROL_ADMIN: "Admin",
  ROL_SERVICE_AGENT: "Service Agent",
  ROL_SERVICE_AGENT_MANAGER: "Service Agent Manager",
  ROL_SENIOR_SERVICE_AGENT: "Senio Service Agent",
  ROL_USER: "User",
  ROL_DIRECTOR: "Director",
  ROL_SHAREHOLDER: "Shareholder",

  //SHARE PROFILE
  DETAILS: "details",
  SHARING: "sharing",
  SHARE: "Share",
  SHARE_PROFILE: "Share Profile",

  SEND_INVITE: "Send invite",
  USER_HISTORY_TITLE: "User History",
  NO_ACTIVITY_YET: "No activity yet",
  FULL_NAME: "Full name",
  RESEND_INVITE: "Resend invite",
  RESIDENTIAL_ADDRESS: "Residential address",
  PRIMARY_RESIDENTIAL_ADDRESS: "Primary residential address",
  SERVICE_ADDRESS: "Service address",
  ISSUED_DATE_FIELD: "Issued Date:",
  ISSUED: "Issued",
  SIGNED: "Signed",
  VESTED: "Vested",
  UNVESTED: "Unvested",
  TERMINATED: "Terminated",
  PENDING: "Pending",
  EXERCISED: "Exercised",
  VALUATION_FIELD: "Valuation:",
  START_DATE: "Start date",
  VESTING_SCHEDULE: "Vesting Schedule",
  CONNECTED_GRANTS: "Connected Grants",
  INVESTMENT_DATE: "Investment Date",
  DIVESTMENT_DATE: "Divestment Date",
  ASSOCIATED_DOCUMENTS_FIELD: "Associated Documents",
  END_DATE: "End Date",
  RESPONSIBILITIES_FIELD: "Responsibilities:",
  POSITION_FIELD_LABEL: "Position:",
  DEPARTMENT_FIELD_LABEL: "Department:",
  SELECT_COMPANY_LABEL: "Select a company:",
  WARRANT_HOLDER_NAME_FIELD: "Warrant Holder Name:",
  SHAREHOLDER_NAME_FIELD: "Shareholder Name:",
  DELETE_BUTTON: "Delete",
  EMAIL_FIELD: "Email:",
  CURRICULUM_VITAE_FIELD: "Curriculum vitae:",
  NUMBER_SHARES_FIELD: "Number of Shares:",
  RESERVED_SHARES_FIELD: "Reserved Shares:",
  CLASS_FIELD: "Class",
  CLASS_FIELD_LABEL: "Class name",
  SHARE_CLASS_FIELD_LABEL: "Share Class",
  SHARE_CLASS_NEW_NAME_LABEL: "New Name",
  SHARE_CLASS_NEW_NOTIFICATION: "The share class {{{name}}} has been created",
  SHARE_CLASS_NAME_CHANGE: "The share class {{{newName}}} has been added",
  SHARE_CLASS_DELETE: "The share class {{{name}}} has been deleted",
  PRICE_SHARES_FIELD: "Price per Share:",
  NATIONALITY_FIELD: "Nationality:",
  ENTER_NUMBER_PLACEHOLDER: "Enter number...",
  EDIT_SHARES: "Edit Shares",
  SIP_NAME_FORM_LABEL: "SIP Name",
  RESERVED_SHARE_AMOUNT_FORM_LABEL: "Reserved share amount",
  EXCERCISE_PRICE_FIELD: "Exercise Price:",
  SHARE_INCENTIVE_PLAN_FIELD: "Share Incentive Plan:",
  DISCOUNT_FIELD: "Discount:",
  INTEREST_RATE_FIELD: "Interest Rate:",
  VALUATION_CAP_FIELD: "Valuation Cap:",
  OPTION_HOLDER_NAME_FIELD: "Option Holder Name:",
  CONVERTIBLE_HOLDER_NAME_FIELD: "Convertible Holder Name:",
  INVESTMENT_AMOUNT_FIELD: "Investment Amount:",
  EDIT_CONVERTIBLES: "Edit a Convertible",
  ADD_CONVERTIBLES: "Add a Convertibles",
  ADD_MANUAL_SIP: "Add Manual Sip",
  MANUAL_SIP_FORM_TITLE: "Edit {{{sipName}}}",
  MANUAL_SIP_FORM_SUBTITLE: "Manual Share Incentive Plan",
  EDIT_DETAILS: "Edit details",
  EDIT_GENERAL_DETAILS: "Edit general details",
  EDIT_ADDRESS: "Edit addresses",
  RECIPIENT_NOTICE_FIELD: "Recipient for Notices:",
  RECIPIENT_NOTICE_ADDRESS_FIELD: "Recipient’s Email Address:",
  EDIT_PROFILE: "Edit <label>Profile</label>",
  EDIT_ROLES_POSITIONS: "Edit <label>Roles & Position</label>",
  ROLES_TEXT_UNDER_TITLE:
    "The roles of a stakeholder has with a company are based on the function the stakeholder has and the legal agreements they have in place with that company. ",
  POSITIONS_TEXT_UNDER_TITLE:
    "The position of a founder, employee, or consultant is their job title along with the department they report to and the responsibilities that they have.",
  MANAGE_DOCUMENTS: "Manage <label>Documents</label>",
  MANAGE_EQUITIES: "Manage <label>Equity Positions</label>",
  WARRANTS: "Warrants",
  PEP: "PEP",
  DIRECTORS: "Directors",
  SOURCE_OF_FUNDS: "Source of Funds",
  SHARES_OPTION_GRANTS: "Share Option Grants",
  SHARES: "Shares",
  CONVERTIBLES: "Convertibles",
  SHARE_OPTIONS: "Share Options",
  COMPANY_NAME_FIELD: "Company Name:",
  DOCUMENTS: "Documents",
  POSITIONS: "Positions",
  POSITION: "Position",
  ROLES: "Roles",
  EQUITY: "Equity",
  KEYPEOPLE: "Key people",
  COMPLIANCE: "Compliance",
  SERVICE_AGREEMENT: "Service Agreement",
  PASSPORT_NUMBER: "Passport number",
  MODAL_SHARE_TITLE: "Grant acces to",
  USER_ROLE: "User role",
  NAME: "Name",
  REQUEST_ACCESS_MODAL_SUBTITLE:
    "Would you like to send a request to have your access restored to this Profile?",
  SELECT_USER_ROLE: "Please select a Role for the new user:",
  OWNER_ROL_EXAMPLE:
    "<strong>Owner</strong><br> e.g. Founders who will have complete access, including managing your subscription.",
  MANAGER_ROL_EXAMPLE:
    "<strong>Manager</strong><br> e.g. Team members or your trusted advisors who can edit and share the profile with others but cannot manage your subscription.",
  EDITOR_ROL_EXAMPLE:
    "<strong>Editor</strong><br> e.g. Trusted advisors or your team members who make changes to your profile but cannot share it with others.",
  VIEWER_ROL_EXAMPLE:
    "<strong>Viewer</strong><br> e.g. Your existing or potential Investors (or their advisors) who can view your profile, comment on your health check but cannot edit or share with others. <strong>They won’t be able to view sensitive data, like personal information and certain documents.</strong>",

  // ASSISTANCE_TITLE: "Hi {{firstName}}",
  ASSISTANCE_ONBOARD_DELETE_NOTE:
    "Please note this will be instantly removed from this Profile on pressing confirm",

  ASSISTANCE_ONBOARD_COMPANY_TITLE: "Onboard a Company",
  ASSISTANCE_ONBOARD_COMPANY_BUBBLE_INFO: `
  {{#first}}The first step to onboarding your Startup is to provide information about its Companies. Let’s set up your first Company.{{/first}}
  {{^first}}You have {{pending}} companies left to onboard. Let’s set up your next company.{{/first}}
  `,
  ASSISTANCE_ONBOARD_COMPANY_BUBBLE_READMORE_INFO:
    "Clara will ask you to enter some details about the Company and upload some documents. You will also need to provide some details about the Company’s directors.",
  ASSISTANCE_ONBOARD_COMPANY_BUTTON_FLOW: "Start Onboarding",
  ASSISTANCE_ONBOARD_COMPANY_BUTTON_REMOVE: "Remove Company",
  ASSISTANCE_ONBOARD_COMPANY_BUTTON_CONCIERGE: "Use Clara Concierge",
  ASSISTANCE_ONBOARD_COMPANY_DELETE_TITLE:
    "Are you sure you want to remove Company #{{number}}?",
  ASSISTANCE_ONBOARD_COMPANY_DELETE_OPTION:
    "Remove Company #{{number}} from Onboarding",

  ASSISTANCE_ONBOARD_EQUITY_TITLE: "Set up Equity Positions for your Companies",
  ASSISTANCE_ONBOARD_EQUITY_BUBBLE_INFO:
    "The next step in onboarding your Startup is to set up any Equity Positions between your Companies. Clara will now ask you to enter some details about the shares in your Companies.",
  ASSISTANCE_ONBOARD_EQUITY_BUBBLE_READMORE_INFO:
    "You will need to know which Companies own shares in other Companies and details of the number of classes of shares. You will also need to upload any supporting documents for these Equity Positions.",
  ASSISTANCE_ONBOARD_EQUITY_BUTTON_FLOW: "Set Up Equity Position",
  ASSISTANCE_ONBOARD_EQUITY_BUTTON_CONCIERGE: "Use Clara Concierge",

  ASSISTANCE_ONBOARD_FOUNDER_TITLE: "Onboard Founder #{{number}}",
  ASSISTANCE_ONBOARD_FOUNDER_BUBBLE_INFO:
    "The next step in onboarding your Startup is to onboard your Team Members. Clara will now ask you to enter some details about the Team Member.",
  ASSISTANCE_ONBOARD_FOUNDER_BUBBLE_READMORE_INFO:
    "You will need to know the Team Member’s name, what agreements they have signed and any Equity Positions they have in your Companies. You will also need to upload any signed agreements with your Companies and any other supporting documents which reflect any Equity Positions.",
  ASSISTANCE_ONBOARD_FOUNDER_BUTTON_FLOW: "Onboard Founder",
  ASSISTANCE_ONBOARD_FOUNDER_BUTTON_REMOVE: "Remove from the List",
  ASSISTANCE_ONBOARD_FOUNDER_BUTTON_CONCIERGE: "Use Clara Concierge",
  ASSISTANCE_ONBOARD_FOUNDER_DELETE_TITLE:
    "Are you sure you want to remove Founder #{{number}}?",
  ASSISTANCE_ONBOARD_FOUNDER_DELETE_OPTION:
    "Remove Founder #{{number}} from Onboarding",

  ASSISTANCE_ONBOARD_EMPLOYEE_TITLE: "Onboard Employee #{{number}}",
  ASSISTANCE_ONBOARD_EMPLOYEE_BUBBLE_INFO:
    "The next step in onboarding your Startup is to onboard your Team Members. Clara will now ask you to enter some details about the Team Member.",
  ASSISTANCE_ONBOARD_EMPLOYEE_BUBBLE_READMORE_INFO:
    "You will need to know the Team Member’s name, what agreements they have signed and any Equity Positions they have in your Companies. You will also need to upload any signed agreements with your Companies and any other supporting documents which reflect any Equity Positions.",
  ASSISTANCE_ONBOARD_EMPLOYEE_BUTTON_FLOW: "Onboard Employee",
  ASSISTANCE_ONBOARD_EMPLOYEE_BUTTON_REMOVE: "Remove from the List",
  ASSISTANCE_ONBOARD_EMPLOYEE_BUTTON_CONCIERGE: "Use Clara Concierge",
  ASSISTANCE_ONBOARD_EMPLOYEE_DELETE_TITLE:
    "Are you sure you want to remove Employee #{{number}}?",
  ASSISTANCE_ONBOARD_EMPLOYEE_DELETE_OPTION:
    "Remove Employee #{{number}} from Onboarding",

  ASSISTANCE_ONBOARD_ADVISOR_TITLE: "Onboard Advisor #{{number}}",
  ASSISTANCE_ONBOARD_ADVISOR_BUBBLE_INFO:
    "The next step in onboarding your Startup is to onboard your Team Members. Clara will now ask you to enter some details about the Team Member.",
  ASSISTANCE_ONBOARD_ADVISOR_BUBBLE_READMORE_INFO:
    "You will need to know the Team Member’s name, what agreements they have signed and any Equity Positions they have in your Companies. You will also need to upload any signed agreements with your Companies and any other supporting documents which reflect any Equity Positions.",
  ASSISTANCE_ONBOARD_ADVISOR_BUTTON_FLOW: "Onboard Advisor",
  ASSISTANCE_ONBOARD_ADVISOR_BUTTON_REMOVE: "Remove from the List",
  ASSISTANCE_ONBOARD_ADVISOR_BUTTON_CONCIERGE: "Use Clara Concierge",
  ASSISTANCE_ONBOARD_ADVISOR_DELETE_TITLE:
    "Are you sure you want to remove Advisor #{{number}}?",
  ASSISTANCE_ONBOARD_ADVISOR_DELETE_OPTION:
    "Remove Advisor #{{number}} from Onboarding",

  ASSISTANCE_ONBOARD_CONSULTANT_TITLE: "Onboard Consultant #{{number}}",
  ASSISTANCE_ONBOARD_CONSULTANT_BUBBLE_INFO:
    "The next step in onboarding your Startup is to onboard your Team Members. Clara will now ask you to enter some details about the Team Member.",
  ASSISTANCE_ONBOARD_CONSULTANT_BUBBLE_READMORE_INFO:
    "You will need to know the Team Member’s name, what agreements they have signed and any Equity Positions they have in your Companies. You will also need to upload any signed agreements with your Companies and any other supporting documents which reflect any Equity Positions.",
  ASSISTANCE_ONBOARD_CONSULTANT_BUTTON_FLOW: "Onboard Consultant",
  ASSISTANCE_ONBOARD_CONSULTANT_BUTTON_REMOVE: "Remove from the List",
  ASSISTANCE_ONBOARD_CONSULTANT_BUTTON_CONCIERGE: "Use Clara Concierge",
  ASSISTANCE_ONBOARD_CONSULTANT_DELETE_TITLE:
    "Are you sure you want to remove Consultant #{{number}}?",
  ASSISTANCE_ONBOARD_CONSULTANT_DELETE_OPTION:
    "Remove Consultant #{{number}} from Onboarding",

  ASSISTANCE_ONBOARD_INVESTOR_TITLE: "Onboard Investor #{{number}}",
  ASSISTANCE_ONBOARD_INVESTOR_BUBBLE_INFO:
    "The next step in onboarding your Startup is to onboard your Investors. Clara will now ask you to enter some details about the Investor.",
  ASSISTANCE_ONBOARD_INVESTOR_BUBBLE_READMORE_INFO:
    "You will need to know the Investor’s name, what agreements they have signed and any Equity Positions they have in your Companies. You will also need to upload any signed agreements with your Companies and any other supporting documents which reflect any Equity Positions.",
  ASSISTANCE_ONBOARD_INVESTOR_BUTTON_FLOW: "Onboard Investor",
  ASSISTANCE_ONBOARD_INVESTOR_BUTTON_REMOVE: "Remove from the List",
  ASSISTANCE_ONBOARD_INVESTOR_BUTTON_CONCIERGE: "Use Clara Concierge",
  ASSISTANCE_ONBOARD_INVESTOR_DELETE_TITLE:
    "Are you sure you want to remove Investor #{{number}}?",
  ASSISTANCE_ONBOARD_INVESTOR_DELETE_OPTION:
    "Remove Investor #{{number}} from Onboarding",

  ASSISTANCE_ONBOARD_PENDING: `
    You have 
    {{#companies}}
      <b>{{companies}} {{#companyPlural}}companies{{/companyPlural}}{{^companyPlural}}company{{/companyPlural}}</b> to onboard 
      {{#equities_team_investors}}and {{/equities_team_investors}}{{#equities}}set equity positions {{#team_investors}}for {{/team_investors}} {{/equities}} 
    {{/companies}} 
    {{#team_investors}}
      {{#teamMembers}} <b>{{teamMembers}} team member{{#teamPlural}}s{{/teamPlural}}</b>{{/teamMembers}} 
      {{#investors}} {{#teamMembers}}and{{/teamMembers}}  <b>{{investors}}  investor{{#investorPlural}}s{{/investorPlural}}</b> {{/investors}} 
      to onboard.
    {{/team_investors}}
  `,
  ASSISTANCE_ONBOARD_PENDING_EQUITY: `
    You have 
    {{#companies}}
      <b>{{companies}} {{#companyPlural}}companies{{/companyPlural}}{{^companyPlural}}company{{/companyPlural}}</b> to 
      {{#equities}}set equity positions {{#team_investors}}for {{/team_investors}} {{/equities}} 
    {{/companies}} 
    {{#team_investors}}
      {{#teamMembers}} <b>{{teamMembers}} team member{{#teamPlural}}s{{/teamPlural}}</b>{{/teamMembers}} 
      {{#investors}} {{#teamMembers}}and{{/teamMembers}}  <b>{{investors}}  investor{{#investorPlural}}s{{/investorPlural}}</b> {{/investors}} 
      to onboard.
    {{/team_investors}}
  `,
  ASSISTANCE_DEFAULT_MENU_CONTACT_MODAL_TITLE:
    "How can Clara Support help you today?",
  ASSISTANCE_DEFAULT_MENU_CONTACT_MODAL_SUBTITLE:
    "What appears to be the problem you are currently having with Clara?",
  ASSISTANCE_DEFAULT_MENU_CONTACT_MODAL_SUBMIT_BUTTON: "Send to Support",
  ASSISTANCE_DEFAULT_MENU_CONTACT_MODAL_SUBMITED_TITLE:
    "Your request for support has been submitted to Clara",
  ASSISTANCE_DEFAULT_MENU_CONTACT_MODAL_SUBMITED_SUBTITLE:
    "We have received your support request and will be in contact shortly.",
  ASSISTANCE_ACADEMY_NOT_FOUND: "No articles found",

  ASSISTANCE_GENRATE_DOCUMENTS_LEFT:
    "You have <b>{{left}}</b> DocuSign envelopes left",
  ASSISTANCE_GENRATE_DOCUMENTS_LEFT_UPDATE:
    "Want to keep getting your documents signed?",
  ASSISTANCE_DOCUMENTS_LEFT:
    "You have <b>{{left}}</b> documents left to upload",
  ASSISTANCE_DOCUMENTS_LEFT_UPDATE:
    "Want to keep uploading your startups documents?",
  ASSISTANCE_SHARE_PROFILES_LEFT:
    "You have <b>{{left}}</b> profile shares left",
  ASSISTANCE_SHARE_PROFILES_LEFT_UPDATE:
    "You can unlock this and more by buying an upgrade.",
  ASSISTANCE_HEALTH_CHECK_LEFT_UPDATE:
    "Scale subscriptions included continuous automated health checks.",
  ASSISTANCE_HEALTH_CHECK_LEFT: "You have <b>{{left}}</b> health check(s) left",
  ASSISTANCE_CONTACT_OWNER: "Contact the profile owner to upgrade",
  ASSISTANCE_CORPORATE_STAKEHOLDER_RENEWAL_IN_PROGGRESS:
    "This stakeholder profile is a part of the {{{company}}} licence renewal application. Please complete the renewal tasks as soon as possible to ensure the renewal can be completed on time and avoid resulting in late penalties.",

  SELECT_INPUT_PLACEHOLDER: "Select from the list",
  SELECT_INPUT_PLACEHOLDER_NORMAL: "Select from the list",

  UPLOADED_FILES_SELECTOR_PRE_TITLE: "Select from already uploaded",
  UPLOADED_FILES_SELECTOR_LIST_TITLE: "Already Uploaded",

  /* Refactor*/
  LAST_BY_COMA: "",
  // START OF MESSAGES
  ...moduleMessages.US,
  // END OF MESSAGES
  STARTUP_VIEW_LABEL_ACCOUNT_CREATED: "Profile Created",
  STARTUP_VIEW_LABEL_ACCELERATOR: "Accelerator",
  STARTUP_VIEW_LABEL_LAST_UPDATED: "Last updated",
  STARTUP_VIEW_LABEL_SHARING: "Shared with",
  STARTUP_VIEW_LABEL_FOUNDERS: "Founders",
  STARTUP_VIEW_LABEL_GENERIC_QUESTIONNAIRE: "Accelerator program questionnaire",
  STARTUP_VIEW_LABEL_SANABIL500_QUESTIONNAIRE:
    "{{{acceleratorName}}} questionnaire",
  STARTUP_VIEW_LABEL_INVOICES: "Invoices",
  STARTUP_VIEW_LABEL_DETAILS: "Details",
  STARTUP_VIEW_LABEL_SUBSCRIPTION: "Subscription Management",
  STARTUP_VIEW_LABEL_BANK: "Bank Cards",
  STARTUP_VIEW_TAB_BUTTON_EDIT: "Edit",
  STARTUP_VIEW_TAB_LABEL_BUSINESS_DESCRIPTION: "Business description",
  STARTUP_VIEW_TAB_LABEL_PROFILE_CREATED: "Profile created",
  STARTUP_VIEW_TAB_LABEL_ACCELERATOR: "Accelerator",
  STARTUP_VIEW_TAB_LABEL_PRIMARY_LOCATION: "Primary Location",
  STARTUP_VIEW_SHARING_TABLE_TITLE_NAME: "Name",
  STARTUP_VIEW_SUBSCRIPTION_TABLE_TITLE_PLAN: "Plan",
  STARTUP_VIEW_SUBSCRIPTION_TABLE_TITLE_START_DATE: "Start Date",
  STARTUP_VIEW_SUBSCRIPTION_TABLE_TITLE_RENEWAL: "Renewal Date",
  STARTUP_VIEW_SUBSCRIPTION_TABLE_TITLE_PAYMENT: "Payment Method",
  STARTUP_VIEW_SUBSCRIPTION_TABLE_TITLE_STATUS: "Status",
  STARTUP_VIEW_BANKS_TABLE_TITLE_CARD: "Cards",
  STARTUP_VIEW_BANKS_TABLE_TITLE_CARD_NAME: "Card Holder Name",
  STARTUP_VIEW_STATUS_ACTIVE: "Active",
  STARTUP_VIEW_STATUS_INACTIVE: "Inactive",
  STARTUP_VIEW_BANKS_TABLE_TITLE_LAST: "Last Used",
  STARTUP_VIEW_INVOICES_TABLE_TITLE_NAME: "Invoice",
  STARTUP_VIEW_INVOICES_TABLE_TITLE_TYPE: "Details",
  STARTUP_VIEW_INVOICES_TABLE_TITLE_CATEGORY: "Category",
  STARTUP_VIEW_INVOICES_TABLE_TITLE_PAYMENT_METHOD: "Payment method",
  STARTUP_VIEW_INVOICES_TABLE_TITLE_DATE: "Date",
  STARTUP_VIEW_INVOICES_TABLE_TITLE_AMOUNT: "Amount",
  STARTUP_VIEW_INVOICES_TABLE_TITLE_SUBCATEGORY: "Subcategory",
  STARTUP_VIEW_SUBSCRIPTION_CANCEL_REASON_LABEL: "Reason",
  STARTUP_VIEW_SUBSCRIPTION_CANCEL_REASON_PLACEHOLDER:
    "Please let us know the reason for your cancellation...",
  STARTUP_VIEW_SUBSCRIPTION_CANCEL_CONTINUE: "Continue",
  CANCEL_SUBSCRIPTION_MODAL_TITLE: "Cancel Subscription",
  EDIT_SUBSCRIPTION_MODAL_TITLE: "Edit Subscription",
  STARTUP_ADD_EDIT_CARD_TITLE: "Edit/Add Card",
  STARTUP_DELETE_CARD_TITLE: "Confirm deletion of card ending in {{last4}}",
  STARTUP_OPTION_DELETE_CARD_TITLE:
    "I confirm that I wish to delete the card ending in  {{last4}}",
  MY_ACCOUNT_VIEW_LABEL_MARKETING: "Marketing preferences:",
  MY_ACCOUNT_VIEW_LABEL_TERMS: "Terms & Conditions:",
  MY_ACCOUNT_VIEW_LABEL_PRIVACY: "Privacy Policy:",
  MY_ACCOUNT_VIEW_LABEL_ACCOUNT_CREATED: "Account Created",
  MY_ACCOUNT_VIEW_LABEL_DETAILS: "Details",
  MY_ACCOUNT_VIEW_LABEL_PROFILE: "Profile Access",
  MY_ACCOUNT_VIEW_LABEL_DEVICES: "Authorised Devices",
  MY_ACCOUNT_VIEW_BUTTON_DELETE: "Delete",
  MY_ACCOUNT_VIEW_BUTTON_UPDATE_PASSWORD: "Update Password",
  MY_ACCOUNT_VIEW_TAB_DETAILS_BUTTON_UPDATE_PASSWORD: "Edit",
  MY_ACCOUNT_VIEW_TAB_DETAILS_LABEL_EMAIL: "Email",
  MY_ACCOUNT_VIEW_TAB_DETAILS_LABEL_MARKETING_PREFERENCES:
    "Marketing Preferences",
  MY_ACCOUNT_VIEW_TAB_DETAILS_LABEL_ACCOUNT_CREATED: "Account Created",
  MY_ACCOUNT_VIEW_TAB_BUTTON_DETAILS_EDIT: "Edit",

  STAKEHOLDER_VIEW_BUTTON_ADD_DOCUMENT: "Add document",
  STAKEHOLDER_VIEW_BUTTON_ADD_EQUITY_POSITION: "Add equity position",
  STAKEHOLDER_VIEW_BUTTON_EDIT_SHARE_CLASS: "Edit share class",
  STAKEHOLDER_VIEW_BUTTON_INVITE_COLLABORATOR: "Invite collaborator",
  STAKEHOLDER_VIEW_BUTTON_DELETE: "Delete",
  STAKEHOLDER_VIEW_TAB_LABEL_DETAILS: "Details",
  STAKEHOLDER_VIEW_TAB_LABEL_GENERAL_DETAILS: "General details",
  STAKEHOLDER_VIEW_TAB_LABEL_POSITION: "Position",
  STAKEHOLDER_VIEW_TAB_LABEL_PROFESSION: "Profession",
  STAKEHOLDER_VIEW_TAB_LABEL_NATIONALITY: "Nationality",
  STAKEHOLDER_VIEW_TAB_LABEL_EMAIL: "Email",
  STAKEHOLDER_VIEW_TAB_LABEL_TEL: "Telephone number",
  STAKEHOLDER_VIEW_TAB_LABEL_START_DATE: "Start date",
  STAKEHOLDER_VIEW_TAB_LABEL_LINKEDIN: "Linkedin",
  STAKEHOLDER_VIEW_TAB_LABEL_CV: "CV",
  STAKEHOLDER_VIEW_TAB_LABEL_ADDRESSES: "Addresses",
  STAKEHOLDER_VIEW_TAB_LABEL_REGISTERED_ADDRESS: "Registered address",
  STAKEHOLDER_VIEW_TAB_LABEL_NOTICES_ADDRESSES: "Address for notices",
  STAKEHOLDER_VIEW_TAB_LABEL_RESIDENTIAL_ADDRESS: "Residential address",
  STAKEHOLDER_VIEW_TAB_LABEL_PASSPORT_NUMBER: "Passport number",
  STAKEHOLDER_VIEW_TAB_LABEL_OTHER_NATIONALITIES: "Other nationalities",
  STAKEHOLDER_VIEW_TAB_LABEL_DATE_OF_BIRTH: "Date of birth",
  STAKEHOLDER_VIEW_TAB_LABEL_CITY_OF_BIRTH: "City of birth",
  STAKEHOLDER_VIEW_TAB_LABEL_COUNTRY_OF_BIRTH: "Country of birth",
  STAKEHOLDER_VIEW_TAB_LABEL_TITLE: "Title",
  STAKEHOLDER_VIEW_TAB_LABEL_ARABIC_NAME: "Arabic name",
  STAKEHOLDER_VIEW_TAB_LABEL_FORMER_NAMES: "Former names (if applicable)",
  STAKEHOLDER_VIEW_TAB_BUSINESS_OCCUPATION: "Business occupation",
  STAKEHOLDER_VIEW_TAB_GROUNDS_OF_BENEFICIAL_OWNER_APPOINTMENT:
    "Grounds of beneficial owner appointment",
  STAKEHOLDER_VIEW_TAB_FIT_AND_PROPER_DECLARATION: "Fit and Proper declaration",

  ASSISTANCE_BUTTON_MY_ACCOUNT_UPDATE: "Update Password",
  ASSISTANCE_BUTTON_MY_ACCOUNT_EDIT: "Edit Account",
  ASSISTANCE_BUTTON_MY_ACCOUNT_DELETE: "Delete Account",
  MODAL_DELETE_GRANT_CHECKBOX_LABEL:
    "I confirm that I wish to delete {{grant}}",
  MODAL_DELETE_SIP_CHECKBOX_LABEL: "I confirm that I wish to delete {{sip}}",
  MODAL_TERMINATE_GRANT_REASON:
    "Can you please state the reason why you are terminating the Grant Agreement?",
  MODAL_DELETE_GRANT_REASON_PLACEHOLDER:
    "Please let us know the reason why you are deleting this Grant...",
  MODAL_TERMINATE_GRANT_CHECKBOX:
    "I confirm that I wish to terminate {{grant}}",
  MODAL_DELETE_GRANT_INFO_TEXT:
    "When you delete {{grant}} any unexercised options will be deleted. All unexercised share options will be returned to the {{sip}} any converted shares will stay in the shares tab along with the signed exercise notice.",
  MODAL_DELETE_MANUAL_SIP_INFO_TEXT:
    "When you delete {{sip}} any unexercised options will be deleted. All exercised options that were connected to {{sip}} will still be connected to their respective stakeholders.",
  MODAL_DELETE_SIP_INFO_TEXT:
    "When you delete {{sip}} all connected grants along with any unexercised options will be deleted. All exercised options that were connected by grants to the {{sip}} will still be connected to their respective stakeholders and any signed documents will still be available to show historic connections.",
  MODAL_DELETE_FILE: "{{name}} has successfully been deleted",
  MODAL_DELETE_GRANT: "Delete {{grant}}",
  MODAL_TERMINATE_GRANT: "Terminate {{grant}}",
  MODAL_DELETE_SIP: "Delete {{sip}}",
  MODAL_CONVERT_SHARE_TITLE: "Converting Your Options to Shares",
  MODAL_CONVERT_SHARE_FIRST_TEXT: "{{vestingDate}} - ({{grant}})",
  MODAL_CONVERT_SHARE_MIDDLE_TEXT:
    "Good news! {{numberOfOptions}} share options have been exercised and can now be issued as shares.",
  MODAL_CONVERT_SHARE_LAST_TEXT:
    "Before converting these options, please check you’ve filed all of the necessary documents. Clara recommends that you confirm this with your corporate services provider or legal counsel.",
  MODAL_CONVERT_SHARE_SUBMIT_BUTTON: "Convert Options",
  MODAL_DELETE_GRANT_REASON_LABEL: "Reason",
  MODAL_CHANGE_DEVICE_NAME_REASON_LABEL: "Device name",
  MODAL_DEAUTHORISE_DEVICE_REASON_HIT:
    "Please let us know the reason for your cancellation...",
  MODAL_DEAUTHORISE_DEVICE_WARNING: "You must confirm this action",
  MODAL_DELETE_PROFILE_OPTION_DELETE: "Confirm deletion of {{{userName}}}",
  MODAL_DELETE_PROFILE_TITLE: "Delete Account",
  MODAL_DELETE_PROFILE_WARNING:
    "This account will be deleted upon pressing confirm. This action cannot be undone.",
  MODAL_VOID_ENVELOPE: "Void {{documentName}}",
  MODAL_VOID_ENVELOPE_INFO_TEXT:
    "Can you please state the reason why you are voiding this docusign document?",
  MODAL_VOID_ENVELOPE_REASON_LABEL: "Reason",
  MODAL_VOID_ENVELOPE_CHECKBOX_LABEL:
    "I confirm that I wish to Void {{documentName}}",
  MY_ACCOUNT_MODAL_UPDATEPASSWORD_TITLE: "Update password",
  MY_ACCOUNT_MODAL_DEAUTHORISE_DEVICE_TITLE:
    "De-authorise device: {{device_name}}",
  MY_ACCOUNT_MODAL_RENAME_DEVICE: "Rename device: {{device_name}}",
  MY_ACCOUNT_MODAL_RENAME_DEVICE_SUBTITLE: "Authorised devices",
  MY_ACCOUNT_MODAL_DEAUTHORISE_DEVICE_CHECKBOX_TEXT:
    "I confirm that I wish to de-authorise {{device_name}}. By ticking the box we will automatically de-authorise the device and log out of all connected sessions.",
  MY_ACCOUNT_MODAL_UPDATEPASSWORD_FIELD_CURRENT_PASSWORD: "Current Password",
  MY_ACCOUNT_MODAL_UPDATEPASSWORD_FIELD_NEW_PASSWORD: "Update Password",
  MY_ACCOUNT_MODAL_UPDATEPASSWORD_NOTE:
    "Your password must be at least 6 characters long and include at least one uppercase letter and one number",
  MY_ACCOUNT_MODAL_UPDATEPASSWORD_SAVE: "Save Changes",
  MY_ACCOUNT_MODAL_DEAUTHORISE_DEVICE_SUBMIT: "De-authorise",

  SAVE_BUTTON: "Save",
  MY_ACCOUNT_MODAL_EDIT_TITLE: "Edit profile",
  MY_ACCOUNT_MODAL_EDIT_FIRST_NAME: "First name",
  MY_ACCOUNT_MODAL_EDIT_LAST_NAME: "Last name",
  MY_ACCOUNT_MODAL_EDIT_EMAIL: "Email address",
  MY_ACCOUNT_MODAL_EDIT_PHONE: "Phone number",
  MY_ACCOUNT_MODAL_EDIT_MARKETING: "Marketing preferences",
  MY_ACCOUNT_MODAL_EDIT_SAVE: "Save changes",
  MY_ACCOUNTNOTIFICATION_SUCCESS_UPDATE_IMAGE: "Your account has been updated",
  DEVICE_DEAUTHORISED_MESSAGE_SUCCESS: "The device has been deauthorised",
  DEVICE_RENAME_MESSAGE_SUCCESS: "The device has been renamed",
  MY_ACCOUNTNOTIFICATION_SUCCESS_UPDATE_PASSWORD:
    "Your password has been updated",
  MY_ACCOUNTNOTIFICATION_SUCCESS_UPDATE_PROFILE:
    "Your account has been updated",
  MY_ACCOUNT_MODAL_PASSWORD: "Password",
  MY_ACCOUNT_ACTIVITY_LOG: "Activity Log",
  NOTIFICATION_OVERLAY_SAVE_SUCCESS:
    "All details for {{{overlayName}}} have successfully been updated",
  FIELD_EMAIL_LABEL: "Email address",
  FIELD_DEVICE_NAME_LABEL: "Device name",
  FIELD_PASSWORD_LABEL: "Password",
  FIELD_VERIFY_PASSWORD_LABEL: "Verify Password",
  FIELD_FIRST_NAME_LABEL: "First name",
  FIELD_LAST_NAME_LABEL: "Last name",

  //View Group Company
  VIEW_COMPANY_LABEL_ADDRESS: "Registered address",
  VIEW_COMPANY_LABEL_PASSPORT_NUMBER: "Company number",
  VIEW_COMPANY_LABEL_INCORPORATION_DATE: "Incorporation date",
  VIEW_COMPANY_LABEL_INVESTMENT: "Investment",
  VIEW_COMPANY_TAB_LABEL_DOCUMENT: "Documents",
  VIEW_COMPANY_TAB_LABEL_EQUITY: "Equity overview",
  VIEW_COMPANY_TAB_LABEL_EQUITY_FORMATION_SHARE_CAPITAL: "Share capital",
  VIEW_COMPANY_TAB_LABEL_DETAILS: "Details",
  VIEW_COMPANY_TAB_KEY_PEOPLE_DETAILS: "Key people",
  VIEW_COMPANY_TAB_LABEL_ECONOMIC_SUBSTANCE: "Economic Substance",
  VIEW_COMPANY_TAB_LABEL_ECONOMIC_SUBSTANCE_TITLE: "Economic substance information",
  VIEW_COMPANY_TAB_LABEL_DETAILS_NAME: "Company name",
  VIEW_COMPANY_TAB_LABEL_DETAILS_IS_TOP_CO:
    "This is the top level holding company.",
  VIEW_COMPANY_TAB_LABEL_DETAILS_COUNTRY: "Country",
  VIEW_COMPANY_TAB_LABEL_DETAILS_JURISDICTION: "Jurisdiction",
  VIEW_COMPANY_TAB_LABEL_DETAILS_PREVIOUS_NAME: "Previous name (if applicable)",
  VIEW_COMPANY_TAB_LABEL_DETAILS_PREVIOUS_COMPANY_NAME:
    "Previous company name (if applicable)",
  VIEW_COMPANY_TAB_LABEL_DETAILS_ENTITY_LEGAL_STRUCTURE:
    "Entity legal structure",
  VIEW_COMPANY_TAB_LABEL_DETAILS_STATE: "State",
  VIEW_COMPANY_TAB_LABEL_DETAILS_CITY: "City",
  VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_TYPE: "Company type",
  VIEW_COMPANY_TAB_LABEL_DETAILS_INCORPORATION_DATE: "Incorporation date",
  VIEW_COMPANY_TAB_LABEL_DETAILS_LINKEDIN: "LinkedIn URL",
  VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE: "Website or social media",
  VIEW_COMPANY_TAB_LABEL_DETAILS_REGISTERED_ADDRESS: "Registered address",
  VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_NUMBER: "Company number",
  VIEW_COMPANY_TAB_LABEL_DETAILS_TAX_REGISTRATION_NUMBER:
    "Tax registration number (if applicable)",
  VIEW_COMPANY_TAB_LABEL_DETAILS_REGISTERED_NUMBER:
    "Company registration number",
  VIEW_COMPANY_TAB_LABEL_DETAILS_CURRENCY: "Currency",
  VIEW_COMPANY_TAB_LABEL_DETAILS_AUTHORISED_SHARE_CAPITAL:
    "Authorised share capital",
  VIEW_COMPANY_TAB_LABEL_DETAILS_PURPOSE_OF_ENTITY: 'Purpose of entity',
  VIEW_COMPANY_TAB_LABEL_DETAILS_PURPOSE_OF_ENTITY_DESCRIPTION: 'Purpose of entity description',
  VIEW_COMPANY_TAB_LABEL_DETAILS_NAME_BECAME_EFFECTIVE_ON:
    "Company name became effective on",
  VIEW_COMPANY_TAB_LABEL_DETAILS_LICENCE_TYPE: "Licence type",
  VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_STATUS: "Company status",
  VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT: "Edit",
  VIEW_COMPANY_TAB_BUTTON_DETAILS_REQUEST_CHANGE: "Request change",
  VIEW_COMPANY_TAB_BUTTON_DETAILS_ADD_DOCUMENT: "Add document",
  VIEW_COMPANY_TAB_BUTTON_DETAILS_ADD_EQUITY_POSITION: "Add equity position",
  VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT_SHARE_CLASS: "Edit share class",
  VIEW_COMPANY_TAB_BUTTON_DETAILS_DELETE: "Delete",
  VIEW_COMPANY_TAB_KEY_PEOPLE_ADGM_DESIGNATED_CONTACT: "ADGM Designated Contact",
  VIEW_COMPANY_TAB_KEY_PEOPLE_ADGM_DESIGNATED_CONTACT_EDIT: "Edit Designated Contact",

  VIEW_COMPANY_TAB_LABEL_SHARES: "Shareholders",
  VIEW_COMPANY_TAB_LABEL_SIPS: "ESOP",
  VIEW_COMPANY_TAB_LABEL_CONVERTIBLES: "Convertibles",
  VIEW_COMPANY_TAB_LABEL_SHARE_OPTION_GRANTS: "Share Option Grants",
  VIEW_COMPANY_TAB_LABEL_SHARE_OPTION: "Share Options",
  VIEW_COMPANY_TAB_LABEL_WARRANTS: "Warrants",
  VIEW_COMPANY_TAB_LABEL_ADDRESS_AND_CURRENCY: "Address & Currency",
  VIEW_COMPANY_TAB_LABEL_NOTICES: "Notices",
  VIEW_COMPANY_TAB_LABEL_DIRECTORS: "Directors",
  VIEW_COMPANY_TAB_LABEL_ASSIGNEE: "Assignee",
  VIEW_COMPANY_TAB_LABEL_PLACE_OF_BUSINESS: "Place of business",
  VIEW_COMPANY_TAB_LABEL_BANKING: "Banking",
  VIEW_COMPANY_TAB_LABEL_NATURE_OF_BUSINESS: "Nature of underlying business",
  VIEW_COMPANY_TAB_LABEL_OPERATION_COMPANY: "Operating Company",
  VIEW_COMPANY_TAB_LABEL_OTHER: "Other",
  VIEW_COMPANY_TAB_ADDRESS_CURRENCY_COMPANY_LABEL: "Company address",
  VIEW_COMPANY_TAB_ADDRESS_CURRENCY_COMPANY_LABEL_TEXT:
    "The Company address is the same as the registered address",
  VIEW_COMPANY_TAB_ADDRESS_CURRENCY_COMPANY_TRANSACTION_CURRENCY_LABEL:
    "Transaction currency:",
  VIEW_COMPANY_TAB_ADDRESS_CURRENCY_COMPANY_TRANSACTION_EXTRA_CURRENCY_LABEL:
    "Additional:",
  VIEW_COMPANY_TAB_ADDRESS_CURRENCY_COMPANY_LABEL_ACCOUNTING_ADDRESS:
    "Accounting address:",

  //Croporate positions
  VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_TITLE: "Corporate positions",
  VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_PRESIDENT: "President (CEO)",
  VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_SECRETARY: "Secretary",
  VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_TREASURER: "Treasurer",
  VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_SOLE_INCORPORATOR:
    "Sole incorporator",
  VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_CORPORATE_SIGNER:
    "Corporate signer",

  //----------

  VERIFY_MAIL_TITLE: "Please verify your email",
  VERIFY_DEVICE_TITLE: "Verify your device",
  DEAUTHORISE_DEVICE_TITLE:
    "Your device has been de-authorised due to three password failed attempts.",
  VERIFY_ERROR: "Verification failed. Check the code and try again.",
  VERIFY_EMPTY: "Make sure you have entered the pin in full.",
  VERIFY_MAIL_FIRST_TEXT:
    "You're almost there! We sent an email to <b>{{userEmail}} </b>",
  VERIFY_IP_MAIL_FIRST_TEXT:
    "You are logging in from an IP Address that we don't recognize. We have sent an email to <b>{{userEmail}} </b> to verify your account.",
  VERIFY_IP_MAIL_FIRST_TEXT_V2: "We sent an email to {{userEmail}}",
  VERIFY_MAIL_SECOND_TEXT:
    "The email will contain your 6 digit verification code. If you don't see it, you may need to check your spam folder.",
  DEAUTHORISE_DEVICE_MAIL_SECOND_TEXT:
    "The email will contain a new 6 digit verification code. If you don't see it, you may need to check your spam folder.",
  VERIFY_MAIL_THIRD_TEXT:
    "Enter your verification code below to complete your signup.",
  VERIFY_MAIL_THIRD_TEXT_V2:
    "Enter your verification code below to finish creating your account.",
  DEAUTHORISED_MAIL_THIRD_TEXT:
    "Enter your verification code below to re-authorised your device.",
  VERIFY_IP_MAIL_THIRD_TEXT:
    "Enter your verification code below to log into your account.",
  VERIFY_IP_DEVICE_NAME: "Last step, confirm or rename your verified device.",
  VERIFY_MAIL_BUTTON_SUBMIT: "Verify and create account",
  VERIFY_IP_MAIL_BUTTON_SUBMIT: "Verify and login",
  VERIFY_IP_MAIL_BUTTON_SUBMIT_V2: "Authorise this device",
  VERIFY_MAIL_NOT_RECEIVED: "Still haven't received the email?",
  VERIFY_MAIL_EXISTS: "Email address already used.",
  VERIFY_DEVICE_EXISTS: "Please enter a device name",

  VERIFY_MAIL_RESEND_BUTTON: "Resend email",
  FORGOT_PASSWORD_TITLE: "Forgotten your password?",
  RESET_PASSWORD_BUTTON: "Reset password",
  REMEMBERED_PASSWORD_TEXT: "Remembered your password?",
  THANK_YOU: "Thank you!",
  DOCUMENT_SEARCH_INPUT: "Search document",
  DOCUMENT_VIEW_USE_DOCUMENT_BUTTON: "Use document",
  DOCUMENT_VIEW_USE_DOCUMENT_BUTTON_BETA: "Try New Generator (Beta)",
  DOCUMENT_VIEW_ANNOTATED_BUTTON: "Annotated",
  DOCUMENT_INTERNATIONAL_DESCRIPTION:
    "These documents are governed under the laws of England and Wales. They might be suitable for use with companies that are in common law jurisdictions (other than the US), such as the UK, Abu Dhabi Global Market, Dubai International Financial Centre, Cayman Islands, British Virgin Islands and Singapore. However, please obtain legal advice before using any of these documents in any jurisdiction to determine suitability or any amendments that may need to be made.",
  DOCUMENT_UNITED_STATES_DESCRIPTION:
    "These documents are governed under Delaware law (other than the Employee Offer Letter (California) and Employee Proprietary Information and Inventions Assignment Agreement (California), which are governed under California law). They should only be used with companies that are C-Corporations incorporated in Delaware. We recommend that you obtain legal advice before using any of these documents.",
  DOCUMENT_UNITED_STATES_POWERED_BY:
    "Powered by Mintz, Levin, Cohn, Ferris, Glovsky and Popeo, P.C.",
  DOCUMENT_Y_COMBINATOR_DESCRIPTION:'These documents are the SAFE documents made available by Y Combinator.',
  RESET_PASSWORD_MAIL_TEXT:
    "We’ve sent you an email with instructions on how to reset your password.",
  RESET_PASSWORD_MAIL_TEXT_RESEND: "Still haven’t received the email?",
  SIGN_IN_NOT_ACCOUNT_TEXT: "Don't have an account yet?",
  VERIFY_PASSWORD_LABEL: "Verify password",
  TEXT_DOWNLOAD_ALL_ZIP: "Download .zip file",
  TEXT_DOWNLOAD_ALL_ZIP_CONTENT:
    "Due to the large amount of documents, this action might take longer than expected. You’ll be notified with a message as soon as this is completed, and you’ll find the .zip file saved in the Miscellaneus section.",
  CATEGORIES_TITLES: {
    BUILD_FORMATIONS_DOCUMENT: "Build Formations Document",
    SPV_BUSINESS_PLAN_TEMPLATE_DOCUMENT: "Business Plan (ADGM SPV)",
    CONSENT_LETTER_TO_USE_REGISTERED_OFFICE_DOCUMENT:
      "Consent Letter to use registered office",
    CONSENT_TO_ACT_DOCUMENT: "Consent to Act",
    CONSENT_TO_ACT_NOMINEE: "Consent to Act Nominee",
    CORPORATE_SHAREHOLDER_RESOLUTION_DOCUMENT:
      "Corporate Shareholder Resolution",
    DATA_PROTECTION_DECLARATION_DOCUMENT:
      "Data Protection Declaration Document (ADGM SPV)",
    FIT_AND_PROPER_DECLARATION_DOCUMENT: "Fit And Proper Declaration",
    NOMINEE_ARRANGEMENT_CONFIRMATION: "Nominee Arrangment Confirmation",
    STATUTORY_APPOINTMENT_NOMINEE: "Statutory Appointment Nominee",
    EQUITY_SUPPORTING_DOCUMENT: "Equity Supporting document",
    REGISTER_OF_DIRECTORS_RESIDENTIAL_ADDRESS_DOCUMENT:
      "Register of Directors Residential Address",
    REGISTER_OF_UBOS_DOCUMENT: "Register of UBOS",
    REGISTERED_OFFICE_AND_SERVICES_AGREEMENT_DOCUMENT:
      "Registered Office and Services Agreement",
    SHARE_CERTIFICATE_DOCUMENT: "Share Certificate",
    ADGM_REGISTER_OF_DIRECTORS_COMPANY_NAME_DOCUMENT:
      "ADGM Register of Directors of Company Name",
    LETTER_OF_CONSENT_TO_USE_A_SIMILAR_NAME_DOCUMENT:
      "Letter of Consent to use a Similar Name",
    OLDPASSPORT_DOCUMENT: "Old Passport",
    USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT:
      "Employee Proprietary Information and Inventions Agreement (California)",
    USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT:
      "Consultant Proprietary Information and Inventions Assignment Agreement",
    USA_STRATEGIC_ADVISORY_BOARD_LETTER: "Strategic Advisory Board Letter",
    USA_NDA: "Non-Disclosure Agreement (One Way) ",
    USA_BOARD_CONSENT_SAFE: "Board consent - Antler SAFE financing",
    USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE:
      "Board consent - Antler SAFE Financing - Convertibles",
    USA_BOARD_CONSENT_SAFE_SAFE: "Board consent - Antler SAFE financing - SAFE",
    USA_BOARD_CONSENT_SAFE_KISS: "Board consent - Antler SAFE financing - KISS",
    USA_BOARD_CONSENT_SAFE_FIXED_EQUITY:
      "Board consent - Antler SAFE financing - Fixed Equity",
    USA_BOARD_CONSENT_SAFE_OTHER:
      "Board consent - Antler SAFE financing - Other",
    USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE:
      "Offer Letter for Exempt Salaried Employee (California)",
    USA_EQUITY_INCENTIVE_PLAN: "Equity Incentive Plan",
    USA_STAKEHOLDERS_DIRECTORS_EQUITY_INCENTIVE_PLAN:
      "Joint Stockholders and Directors Consent - Adopting Equity Incentive Plan",
    USA_CONSULTING_AGREEMENT: "Consulting Agreement",
    USA_BOARD_APPROVING_OPTION_GRANT: "Board Consent Approving Option Grants",
    USA_OPTION_GRANT_NOTICE:
      "Stock Option Grant Notice and Stock Option Agreement",
    CORPORATE_STRUCTURE: "Corporate Structure",
    corporateStructure: "Corporate Structure",
    constitutionalDocuments: "Constitutional Documents",
    MEMORANDUM_OF_ASSOCIATION: "Memorandum of Association",
    ARTICLES_OF_ASSOCIATION: "Articles of Association",
    CERTIFICATE_OF_INCORPORATION: "Certificate of Incorporation",
    STRUCTURE_CHART: "Structure Chart",
    TRADE_LICENSE: "Commercial/Trade License",
    REGISTER_OF_DIRECTORS: "Register of Directors",
    REGISTER_OF_SHARES: "Register of Shares",
    SHARE_CERTIFICATE: "Share Certificate",
    SHARE_RESOLUTION: "Shareholder Resolution",
    BOARD_RESOLUTION: "Board Resolution",
    OTHER_CONSTITUTIONAL_DOC: "Miscellaneous Constitutional Document",
    ownership: "Ownership",
    jurisdiction: "Jurisdiction",
    EQUITY: "Equity",
    equity: "Equity",

    sharePurchaseAgreements: "Share Purchase Agreements",
    SHARE_PURCHASE_AGREEMENTS: "Share Purchase Agreement",
    OTHER_SHARE_PURCHASE_AGREEMENT: "Miscellaneous Share Purchase Document",
    subscriptionAgreements: "Subscription Agreements",
    SHARE_SUBSCRIPTION: "Share Subscription Agreement",
    OTHER_SUBSCRIPTION_AGREEMENT: "Miscellaneous Share Subscription Document",
    shareOptionsAgreements: "Share Option Agreements",
    GRANT_AGREEMENT: "Grant Agreement",
    EXERCISE_NOTICE: "Exercise Notice",
    SHARE_INCENTIVE_PLAN: "Share Incentive Plan",
    SHARE_INCENTIVE_PLAN_FAQ: "Share Incentive Plan (FAQ)",
    OTHER_SHARE_OPTION_AGREEMENT: "Miscellaneous Share Options Document",
    convertibleInstruments: "Convertible Instruments",
    CONVERTIBLE_NOTE: "Convertible Note",
    KISS: "Keep It Simple Security",
    SAFE: "Simple Agreement for Future Equity",
    WARRANT_AGREEMENT: "Warrant Agreement",
    OTHER_CONVERTIBLE_AGREEMENT:
      "Miscellaneous Convertible Instrument Document",
    GOVERNANCE: "Governance",
    governance: "Governance",
    shareHoldersAgreements: "Shareholders Agreements",
    FOUNDERS_TERM_SHEET: "Founders Term Sheet",
    FOUNDERS_AGREEMENT: "Founders Agreement",
    SHAREHOLDERS_AGREEMENT: "Shareholders Agreement",
    OTHER_SHAREHOLDER_AGREEMENT: "Miscellaneous Shareholders Document",
    intra_agreements: "Intra-group Agreements",
    INTRAGROUP_AGREEMENT: "Intra-Group Agreement",
    GROUP_AGREEMENT: "Intra-Group Agreements: Other",
    relatedParty: "Related Party Transactions",
    RELATED_PARTY_AGREEMENT: "Related Party Agreement",
    OTHER_RELATED_PARTY_AGREEMENT: "Miscellaneous Related Party Document",
    TEAM: "Team",
    team: "Team",
    foundersServicesAgreements: "Founder Services Agreements",
    founderServicesAgreements: "Founder Services Agreements",
    FOUNDER_SERVICES_AGREEMENT: "Founder Services Agreement",
    MANAGEMENT_AGREEMENT: "Management Agreement",
    OTHER_FOUNDER_SERVICE_AGREEMENT: "Miscellaneous Founder Services Document",
    employmentAgreements: "Employment Agreements",
    EMPLOYMENT_AGREEMENT: "Employment Agreement",
    EMPLOYMENT_AGREEMENT_ADGM: "Employment Agreement - ADGM",
    EMPLOYMENT_AGREEMENT_DIFC: "Employment Agreement - DIFC",
    EMPLOYMENT_AGREEMENT_UK: "Employment Agreement - UK",
    OTHER_EMPLOYMENT_AGREEMENT: "Miscellaneous Employment Document",
    CONSULTANT_AGREEMENT: "Consultancy Agreement",
    OTHER_CONSULTING_AGREEMENT: "Miscellaneous Consultancy Document",
    advisoryAgreements: "Advisory Agreements",
    ADVISOR_AGREEMENT: "Advisor Agreement",
    OTHER_ADVISORY_AGREEMENT: "Miscellaneous Advisor Document",
    consultancyAgreements: "Consultancy Agreements",
    INTELLECTUAL_PROPERTY: "Intellectual Property",
    intellectualProperty: "Intellectual Property",
    registeredIP: "Registered IP",
    IP_REGISTRATION_DOCUMENT: "IP Registration Document",
    unregisteredIP: "Unregistered IP",
    UNREGISTERED_IP_DOCUMENT: "Unregistered IP Document",
    IPInfringement: "IP Infringement",
    IP_INFRINGEMENT_DOCUMENT: "IP Infringement Document",
    IPAssignment: "IP Assignment",
    IP_ASSIGNMENT: "IP Assignment Agreement",
    OTHER_IP_ASSIGNMENT: "Miscellaneous IP Assignment Document",
    OTHER_INTRA_GROUP_AGREEMENT: "Miscellaneous Intra-Group Document",
    OTHER: "Miscellaneous",
    other: "Miscellaneous",
    litigation: "Litigation",
    LITIGATION_DOCUMENT: "Litigation Document",
    materialAgreements: "Material Agreements",
    KEY_CUSTOMER_AGREEMENT: "Key Customer Agreement",
    KEY_SUPPLIER_AGREEMENT: "Key Supplier Agreement",
    RESTRICTIVE_COVENANT_AGREEMENT: "Non-Compete Agreement",
    realEstate: "Real Estate",
    REAL_ESTATE_DOCUMENT: "Real Estate Document",
    financialDebt: "Finance",
    FINANCIAL_DEBT_DOCUMENT: "Financing/Debt Document",
    investorUpdates: "Investor Updates",
    INVESTOR_UPDATES: "Investor Update",
    equitySupportingDocument: "Equity Supporting Document",
    miscellaneous: "Other - Miscellaneous",
    miscellaneousTeam: "Team - Miscellaneous",
    miscellaneousIntellectualProperty: "Intellectual Property - Miscellaneous",
    MISCELLANEOUS_DOCUMENT: "Miscellaneous Document",
    NDA_MUTUAL: "Non-Disclosure Agreement (Mutual)",
    WEBSITE_TERMS_OF_USE: "Website Terms Of Use",
    WEBSITE_PRIVACY_POLICY: "Website Privacy Policy",
    WEBSITE_COOKIE_NOTICE: "Website Cookie Notice",
    WEBSITE_ACCEPTABLE_USE_POLICY: "Website Acceptable Use Policy",
    COMMERCIAL_LICENSE_DOCUMENT: "Commercial License",
    BOARD_RESOLUTION_CONVERTIBLE: "Board Resolution Convertibles",
    BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE:
      "Board Resolution - Convertible - Loan Note",
    BOARD_RESOLUTION_CONVERTIBLE_SAFE: "Board Resolution - Convertible - SAFE",
    BOARD_RESOLUTION_CONVERTIBLE_KISS: "Board Resolution - Convertible - KISS",
    BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY:
      "Board Resolution - Convertible - Equity Agreement",
    BOARD_RESOLUTION_CONVERTIBLE_OTHER:
      "Board Resolution - Convertible - Other",
    DETAILS: "Details",
    passport_visa_ids: "Passport visa ids",
    PROOF_OF_ADDRESS_DOCUMENT: "Proof Of Address",
    THIRDPARTY_ADDRESS_FORM_DOCUMENT: "Third Party Address Form",
    THIRDPARTY_ADDRESS_PASSPORT_DOCUMENT: "Third Party Passport",
    UAE_VISA_DOCUMENT: "UAE VISA",
    UAE_VISA_LASTPAGE_DOCUMENT: "UAE VISA last page",
    PASSPORT_DOCUMENT: "Passport Document",
    EID_DOCUMENT: "Emirates ID",
    EID_LASTPAGE_DOCUMENT: "Emirates ID last page",
    UID_STAMP_DOCUMENT: "UID Stamp",
    UID_OLDPASSPORT_DOCUMENT: "UID Stamp Old Passport",
    GCC_RESIDENT_ID_DOCUMENT: "GCC Residency",
    NATIONAL_ID_DOCUMENT: "National ID",
    UAE_RESIDENCE_VISA_DOCUMENT: "UAE Residence VISA",
    UAE_ENTRY_STAMP_DOCUMENT: "UAE Entry Stamp",
    ADDRESS_FAMILY_CONTACT_PAGE_DOCUMENT: "Address Family Contact Page",
    ADGM_TSL_BUSINESS_PLAN_TEMPLATE_DOCUMENT:
      "Business Plan (ADGM Tech Licence)",
    ADGM_TSL_CONFIRMATION_EMAIL: "Confirmation Email",
    ADGM_TSL_PITCH_DECK: "Pitch Deck",
    ADGM_TSL_DATA_PROTECTION_DECLARATION:
      "Data Protection Declaration Document (ADGM Tech Licence)",
    ADGM_TSL_LEASE_AGREEMENT: "Lease Agreement",
    ADGM_INDEMNITY_AGREEMENT: "Indemnity Agreement",
    ADGM_NAME_SELF_DECLARATION: "Self Name Declaration",
    ADGM_WELCOME_PACK_COVER_NOTE: "Welcome Pack Cover Note",
    REGISTER_OF_MEMBERS: "Register of Members",
    ADGM_CERTIFICATE_OF_INCUMBENCY: "Certificate of Incumbency",
    CERTIFICATE_OF_GOOD_STANDING: "Certificate of Good Standing",
    REGISTRY_EXTRACT: "Registry Extract",
    CERTIFICATE_OF_CHANGE_NAME: "Certificate of Change Name",
    REGISTER_OF_PARTNERS: "Register of Partners",
    LIMITED_PARTNERSHIP_AGREEMENT: "Limited Partnership Agreement",
    CERTIFICATE_OF_REGISTRATION: "Certificate of Registration",
    TRUST_DEED: "Trust Deed",
    // Compliance
    COMPLIANCE: 'Compliance',
    kycDocuments: 'KYC Documents',
    kycDocumentsIndividual: 'KYC Documents (Individual)',
    kycDocumentsCorporate: 'KYC Documents (Corporate)',
    externalResearch: 'External Research',
    COMPLIANCE_WORLD_CHECK: 'World Check',
    COMPLIANCE_GOOGLE_SEARCH: 'Google Search',
    crafs: 'CRAFs',
    CRAF_DOCUMENT: 'CRAF Document',
    complianceMiscellaneous: 'Compliance - Miscellaneous',
    CAYMAN_SOURCE_OF_FUNDS_SHAREHOLDER: 'Cayman Source of Funds - Shareholder',
    CAYMAN_SOURCE_OF_FUNDS_DIRECTOR: 'Cayman Source of Funds - Director',
    CAYMAN_SOURCE_OF_WEALTH_SHAREHOLDER: 'Cayman Source of Wealth - Shareholder',
    COMPLIANCE_MISCELLANEOUS_DOCUMENT: 'Compliance Miscellaneous Document',
    SOW_DECLARATION_FORM: 'SOW Declaration Form',
    SOW_SUPPORTING_DOCUMENT: 'SOW Supporting Document',
    DIRECTORS_CERTIFICATE: "Director's Certificate",
    CAYMAN_DITC_SUBMISSION_CONFIRMATION: 'DITC Submission confirmation',
  },

  CATEGORIES_ORDER: {
    CORPORATE_STRUCTURE: 0,
    OTHER: 5,
    TEAM: 3,
    GOVERNANCE: 1,
    INTELLECTUAL_PROPERTY: 4,
    EQUITY: 2,
    COMPLIANCE: 6
  },

  CREATION_MODAL_TITLE: "Congratulations on creating an account",
  CREATION_MODAL_SUBTITLE:
    "As a <b>“Start” subscriber</b>, you have access to these features:",
  CREATION_MODAL_BUTTON: "Create profile",

  // Assistance Menu GroupCompany
  BUTTON_DELETE: "Delete",
  ASSISTANCE_BUTTON_GROUP_COMPANY_ADDRESS_AND_CURRENCY:
    "Edit <label>Address & Currency</label>",
  ASSISTANCE_BUTTON_GROUP_COMPANY_NOTICES: "Edit <label>Notices</label>",
  ASSISTANCE_BUTTON_GROUP_COMPANY_DIRECTORS: "Add <label>Directors</label>",
  ASSISTANCE_BUTTON_GROUP_COMPANY_UPDATE: "Edit  <label>Profile</label>",
  OVERLAY_NOTIFICATION_ASSISTANCE_BUTTON_GROUP_COMPANY_UPDATE: "Edit  Profile",
  ASSISTANCE_BUTTON_GROUP_COMPANY_MANAGE_DOCUMENTS:
    "Manage <label>Documents</label>",
  ASSISTANCE_BUTTON_GROUP_COMPANY_MANAGE_EQUITY_POSITIONS:
    "Manage <label>Equity Positions</label>",
  GROUP_COMPANY_MODAL_UPDATE_PROFILE: "Edit <label>Details</label>",

  //Form Edit Company Profile

  FORM_COMAPANY_LABEL_STARTUP_NAME: "Business name",
  FORM_COMAPANY_LABEL_STARTUP_DESCRIPTION: "Business description",
  FORM_COMAPANY_LABEL_STARTUP_WEBSITE: "Website",
  FORM_COMAPANY_LABEL_STARTUP_ACCELERATOR: "Accelerator",
  FORM_COMAPANY_LABEL_STARTUP_PRIMARYLOCATION: "Primary Location",
  FORM_COMAPANY_LABEL_STARTUP_PROFILE_CREATED: "Profile created",
  FORM_COMAPANY_LABEL_STARTUP_LINKEDIN: "LinkedIn URL",
  //Form Edit Company Profile

  FORM_COMAPANY_LABEL_COMPANY_NAME: "Company name",
  FORM_COMAPANY_LABEL_COUNTRY: "Country",
  FORM_COMAPANY_LABEL_COUNTRY_INCORPORATION: "Country of incorporation",
  FORM_COMAPANY_LABEL_COMPANY_TYPE: "Company type",
  FORM_COMAPANY_LABEL_JURISDICTION: "Jurisdiction",
  FORM_COMAPANY_LABEL_JURISDICTION_BIS: "Jurisdiction",
  FORM_COMAPANY_LABEL_JURISDICTION_OTHER: "Other jurisdiction",
  FORM_COMAPANY_LABEL_COMPANY_NUMBER: "Company number",
  FORM_COMAPANY_LABEL_COMPANY_REGISTERED_ADDRESS: "Registered address",
  FORM_COMAPANY_LINKEDIN_URL: "Linkedin URL",
  FORM_COMAPANY_DISSOLUTION_DATE_LABEL: "Dissolution date",
  FORM_COMAPANY_INCORPORATION_DATE_LABEL: "Incorporation date",
  COMPANY_MODAL_DELETE_PROFILE_TITLE: "Delete {{companyName}} group company",
  STAKEHOLDER_MODAL_DELETE_PROFILE_TITLE:
    "Delete {{{stakeholderName}}} {{#isIndividual}}Individual{{/isIndividual}}{{^isIndividual}}Corporate{{/isIndividual}} Stakeholder",

  FORM_COMAPANY_LABEL_COMPANY_HOLDING:
    "This Group Company is the top level Holding Company for the Startup.",

  //Form add Company Details
  FORM_ADD_COMPANY_LABEL_COMPANY_CURRENCY: "Currency:",

  //Form Edit Company  Address and Currency
  FORM_ADDRESS_AND_CURRENCY_TITLE_LABEL: "Address & Currency",
  NOTIFICATION_FORM_ADDRESS_AND_CURRENCY_TITLE_LABEL: "Address and Currency",
  FORM_ADDRESS_AND_CURRENCY_COMPANY_ADDRESS: "Registered Address:",
  FORM_COMPANY_LABEL_COMPANY_WORKING_ADDRESS:
    "The address for the Company is the same as the Registered address",
  FORM_COMPANY_LABEL_COMPANY_CURRENCY: "Transaction Currency:",
  FORM_COMPANY_LABEL_COMPANY_EXTRA_CURRENCY_BUTTON: "Add Currency",
  FORM_COMPANY_LABEL_COMPANY_EXTRA_CURRENCY_LABEL: "Additional Currency:",

  FORM_STAKEHOLDER_ADDRESS_SERVICE_USE_RESIDENTIAL_ADDRESS:
    "The service address is the same as the residential address",

  //Form Edit Company  Address and Currency

  FORM_NOTICES_TITLE_LABEL: "Edit Notices",

  FORM_NOTICES_FULL_LEGAL_NAME: "Full Legal Name",
  FORM_NOTICES_EMAIL: "Email",
  FORM_NOTICES_ADDRESS_NOTICES:
    "The address for the recipient is the same as the registered address",

  //Form Equity Share

  FORM_EQUITY_TITLE_LABEL: "Edit Shares",
  FORM_EQUITY_SHARE_COMPANY_LABEL: "Company Name:",
  MODAL_DELETE_SHARE_TITLE: "Delete these Shares?",
  MODAL_DELETE_SHARE_CLASS_LABEL: "Delete this Share Class",
  MODAL_DELETE_SHARE_CLASS_IN_USE_TEXT:
    "This share class currently can not be deleted as it is in use. If you still wish to delete this, then choose a new class for the shares that are using the share class in use class",

  // Form Directors
  ADD_SHARES: "Add Shares",
  ADD_CLASS: "Add Class",
  EDIT_CLASS: "Edit Share Class",
  ADD_SHARES_OPTION: "Add share options",
  EDIT_SHARES_OPTION: "Edit Share Options",

  FORM_DIRECTORS_TITLE_LABEL: "Edit Directors",
  FORM_ADD_DIRECTORS_TITLE_LABEL: "Add Directors",

  FORM_DIRECTORS_OPTIONS_LABEL: "Directors:",

  FORM_DIRECTORS_ADD_DIRECTOR_LABEL: "Edit Directors",

  MESSAGE_ADD_COMPANY_COMPONENT:
    "This will create a new company for your startup. You can add more details later from the company's profile page.",
  MESSAGE_ADD_STAKEHOLDER_COMPONENT:
    "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
  MESSAGE_ADD_POSITION_COMPONENT:
    "This will create a new Position for your startup. You can add more details later on responsibilities field.",
  MESSAGE_ADD_DEPARTMENT_COMPONENT:
    "This will create a new Department for your startup.",

  FORM_EQUITY_SHARE_SHAREHOLDER_LABEL: "Shareholder Name: ",
  DUEDILIGENCE_LAST_RUN: "Last run:",
  MODAL_DELETE_CONVERTIBLE_TITLE: "Delete this Convertible?",
  EDIT_WARRANT: "Edit a Warrant",
  ADD_WARRANT: "Add a Warrant",
  MODAL_DELETE_WARRANT_TITLE: "Delete this Warrant?",
  MODAL_DELETE_SHARE_OPTIONS_TITLE: "Delete this Share Options?",
  VIEW_COMPANY_LABEL_DISSOLUTION_DATE: "Dissolution date",

  ASSISTANCE_QUESTION_TITLE_EQUITY_MENU:
    "Here are some other things you can do with Equity...",
  ASSISTANCE_BUTTON_ADD_EQUITY_SHARE: "Add <label>Share Positions</label>",
  ASSISTANCE_BUTTON_ADD_EQUITY_SHARE_OPTION: "Add <label>Share Option</label>",
  ASSISTANCE_BUTTON_ADD_EQUITY_CONVERTIBLE: "Add <label>Convertible</label>",
  ASSISTANCE_BUTTON_ADD_EQUITY_WARRANT: "Add <label>Warrants</label>",
  ASSISTANCE_BUTTON_PREVIOUS: "Previous",
  ASSISTANCE_QUESTION_TITLE_DOCUMENTS_MENU:
    "Here are some other things you can do with documents...",
  ASSISTANCE_BUTTON_ADD_DOCUMENTS_UPLOAD: "Add <label>Document</label>",
  ASSISTANCE_BUTTON_ADD_DOCUMENTS_GENERATE: "Create <label>Document</label>",
  FORM_STAKEHOLDER_CORPORATE_LABEL_COMPANY_NAME: "Company Name:",
  FORM_STAKEHOLDER_CORPORATE_LABEL_COMPANY_TYPE: "Company Type:",
  FORM_STAKEHOLDER_CORPORATE_LABEL_COMPANY_NUMBER: "Company Number:",
  FORM_STAKEHOLDER_CORPORATE_LABEL_COMPANY_HOLDING:
    "The Group Company is the top level Holding Company for the startup.",
  FORM_STAKEHOLDER_CORPORATE_LABEL_COMPANY_REGISTERED_ADDRESS:
    "Registered Address:",
  FORM_STAKEHOLDER_CORPORATE_START_DATE_LABEL: "Start Date:",
  FORM_STAKEHOLDER_CORPORATE_END_DATE_LABEL: "End Date:",
  FORM_STAKEHOLDER_CORPORATE_LINKEDIN_URL: "Linkedln URL:",
  REGISTERED_ADDRESS: "Registered Address:",
  EDIT_NOTICES: "Edit <label>Notices</label>",
  AUTHORISED_SIGNATORY: "Authorized Signatory",
  CORPORATE_SIGNATORY: "Corporate Signatory",
  EDIT_AUTHORISED_SIGNATORIES: "Edit <label>Signatories</label>",
  FORM_AUTHORISED_SIGNATORIES_TITLE_LABEL: "Corporate Signatory",
  FORM_AUTHORISED_SIGNATORIES_FULL_LEGAL_NAME: "Full legal name",
  FORM_AUTHORISED_SIGNATORIES_ROLE: "Role:",
  FORM_AUTHORISED_SIGNATORIES_EMAIL: "Email address",
  FORM_AUTHORISED_SIGNATORIES_PHONE_NUMBER: "Telephone number",
  FORM_AUTHORISED_SIGNATORIES_SIGNING_AUTHORITY: "Signing authority",

  FORM_CORPORATE_SIGNATORY_FULL_LEGAL_NAME: "Full legal name",
  FORM_CORPORATE_SIGNATORY_EMAIL_ADDRESS: "Email address",
  FORM_CORPORATE_SIGNATORY_TELEPHONE_NUMBER: "Telephone number",
  FORM_CORPORATE_SIGNATORY_SIGNING_AUTHORITY: "Signing authority",
  FORM_CORPORATE_SIGNATORY_POSITION: "Position",

  DOCUMENT_FILE_LABEL: "Document File:",
  DOCUMENT_UPLOAD_MODAL_DELETE_TITLE: "Delete document",
  DOCUMENT_HISTORY_GENERATED_DOCUMENT_TERMINATED:
    "{{date}} - Terminated by {{user}}",
  DOCUMENT_HISTORY_DOCUMENT_CREATED: "{{date}} - Created by {{user}}",
  DOCUMENT_HISTORY_DOCUMENT_EDITED: "{{date}} - Edited by {{user}}",
  DOCUMENT_HISTORY_DOCUMENT_CATEGORIZED: "{{date}} - Categorised by {{user}}",
  DOCUMENT_HISTORY_UNCATEGORIZED_DOCUMENT_CREATED:
    "{{date}} - Uncategorised created by {{user}}",
  DOCUMENT_HISTORY_GENERATED_DOCUMENT_CREATED:
    "{{date}} - Generated by {{user}}",
  DOCUMENT_HISTORY_GENERATED_DOCUMENT_SENT: "{{date}} - Sent  by {{user}}",
  DOCUMENT_HISTORY_GENERATED_DOCUMENT_RESEND: "{{date}} - Sent  by {{user}}",
  DOCUMENT_HISTORY_GENERATED_DOCUMENT_SIGNED: "{{date}} - Signed ",
  DOCUMENT_HISTORY_GENERATED_DOCUMENT_VOID: "{{date}} - Voided by {{user}}",
  DOCUMENT_HISTORY_DOCUMENT_CHANGED_NAME: "{{date}} - Name changed by {{user}}",
  DOCUMENT_DETAIL_LABEL_TYPE: "Document Type:",
  DOCUMENT_DETAIL_LABEL_CATEGORY: "Category:",
  DOCUMENT_DETAIL_LABEL_SUBCATEGORY: "Subcategory:",
  DOCUMENT_DETAIL_LABEL_EXECUTION_DATE: "Execution Date",
  DOCUMENT_DETAIL_LABEL_EXPIRATION_DATE: "Expiration Date",
  SUBSCRIPTION: "Subscription",
  INVOICE: "Invoice",
  SUBCATEGORY_INVOICE_SCALE: "Scale",
  SCALE_TYPE: "Clara Site-Scale",
  REQUEST_SIGNATURES_DOCUMENT: "Request Signatures",
  RESEND_REQUEST_SIGNATURES_DOCUMENT: "Resend Request Signatures",
  RANGE_CARD_INVALID: "We only accept Visa, American Express and Mastercard.",
  //Versions
  VERSION_KEY_CARDS: "Card Handling",
  VERSION_KEY_PAGES: "Detail Screens",
  VERSION_KEY_SUBSCRIPTION: "Checkout for startups",
  VERSION_KEY_FORMATION: "Checkout for formations",
  VERSION_KEY_AUTOMATIC_EQUITY_POSITIONS: "Automatic equity positions",
  VERSION_KEY_VESTING_DETAILS: "Vesting Details",
  VERSION_KEY_GENERATE_DOCUMENT: "Generate Document View",
  VERSION_KEY_UNALLOCATE_SHARE: "Unallocate Share",
  VERSION_KEY_THIRD_PARTY: "Third Party",
  VERSION_KEY_PAYMENT: "Monthly subscription",
  VERSION_KEY_ONBOARDING: "Onboarding",
  VERSION_KEY_MFA_DEVICES: "MFA Devices",
  VERSION_KEY_TASKS_AND_GOALS: "Tasks and goals",


  ASSISTANCE_DOCUMENT_UPLOAD_DETAIL: "Here are some other things you can do...",
  ASSISTANCE_BUTTON_DOCUMENT_UPLOAD_UPDATE: "Edit <label>Document</label>",
  ASSISTANCE_BUTTON_DOWLOAD_DOCUMENT_UPLOAD: "Download <label>Document</label>",
  ASSISTANCE_BUTTON_UPLOAD_DOCUMENT: "Upload new <label>Document</label>",
  ASSISTANCE_BUTTON_DELETE_DOCUMENT_UPLOAD: "Delete <label>Document</label>",
  ASSISTANCE_BUTTON_DELETE_GRANT: "Delete <label> Grant </label>",
  ASSISTANCE_BUTTON_DELETE_SIP: "Delete <label> SIP </label>",
  ASSISTANCE_BUTTON_TERMINATE_GRANT: "Terminate <label> Grant </label>",
  ASSISTANCE_BUTTON_VIEW_VESTING_SCHEDULE:
    "View <label> Vesting Schedule </label>",
  ASSISTANCE_BUTTON_VIEW_CONNECTED_GRANTS:
    "View <label> Connected Grants </label>",
  ASSISTANCE_BUTTON_GENERATE_DOCUMENT_UPLOAD:
    "Generate <label>New Document</label>",
  ASSISTANCE_BUTTON_GENERATE_DOCUMENT_GET_SIGNATURE: "Get Signatures",
  ASSISTANCE_BUTTON_RESEND_DOCUMENT_NOTIFICATION:
    "Resend <label>Document</label>",
  ASSISTANCE_BUTTON_GENERATE_VOID_DOCUMENT: "Void <label>Document</label>",

  // Table Agreements
  MODAL_UPLOAD_DOCUMENT_NEW_VERSION_TITLE: "Warning Before You Proceed",
  MODAL_UPLOAD_DOCUMENT_NEW_VERSION_PROCEED_BUTTON: "Proceed",
  MODAL_UPLOAD_DOCUMENT_NEW_VERSION_PARAGRAPH_FIRST:
    "Please note that any edits to the content of the signature block can break Clara's DocuSign integration. It is recommended that users do not edit any content on the signature block including the signing date. Please note that the signing date will be completed via DocuSign when the document is fully executed. If you would like to edit the parties signing this document, it is recommended that you edit the parties on the Clara platform and re-generate the draft before making any other edits and re-uploading the document.",
  MODAL_UPLOAD_DOCUMENT_NEW_VERSION_PARAGRAPH_SECOND:
    "Please also note that if you re-generate the draft on Clara by editing the details of the document after uploading a version and verifying the details, Clara will overwrite your manual edits and you will need to re-download and edit the newest version of the document.",

  //Options in Founders Services Agreements in Comiment

  //TO DELETE
  UPGRADE_START_TEXT_CONTENT: `All the features you need to prepare for initial seed funding. Or, if you are getting your idea off the ground, you can create a profile and grow your startup on Clara.`,
  UPGRADE_START_PRICE_TEXT: `{{currencyAmount}} <p>{{amount}}</p> / month`,
  UPGRADE_START_PRICE_SUB_TEXT: `<label>Free</label>`,
  UPGRADE_START_PRICE_BUTTON: `Active`,

  UPGRADE_SCALE_TEXT_CONTENT: `Startups looking to grow and communicate efficiently with multiple investors plus the ability to manage and share numerous legal documents across business entities and stakeholders.`,
  UPGRADE_SCALE_PRICE_TEXT: `{{currencyAmount}} <p>{{amount}}</p> / month `,
  UPGRADE_SCALE_PRICE_SUB_TEXT: `<label> {{currencyAmount}} 3,600 / year (billed annually) </label>`,
  UPGRADE_SCALE_PRICE_BUTTON: `Upgrade`,

  //UPDATE PASSWORD
  UPDATE_PASSWORD_SUCCESS_TOAST: "Your password has been changed",
  UPDATE_PASSWORD_ERROR_REGEXP:
    "Must be at least 8 characters, have an uppercase letter, a lowercase letter and a number",
  UPDATE_PASSWORD_NO_MACH: "Passwords must match",
  UPDATE_PASSWORD_LABEL_TERMS: "Terms & Conditions",
  UPDATE_PASSWORD_LABEL_PRIVACY: "Privacy Policy",

  HOURS10: "10 hours/week",
  HOURS20: "20 hours/week",
  HOURS30: "30 hours/week",
  HOURS40: "40 hours/week",
  RELOAD_DOCUMENT: "If the document is not loading, click here to refresh.",
  MODULES: {
    GENERIC: moduleGeneric.US,
    MAP: moduleMap.US,
    EMPLOYEE: moduleOnboardingEmployee.US,
    ONBOARDINGCOMPANY: moduleOnboardingCompany.US,
    ONBOARDINGROLES: moduleOnboardingRoles.US,
    CHECKOUT: moduleCheckout.US,
    DOCUMENTS: moduleDocuments.US,
    FORMATION: moduleFormation.US,
    ASSISTANCEV4: moduleAssistanceV4.US,
    ONBOARDING_FORMS: moduleOnBoardingFormsStartup.US,
    ONBOARDING_STEPS: moduleOnBoardingStepsStartup.US,
    EQUITY: moduleEquity.US,
    DOCUMENTS_STARTUP: moduleDocumentsStartup.US,
    STAKEHOLDER: moduleStakeholder.US,
    CLARA: moduleClara.US,
    INDIVIDUAL_STAKEHOLDER: moduleIndividualStakeholder.US,
    GROUP_COMPANY: moduleGroupCompany.US,
    MAPMODALS: moduleMapModals.US,
    COMPLETE_STAKEHOLDERS: moduleCompleteStakeholder.US,
    STARTUP: moduleStartup.US,
    CONSTITUTIONAL_DOCUMENTS: moduleConstitutionalDocuments.US,
    SUBMIT_APPLICATION: moduleSubmitApplication.US,
    ACCELERATORS: moduleAccelerators.US,
    RENEWALS: moduleRenewalsKY.US,
    RENEWALS_ADGM: moduleRenewalsADGM.US,
  },

  TAB_PEP_LABEL_AFIRMATIVE: "Yes - {{{description}}}",
  TAB_PEP_LABEL_NEGATIVE: "No",
  TAB_PEP_LABEL_IS_PEP: "Is this stakeholder a PEP?",
  TAB_PEP_LABEL_IS_FAMILY_PEP: "Is this stakeholder a family member of a PEP?",
  TAB_PEP_LABEL_ASSOCIATE_PEP:
    "Is this stakeholder a close associate of a PEP?",
  TAB_SOURCE_SOURCE_OF_FUNDS: "Source of funds",
  TAB_SOURCE_WEALTH: "Source of wealth",
  TAB_SOURCE_CRYPTO:
    "Does this stakeholder have a source of wealth resulting from any material receipt of cryptocurrencies, or material receipt of funds stemming from businesses involved with the issuance of receipt of cryptocurrencies, gambling, the sale or distribution of narcotics, and/or predominantly a “cash-only” business?",

  TAB_ASSIGNEE_ADGM: "ADGM",
  TAB_ASSIGNEE_BANKING: "Banking",
  TAB_ASSIGNEE_DATA_PROTECTION: "Data Protection",

  TAB_OTHER_TITLE: "Assets and revenue",
  TAB_OTHER_HIT:
    "Will the company have shares in any companies not yet formed?",
  TAB_OTHER_HIT_COMPANIES:
    "Will the company have shares in any incorporated companies?",
  TAB_OTHER_LABEL_AFIRMATIVE: "Yes - {{{description}}}",
  TAB_OTHER_LABEL_NEGATIVE: "No",

  TAB_BANKING_HIT: "Banking Authority:",
  TAB_BANKING_SINGLY: "Sign singly",
  TAB_BANKING_JOINTLY: "Sign jointly",
  LAST_UPDATE: "Last updated ",
  DATA_ROOM_TITLE: "Data Room",
  DATA_ROOM_BUTTON_UPLOAD_DOCUMENT: "Upload documents",
  DATA_ROOM_BUTTON_DOWLOAD_ALL_DOCUMENTS: "Download all documents",

  HEALTH_CHECK_TITLE: "HealthCheck",
  HEALTH_CHECK_BUTTON_RUN: "Run health check",
  HEALTH_CHECK_BUTTON_ADD_REMARK: "Add remark",

  CAP_TABLE_TITLE: "Cap Table",
  CAP_TABLE_BUTTON_ADD_EQUITY_POSITION: "Add equity position",
  CAP_TABLE_BUTTON_EDIT_SHARE_CLASS: "Edit share class",
  CAP_TABLE_BUTTON_DOWLOAD_CAP_TABLE: "Download cap table",
  GROUP_COMPANY_TAB_SHAREHOLDERS_DELETE_SHARE_BUTTON: "Delete",
  GROUP_COMPANY_TAB_SHAREHOLDERS_EDIT_SHARE_BUTTON: "Edit",
  GROUP_COMPANY_TAB_SHAREHOLDERS_EDIT_GRANT_BUTTON: "Grants",
  GROUP_COMPANY_TAB_SHAREHOLDERS_EDIT_VESTING_SHARE_BUTTON: "Vesting schedule",
  GROUP_COMPANY_TAB_SHAREHOLDERS_VIEW_BUTTON: "View",

  ROLES_EMPTY_STATE_USER:
    "Click ‘Add role’ at the top to start adding a role to the stakeholder",
  ROLES_EMPTY_STATE_VIEWER: "No roles are available yet.",
  POSITION_EMPTY_STATE_USER: "Click ‘Edit’ to start adding information",
  POSITION_EMPTY_STATE_VIEWER: "No positions are available yet.",
  POSITION_TITLE_POSITION: "Position",
  POSITION_TITLE_DEPARTMENT: "Department",
  POSITION_TITLE_RESPONSABILITIES: "Responsibilities",
  DETAILS_EMPTY_STATE_USER: "Click ‘Edit’ to start adding information",
  DETAILS_EMPTY_STATE_VIEWER: "No details are available yet.",
  DETAILS_FORVIDEN_STATE_VIEWER:
    "You don’t have access to view this information.",
  DOCUMENTS_EMPTY_STATE_VIEWER: "No documents have been<br>uploaded yet.",
  DOCUMENTS_EMPTY_STATE_USER:
    "Click ‘Add document’ at the top<br>to start adding documents",
  DETAILS_DOCUMENT_BUTTON_UPLOAD_NEW_VERSION_DOCUMENT: "Upload new version",
  DETAILS_DOCUMENT_BUTTON_EDIT_DOCUMENT: "Edit",
  DETAILS_DOCUMENT_BUTTON_TERMINATE_GRANT: "Terminate grant",
  DETAILS_DOCUMENT_GENERATED_VOID_DOCUMENT: "Void",
  DETAILS_DOCUMENT_GENERATED_RENAME_DOCUMENT: "Rename",
  DETAILS_DOCUMENT_GENERATED_DOWNLOAD_DOCUMENT: "Download",
  DETAILS_DOCUMENT_GENERATED_BUTTON_DELETE_DOCUMENT: "Delete",
  DETAILS_DOCUMENT_GENERATED_BUTTON_VIEW_CONNECTED_GRANTS:
    "View connected grants",
  DETAILS_DOCUMENT_GENERATED_BUTTON_VIEW_VESTING_SCHEDULE: "Vesting schedule",
  DETAILS_DOCUMENT_UPLOADED_EDIT_DOCUMENT: "Edit",
  DETAILS_DOCUMENT_UPLOADED_RENAME_DOCUMENT: "Rename",
  DETAILS_DOCUMENT_UPLOADED_DOWNLOAD_DOCUMENT: "Download",
  DETAILS_DOCUMENT_UPLOADED_DELETE_DOCUMENT: "Delete",

  TOOLTIPS_DOCUMENTS_TITLE: "{{left}} uploads remaining...",
  TOOLTIPS_DOCUMENTS_TITLE_UPGRADE: "{{left}} uploads remaining",
  TOOLTIPS_DOCUMENTS_SUBTITLE:
    "As part of your free Start subscription before you will need to upgrade.",
  TOOLTIPS_DOCUMENTS_SUBTITLE_UPGRADE:
    "Generate and store unlimited key documents, plus lots more handy features.",

  TOOLTIPS_HEALTHCHECK_TITLE: "{{left}} Health checks remaining...",
  TOOLTIPS_HEALTHCHECK_TITLE_UPGRADE: "{{left}} Health checks remaining",
  TOOLTIPS_HEALTHCHECK_SUBTITLE:
    "As part of your free Start subscription before you will need to upgrade.",
  TOOLTIPS_HEALTHCHECK_SUBTITLE_UPGRADE:
    "Run unlimited health checks, plus lots more handy features.",

  TOOLTIPS_PROFILE_TITLE: "{{left}} profile shares remaining...",
  TOOLTIPS_PROFILE_TITLE_UPGRADE: "{{left}} shares remaining",
  TOOLTIPS_PROFILE_SUBTITLE:
    "As part of your free Start subscription before you will need to upgrade.",
  TOOLTIPS_PROFILE_SUBTITLE_UPGRADE:
    "Share your profile unlimited times, plus lots more handy features.",

  TOOLTIPS_GENERATE_DOCUMENTS_TITLE: "{{left}} documents remaining...",
  TOOLTIPS_GENERATE_DOCUMENTS_TITLE_UPGRADE: "{{left}} documents remaining",
  TOOLTIPS_GENERATE_DOCUMENTS_SUBTITLE:
    "As part of your free Start subscription before you will need to upgrade.",
  TOOLTIPS_GENERATE_DOCUMENTS_SUBTITLE_UPGRADE:
    "Generate unlimited documents, plus lots more handy features.",
  TOOLTIPS_STAKEHOLDER_DETAILS_VIEWER:
    "Your access to this profile has been granted as Viewer. This means that certain sensitive data and documents (for example, anything related to personal information) are hidden. You can change this by requesting wider access to the Owner of this profile.",
  ASSISTANCE_VIEWER_TEXT: "You only have Viewer access to this profile.",
  EQUITY_SHARE_OPTION_TASK_TITLE:
    "Scale up to generate a Grant Agreement for these share options",
  DATA_ROOM_UPLOAD_FILES_DEFAULT_TITLE: "Uploading documents",
  DATA_ROOM_UPLOAD_FILES_COUNT_TITLE: "Uploading {{cantFiles}} documents...",
  DATA_ROOM_CATEGORIES: "Categories",
  VIEW_ROLES_TAB_BUTTON_REMOVE_FOUNDER: "Remove as founder",
  VIEW_ROLES_TAB_BUTTON_REMOVE_AS_FOUNDER: "Remove as founder",
  VIEW_ROLES_DEATILS_BUTTON_ADD_ROLE: "Add role",

  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP:
    "Select all the roles this stakeholder has in one or multiple companies.",
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_FOUNDER: `A <a rel='noopener noreferrer' target='_blank' href="https://academy.clara.co/article/4prCrt3uprKNf5bhkYAMyI" ><b>Founder</b></a> is a person who set up the business from the outset.`,
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_SHAREHOLDER: `A shareholder is an individual or company (a corporate shareholder) holding shares in a company.`,
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_DIRECTOR: `A <a rel='noopener noreferrer' target='_blank' href="https://academy.clara.co/article/2fvaE5tVF7ywn7izaON0PR" >director</a> is a member of the company's board, and can be responsible for the day-to-day management of the company and for setting the company's strategy.`,
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_EMPLOYEE: "",
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_CONSULTANT: `A consultant is self-employed (and may work through another company) and hired to provide their services to your business.`,
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_ADVISOR: `An advisor provides the expertise you need to help you through the initial startup phase and other significant milestones for your business.`,
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_INVESTOR: `An investor is the one backing your big idea. It could be a friend and family, accelerator, angel or seed investor and venture capitalist.`,
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_THIRDPARTY: "",
  MODAL_ADD_SHAREHOLDER_ADD_NAME_EXISTING:
    "{{name}} has already been added. If you’d like to add or edit a role for this stakeholder, go to their profile page and select add role.",
  MODAL_ADD_SHAREHOLDER_ADD_NAME_EXISTING_2:
    "this name is the same as the current director, you need to specify another name",
  MODAL_ADD_SHAREHOLDER_ADD_NAME_EXISTING_BUTTON: "Go to their profile",
  MODAL_ADD_SHAREHOLDER_TYPE_CORPORATE_TOOLTIP:
    "A corporate stakeholder cannot be a founder or employee of the company.",
  BUTTON_SAVE_CHANGES: "Save changes",
  STAKEHOLDER_ADD_SUCCESS:
    "The stakeholder has been added successfully to the map",
  RENEWAL_START: "You have started the renewal process",
  RENEWAL_START_STRIKE_OFF: "You have started the close down process",
  RENEWAL_CANCEL_STRIKE_OFF: "You have canceled the close down process",
  STAKEHOLDER_EDIT_SUCCESS: "The stakeholder has been successfully modified",
  COMPANY_ADD_SUCCESS: "The company has been added successfully to the map",
  ADD_ROLE_SUCCESS: "The role has been added successfully",
  MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_IN_FORMATION:
    "Since your company is in formation, you can only add shareholders and directors to this company. Once the company is formed, you'll be able to add other roles to it.",
  MODAL_ADD_STAKEHOLDER_SELECT_ROLES_TOOLTIP_NO_GROUPCOMPANIES:
    "Since you don't have any company added to your profile yet, you can only add the founders of your startup. Once a company is added, you can go to their profile and assign additional roles to them using the add role button.",

  MODAL_SELECT_TOPCO_STEP1_TOOLTIP1:
    "It's required to have a topco formed in an approved jurisdiction to proceed with your application. <a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6701573-list-of-approved-jurisdictions-all-programmes' >Click here</a> to learn more about approved jurisdictions.",
  MODAL_SELECT_TOPCO_STEP1_TOOLTIP2:
    "The list of approved jurisdictions includes:<br>{{{approvedJurisdictions}}}With Clara, you can form a company in the following {{{acceleratorName}}}'s approved jurisdictions:<br>{{{approvedJurisdictionsForAccelerator}}}",

  MODAL_SELECT_TOPCO_STEP1_P1:
    "Your selected topco is in an approved jurisdiction.",
  MODAL_SELECT_TOPCO_STEP1_P2:
    "A topco is the company that sits at the very top of your startup’s corporate structure. In most cases, it acts purely as a company to hold shares in the company or companies of your startup that carry out your business.",
  MODAL_SELECT_TOPCO_STEP1_P3:
    "To proceed with {{{acceleratorName}}} program, this company must be in an approved jurisdiction to receive the investment funds.",
  MODAL_SELECT_TOPCO_STEP1_P4: "What would you like to do?",
  MODAL_SELECT_TOPCO_STEP1_CONFIRM: "Confirm {{{company}}} as my topco",
  MODAL_SELECT_TOPCO_STEP1_ADD: "Add another company as my topco",
  MODAL_SELECT_TOPCO_STEP1_FORM: "Form a new topco with Clara",
  MODAL_SELECT_TOPCO_STEP1_ADD_INFO_P1:
    "1.  Go to the Dashboard and click on the Add Company button.",
  MODAL_SELECT_TOPCO_STEP1_ADD_INFO_P2:
    "2. Add another company that is in an approved jurisdiction and select it as your topco. You can always form one with Clara if you don't have any.",
  MODAL_SELECT_TOPCO_STEP1_ADD_INFO_P3:
    "3. Come back to this task and confirm the topco that will receive the investment funds.",
  MODAL_SELECT_TOPCO_STEP2_P1:
    "Your selected topco is NOT in an approved jurisdiction.",
  MODAL_SELECT_TOPCO_STEP2_P2:
    "A topco is the company that sits at the very top of your startup’s corporate structure. In most cases, it acts purely as a company to hold shares in the company or companies of your startup that carry out your business.",
  MODAL_SELECT_TOPCO_STEP2_P3:
    "To proceed with {{{acceleratorName}}} program, this company must be in an approved jurisdiction to receive the investment funds.",
  MODAL_SELECT_TOPCO_STEP2_P4: "What would you like to do?",
  MODAL_SELECT_TOPCO_STEP2_CONFIRM:
    "Select a topco from companies I’ve already added",
  MODAL_SELECT_TOPCO_STEP2_ADD: "Add another company as my topco",
  MODAL_SELECT_TOPCO_STEP2_FORM: "Form a new topco with Clara",
  MODAL_SELECT_TOPCO_STEP3_P1:
    "Your selected topco is NOT in an approved jurisdiction.",
  MODAL_SELECT_TOPCO_STEP3_P2:
    "A topco is the company that sits at the very top of your startup’s corporate structure. In most cases, it acts purely as a company to hold shares in the company or companies of your startup that carry out your business.",
  MODAL_SELECT_TOPCO_STEP3_P3:
    "To proceed with {{{acceleratorName}}} program, this company must be in an approved jurisdiction to receive the investment funds.",
  MODAL_SELECT_TOPCO_STEP3_P4: "What would you like to do?",
  MODAL_SELECT_TOPCO_STEP3_CONFIRM:
    "Select a topco from companies I’ve already added",
  MODAL_SELECT_TOPCO_STEP3_ADD: "Add another company as my topco",
  MODAL_SELECT_TOPCO_STEP3_FORM: "Form a new topco with Clara",
  MODAL_SELECT_TOPCO_STEP4_P1: "You must select a company to be your topco.",
  MODAL_SELECT_TOPCO_STEP4_P2:
    "A topco is the company that sits at the very top of your startup’s corporate structure. In most cases, it acts purely as a company to hold shares in the company or companies of your startup that carry out your business.",
  MODAL_SELECT_TOPCO_STEP4_P3:
    "To proceed with {{{acceleratorName}}} program, this company must be in an approved jurisdiction to receive the investment funds.",
  MODAL_SELECT_TOPCO_STEP4_P4: "What would you like to do?",
  MODAL_SELECT_TOPCO_STEP4_SELECT:
    "Select a topco from companies I’ve already added",
  MODAL_SELECT_TOPCO_STEP4_ADD: "Add another company as my topco",
  MODAL_SELECT_TOPCO_STEP4_FORM: "Form a new topco with Clara",
  MODAL_SELECT_TOPCO_STEP4_SELECT_TOOLTIP1:
    "You need to have a topco formed in a investible jurisdiction in order to prepare for this accelerator. If you don’t have a company yet, you can form it with Clara.",
  MODAL_SELECT_TOPCO_STEP4_NOT_APPROVED:
    "{{{company}}} (Not approved jurisdiction)",
  MODAL_SELECT_TOPCO_STEP5_P1:
    "You don’t currently have a company in an approved jurisdiction that can be selected as a topco.",
  MODAL_SELECT_TOPCO_STEP5_P2:
    "A topco is the company that sits at the very top of your startup’s corporate structure. In most cases, it acts purely as a company to hold shares in the company or companies of your startup that carry out your business.",
  MODAL_SELECT_TOPCO_STEP5_P3:
    "To proceed with {{{acceleratorName}}} program, this company must be in an approved jurisdiction to receive the investment funds.",
  MODAL_SELECT_TOPCO_STEP5_P4: "What would you like to do?",
  MODAL_SELECT_TOPCO_STEP5_SELECT:
    "Select a topco from companies I’ve already added",
  MODAL_SELECT_TOPCO_STEP5_ADD: "Add another company as my topco",
  MODAL_SELECT_TOPCO_STEP5_FORM: "Form a new topco with Clara",

  BOARD_RESOLUTION_STEP1_TITLE:
    "Provide documentation approving {{{acceleratorName}}}'s investment",
  BOARD_RESOLUTION_STEP1_TOOLTIP1:
    "Upload a signed consent or resolution document showing approval for {{{acceleratorName}}}’s investment (i.e. the Company’s Board of Directors or Shareholders).​",
  BOARD_RESOLUTION_STEP1_TOOLTIP2:
    "If you are not sure what this is, reach out to your {{{acceleratorName}}} legal contact and they may be able to help provide an example.​​",
  BOARD_RESOLUTION_STEP1_TOOLTIP3:
    "Once uploaded, this document will be stored in your Data Room.​​",
  BOARD_RESOLUTION_STEP1_OPTION1: "Generate a Board Resolution on Clara​​",
  BOARD_RESOLUTION_STEP1_OPTION2:
    "Upload an existing Board Resolution or other similar document​",

  MODAL_IP_ASSIGNMENT_AGREEMENT_TOOLTIP1:
    "The IP Assignment Agreement is typically (but not always) entered into with your topco. If your topco is not yet formed, please consider if you should wait until is formed and its details completed on your profile before executing your IP Assignment Agreement.",
  MODAL_EQUITY_POSITIONS_ADD_MORE_TOOLTIP:
    "You can also do this later by using the Add equity position action button at the top of the page.",
  MODAL_EQUITY_POSITIONS_INTRUDUCTION_SIP_TOOLTIP:
    "<a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6417338-an-introduction-to-share-incentive-plans-grant-agreements-on-clara'>Click here</a> for an introduction to Share Incentive Plans & Grant Agreements on Clara.",
  MODAL_EQUITY_POSITIONS_INTRUDUCTION_SIP_TOOLTIP2:
    "A new task will be created on the company profile page where you can upload the SIP connected to this option grant.",
  MODAL_EQUITY_POSITIONS_WARRANT_AGREEMENT_TOOLTIP:
    "A Warrant Agreement sets out the terms and conditions of a warrant, such the right of a holder to puchase shares, the type and number of shares and warrant coverage.",
  MODAL_EQUITY_POSITIONS_SHARE_AGREEMENT_TOOLTIP:
    "A Warrant Agreement sets out the terms and conditions of a warrant, such the right of a holder to puchase shares, the type and number of shares and warrant coverage.",

  MODAL_ADD_RECIPIENT_FOR_NOTICES_TOOLTIP1:
    "The recipient will be the person who will receive notices on behalf of the company under the contracts it’s a party to.",
  MODAL_ADD_RECIPIENT_FOR_NOTICES_TOOLTIP2:
    "Please select one of the stakeholders you have already added.",

  MODAL_MANUAL_INVOICE_SUCCESS_TEXT1: "Thanks for your payment",
  MODAL_MANUAL_INVOICE_SUCCESS_TEXT2:
    "The invoice has now been paid and can be viewed in your invoices list.",

  MODAL_PAY_OVERDUE_SUCCESS_TEXT1:
    "The receipt for this transaction can be found on the View Invoices page.",
  MODAL_PAY_OVERDUE_SUCCESS_TEXT2:
    "Your payment method has been updated for future transactions.",
  MODAL_BLOCKING_USER_PAY_SUCCESS_TEXT2:
    "You can now access {{{startupName}}} profile and continue using all Clara features.",

  MODAL_PAY_OVERDUE_WARNING_TITLE: "Payment overdue",
  MODAL_PAY_OVERDUE_WARNING_TEXT_CARD:
    "We have failed to take payment from your card ending in {{card}}.",
  MODAL_PAY_OVERDUE_WARNING_TEXT1:
    "Please update your payment method and make the payment as soon as possible.",
  MODAL_PAY_OVERDUE_WARNING_TEXT2:
    "If you don’t, your account will be downgraded on {{date}}.",

  MODAL_BLOCK_USER_ONE_STARTUP_VIEWER_TEXT1: "Unpaid subscription",
  MODAL_BLOCK_USER_ONE_STARTUP_VIEWER_TEXT2:
    "Your access to {{startupName}} profile has been restricted",
  MODAL_BLOCK_USER_ONE_STARTUP_VIEWER_TEXT3:
    "The profile will be accessible again once the subscription is renewed. Please contact the owner of this profile for more information.",

  MODAL_BLOCK_USER_ONE_STARTUP_OWNER_TEXT1: "Unpaid subscription",
  MODAL_BLOCK_USER_ONE_STARTUP_OWNER_TEXT2:
    "Your access to {{startupName}} profile has been restricted",
  MODAL_BLOCK_USER_ONE_STARTUP_OWNER_TEXT3:
    "To access this profile and continue using Clara features you will have to buy a new Scale subscription.",

  MODAL_BLOCK_USER_MORE_ONE_STARTUP_VIEWER_TEXT1: "Unpaid subscription",
  MODAL_BLOCK_USER_MORE_ONE_STARTUP_VIEWER_TEXT2:
    "Your access to {{startupName}} profile has been restricted",
  MODAL_BLOCK_USER_MORE_ONE_STARTUP_VIEWER_TEXT3:
    "The profile will be accessible again once the subscription is renewed. Please contact the owner of this profile for more information.",
  MODAL_BLOCK_USER_MORE_ONE_STARTUP_VIEWER_BUTTON: "Select another Startup",

  MODAL_BLOCK_USER_MORE_ONE_STARTUP_OWNER_TEXT1: "Unpaid subscription",
  MODAL_BLOCK_USER_MORE_ONE_STARTUP_OWNER_TEXT2:
    "Your access to {{startupName}} profile has been restricted",
  MODAL_BLOCK_USER_MORE_ONE_STARTUP_OWNER_TEXT3:
    "To continue using Clara features and access this profile you will need to buy a new Scale subscription.",

  MODAL_BLOCK_USER_PRODUCT_DESCRIPTION_SCALE_ANNUAL_SUBSCRIPTION_MONTHLY:
    "Monthly instalments (Annual commitment)",
  MODAL_BLOCK_USER_PRODUCT_DESCRIPTION_SCALE_ANNUAL_SUBSCRIPTION:
    "Pre-paid (Annually)",

  MODAL_CATEGORISE_DOCUMENT_DYNAMIC_TOOLTIP:
    "This document will be safely stored in your Data room under:<p></p><br><span style='color: #828C98;'>Category</span><br><strong>{{{category}}}</strong><p></p><br><span style='color: #828C98;'>Sub Category</span><br><strong>{{{subcategory}}}</strong>",
  MODAL_CATEGORISE_IDENTITY_DOCUMENT_DYNAMIC_TOOLTIP:
    "This document will be safely stored in your Data room under:<p></p><br><span style='color: #828C98;'>Category</span><br><strong>{{{category}}}</strong><p></p><br><span style='color: #828C98;'>Sub Category</span><br><strong>{{{subcategory}}}</strong><p></p><br><span style='color: #828C98;'>Document type</span><br><strong>{{{documentType}}}</strong>",
  MODAL_CATEGORISE_DOCUMENT_DYNAMIC_PARTIES: "Party {{number}}",
  MODAL_CATEGORISE_DOCUMENT_TITLE: "Categorise document",
  MODAL_CATEGORISE_DOCUMENT_SUBTITLE: "Categorise",
  MODAL_EDIT_DOCUMENT_SUBTITLE: "Edit",
  MODAL_EDIT_DOCUMENT_TITLE: "Edit document details",
  MODAL_ADD_DOCUMENT_SUBTITLE: "Add document",
  MODAL_ADD_DOCUMENT_TITLE: "Add document",

  MODAL_PURPOSE_OF_ENTITY_FIRST_TOOLTIP: "A company will be a holding company if the company will hold only shares or securities in other entities. If so, please select Holding company for an underlying business",
  MODAL_PURPOSE_OF_ENTITY_SECOND_TOOLTIP: "A company will be a personal assets holding company if the company is not only holding shares or securities in other entities. Please give a description of the underlying assets that the company will hold.",

  MODAL_BUSINESS_ACTIVITY_TOOLTIP: "It's OK if you haven't formed this operating company yet. Just tell us what the planned business activities will be once it's formed.",
  LIMITED_BY_SHARES: "limited by shares",
  MAILING_ADDRESS: "Mailing address",
  MAILING_ADDRESS_TYPE: "Mailing address type",
};
