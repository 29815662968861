import useLazyQuery from "src/hooks/custom/useLazyQuery";

export type GetShareCapitalView = {
  getShareCapitalView?: {
    shareCapitalRow: {
      shareClass: {
        id: string;
        name: string;
        parValue: {
          amount: number;
          currency:string;
        };
      };
      shareholders: {
        issuedShares: string;
        status: string;
        isAddedToCart: boolean;
        holder:
          | {
            id: string;
            name: string;
            avatar: string;
          }
          | {
            id: string;
            fullName: string;
            avatar: string;
          }
      }[];
      total: number;
      amountPaid: {
        amount: number;
        currency: string;
      };
      amountUnpaid: {
        amount: number;
        currency: string;
      };
    }[];
  };
};

const GET_SHARE_CAPITAL_VIEW = `
query getShareCapitalView($startupId: ID!, $groupCompanyId: ID!) {
  getShareCapitalView(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    shareCapitalRow {
      shareClass {
        id
        name
        parValue {
          amount
          currency
        }
      }
      shareholders {
        issuedShares
        status
        isAddedToCart
        holder {
          ... on GroupCompany {
              id
              name
              avatar
          }
          ... on StakeHolder {
              id
              fullName
              avatar
          }
        }
      }
      total
      amountPaid {
        amount
        currency
      }
      amountUnpaid {
        amount
        currency
      }
    }
  }
}`;

export const useGetShareCapitalView = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_SHARE_CAPITAL_VIEW, variables, config);
  const typedData = data as GetShareCapitalView;
  return { loading, error, data: typedData, refetch, manualQuery };
};

export default useGetShareCapitalView;
