import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertCompanyFormation from 'src/hooks/services/modules/groupCompany/useUpsertCompanyFormation';
import useUpsertConciergeMail from 'src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AdgmTechStartupModal/wizard/hooks/useUpsertConciergeMail';
import _ from 'lodash';
import { Constants } from 'src/v1/utils/constants';
import { useLD } from 'src/providers/LDProvider';

const useServices = () => {

  const { startupId, selectStartup } = useSession();
  const mutationAddCompany = useUpsertCompanyFormation();
  const mutationSendConciergeMail = useUpsertConciergeMail();
  const ldProvider = useLD();
  const emergentTSLremoved = ldProvider.featureFlags['emergent-tsl-removal']
  return useMemo(() => ({

    SaveStep_ADGM: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyData: {
          country: "AE",
          isHoldingCompany: _.get(context, "AddADGMCompany.isHoldingCompany", false),
          jurisdiction: "Abu Dhabi Global Market",
          jurisdictionType: "ADGM",
          companyType: Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_TSL,
          name:`${_.get(context, "AddADGMCompany.name", "")} Limited`,
        }
      };
      if(emergentTSLremoved) {
        Object.assign(variables.groupCompanyData, {
          formationData: {
            tslType: "SEED"
          }
        })
      }
      if (_.get(context, "WhatIsYourStartupFormStep.startupStage", "") === "SEED") {
        Object.assign(variables.groupCompanyData, {
          formationData: {
            tslType: "SEED"
          }
        });
      }
      if(_.get(context, "WhatIsYourStartupFormStep.startupStage", "") === "EMERGENT") {
        Object.assign(variables.groupCompanyData, {
          formationData: {
            tslType: "EMERGENT"
          }
        });
      }
      if(_.get(context, "AdgmTechStartupAuthorisedSignatoryFormStep.authorisedSignatory", "") === "ClarasNominee") {
        _.merge(variables, {
          groupCompanyData: {
            formationData: {
              isUsingNominee: true
            }
          }
        });
      }
      if(_.get(context, "AdgmTechStartupAuthorisedSignatoryFormStep.authorisedSignatory", "") === "ThirdParty") {
        _.merge(variables, {
          groupCompanyData: {
            formationData: {
              isUsingThirdParty: true
            }
          }
        });
      }
      const result = await mutationAddCompany(variables);
      await selectStartup(startupId,null);
      return result;
    },

    SaveStep_teamExperts: async (context, event) => {
      const variables = {
        startupId,
        emailData: {
          name: _.get(context, "CallTeamExpertsFormStep.name", ""),
          email: _.get(context, "CallTeamExpertsFormStep.email", ""),
          telephone: _.get(context, "CallTeamExpertsFormStep.telephoneNumber", ""),
          profileName: _.get(context, "CallTeamExpertsFormStep.profileName", ""),
          additionalInfo: _.get(context, "CallTeamExpertsFormStep.additionalInfo", ""),
          jurisdiction: "ADGM"
        }
      };
      const result = await mutationSendConciergeMail(variables);
      return result;
    },

    GetWhatIsYourStartupData: async (context, event) => {
      return {
        seed: {
          currency: "USD",
          value: "2,745"
        },
        emergent: {
          currency: "USD",
          value: "6,045"
        }
      }
    },

    GetAdgmTechStartupAuthorisedSignatoryData: async (context, event) => {
      return {
        product: {
          currency: "USD",
          value: "700"
        },
      }
    },


  }), []);
}

export default useServices;
