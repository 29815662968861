import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { useLD } from 'src/providers/LDProvider';

export default function ReviewPersonalDetailAddressesStepForm() {
  const { next, context, cancel, prev } = useMachine();
  const { initialData } = context;
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  const initialValues = context?.ReviewUboAddress ?? initialData?.address;

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        isPaymentProcessingRemoved ?
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_1_PAYMENT_REMOVAL") :
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_1"),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    address: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
  });

  const handlePrev = () => {
    prev();
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm their addresses",
          subTitle: `Review details for ${initialData.stakeholder.fullName}`,
        }}
        buttons={{
          submit: (
            <SubmitButton>{translate("MODULES.CLARA.BTN_NEXT")}</SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
        }}
      >
        <AddressInput
          name="address"
          label={{
            country: translate("MODULES.CLARA.ADDRESS_REGISTERED_LABEL"),
          }}
          stateOptional
          zipCodeOptional
          isRequired
        />
        <div />
      </TemplateWizard>
    </Form>
  );
}
