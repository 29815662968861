import { Stack } from "@mui/material";
import { default as React, useMemo } from 'react';
import img from 'src/images/Whats-next.svg';
import Button from 'src/modules/generic/components/Buttons/Button';
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import cls from "./classes.module.scss";
import { useLD } from "src/providers/LDProvider";

/**
* Description Step: Step show when payment is completed in ADGM
*/

function ThanksForYourPaymentPincasFormStep() {

  const { next } = useMachine();
  
  const ldProvider = useLD();
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  const handleSubmit = () => {
    next();
  }

  const texts = useMemo(() => isPaymentProcessingRemoved ? 
  {

    title: <>Great! <br />What’s next?</>,
    subTexts: [
      'Click "Submit to Clara" and our team will start our review.',
      'Our team will reach out to you to discuss any fees that may need to be paid.',
    ]
  }
  : 
  {

    title: <>Great! <br />What’s next?</>,
    subTexts: [
      'Thanks for your payment, the receipt for this transaction can be found on the View Invoices page.',
      'Select ‘Submit to Clara’ to send your change requests application to Clara for review.',
    ]
    }, [])

  return (
    <TemplateWizard
      props={{
        skeleton: null,
        variant: 'twoColumns'
      }}
      buttons={{
        submit: <Button onClick={handleSubmit} variant='primary'>Submit to Clara</Button>,
      }}
    >
      <Stack spacing={4}>
        <Typography className={cls["cartTitle"]} weight={"bold"}>
          {texts.title}
        </Typography>
        <>
          {texts?.subTexts?.map(text => <Typography variant="h3" color={"black"} component={"p"}>{text}</Typography>)}
        </>
      </Stack>
      <div className={cls["rightContentImage"]}>
        <img src={img} alt="" style={{ width: "40.5385rem" }} />
      </div>
    </TemplateWizard>
  );
}

export default ThanksForYourPaymentPincasFormStep;

