export default {
  PRESUBMITTED_FORMATION_STEP: {
    TITLE: 'Before you take the leap...',
    SUBTITLE: 'Have you checked that all your added info is correct? Here’s your chance to give the details one last overview.',
    BUTTON_NEXT: 'Yes, I’m sure',
    BUTTON_PREVIOUS: 'No, let’s check',
  },
  WHATS_NEXT_STEP: {
    TITLE: 'Great! So, what’s next?',
    SUBTITLE: 'Once submitted, Clara will reach out to you and your collaborators if we need additional info or have some questions about your formation.',
    BUTTON_NEXT: 'Submit to Clara',
    BUTTON_PREVIOUS: 'Previous',
  },
  PRESUBMITTED_FORMATION_STEP_PAYMENT_REMOVED: {
    TITLE: 'Before you submit...',
    SUBTITLE: 'Have you checked that all your added info is correct? Here’s your chance to give the details one last review.',
    BUTTON_NEXT: 'Yes, I’m sure',
    BUTTON_PREVIOUS: 'No, let’s check',
  },
  WHATS_NEXT_STEP_PAYMENT_REMOVED: {
    TITLE: 'Great! So, what’s next?',
    SUBTITLE: 'Once submitted, we will review your application. If we need additional information, we will reach out to you. If you have not paid or there is a change to the scope, you will receive details of the fees that need to be paid before we can prepare the incorporation application.',
    BUTTON_NEXT: 'Submit to Clara',
    BUTTON_PREVIOUS: 'Previous',
  },
}
