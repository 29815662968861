import React, { createContext, useContext } from 'react';
import Rollbar from 'rollbar';

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_SDK,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENV || 'dev',
  },
});

const RollbarContext = createContext<Rollbar | null>(null);

export const RollbarProvider = ({ children }: any) => {
  return (
    <RollbarContext.Provider value={rollbar}>
      {children}
    </RollbarContext.Provider>
  );
};

export const useRollbar = () => {
  return useContext(RollbarContext);
};