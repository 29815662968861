export default {
  GENERIC: {
    SUBTITLE: 'Articles of Association & Share Capital',
  },
  FIRST_STEP: {
    TITLE: 'Have you added all of your directors and shareholders? ',
    SUBTITLE: 'Share capital',
    TOOLTIP: 'Details of all shareholders and directors are needed in order to form a company. Not added them all? Go back to the Map to finish up.'
  },
  SECOND_STEP: {
    TITLE: 'Share issue amount',
    SUBTITLE: 'Articles of Association & Share Capital',
    TOOLTIP_1: 'Clara does not amend Articles of Association. You must provide us with the amended Articles.<br/><br/>' +
      'Amended Articles must be prepared by qualified professionals and comply with the applicable regulations. Clara will not review the amended Articles. The directors will need to sign a declaration to confirm compliance.<br/><br/>' +
      'Amending Articles can take time and delay the incorporation. If the incorporation is urgent, consider incorporating with the model Articles and amend after incorporation. Fees apply.',
    TOOLTIP_2: 'The model Articles of Association contain the basic requirements. If you want to add additional share classes or include details from a Shareholders\' Agreement then you will need to supply us with your amended Articles of Association and confirm that they comply with the regulations.',
  },
  AUTHORISE_SHARES_STEP: {
    TITLE: 'How many shares do you want to authorise?',
    SUBTITLE: 'Articles of Associations & Share Capital',
    TOOLTIP: 'The authorised share capital for a {{jurisdictionName}} company is the maximum amount (in {{currency}}) of share capital that the company is authorised by its shareholders to issue to shareholders.',
    DESCRIPTION: 'Select your own number of authorised shares, or use Clara’s recommended approach ({{authorisedShares}} authorised shares at a par value of {{currency}} {{parValue}} each).'
  },
  SHARE_STEP: {
    TITLE: 'Share issue amount',
    DESCRIPTION: 'Your company will be formed with the standard setup of <b>{{authorisedShares}}</b> authorised shares, at a par value of <b>{{currency}} {{parValue}}</b>. Enter the number of shares you want to give to each stakeholder below. We recommend only issuing 10-20% of your authorised shares at the time of incorporation. This leaves some room in your authorised share capital for future share issuances (e.g. to bring on new shareholders or for an ESOP allocation).',
    ISSUED: 'Amount issued: <b>{{amount}}</b>',
    TOOLTIP: 'Issued shares are the shares which have been alloted by the company to the shareholders. Not all authorised shares have to be issued.<br/><br/>' +
      'If the full authorised shares are issued, the shareholders will have to approve an increase in the authorised share capital'
  },
  UPLOAD: {
    TITLE: 'Upload the amended Articles of Association'
  },
  OWN_AMOUNT_STEP: {
    TITLE: 'Select your own authorised share capital amount'
  },
  EDIT_SECOND_STEP: {
    TITLE: 'Share issue amount',
    SUBTITLE: 'Share capital',
  }
}