import _ from "lodash";
import { assign } from "xstate";

const setHasDesignatedContact = assign((context, event) => {
  const hasDesignatedContact = _.get(event, "data.hasDesignatedContact", false);

  return {
    context,
    hasDesignatedContact,
  };
});

export const actions = {
  setHasDesignatedContact,
};

