import React, { FC, useEffect } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';

import Button from 'src/modules/generic/components/Buttons/Button';
import FormSelectDataProtectionContact from 'src/modules/startup/GroupCompany/Forms/FormSelectDataProtectionContact';
import { StepProps } from 'src/modules/generic/components/Wizard/Step';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import { useLD } from 'src/providers/LDProvider';
import { is } from 'date-fns/locale';
const SelectNewDataProtectionContact: FC<StepProps> = () => {
  const { next, send, context, prev } = useMachine();
  const dispatch = useDispatch();
  const dataProtectionContact = context?.initialData?.dataProtectionContact;
  const stakeholders = context?.initialData?.stakeholders;

  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  useEffect(() => {
    dispatch(clearAssistatText());
    isPaymentProcessingRemoved ?
    dispatch(addAssistantText("<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM Registrar.",
      'alert'))
    :
    dispatch(addAssistantText("<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM Registrar.<br><br>Click on the field to update the details and view the corresponding fees (if any). All changes made will be added to your cart on the Dashboard, where you can pay and submit the updates.<br><br>Please note: all renewal tasks must be completed before submitting the application.",
      'alert'));
    dispatch(addAssistantText('The data protection contact for an ADGM entity is the individual appointed to be the point of contact with ADGM with respect to data protection matters. There are no nationality or residency requirements for this role.'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev()
  }

  return (
    <>
      <FormSelectDataProtectionContact
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        dataProtectionContact={dataProtectionContact}
        stakeholders={stakeholders}
        propsTemplate={{
          title: "Select a data protection contact",
          subTitle: "Review data protection contact"
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}
      />
    </>
  );
}

export default SelectNewDataProtectionContact;
