import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ClaraList from "src/modules/generic/components/Lists/ClaraList";
import Typography from "src/modules/generic/components/Texts/Typography";
import cls from "./classes.module.scss";
import { useLD } from "src/providers/LDProvider";

function ReviewAndConfirmStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [isChange, setIsChange] = useState();

  const ldProvider = useLD();
  const isPaymentProcessingRemoved =
    ldProvider.featureFlags["payment-process-removal-pincas-and-renewals"];

  const initialValues = useMemo(() => {
    return context?.ReviewAndConfirmStep;
  }, [context]);

  const signatories = context?.initialData?.signatories;

  const stakeholderList = useMemo(() => {
    return _.map(signatories, (signatory) => {
      const signatoryAvatar = signatory?.avatar ?? signatory?.fullName;
      const useClaraAvatar = signatory?.fullName === "Clara Nominee";
      return {
        name: signatory?.fullName,
        avatar: signatoryAvatar,
        isFounder: signatory?.isFounder,
        useClaraAvatar,
      };
    });
  }, [context, signatories]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        isPaymentProcessingRemoved ?
          translate(
            "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.REVIEW_STEP.TOOLTIP_PAYMENT_REMOVAL"
          ) :
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.REVIEW_STEP.TOOLTIP"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    confirm: Yup.boolean().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.REVIEW_STEP.TITLE"
          ),
          skeleton: null,
          subTitle: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.SUBTITLE"
          ),
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>{isChange ? "Confirm" : "Next"}</SubmitButton>,
        }}
      >
        <Typography component="h5">
          {translate(
            "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.REVIEW_STEP.DESCRIPTION"
          )}
        </Typography>
        <ClaraList variant="normal" items={stakeholderList} />
        <TemplateLabel
          label="Please confirm this is correct"
          isRequired
          className={cls.labelOnTop}
        >
          <ControllerInput
            name="confirm"
            render={CheckboxButtonSimple}
            onChange={(value) => {
              setIsChange(value);
            }}
            classNameButton={cls.buttonCheckBox}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewAndConfirmStep;
