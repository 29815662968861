import PropTypes from "prop-types";
import React from "react";
import Divider from "@mui/material/Divider";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import classes from "../../styles.module.scss";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { Constants } from "src/v1/utils";
import useModal from "src/modules/generic/hooks/useModal";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ModalDesignatedContact from "src/modules/startup/Stakeholder/ADGM/DesignatedContact/DesignatedContact";
import ModalEditDesignatedContact from "src/modules/startup/GroupCompany/ADGM/EditDesignatedContact";
import UserItem from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/components/UserItem";
import { RoleAccess } from "src/v1/components";

function DesignatedContactPanel({ designatedContact, isLock, isFormation,groupCompany, havePincas = false }) {
  const { goTo } = useNavigation();
  const { openModal } = useModal();
  const { translate } = useTranslate();

  const actionButton = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          openModal(isFormation?ModalDesignatedContact:ModalEditDesignatedContact, {initialValues:{groupCompanyId:groupCompany.id}});
        }}
      />
    </RoleAccess>
  );

  const goToStakeholder = (stakeholder) => {
    goTo(Constants.PAGES.viewStakeholderIndividual, { id: stakeholder.id });
  };

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle
        title={translate("VIEW_COMPANY_TAB_KEY_PEOPLE_ADGM_DESIGNATED_CONTACT")}
        buttons={actionButton}
        inPincas={havePincas}
      />
      <div className={classes.containerDirectorsTab}>
        {designatedContact.map((contact) => (
          <UserItem 
            key={contact.id} 
            fullName={contact.fullName} 
            avatar={contact.avatar} 
            onClick={() => goToStakeholder(contact)} 
          />
        ))}
      </div>
    </>
  );
}

DesignatedContactPanel.propTypes = {
  designatedContact: PropTypes.any,
  isEdit: PropTypes.bool,
  isLock: PropTypes.bool,
  isFormation: PropTypes.bool,
  havePincas: PropTypes.bool,
};

export default DesignatedContactPanel;
