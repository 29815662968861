import * as Yup from 'yup';

import React, { useRef, useState } from 'react';

import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import FileUploaderInput from "src/components/inputs/FileUploader/FileUploaderInput";
import Form from 'src/components/forms/Form/Form';
import FormProps from 'src/modules/generic/forms/interfaces/FormProps';
import { HiddenFieldForm } from 'src/components/forms/contents';
import { InputLabelWithBanner } from "../../../../components/inputs/InputWithBanner";
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import _ from 'lodash';
import classes from "src/modules/startup/onBoarding/roles/DirectorV2Modal/wizard/styles/director.module.scss";
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { useLD } from 'src/providers/LDProvider';

export enum RELEVANT_ACTIVITIES {
  ACTING_AS_HOLDING_COMPANY = "ACTING_AS_HOLDING_COMPANY",
  BANKING = "BANKING",
  DISTRIBUTION_AND_SERVICE = "DISTRIBUTION_AND_SERVICE",
  FINANCING_AND_LEASING = "FINANCING_AND_LEASING",
  INSURANCE = "INSURANCE",
  INTELLECTUAL_PROPERTY = "INTELLECTUAL_PROPERTY",
  SHIPPING = "SHIPPING",
  FUND_MANAGEMENT = "FUND_MANAGEMENT",
  ACTING_AS_HEADQUARTERS = "ACTING_AS_HEADQUARTERS",
  NONE_OF_ABOVE = "NONE_OF_ABOVE",
}

export enum ARTICLES_OF_ASSOCIATION_TYPE {
  MODEL = 'MODEL',
  CUSTOM = 'CUSTOM',
  MODEL_WITH_AMENDMENTS = 'MODEL_WITH_AMENDMENTS'
}

interface ExtendedFormProps extends FormProps {
  pincas: object; 
}

const schema = Yup.object().shape({
  activities: Yup.array().required('Required').min(1, 'Please select at least one option'),
  articlesOfAssociationType: Yup.string().required('Required'),
  file: Yup.object().when(['articlesOfAssociationType'], {
    is: ARTICLES_OF_ASSOCIATION_TYPE.CUSTOM,
    then: Yup.object().nullable().required("This field is required"),
    otherwise: Yup.object().nullable()
  }),
});

const FormBusinessActivityCayman: React.FC<ExtendedFormProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  pincas = {}
}: ExtendedFormProps) => {
  const { translate } = useTranslate();
  const [showBanner, setShowBanner] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const ref = useRef(null);

  const handleSubmit = (values) => {
    onCompleteSubmit && onCompleteSubmit(values);
  }

  const ldProvider = useLD();
  const isPaymentProcessingRemoved = ldProvider.featureFlags["payment-process-removal-pincas-and-renewals"];

  const isManagedByClara = initialValues?.isManagedByClara

  const options = [
    { value: RELEVANT_ACTIVITIES.ACTING_AS_HOLDING_COMPANY, label: translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.ACTING_AS_HOLDING_COMPANY'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.BANKING, label: translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.BANKING'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.DISTRIBUTION_AND_SERVICE, label:translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.DISTRIBUTION_AND_SERVICE'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.FINANCING_AND_LEASING, label:translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.FINANCING_AND_LEASING'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.SHIPPING, label: translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.SHIPPING'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.FUND_MANAGEMENT, label: translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.FUND_MANAGEMENT'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.ACTING_AS_HEADQUARTERS, label: translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.ACTING_AS_HEADQUARTERS'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.INSURANCE, label: translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.INSURANCE'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.INTELLECTUAL_PROPERTY, label: translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.INTELLECTUAL_PROPERTY'), disabled: isDisabled },
    { value: RELEVANT_ACTIVITIES.NONE_OF_ABOVE, label: translate('MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.NONE_OF_ABOVE'), disabled: isDisabled},
  ];

  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onSubmit={handleSubmit}
      ref={ref}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <TemplateLabel
          label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.ACTIVITIES_LABEL')}
          isRequired
          variant="bannerLabel"
          bannerTitle={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.ACTIVITIES_BANNER')}
          showBanner={showBanner && isManagedByClara}
        >
          <ControllerInput
            render={Checkbox}
            name="activities"
            options={options}
            onChange={(val: string[]) => {
              if ((val || []).length === 0) {
                setIsDisabled(false);
                return setShowBanner(false);
              }
              if (_.includes(val, RELEVANT_ACTIVITIES.NONE_OF_ABOVE)) {
                setIsDisabled(true);
                ref.current.setValue('activities', [RELEVANT_ACTIVITIES.NONE_OF_ABOVE]);
                return setShowBanner(false);
              }
              setIsDisabled(false);
              return setShowBanner(true);
            }}
            classCheckboxLine={classes.CompanyCheckboxButton}
          />
        </TemplateLabel>
        <InputLabelWithBanner
          bannerLogicType='touched'
          component={SelectInput}
          show={isManagedByClara}
          controllerInputProps={{
            name: 'articlesOfAssociationType',
            placeholder: 'Select',
            disableTyping: true,
            list: [{ value: ARTICLES_OF_ASSOCIATION_TYPE.MODEL, label: 'Model' }, { value: ARTICLES_OF_ASSOCIATION_TYPE.MODEL_WITH_AMENDMENTS, label: 'Model with amendments' }, { value: ARTICLES_OF_ASSOCIATION_TYPE.CUSTOM, label: 'Bespoke' }]
          }}
          templateLabelProps={{
            bannerTitle: isPaymentProcessingRemoved ?
            'Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review.'
            : `Costs to file this change start from <strong>USD ${pincas?.CHANGE_COMPANY_ARTICLES_ASSOCIATION?.value}</strong>. If you select \"Save changes\", this items will be added to your cart. Then go to the Dashboard, click \"View cart\" to pay and submit.`,
            label: "Articles of Association",
            isRequired: true
          }}
        />
        <HiddenFieldForm fieldsWatch='articlesOfAssociationType' conditionHideCallback={value => value === ARTICLES_OF_ASSOCIATION_TYPE.CUSTOM}>
          <TemplateLabel
            label="Upload document"
            isRequired
          >
            <ControllerInput
              render={FileUploaderInput}
              name="file"
              typeFileAllowed={
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              }
              icons={["DOC"]}
            />
          </TemplateLabel>
        </HiddenFieldForm>
        <div>&nbsp;</div>
      </Template>
    </Form>
  );
}

export default FormBusinessActivityCayman;