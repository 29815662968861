import * as Yup from 'yup';

import { default as React, useEffect, useMemo } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';

import Box from '@mui/material/Box';
import Button from 'src/modules/generic/components/Buttons/Button';
import Form from 'src/components/forms/Form/Form';
import ListItemOrder from 'src/modules/generic/components/Lists/ListItemOrder';
import Stack from '@mui/material/Stack';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import Typography from "src/modules/generic/components/Texts/Typography";
import classesModule from "../classes.module.scss";
import reactImage from "src/images/Group2022.png";
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { useLD } from 'src/providers/LDProvider';

function FormInCaymanInfo() {

  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
    
  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-processing-removal'];
  
  const initialValues = useMemo(() => {
    return context?.FormInCaymanInfo ?? {}
  }, [context]);
  
  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }
  
  useEffect( ()=>{
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.TOOLTIP_CREATE_CAYMAN_INFO')
      )
    );
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])
  
  const schema = Yup.object().shape({});

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
    
      <TemplateWizard
        props={{
          title: "You're ready to start​",
          subTitle: "Let's form a company ​​",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Box sx={{ width: "100%"}}>
          <Stack sx={{ width: "100%"}} gap={2}>
              <Typography component='p' variant='xbody'>
                Follow the tasks on <strong>My Tasks</strong> page to form your company. 
              </Typography>
              <Typography component='p' variant='xbody'>
                Once completed, you will be able to <strong>{isPaymentProcessingRemoved?'submit':'pay and submit'}</strong> your formation to Clara. You will be able to keep track of its status from the <strong>Dashboard</strong> and <strong>My Tasks</strong> page.
              </Typography>
              <Typography component='p' variant='xbody'>
                If you need help at any time, click on <strong>Clara Help</strong> in the bottom right corner to contact us.
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ width: "80%", height: "28.7692rem"}}>
            <Box sx={{width: "22.3077rem", position: "absolute", right: "0.7692rem", marginTop: "2.1538rem"}}>
              <img src={reactImage} alt="" className={classesModule.ViewCompanyFomation}/>
            </Box>
          </Box>
        </Stack>
        </>
      </TemplateWizard> 
    </Form>
  );
}

export default FormInCaymanInfo;

