export default {
  CORPORATE_SIGNATORY: {
    TITLE: "Who is the corporate signatory for {{{stakeholderName}}}?",
    SUBTITLE: "Corporate signatory",
    REQUIRED: "This field is required.",
    EMAIL_VALIDATION: "This field must be a valid email.",
    TOOLTIP: "A <strong>Corporate Signatory</strong> is any individual authorised by the Board of Directors to sign on behalf of this Corporate Stakeholder. Usually this is one of the Directors of the Corporate Stakeholder, though it could be a Shareholder, or a third party like a lawyer. The ‘Entity Representative’  will be contacted if this Corporate Stakeholder is required to sign any future resolutions of the Company, and will called upon to sign those resolutions on the Corporate Stakeholder’s behalf. The Entity Representative is relevant if the Corporate Stakeholder is becoming a Corporate Director (they will sign Board Resolutions on behalf of the Corporate Director) or a Corporate Shareholder (they will sign Shareholder Resolutions on behalf of the Corporate Shareholder).",
    FULL_NAME_LABEL: "Full name",
    FULL_NAME_PLACEHOLDER: "As shown on their passport",
    EMAIL_LABEL: "Email address",
    EMAIL_PLACEHOLDER: "name@example.com",
    TELEPHONE_NUMBER_LABEL: "Telephone number",
    TELEPHONE_NUMBER_PLACEHOLDER: "+1 (702) 123-4567",
    SIGNING_AUTHORITY_LABEL: "Signing authority",
    SIGNING_AUTHORITY_PLACEHOLDER: 'For example, "appointed by board" or "by resolution", etc',
    ROLE_LABEL: "Position",
    ROLE_PLACEHOLDER: "Enter position",
  },
  CERTIFICATE_OF_INCORPORATION: {
    TITLE: "Upload the Certificate of Incorporation",
    SUBTITLE: "Certificate of Incorporation",
    REQUIRED: "This field is required.",
    FIELD_DOCUMENT: "Document",
    FIELD_DATE: "Issue date",
    REQUIRED_DATE: "Must be a date",
    TOOLTIP: "If this document is not in English, it must be legally translated into English.<br><br>The document uploaded must be a certified true copy, certified by the Registered Agent or Issuing Authority within the past 2 months."
  },
  STRUCTURE_CHART: {
    TITLE: "Upload a structure chart",
    SUBTITLE: "Structure Chart",
    REQUIRED: "This field is required.",
    FIELD_DOCUMENT: "Document",
    FIELD_DATE: "Issue date",
    REQUIRED_DATE: "Must be a date",
    TOOLTIP: `Please provide a structure chart indicating <strong>all corporate layers</strong> with <strong>percentages of ownership</strong> up to the <a href="https://help.clara.co/en/articles/6453145-who-is-the-ultimate-beneficial-owner-ubo-of-an-adgm-entity" target="_blank" rel="noopener noreferrer">ultimate beneficial owner</a>.`
  },
  CERTIFICATE_OF_REGISTRATION: {
    TITLE: "Upload the Certificate of Registration of the Trust",
    SUBTITLE: "Certificate of Registration",
    REQUIRED: "This field is required.",
    FIELD_DOCUMENT: "Document",
    FIELD_DATE: "Issue date",
    REQUIRED_DATE: "Must be a date",
    TOOLTIP: "If not in English, this must be legally translated into English."
  },
  ARTICLES_OF_ASSOCIATION: {
    TITLE: "Upload the Articles of Association",
    SUBTITLE: "Articles of Association",
    REQUIRED: "This field is required.",
    FIELD_DOCUMENT: "Document",
    ISSUE_DATE: "Issue date",
    REQUIRED_DATE: "Must be a date",
    TOOLTIP: "This should include amendments and/or the latest consolidation. If not in English, this must be legally translated into English."
  },
  SOURCE_OF_FUNDS: {
    TITLE: "What is {{{stakeholderName}}} source of funds and wealth?",
    SUBTITLE: "Source of funds",
    DESCRIPTION: "What is the source of funds for this Corporate Stakeholder?",
    ASSISTANT: "<strong>Source of funds</strong> refers to the origin of money used for this corporate stakeholder current and future payments. <br/><br/><strong>Source of wealth</strong> refers to how this this corporate stakeholder generates its revenue.  If this is revenue from its operations, please describe with detail. If revenue is generated from assets held, describe the assets and how they generate revenue. A company may not generate revenue and rely on shareholder investment.",
    SOURCE_OF_FUNDS_LABEL: "Source of funds",
    SOURCE_OF_WEALTH_LABEL: "Source of wealth"
  },
  REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY: {
    TITLE: "Upload a current dated evidence of the shareholders and directors of this entity. Select from the options below:",
    SUBTITLE: "Registers or Certificate of Incumbency",
    SUBTITLE_2: "Registers",
    SUBTITLE_3: "Certificate of Incumbency",
    SUBTITLE_4: "Confirmation of shareholders and directors",
    REQUIRED: "This field is required.",
    OPTION_REGISTER: "Register of Directors and Register of Members",
    OPTION_CERTIFICATE: "Certificate of Incumbency",
    OPTION_REGISTRY_EXTRACT: "Registry Extract",
    TOOLTIP: "These documents must be dated within the last 2 months. If not in English, they must be legally translated into English",
    TOOLTIP_1: "The document can be one of the following:<br><strong><ol style='padding-left: 20px'><li>Register of Members issued by CSP or stamped by Regulator AND Register of Directors issued; by CSP or stamped by Regulator.</li><li>Certificate of Incumbency showing shareholders and Directors.</li><li>Registry Extract recently dated showing shareholders and Directors.</li></ol></strong>These documents must be dated within the last 2 months. If not in English, they must be legally translated into English.",
    REGISTER_DIRECTORS_AND_MEMBERS: {
      TITLE: "Upload the Register of Directors and the Register of Members",
      TITLE_2: "Upload the Register of Directors AND the Register of Members",
      FIELD_DATE: "Issue date",
      REQUIRED_DATE: "Must be a date",
      REQUIRED: "This field is required.",
      FIELD_DIRECTORS: "Register of Directors",
      FIELD_MEMBERS: "Register of Members",
      TOOLTIP: "These documents must be dated within the last 2 months. If not in English, they must be legally translated into English"
    },
    CERTIFICATE_OF_INCUMBENCY: {
      TITLE: "Upload the Certificate of Incumbency",
      FIELD_DATE: "Issue date",
      FIELD_CERTIFICATE: "Certificate of Incumbency",
      REQUIRED_DATE: "Must be a date",
      REQUIRED: "This field is required.",
      TOOLTIP: "This document must be dated within the last 2 months. If not in English, this must be legally translated into English."
    },
    REGISTRY_EXTRACT: {
      TITLE: "Upload the Registry Extract",
      FIELD_DATE: "Issue date",
      FIELD_CERTIFICATE: "Registry Extract",
      REQUIRED_DATE: "Must be a date",
      REQUIRED: "This field is required.",
      TOOLTIP: "This document must be dated within the last 2 months. If not in English, this must be legally translated into English."
    }
  },
  COMPANY_DIRECTORS: {
    SUBTITLE: "Company directors",
    ADD_TITLE: "Add details for director {{{directorNumber}}} of {{{stakeholderName}}}",
    CONDITION_TITLE: "Does {{{stakeholderName}}} have more directors?",
    NAME_FIELD_LABEL: "Name",
    CORPORATE_NAME_FIELD_LABEL: "Company name",
    NAME_FIELD_PLACEHOLDER: "As shown on their passport",
    COMPANY_FIELD_PLACEHOLDER: "As show on the Certificate of Incorporation",
    FIELD_REQUIRED: "This field is required.",
    EMAIL_FIELD_LABEL: "Email address",
    EMAIL_FIELD_PLACEHOLDER: "name@example.com",
    EMAIL_FIELD_VALIDATION: "This field must be a valid email.",
    OPTION_1: "No, it doesn't",
    OPTION_2: "Yes, it does",
    FIRST_ADD_TOOLTIP: "A <a href=\"https://academy.clara.co/article/2fvaE5tVF7ywn7izaON0PR\" target=\"_blank\">director</a> is a member of the company's board, and can be responsible for the day-to-day management of the company and for setting the company's strategy.",
    CORP_SELECTED_TOOLTIP: "Please be aware that adding a company as a director might cause potential delays with the incorporation due to increased compliance requirements.<br/>If speed of incorporation is a priority, you might consider incorporating with individuals as the directors and adding in the corporate director following incorporation. Additional fees do apply.",
    SECOND_ADD_TOOLTIP: "The Director of this Corporate Stakeholder may itself be another Company.<br><br> +" +
        "In these instances, please enter the name of the Company and an email address of a contact at the Corporate Entity. <br><br>" +
        "<strong>Clara may require further KYC information on these stakeholders later on.</strong>",
    IS_CORPORATE_FIELD_LABEL: "Is this director an individual or a corporate stakeholder?",
    STAKEHOLDER_OPTION_1: "Individual",
    STAKEHOLDER_OPTION_2: "Corporate stakeholder",
  },
  PEP: {
    TITLE: "PEP (Politically Exposed Person)",
    SUBTITLE: "PEP",
    DESCRIPTION: "Are any directors, managers, or shareholders of {{{stakeholderName}}} a PEP, a family member of a PEP or a close associate of a PEP?",
    OPTION_1: "No, they're not",
    OPTION_2: "Yes, they are",
    WHO_IS_PEP: "Who is a PEP, what is their role and what makes them a PEP?",
    ASSISTANT: "A PEP is:<br><br>" +
        "<div style='display: flex; gap: 6px; margin-bottom: 12px'>" +
        "<strong>•&nbsp;</strong>" +
        "<div>An individual who is, or has been, entrusted with prominent public functions in any country (including heads of state, senior government / politician, important political party officials, judicial or military officials and senior executives of state-owned corporations and international organisations);</div>" +
        "</div>" +
        "<div style='display: flex; gap: 6px; margin-bottom: 12px'>" +
        "<strong>•&nbsp;</strong>" +
        "<div>A family member of a PEP (includes if you are the parent, spouse or child of a PEP, or you are the spouse of a child of a PEP);</div>" +
        "</div>" +
        "<div style='display: flex; gap: 6px; margin-bottom: 12px'>" +
        "<strong>•&nbsp;</strong>" +
        "<div>A close associate of a PEP, including:" +
        "<div style='display: flex; gap: 6px; margin-bottom: 12px'>" +
        "a.&nbsp;" +
        "<div>individuals with joint beneficial ownership of a legal entity or legal arrangement, or any close business relations; and</div>" +
        "</div>" +
        "<div style='display: flex'>" +
        "b.&nbsp;" +
        "<div>individuals with sole beneficial ownership of an entity or arrangement set up for the PEP’s benefit.</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "<br></strong>",
  },
  OFFICIAL_DOCUMENT: {
    TITLE: "Upload an Official Document",
    SUBTITLE: "Official Document",
    REQUIRED: "This field is required.",
    FIELD_DOCUMENT: "Document",
    FIELD_DOCUMENT_TYPE: "Document type",
    DOCUMENT_TYPE: {
      OPTION_1: "Certificate of Good Standing",
      OPTION_2: "Trade Licence",
      OPTION_3: "Commercial Licence",
      OPTION_4: "Registry Extract",
    },
    FIELD_DATE: "Issue date",
    FIELD_DATE_EXPIRY: "Expiry date",
    REQUIRED_DATE: "Must be a date",
    TOOLTIP: "The Official Document can be one of the following:<br><br>" +
    "<div style='display: flex'>" +
    "<strong>1.&nbsp;</strong>" +
    "<div><strong>A Certificate of Good Standing;</strong></div>" +
    "</div>" +
    "<div style='display: flex'>" +
    "<strong>2.&nbsp;</strong>" +
    "<div><strong>Trade or Commercial Licence of the Corporate Stakeholder;</strong></div>" +
    "</div>" +
    "<div style='display: flex'>" +
    "<strong>3.&nbsp;</strong>" +
    "<div><strong>An Official Extract from the relevant authority’s website (which must show current directors and shareholders):" +
    "<div style='display: flex'>" +
    "<strong>a.&nbsp;</strong>" +
    "<div><strong>This is known as CR Extract in Saudi;</strong></div>" +
    "</div>" +
    "<div style='display: flex'>" +
    "<strong>b.&nbsp;</strong>" +
    "<div><strong>This is known as Commercial Circular in Lebanon.</strong></div>" +
    "</div>" +
    "</strong></div>" +
    "</div>" +
    "<br></strong>A Trade or Commercial Licence must be dated within the last 2 months must be legally translated into English if not.",
  },
  CERTIFICATE_OF_GOOD_STANDING: {
    TITLE: "Upload the Certificate of Good Standing",
    SUBTITLE: "Certificate of Good Standing",
    FIELD_DATE: "Issue date",
    FIELD_DOCUMENT: "Document",
    REQUIRED_DATE: "Must be a date",
    REQUIRED: "This field is required.",
    TOOLTIP: "This must be:<br>" +
    "<div style='display: flex'>" +
    "1.&nbsp;" +
    "<div>Issued within the last 2 months;</div>" +
    "</div>" +
    "<div style='display: flex'>" +
    "2.&nbsp;" +
    "<div>If not in English, must be legally translated into English;</div>" +
    "</div>" +
    "<div style='display: flex'>" +
    "3.&nbsp;" +
    "<div>Any equivalent document showing that the LP is in good standing." +
    "</div>" +
    "</div>",
  },
  COMPANY_DETAILS: {
    SUBTITLE: "Company details",
    REQUIRED: "This field is required.",
    STEP_1: {
      TITLE: "Add {{{stakeholderName}}} info",
      TOOLTIP: "If this Corporate Stakeholder has changed names in the past, please supply a copy of the Certificate of Change of Name or other form of evidence (noting in some jurisdictions, a change of name certificate may not be generated).",
      FIELD_COUNTRY: "Country",
      FIELD_COMPANY_NUMBER: "Company number",
      FIELD_COMPANY_NUMBER_PLACEHOLDER: "Include letters and numbers only",
      FIELD_TAX_NUMBER: "Tax registration number (if applicable)",
      FIELD_TAX_NUMBER_PLACEHOLDER: "Include numbers only",
      FIELD_PREVIOUS_NAME: "Previous name (if applicable)",
      FIELD_PREVIOUS_NAME_PLACEHOLDER: "Type here",
      FIELD_DOCUMENT_PLACEHOLDER: "Certificate of Change of Name or equivalent document (if applicable)",
    },
    STEP_2: {
      TITLE: "Add {{{stakeholderName}}} address",
      TOOLTIP: "This is the full registered office address of this Corporate Stakeholder. It should be evidenced in the corporate documents provided.",
      FIELD_REGISTERED_ADDRESS: "Registered address",
    }
  },
  LIMITED_PARTNERSHIP_DETAILS: {
    SUBTITLE: "Limited partnership details",
    REQUIRED: "This field is required.",
    STEP_1: {
      TITLE: "Add the Limited Partnership info",
      TOOLTIP: "If this Corporate Stakeholder has changed names in the past, please supply a copy of the Certificate of Change of Name or other form of evidence (noting in some jurisdictions, a change of name certificate may not be generated).",
      FIELD_COUNTRY: "Country",
      FIELD_COMPANY_NUMBER: "Company number",
      FIELD_COMPANY_NUMBER_PLACEHOLDER: "Include letters and numbers only",
      FIELD_TAX_NUMBER: "Tax registration number (if applicable)",
      FIELD_TAX_NUMBER_PLACEHOLDER: "Include numbers only",
      FIELD_PREVIOUS_NAME: "Previous name (if applicable)",
      FIELD_PREVIOUS_NAME_PLACEHOLDER: "Type here",
      FIELD_DOCUMENT_PLACEHOLDER: "Certificate of Change of Name or equivalent document (if applicable)",
    },
    STEP_2: {
      TITLE: "Add its registered address",
      TOOLTIP: "This is the full registered office address of this Corporate Stakeholder. It should be evidenced in the corporate documents provided.",
      FIELD_REGISTERED_ADDRESS: "Registered address",
    },
    STEP_3: {
      TITLE: "Is this Limited Partnership a separate legal entity such that it will be holding shares in its own right?",
      TOOLTIP: "If it is not a separate legal entity then the General Partner would be listed on the Register of Members as the holder of the shares on behalf of the Limited Partnership.",
      OPTION_1: "Yes, it is",
      OPTION_2: "No, it is not",
    }
  },
  TRUST_DEED: {
    TITLE: "Upload the Trust Deed",
    SUBTITLE: "Trust Deed",
    REQUIRED: "This field is required.",
    FIELD_DOCUMENT: "Document",
    FIELD_DATE: "Issue date",
    REQUIRED_DATE: "Must be a date",
    TOOLTIP: "Recently certified (within the last 2 months) copy of the Trust Deed that has been certified by the Trustee. This should include any Deed of Amendments to the Trust Deed"
  },
  REGISTERS: {
    TITLE: "Upload the Register of Directors and the Register of Members",
    FIELD_DATE: "Issue date",
    REQUIRED_DATE: "Must be a date",
    REQUIRED: "This field is required.",
    FIELD_DIRECTORS: "Register of Directors",
    FIELD_MEMBERS: "Register of Members",
    TOOLTIP: "These documents must be dated within the last 2 months. If not in English, they must be legally translated into English"
  },
  REG_OF_PARTNERS_OR_LIM_PARTNESHIP_AGREE: {
    TITLE: "Upload the Register of Partners or the Limited Partnership Agreement",
    SUBTITLE: "Register of Partners or Limited Partnership Agreement",
    REQUIRED: "This field is required.",
    FIELD_DOCUMENT: "Document",
    FIELD_DOCUMENT_TYPE: "Document type",
    DOCUMENT_TYPE: {
      OPTION_1: "Register Of Partners",
      OPTION_2: "Limited Partnership Agreement",
    },
    FIELD_DATE: "Issue date",
    REQUIRED_DATE: "Must be a date",
    TOOLTIP: "Provide only one of the following to show who the Partners of the Limited Partnership are:<br></br><strong>1. Register of Partners<br>2. Limited Partnership Agreement</strong><br></br>The document must be dated within the last 2 months. If not in English, this must be legally translated into English.",
  },
};
