import * as Yup from "yup";
import { Constants } from "src/v1/utils/constants";

export const schemaNormal = {
  fullName: Yup.string().required("Required"),
  email: Yup.string()
    .lowercase()
    .matches(Constants.REGEXP.EMAIL, {
      message: "Enter a valid email",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
  nationality: Yup.string().required("Required").nullable(),
  hasNationalities: Yup.boolean()
    .required("This field is required")
    .typeError("This field is required"),
  nationalities: Yup.mixed().when("hasNationalities", {
    is: true,
    then: Yup.array()
      .of(Yup.string())
      .min(1, "Required")
      .required("Required"),
    otherwise: Yup.array().of(Yup.string()).nullable(),
  }),
  linkedinURL: Yup.string()
    .matches(Constants.REGEXP.URL_LINKEDIN, {
      message: "Enter valid URL",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
  startDate: Yup.string()
    .matches(Constants.REGEXP.DATE, {
      message: "Enter valid date",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
  endDate: Yup.string()
    .matches(Constants.REGEXP.DATE, {
      message: "Enter valid date",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
  address: Yup.object().shape({
    country: Yup.string().nullable().notRequired(),
    street: Yup.string().nullable().notRequired(),
    city: Yup.string().nullable().notRequired(),
    state: Yup.string().nullable().notRequired(),
    zipCode: Yup.string().nullable().notRequired(),
  }).nullable(),
  passportNumber: Yup.string("Enter passport").nullable(),
  profession: Yup.string("Enter profession").nullable(),
  phoneNumber: Yup.string("Enter phone number")
    .nullable()
    .typeError("Required"),
  dateOfBirth: Yup.string()
    .matches(Constants.REGEXP.DATE, {
      message: "Enter valid date",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
};

export const schemaEGate = {
  fullName: Yup.string().required("Required"),
  email: Yup.string()
    .lowercase()
    .matches(Constants.REGEXP.EMAIL, {
      message: "Enter valid email",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
  linkedinURL: Yup.string()
    .matches(Constants.REGEXP.URL_PROTOCOL, {
      message:
        "Please enter a valid URL. eg:'https://...' or 'http://... '",
      excludeEmptyString: true,
    })
    .nullable(),
  nationality: Yup.string().required("Required").nullable(),
  hasNationalities: Yup.boolean()
    .required("This field is required")
    .typeError("This field is required"),
  nationalities: Yup.mixed().when("hasNationalities", {
    is: true,
    then: Yup.array()
      .of(Yup.string())
      .min(1, "Required")
      .required("Required"),
    otherwise: Yup.array().of(Yup.string()).nullable(),
  }),
  startDate: Yup.string()
    .matches(Constants.REGEXP.DATE, {
      message: "Enter valid date",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
  endDate: Yup.string()
    .matches(Constants.REGEXP.DATE, {
      message: "Enter valid date",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
  entryDate: Yup.string()
    .matches(Constants.REGEXP.DATE, {
      message: "Enter valid date",
      excludeEmptyString: true,
    })
    .required("Required")
    .nullable(),
  address: Yup.object().shape({
    country: Yup.string().nullable().notRequired(),
    street: Yup.string().nullable().notRequired(),
    city: Yup.string().nullable().notRequired(),
    state: Yup.string().nullable().notRequired(),
    zipCode: Yup.string().nullable().notRequired(),
  }).nullable(),
  passportNumber: Yup.string("Enter passport").nullable(),
  profession: Yup.string("Enter profession").nullable(),
  phoneNumber: Yup.string("Enter phone number")
    .nullable()
    .typeError("Required"),
  dateOfBirth: Yup.string()
    .matches(Constants.REGEXP.DATE, {
      message: "Enter valid date",
      excludeEmptyString: true,
    })
    .notRequired()
    .nullable(),
};
