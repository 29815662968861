
import React, {FC} from 'react';
import classesModule from './classes.module.scss'
import animationData from './../../../../../../../generic/animations/journey-finish.json';
import { StepProps } from '../../../../../../../generic/components/Wizard/Step';
import useCustomMachine from '../../../../../../../generic/hooks/useCustomMachine';
import Button from '../../../../../../../generic/components/Buttons/Button';
import useTranslate from '../../../../../../../generic/hooks/useTranslate';
import ModalTourTemplate from '../../../../../../../../components/templates/ModalTourTemplate';
import Lottie from '../../../../../../../generic/components/Animations/Lottie/Lottie';
import { useLD } from 'src/providers/LDProvider';


/**
 - Engage Concierge Done Form
 **/
const PreSubmittedStep: FC<StepProps> = ({ ...props }: StepProps) => {
  const { translate } = useTranslate()
  const { featureFlags } = useLD();
  const isPaymentProcessingRemoved = featureFlags['payment-processing-removal'];
  const { next, state, stepData, send, prev } = useCustomMachine();
  const { steps, actualStep } = state.context.paramsData;
  

  const handleDone = async values => {
    next(values);
  }


  const handleCancel = () => {
    send('CANCEL')
  }

  const handleNext = () => {
    next();
  }

  return (
    <React.Fragment>
      <ModalTourTemplate
        title={
          isPaymentProcessingRemoved
            ? translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.PRESUBMITTED_FORMATION_STEP_PAYMENT_REMOVED.TITLE')
            : translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.PRESUBMITTED_FORMATION_STEP.TITLE')
        }
        subtitle={
          isPaymentProcessingRemoved
            ? translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.PRESUBMITTED_FORMATION_STEP_PAYMENT_REMOVED.SUBTITLE')
            : translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.PRESUBMITTED_FORMATION_STEP.SUBTITLE')
        }
        image={<Lottie
          height={490}
          width={470}
          options={{
            loop: true,
            autoplay: true,
          }} 
          animation={animationData} 
        />}
        buttons={[
          <Button onClick={handleCancel} variant="secondary">
            {isPaymentProcessingRemoved
              ? translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.PRESUBMITTED_FORMATION_STEP_PAYMENT_REMOVED.BUTTON_PREVIOUS')
              : translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.PRESUBMITTED_FORMATION_STEP.BUTTON_PREVIOUS')}
          </Button>,
          <Button onClick={handleNext} variant="primary">
            {isPaymentProcessingRemoved
              ? translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.PRESUBMITTED_FORMATION_STEP_PAYMENT_REMOVED.BUTTON_NEXT')
              : translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.PRESUBMITTED_FORMATION_STEP.BUTTON_NEXT')}
          </Button>]}
        className= {''}  
        actualStep={actualStep}
        steps={steps}
        setActualStep={() => {}}    
      />  
    </React.Fragment>
  )
}

export default PreSubmittedStep;
