import { default as React, useCallback, useEffect, useMemo } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import Button from 'src/modules/generic/components/Buttons/Button';
import { Divider } from '@material-ui/core';
import Money from 'src/components/text/Text/Money';
import { PaymentProvider } from 'src/modules/checkout/hooks/usePayment';
import PrimaryButton from 'src/components/generic/Buttons/PrimaryButton/PrimaryButton';
import Scrollbar from 'src/components/generic/Scrollbar/Scrollbar';
import { Stack } from '@mui/material';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import Typography from 'src/modules/generic/components/Texts/Typography';
import _ from 'lodash';
import cls from "./CartStep.module.scss";
import currency from 'currency.js';
import moment from "moment";
import useCountryList from 'src/hooks/services/generic/useCountryList';
import useDeleteRequestBasketItems from '../../services/useDeleteRequestBasketItems';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useSession from 'src/modules/session/hooks/useSession';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import CartItemsLogic from './components/CartItemsLogic';
import Icon from "src/modules/generic/components/Icon";
import { useLD } from 'src/providers/LDProvider';

/**
* Description Step: Select the stakeholder to pay
*
* @returns
*/


function CartStep() {

  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  const CATEGORIES_CONFIG = {
    COMPANY: {
      showTitle: false,
      variantItem: "item",
      deleteItem: () => false,
      deleteCategory: false,
      showTotalCategory: false,
      showTotalItem: true,
    },
    COMPANY_DETAILS: {
      showTitle: true,
      variantItem: "subitem",
      deleteItem: (item) => {
        return item?.type !== "CHANGE_COMPANY_NOMINEE_AUTHORISED_SIGNATORY_SERVICE"
      },
      deleteCategory: false,
      showTotalCategory: true,
      showTotalItem: true,
      getFunctionToCustomItems: (item) => {
        if (item?.type === "CHANGE_COMPANY_REGISTERED_ADDRESS") {
          const useRegisteredAddress = item?.values?.newValues?.mailingAddress?.useRegisteredAddress;
          if (useRegisteredAddress) {
            return (item, getValue, translate) => {
              return [
                {
                  description: item?.product?.name,
                  // amount: item?.product?.value,
                  amount: item?.value,
                  type: item?.type,
                  canDelete: false,
                  variant: CATEGORIES_CONFIG[item?.category].variantItem,
                  delete: CATEGORIES_CONFIG[item?.category].deleteItem(item),
                  showTotal: CATEGORIES_CONFIG[item?.category].showTotalItem,
                  params: {
                    prevValue: "test",
                    newValue: getValue(item?.values?.newValues["registeredAddress"], "registeredAddress"), // newValues = newValues[roles] | newValues[companyType] | etc
                    field: translate(`MODULES.STARTUP.FIELD_NAME_LABEL_REGISTEREDADDRESS_MAILINGADDRESS`),
                    entity: item?.entity,
                  },
                  id: item?.id
                }
              ]
            }
          }
        }

        return null
      },
    },
    STAKEHOLDER_DETAILS: {
      showTitle: true,
      variantItem: "subitem",
      deleteItem: () => isPaymentProcessingRemoved ? true : false,
      deleteCategory: true,
      showTotalCategory: true,
      showTotalItem: false,
    },
    STAKEHOLDER_ROLES: {
      showTitle: true,
      variantItem: "subitem",
      deleteItem: () => isPaymentProcessingRemoved ? true : false,
      deleteCategory: true,
      showTotalCategory: true,
      showTotalItem: false,
    },
    EQUITY: {
      showTitle: true,
      variantItem: "subitem",
      deleteItem: () => true,
      deleteCategory: false,
      showTotalCategory: false,
      showTotalItem: true,
    },
  }

  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [requestItems, setRequestItems] = React.useState([]);
  const [loadingIds, setLoadingIds] = React.useState([]);

  const { data } = useCountryList({
    code: "",
    name: "",
    language: "en",
    "orderBy": "FORMATION"
  });

  const countriesList = useMemo(() => data?.countryList, [JSON.stringify(data)])

  useEffect(() => {
    setRequestItems(context?.getCart?.requestBasket?.requestItems)
  }, [context?.getCart?.requestBasket?.requestItems])

  const getCountryName = (code) => {
    const countryFiltered = countriesList?.filter(country => country.code === code);
    return _.get(countryFiltered, '[0].name', '')
  }

  const handleNext = useCallback((values) => {
    next({})
  }, [next]);

  const getValue = (value, key) => {

    switch (key) {
      case 'businessActivities':
        if (value?.relevantActivities) {
          return _.join(_.map(value?.relevantActivities, (activity) => translate(`MODULES.RENEWALS.RELEVANT_ACTIVITIES_LABELS.${activity}`)), ', ')
        }
        if (value?.articlesOfAssociationType) {
          return value?.articlesOfAssociationType
        }
        return null;
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'authorisedShareCapital':
        return `USD ${value.toLocaleString()}`;
      case 'registeredAddress':
      case 'mailingAddress':
      case 'address':
        return `${value?.street ?? ""}${value?.street ? "," : ""} ${value?.city ?? ""}${value?.city ? "," : ""} ${value?.state ?? ""}${value?.state ? "," : ""}  ${getCountryName(value?.country) ?? ""}${value?.zipCode ? `, ${value.zipcode}`: ""}`;
      case 'nationality':
        return getCountryName(value)
      case 'dateOfBirth':
        return value ? moment.utc(value).format('DD MMM YYYY') : "";
      case 'companyType':
        const formatedCompanyType = {
          'LIMITED_PARTNERSHIP': "Limited partnership",
          'LIMITED_BY_SHARES': 'Company Limited by shares',
          'TRUST': 'Trust',
          'OTHER': 'Other',
          'DEFAULT': 'Default'
        }
        return formatedCompanyType[value]
      case 'roles':
        return value[0]?.entityName
      case 'keyPeople':
        return value
      default:
        return value ? value.toString() : '';
    }
  };

  const categories = useMemo(() => {
    let categories = {};
    _.forEach(requestItems, (item) => {
      let category = `${item?.category}_${item?.entity?.id}`;
      if (!categories[category]) {
        categories[category] = {
          code: item?.category,
          showTitle: CATEGORIES_CONFIG[item?.category].showTitle,
          description: item?.category,
          delete: CATEGORIES_CONFIG[item?.category].deleteCategory,
          items: [],
          total: currency(0),
          showTotal: CATEGORIES_CONFIG[item?.category].showTotalCategory,
          params: item?.entity
        }
      }
      // categories[category].total = categories[category].total.add(item?.product?.value);
      categories[category].total = categories[category].total.add(item?.value);
      if (item?.values?.newValues) {
        if (CATEGORIES_CONFIG[item?.category].getFunctionToCustomItems && CATEGORIES_CONFIG[item?.category].getFunctionToCustomItems(item)) {
          const customFunction = CATEGORIES_CONFIG[item?.category].getFunctionToCustomItems(item);
          const itemsToAdd = customFunction(item, getValue,translate);
          _.forEach(itemsToAdd, (item) => {
            categories[category].items.push(item)
          })
        } else {
          _.forEach(item?.values?.newValues, (value, field) => {
            categories[category].items.push({
              description: item?.product?.name,
              // amount: item?.product?.value,
              amount: item?.value,
              type: item?.type,
              canDelete: true,
              variant: CATEGORIES_CONFIG[item?.category].variantItem,
              delete: CATEGORIES_CONFIG[item?.category].deleteItem(item),
              showTotal: CATEGORIES_CONFIG[item?.category].showTotalItem,
              params: {
                prevValue: getValue(item?.values?.oldValues[field], field) || 'N/A',
                newValue: getValue(item?.values?.newValues[field], field), // newValues = newValues[roles] | newValues[companyType] | etc
                companyName: getValue(item?.values.newValues[field], field),
                field: isPaymentProcessingRemoved && !translate(`MODULES.STARTUP.FIELD_NAME_LABEL_${field.toUpperCase()}_PAYMENT_REMOVAL`).includes("MODULES.STARTUP") ? translate(`MODULES.STARTUP.FIELD_NAME_LABEL_${field.toUpperCase()}_PAYMENT_REMOVAL`): translate(`MODULES.STARTUP.FIELD_NAME_LABEL_${field.toUpperCase()}`),
                entity: item?.entity,
                shareIssuances: item?.shareIssuances
              },
              id: item?.id
            })
          })
        }
      } else {
        categories[category].items.push({
          id: item?.id,
          description: item?.product?.name,
          // amount: item?.product?.value,
          amount: item?.value,
          type: item?.type,
          canDelete: false,
          variant: CATEGORIES_CONFIG[item?.category].variantItem,
          delete: CATEGORIES_CONFIG[item?.category].deleteItem(item),
          showTotal: CATEGORIES_CONFIG[item?.category].showTotalItem,
          params: {
            entity: item?.entity,
            shareIssuances: item?.shareIssuances
          }
        })
      }
    });
    return categories;
  }, [requestItems, translate])

  const handleCancel = (values) => {
    cancel();
  }

  const { startup } = useSession();
  const [deleteRequestBasket, rest] = useDeleteRequestBasketItems({}, {});

  const handleDeleteItem = useCallback(async (category, _item) => {
    const item = _item ?? category?.items[0]
    if (!item) {
      return;
    }
    let variables = {
      "startupId": startup?.id,
      "groupCompanyId": context?.initialData?.groupCompanyId,
      "itemId": item?.id,
      "type": item?.type,
      "entity": {
        "entityType": item?.params?.entity?.__typename === 'GroupCompany' ? "GROUP_COMPANY" : "STAKEHOLDER",
        "entityId": item?.params?.entity?.id
      }
    };
    setLoadingIds([...loadingIds, item?.id])
    await deleteRequestBasket({ variables: variables });
    prev();
  }, [context?.initialData?.groupCompanyId, deleteRequestBasket, loadingIds, requestItems, startup?.id])

  useEffect(() => {
    dispatch(addAssistantText('-'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch]);

  const { totalClaraAmount, totalGovernmentFee, totalPricingAmount, allVat, allTotalDiscount, newValue } = useMemo(() => {
    let allTotalDiscountBase = 0;
    let totalClaraAmountBase = 0;
    let totalGovernmentAmountBase = 0;
    let totalPricingAmountBase = 0;
    let allVat = 0;
    _.forEach(context?.getCart?.requestBasket?.pricingItems, (item) => {
      allTotalDiscountBase += item?.totalDiscount;
      totalClaraAmountBase += item?.totalClaraAmount;
      totalGovernmentAmountBase += item?.totalGovernmentFee;
      totalPricingAmountBase += item?.totalPricingAmount;
      allVat += item?.vat;
    });
    return {
      totalClaraAmount: totalClaraAmountBase + totalGovernmentAmountBase,
      totalGovernmentFee: totalGovernmentAmountBase,
      totalPricingAmount: totalPricingAmountBase + totalGovernmentAmountBase,
      allVat: allVat,
      allTotalDiscount: allTotalDiscountBase,
      newValue: (totalClaraAmountBase + totalGovernmentAmountBase) - allTotalDiscountBase
    };
  }, [context?.getCart?.requestBasket?.pricingItems]);

  const showFee = useMemo(() => {
    let result = false;
    if (allTotalDiscount === 0) {
      if (totalClaraAmount > 0) {
        result = true;
      }
    } else {
      if (newValue > 0) {
        result = true;
      }
    }
    return result;
  }, [allTotalDiscount, newValue, totalClaraAmount]);

  return (
    <PaymentProvider>
      <TemplateWizard
        props={{
          title: isPaymentProcessingRemoved ? `Submission review${context?.getCart?.name ? ` for ${context?.getCart?.name}` : null}` : 'Cart overview',
          skeleton: null,
          subTitle: null,
        }}
        buttons={{
          cancel: (
            <Button
              variant={!_.isEmpty(categories) ? 'secondary' : "primary"}
              onClick={handleCancel}
            >
              {!_.isEmpty(categories) ? translate('BUTTON_CANCEL') : translate('GENERIC_BUTTON_CLOSE')}
            </Button>
          ),
          submit: !_.isEmpty(categories) ? <PrimaryButton onClick={handleNext} disabled={(context?.initialData?.disabledPayCart ?? false) || _.isEmpty(categories)} >{isPaymentProcessingRemoved ? 'Confirm' : 'Pay and submit'}</PrimaryButton> : null,
          anotherLeft: [
            <>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0}
                sx={{
                  height: "10px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  {allTotalDiscount > 0 && totalClaraAmount > 0 && !isPaymentProcessingRemoved && (
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Typography variant={"bodyLineThrough"} weight={"extrabold"} color="dark-grey">
                        <Money value={totalClaraAmount} showDecimals={true} prefix={"USD"} />
                      </Typography>
                    </Stack>
                  )}
                  {isPaymentProcessingRemoved ? null :
                  <Stack
                    alignItems={"center"}
                    direction={"row"}
                  >
                    {!_.isEmpty(categories) ? (
                      <Typography variant={"h5"} weight={"extrabold"}>
                        <Money value={allTotalDiscount === 0 ? totalClaraAmount : newValue} showDecimals={true} prefix={"USD"} />
                      </Typography>
                    ) : (
                      <Typography variant={"h5"} weight={"extrabold"}>
                        USD 0.00
                      </Typography>
                    )}
                    {allVat > 0 && (
                      <>
                        &nbsp;
                        <Typography variant={"h5"} weight={"extrabold"}>
                          + VAT
                        </Typography>
                      </>
                    )}
                  </Stack>}
                </Stack>
                {allTotalDiscount > 0 && !isPaymentProcessingRemoved && (
                  <Stack
                    alignItems={"center"}
                    direction={"row"}
                  >
                    <Typography variant={"body"} weight={"regular"} color="error">
                      Multiple transactions discount&nbsp;-&nbsp;
                    </Typography>
                    <Typography variant={"body"} weight={"semibold"} color="error">
                      <Money value={allTotalDiscount} showDecimals={true} prefix={"USD"} />
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </>
          ],
        }}
      >
        {!_.isEmpty(categories) ? (
          <>
            <Stack spacing={2}>
              <div>
              {isPaymentProcessingRemoved ? 'Here is the list of the items you are submitting. Please review and confirm before you submit.' : 'Here are your service requests and related fees to file them:'}
              </div>
              {!isPaymentProcessingRemoved && <Stack spacing={2} justifyContent={"space-between"} alignItems={"center"} direction={"row"} sx={{ paddingRight: "27px" }}>
                <Typography variant={"body"} color="dark-grey" weight={"semibold"}>Description</Typography>
                <Typography variant={"body"} color="dark-grey" weight={"semibold"}>Fee</Typography>
              </Stack>}
              <Divider />
              <Scrollbar className={cls.Scrollbar} >
                <CartItemsLogic
                  categories={categories}
                  loadingIds={loadingIds}
                  handleDeleteItem={handleDeleteItem}
                  totalGovernmentFee={totalGovernmentFee}
                  showFee={showFee}
                />
              </Scrollbar>
            </Stack>
          </>
        ) : (
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Icon isClara icon={"Empty-cart"} size={"96px"} color={"#2C74FF"} />
            <Typography variant={"h5"} weight={"semibold"}>{isPaymentProcessingRemoved ? "There is nothing to submit." : "This cart is empty."}</Typography>
          </Stack>
        )}
      </TemplateWizard>
    </PaymentProvider>
  );
}

export default CartStep;

