export default {
  ACCOUNTING_RECORDS : {
    FIRST_STEP: {
      TITLE: "Where will the accounting records  for the company be maintained?",
      SUBTITLE: "Accounting records",
      TOOLTIP1:
        "In order to form your company, you’ll need to provide details of where your accounting records are kept.",
        TOOLTIP2: "The address <strong>cannot</strong> be the same as your registered office address."
    },
  },
  AUTHORIZED_SIGNATORY:{
    SELECT_ADD_ENTITY:{
      TITLE: "Have you added all your company's directors and shareholders?",
      SUBTITLE: "ADGM authorised signatories",
      TOOLTIP:"Add all current directors and shareholders in order to form this company.",
      TOOLTIP_2:"This info is essential to the formation process, so add all your current directors and shareholders before we continue.",
      OPTIONS:{
        NO: "No, not yet",
        YES: "Yes, all added!"
      }
    },
    ADD_AUTHORIZED_SGNATORY:{
      TITLE: "Who will be your GCC national or UAE resident ADGM authorised signatory? (Select one or more)",
      SUBTITLE: "ADGM authorised signatories",
      TOOLTIP:"An ADGM authorised signatory is a person who can legally sign ADGM documents on the company’s behalf. Be sure to select at least one GCC national or UAE resident."
    },
    ADD_AUTHORIZED_SGNATORY_EMPTY:{
      TITLE: "Add a GCC national or UAE resident",
      SUBTITLE: "ADGM authorised signatories",
      TOOLTIP:"To qualify as a GCC national or UAE resident, the person should a hold passport from Bahrain, Kuwait, Oman, Qatar or Saudi Arabia or UAE, or a residence visa for the UAE.​",
      CONTENT_1: "To continue with this formation, add a GCC national or UAE resident as a director or shareholder in the company.",
      CONTENT_2: "Go back to the map to add a new stakeholder as a director or shareholder in this soon to-be-formed company.​"
    },
    SELECT_ADD_ANOTHER_STAKEHOLDER:{
      TITLE: "Add another ADGM authorised signatory?",
      SUBTITLE: "ADGM authorised signatories",
      TOOLTIP:"An ADGM authorised signatory is a person who can legally sign ADGM documents on the company\'s behalf.",
      OPTIONS:{
        NO: "No, not for now",
        YES: "Yes, add another"
      }
    },
    ADD_ANOTHER_STAKEHOLDER:{
      TITLE: "Select additional ADGM authorised signatories",
      SUBTITLE: "ADGM authorised signatories",
      TOOLTIP1:"An ADGM authorised signatory is a person who can legally sign ADGM documents on the company\'s behalf.",
      TOOLTIP2:"An ADGM authorised signatory who is neither a GCC National nor a GCC Resident must provide a copy of a UAE Entry Stamp."
    }
  },
  DATA_PROTECTION_CONTACT : {
    FIRST_STEP: {
      TITLE: "Who is going to be your data protection contact?",
      SUBTITLE: "Data protection contact",
      TOOLTIP:
        "Your data protection contact is the person in charge of compliance with data protection regulations and how personal data is dealt with.",
    },
  },
  DESIGNATED_CONTACT : {
    FIRST_STEP: {
      TITLE: "Who is going to be your designated contact?",
      SUBTITLE: "ADGM Designated contact",
      TOOLTIP:
        "The ADGM Designated Contact is a contact person who can be reached regarding day-to-day operational matters for the company. If Clara is appointed as the Company Service Provider, ADGM will contact Clara as a first step. However, there may be instances where ADGM will want to contact the Company directly and therefore a contact must be selected.",
    },
  },
  BANKING_AUTHORITY : {
    FIRST_STEP: {
      TITLE: "Will they be able to sign singly or jointly?",
      SUBTITLE: "Banking authority",
      TOOLTIP:
        "Select 'singly' if the selected individuals can sign documents on behalf of other signatories. Select 'jointly' if all signatories should sign every required document.", 
    },
  },
  CANCEL: "Cancel",
  PREVIOUS: "Previous",
  ADD: "Add",
  COUNTRY: "Country",
  ADDRESS: "Address",
  REGISTERED_ADDRESS: "Registered address",
  STREET: "Street",
  CITY: "City",
  STATE_REGION: "State / Region",
  ZIP_POST: "Zip Code / Post Code",
  REQUIRED: "This field is required",
  CLOSE:"Close",
  SAVE_CHANGES: "Save changes",
  SINGLY: "Singly",
  JOINTLY: "Jointly", 
};
