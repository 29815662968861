import React, { FC, useRef, useEffect, useState } from 'react';
import { Constants } from "src/v1/utils";
import * as Yup from 'yup';
import Form from "../../../../../../components/forms/Form/Form";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../../../generic/components/Inputs/CheckboxButtonSimple";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import classes from './classes.module.scss';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import { InputWithBanner } from 'src/components/inputs/InputWithBanner';
import GroupCompany from 'src/components/modules/clara/startups/entities/classes/GroupCompany';
import { useLD } from 'src/providers/LDProvider';

const schema = Yup.object().shape({
  roles: Yup.array().of(Yup.string()).required('This field is required')
})

type Roles = keyof typeof Constants.ROLES_FROM_BACKEND
interface Props extends FormProps {
  roles: Array<CheckboxButtonOptions<Roles, string>>
  isEntity: any,
  inFormation: boolean,
  hasPincas: boolean
  groupCompanies: any[],
  priceList: any[]
}

const SelectRoles: FC<Props> = ({
  initialValues,
  tooltips,
  roles,
  groupCompanies,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  isEntity,
  inFormation,
  priceList,
  hasPincas
}) => {

  const ldProvider = useLD();
  const isPaymentProcessingRemoved = ldProvider.featureFlags["payment-process-removal-pincas-and-renewals"];
  const refForm = useRef();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [rolesSelected, setRolesSeleted] = useState([])

  useEffect(() => {
    dispatch(clearAssistatText());
    handleInitialTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handleInitialTooltips = () => {
    // FOR WHEN MODAL OPENS TOOLTIPS
    const noGroupCompanies = groupCompanies && groupCompanies.length === 0;
    if (noGroupCompanies) dispatch(addAssistantText(translate('MODAL_ADD_STAKEHOLDER_SELECT_ROLES_TOOLTIP_NO_GROUPCOMPANIES')));
    if (inFormation) dispatch(addAssistantText(translate('MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_IN_FORMATION')));
    if (!inFormation) dispatch(addAssistantText(translate('MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP')));
    if (isEntity) dispatch(addAssistantText(translate('MODAL_ADD_SHAREHOLDER_TYPE_CORPORATE_TOOLTIP')));
  }

  const handleAddTooltips = (values) => {
    // AFTER USER STARTS SELECTING TOOLTIPS
    dispatch(clearAssistatText())
    handleInitialTooltips()
    if (hasPincas && values.includes('SHAREHOLDER')) dispatch(addAssistantText('Please go to the Equity page to add a share position for this stakeholder.', 'alert'));
    if (values && values.length && !(values.includes('EMPLOYEE') || values.includes('THIRDPARTY'))) dispatch(addAssistantText(translate('MODAL_ADD_SHAREHOLDER_SELECT_ROLES_TOOLTIP_' + values[(values || []).length - 1])));
  }

  const handleBannerLogic = (value) => {
    setRolesSeleted(value[0]);
    const hasMultipleCompanies = groupCompanies && groupCompanies.length > 1;
    let result = null;
    if (!hasMultipleCompanies) {
      const groupCompanyClass = new GroupCompany(groupCompanies[0]);
      if ((groupCompanyClass.isADGM() || groupCompanyClass.isCayman()) && groupCompanyClass.isManagedByClara() && value[0] && value[0].includes('DIRECTOR')) {
        result = true;
      }
    }
    return result;
  }

  const handleBannerInfo = () => {
    const price = priceList.find(p => p.role === 'DIRECTOR')?.price;
    const showVat = priceList.find(p => p.role === 'DIRECTOR')?.useVat;
    if ((rolesSelected || []).includes('DIRECTOR')) return isPaymentProcessingRemoved ? 'Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review.' : `Costs to file this change start from <strong>USD ${price}${showVat ? ' + VAT': ""}</strong>. Select “Next” to add it to your cart, then go to the Dashboard and select “Pay and submit” to proceed. Please note that if you are adding a new stakeholder, you must complete their profile details before submitting the change request.`
    return ""
  }

  const handleDisable = (formState) => {
    const onlyGroupCompanyLocked = groupCompanies && groupCompanies.length === 1 && groupCompanies[0].isLocked
    const { isSubmitting, isValid } = formState;
    if (onlyGroupCompanyLocked && ((rolesSelected || []).includes('DIRECTOR') || (rolesSelected || []).includes('SHAREHOLDER'))) return true;
    return isSubmitting || !isValid
  }

  return (
    <React.Fragment>
      <Form
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          props={propsTemplate}
          buttons={{
            ...buttons,
            submit: <SubmitButton handleDisabled={handleDisable} {...buttonSubmitProps}>Next</SubmitButton>
          }}
        >
          <InputWithBanner
            component={CheckboxButtonSimple}
            controllerInputProps={{
              name: 'roles',
              classNameButton: classes.buttonCheckBox,
              options: roles,
              defaultValue: [],
              onChange: (value) => {
                handleAddTooltips(value);
              },
              isMultiple: true
            }}
            //TODO: add logic to show banner for each role of director or shareholder
            info={handleBannerInfo()}
            applyLogic={handleBannerLogic}
            bannerLogicType='values'
            showTop={true}
            paddingRight='12px'
          />
          <div>&nbsp;</div>
        </Template>
      </Form>
    </React.Fragment>
  )
}

export default SelectRoles