import * as Yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import Button from "src/modules/generic/components/Buttons/Button";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import { Constants } from "src/v1/utils/constants";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import Form from "src/components/forms/Form/Form";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import TextInput from "src/components/inputs/Text/TextInput";
import _ from "lodash";
import { useDispatch } from "react-redux";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import RadioInput from "src/components/inputs/Radio/RadioInput";
import { HiddenFieldForm } from "src/components/forms/contents";
import ClaraCountryAutocomplete from "src/v1/components/Form/ClaraCountryAutocomplete/ClaraCountrySelector";
import { useLD } from 'src/providers/LDProvider';

function ReviewPersonalDetailShareholderConfirmDetailForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const { initialData } = context;
  const dispatch = useDispatch();
  const [countryNationality, setCountryNationality] = useState();

  const ldProvider = useLD()
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];
  
  const listTitle = useMemo(() => {
    return [
      { label: "Mr", value: "Mr" },
      { label: "Mrs", value: "Mrs" },
      { label: "Ms", value: "Ms" },
      { label: "Dr", value: "Dr" },
      { label: "Hon", value: "Hon" },
      { label: "Reverend", value: "Reverend" },
      { label: "Very Reverend", value: "Very Reverend" },
      { label: "Senator", value: "Senator" },
    ];
  }, []);

  const initialValues =
    context?.ReviewPersonalDetailShareholderConfirmDetailForm ??
    initialData?.stakeholder;

  const residenceCountry = _.get(initialValues, "address.country.code")
    ? _.get(initialValues, "address.country.code").toLowerCase()
    : "";

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next({ ...values, countryNationality });
  };

  useEffect(() => {
    isPaymentProcessingRemoved ?
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_COMPANY_DETAILS.STEP_1.TOOLTIP_1_PAYMENT_REMOVAL"
        ),
        "alert"
      )
    )
    :
      dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_COMPANY_DETAILS.STEP_1.TOOLTIP_1"
        ),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    title: Yup.string()
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
    fullName: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    profession: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    nationality: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    hasNationalities: Yup.boolean().required("This field is required").typeError("This field is required"),
    nationalities: Yup.mixed().when('hasNationalities', {
      is: true,
      then: Yup.array().of(Yup.string()).min(1, translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      otherwise: Yup.array().of(Yup.string()).nullable(),
    }),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_EMAIL"),
        excludeEmptyString: true,
      })
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    phoneNumber: Yup.string()
      .nullable()
      .required("This field is required")
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    dateOfBirth: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
    countryOfBirth: Yup.string().required("This field is required").typeError("This field is required").nullable(),
    cityOfBirth: Yup.string().required("This field is required").nullable(),
  });

  const labelLines = [
    "Do they have other<br />nationalities?",
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm their details",
          subTitle: `Review details for ${initialValues.fullName}`,
        }}
        buttons={{
          submit: (
            <SubmitButton>{translate("MODULES.CLARA.BTN_NEXT")}</SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <TemplateLabel label="Title" isRequired={true}>
          <ControllerInput
            name="title"
            placeholder="Type here"
            render={SelectInput}
            list={listTitle}
          />
        </TemplateLabel>
        <TemplateLabel label="Full legal name" isRequired={true}>
          <ControllerInput
            name="fullName"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Arabic Name">
          <ControllerInput
            name="arabicName"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Former name">
          <ControllerInput
            name="previousName"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Nationality" isRequired={true}>
          <ControllerInput
            name="nationality"
            placeholder="Type here"
            render={CountrySelector}
            callbakcs={{ updateValues: (v) => setCountryNationality(v) }}
          />
        </TemplateLabel>
        <TemplateLabel 
          multilineTexts={labelLines}
          isRequired
        >
          <ControllerInput
            render={RadioInput}
            name='hasNationalities'
          />
        </TemplateLabel>
        <HiddenFieldForm
          fieldsWatch="hasNationalities"
          conditionHideCallback={(value) => value}
        >
          <TemplateLabel label="Other nationalities" isRequired>
            <ControllerInput
              placeholder="Select all that apply"
              render={ClaraCountryAutocomplete}
              name='nationalities'
            />
          </TemplateLabel>
        </HiddenFieldForm>
        <TemplateLabel label="Date of birth" isRequired={true}>
          <ControllerInput
            name="dateOfBirth"
            placeholder="dd/mm/yyyy"
            render={CalendarInputDate}
          />
        </TemplateLabel>
        <TemplateLabel label="Country of birth" isRequired>
          <ControllerInput
            placeholder="Select country"
            render={CountrySelector}
            name='countryOfBirth'
          />
        </TemplateLabel>
        <TemplateLabel label="City of birth" isRequired>
          <ControllerInput
            render={TextInput}
            name="cityOfBirth"
            placeholder="Type here"
          />
        </TemplateLabel>
        <TemplateLabel label="Profession" isRequired={true}>
          <ControllerInput
            name="profession"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Email address" isRequired={true}>
          <ControllerInput
            name="email"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_TELEPHONE_NUMBER")}
          isRequired
        >
          <ControllerInput
            render={PhoneInput}
            name="phoneNumber"
            defaultCountry={residenceCountry}
            disableDropdown={false}
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewPersonalDetailShareholderConfirmDetailForm;