import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Stack } from "@mui/material";
import ADGMRenewalIndividualStakeholderForm from "src/components/forms/modules/startups/individualStakeholder/ADGMRenewalIndividualStakeholderForm";
import CaymanRenewalIndividualStakeholderForm from "src/components/forms/modules/startups/individualStakeholder/CaymanRenewalIndividualStakeholderForm";
import IndividualStakeholderForm from "src/components/forms/modules/startups/individualStakeholder/IndividualStakeholderForm";
import Line from "src/components/generic/Line/Line";
import { LoadingProvider } from "src/modules/generic/hooks/useLoading";
import ModalAssistant from "src/modules/generic/components/Modal/ModalAssistant";
import SkeletonArea from "src/modules/generic/components/Skeletons/Overlays/SkeletonArea";
import SkeletonInput from "src/modules/generic/components/Skeletons/Overlays/SkeletonInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useIndividualStakeholderData from "src/hooks/services/modules/startups/individual/useIndividualStakeholderData";
import useSession from "src/modules/session/hooks/useSession";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";
import { handleNationalitiesInitialValue, handleNationalitiesSaveValue } from "src/utils/otherNationalitiesHelper";

const EditProfile = ({
  showModal,
  open,
  refresh,
  handleCloseModal,
  isManagedByClara,
  jurisdictions,
}) => {
  const { translate } = useTranslate();
  const { startupId } = useSession();
  const params = useParams();
  const overlayName = translate("EDIT_DETAILS");

  const handleCloseForm = () => {
    refresh && refresh();
    handleCloseModal();
  };

  const { updateStakeholder: upsertIndividualStakeholder } = useUpdateStakeholder({
    isEdit: true,
    onCompleted: handleCloseForm,
    successMessage: "EDIT_INDIVIDUAL_STAKEHOLDER_NOTIFICATION_SUCCESS",
    paramsSuccessMessage: { overlayName },
    showSuccessNotification: true,
  });

  const handleSubmit = async (values) => {
    values.linkedinURL = values.linkedinURL ? values.linkedinURL.split('?')[0] : "";
    const { cityOfBirth, countryOfBirth, nationalities, hasNationalities, ...rest } = values;

    const nationalitiesValue = handleNationalitiesSaveValue(nationalities, hasNationalities, _.get(userData, "getStakeholder.nationalities.countries", []));

    let stakeholder = {
      ...rest,
      id: params.id,
      birthAddress: {
        city: cityOfBirth,
        country: countryOfBirth,
      },
      nationalities: nationalitiesValue,
    };

    await upsertIndividualStakeholder(
      {
        variables: {
          startupId,
          stakeholderId: params.id,
          stakeholderData: { stakeholder },
        },
      },
      {
        paramsSuccessMessage: { overlayName: stakeholder?.fullName ?? "" },
      }
    );
  };

  const { data: userData, loading } = useIndividualStakeholderData({
    startupId,
    stakeholderId: params.id,
  });

  const defaultValues = useMemo(() => {
    return {
      ..._.get(userData, "getStakeholder"),
      cityOfBirth: _.get(userData, "getStakeholder.birthAddress.city"),
      countryOfBirth: _.get(userData, "getStakeholder.birthAddress.country.name"),
      hasNationalities: handleNationalitiesInitialValue(_.get(userData, "getStakeholder.nationalities.none")),
      nationalities: _.map(_.get(userData, "getStakeholder.nationalities.countries", []),
        (country) => country?.country?.code
      ),
    };
  }, [JSON.stringify(userData)]);

  const renderIndividualStakeholderForm = () => {
    if (_.includes(jurisdictions, "ADGM")) {
      return (
        <ADGMRenewalIndividualStakeholderForm
          initialValues={defaultValues}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
          Template={TemplateWizard}
          banners={isManagedByClara}
          propsTemplate={{
            title: translate(`EDIT_DETAILS`),
            skeleton: (
              <>
                <SkeletonInput count={3} />
                <Line />
                <SkeletonInput />
                <SkeletonArea noLabel={true} />
              </>
            ),
          }}
        />
      );
    } else if (_.includes(jurisdictions, "CAYMAN")) {
      return (
        <CaymanRenewalIndividualStakeholderForm
          initialValues={defaultValues}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
          Template={TemplateWizard}
          banners={isManagedByClara}
          propsTemplate={{
            title: translate(`EDIT_DETAILS`),
            skeleton: (
              <>
                <SkeletonInput count={3} />
                <Line />
                <SkeletonInput />
                <SkeletonArea noLabel={true} />
              </>
            ),
          }}
        />
      );
    } else {
      return (
        <IndividualStakeholderForm
          initialValues={_.get(userData, "getStakeholder")}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
          Template={TemplateWizard}
          propsTemplate={{
            title: translate(`EDIT_DETAILS`),
            skeleton: (
              <>
                <SkeletonInput count={3} />
                <Line />
                <SkeletonInput />
                <SkeletonArea noLabel={true} />
              </>
            ),
          }}
        />
      );
    }
  };

  return (
    <ModalAssistant open={showModal || open}>
      <LoadingProvider>
        {loading && (
          <Stack padding={6} spacing={5}>
            <SkeletonInput count={3} />
            <Line />
            <SkeletonInput />
            <SkeletonArea noLabel={true} />
          </Stack>
        )}
        {!loading && renderIndividualStakeholderForm()}
      </LoadingProvider>
    </ModalAssistant>
  );
};

export default EditProfile;
