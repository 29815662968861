import { useMemo } from "react";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import useSession from "src/modules/session/hooks/useSession";
import { Constants } from "src/v1/utils/constants";

const useGuards = () => {
  const { startup } = useSession();

  return useMemo(
    () => ({
      isPincasSubmit: (context) => {
        return context?.initialData?.isPincasSubmit ?? false;
      },
      isPincasSubmitPaymentRemoved: (context) => {
        return context?.initialData?.isPincasSubmitPaymentRemoved ?? false;
      },
      isAlreadyPaid: (context) => {
        return (
          (context?.initialData?.formationData?.isPaid ||
            context?.initialData?.formationData?.isAwaitingFunds) &&
          !context?.initialData?.formationData?.isSubmitted
        );
      },
      isPincas: (context) => {
        switch (context?.initialData?.productCode) {
          case Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART:
          case Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART:
          case Constants.MODULES.CHECKOUT.PRODUCTS.DELAWARE_SHOPPING_CART:
            return true;
          default:
            return false;
        }
      },
      isNoPincas: (context) => {
        switch (context?.initialData?.productCode) {
          case Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART:
          case Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART:
          case Constants.MODULES.CHECKOUT.PRODUCTS.DELAWARE_SHOPPING_CART:
            return false;
          default:
            return true;
        }
      },
      hasProductCode: (context) => {
        return context?.initialData?.productCode;
      },
      noHasProductCode: (context) => {
        return !context?.initialData?.productCode;
      },
      hasDisabledBundle: (context) => {
        return (
          startup?.hasScaleSubscription ||
          context?.initialData?.companyType ===
            Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_TSL
        );
      },
      
      isFinishProcessStripe: (context) => {
        console.log("**** isFinishProcessStripe",context?.StripePayment);
        return context?.StripePayment?.finishProcess;
      },
      hasTokenErrorCardStripe: (context) => {
        return context?.StripePayment?.errorCard;
      },
      noHasTokenErrorCardStripe: (context) => {
        return !context?.StripePayment?.errorCard;
      },
      

      isFinishProcess: (context) => {
        return context?.CheckoutPayment?.finishProcess;
      },
      hasTokenErrorCard: (context) => {
        return context?.CheckoutPayment?.errorCard;
      },
      noHasTokenErrorCard: (context) => {
        return !context?.CheckoutPayment?.errorCard;
      },
      hasFinishErrorCard: (context) => {
        return context?.CheckoutFormStep?.status === "error";
      },
      noHasFinishErrorCard: (context) => {
        return context?.CheckoutFormStep?.status !== "error";
      },
      isADGM_SPV_Formation: (context) => {
        return (
          context?.initialData?.companyType ===
          Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_SPV
        );
      },
      isADGM_TSL_Formation: (context) => {
        return (
          context?.initialData?.companyType ===
          Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_TSL
        );
      },
      isCaymanFormation: (context) => {
        return (
          context?.initialData?.companyType ===
          Constants.MODULES.FORMATION.COMPANIES_TYPE.CAYMAN_EXEMPTED_COMPANY
        );
      },
      isDelawareFormation: (context) => {
        return (
          context?.initialData?.companyType ===
          Constants.MODULES.FORMATION.COMPANIES_TYPE.DELAWARE_C_CORP
        );
      },
      isADGM: (context) => {
        const groupCompanyClass = new GroupCompany(context?.initialData?.company);
        return groupCompanyClass.isADGM();
      },
      isCayman: (context) => {
        const groupCompanyClass = new GroupCompany(context?.initialData?.company);
        return groupCompanyClass.isCayman();
      }
    }),
    [startup?.hasScaleSubscription]
  );
};

export default useGuards;
