import React, { FC } from "react";
import Wizard from "../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from "../../../../generic/components/Modal/ModalAssistant";
import MachineDesignatedContact from "./machine";
import useModal from "../../../../generic/hooks/useModal";

interface ModalDesignatedContactProps extends ModalAssistantProps {
  paramsMutation: object;
}

const ModalDesignatedContact: FC<ModalDesignatedContactProps> = ({
  open = false,
  paramsMutation,
}: ModalDesignatedContactProps) => {

  const { closeModal } = useModal();

  return (
    <ModalAssistant open={open}>
      <Wizard
        machine={MachineDesignatedContact}
        initialValues={paramsMutation}
        onComplete={closeModal}
      />
    </ModalAssistant>
  );
};

export default ModalDesignatedContact;