export default {
  FIRST_STEP: {
    TITLE: 'Have you added all directors and shareholders?',
    SUBTITLE: 'Directors and shareholders',
    TOOLTIP: 'To complete your company setup, first add all your company\'s current directors and shareholders.'
  },
  SECOND_STEP: {
    TITLE: 'Who will sign your share certificates?',
    SUBTITLE: 'Directors and shareholders',
    TOOLTIP: 'Select one director only to sign the company\'s share certificates.',
    TOOLTIP_KY: 'Select one director to sign the following documents needed for your company formation:<p>&nbsp;</p><ol><li> Economic Substance Questionnaire</li><br><li> Registered Office Agreement</li></ol>',
    TITLE_KY: 'Who will sign your formation documents?',
    SUBTITLE_KY: 'Directors',
  },
  THIRD_STEP: {
    TITLE: 'Who will be the ADGM Designated Contact for this company?',
    SUBTITLE: 'ADGM Designated contact',
    TOOLTIP: 'The ADGM Designated Contact is a contact person who can be reached regarding day-to-day operational matters for the company. If Clara is appointed as the Company Service Provider, ADGM will contact Clara as a first step. However, there may be instances where ADGM will want to contact the Company directly and therefore a contact must be selected.',
    SUBTITLE_KY: 'ADGM Designated Contact',
  }
}
