import useGetGroupCompanyAndStakeholders from './graphql/useGetGroupCompanyAndStakeholders';
import useGetPINCAPrices from "src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices";
import useSession from 'src/modules/session/hooks/useSession';
import useUpdateGroupCompany from './graphql/useUpdateGroupCompany';

const useServices = ({ groupCompanyId })=>{
  const { query } = useGetGroupCompanyAndStakeholders({});
  const { startupId } = useSession();
  const update = useUpdateGroupCompany()
  const { manualQuery: getPincasPrices } = useGetPINCAPrices({
    variables: {
      filters: {
        jurisdictionTypes: ["ADGM"],
      },
    },
  });

  const pincasData = async () => {
    const pincasData = await getPincasPrices();
    return { pincasData };
  }

  const initialData = async () => {
    const data = await query({startupId,groupCompanyId});
    const stakeholders = data?.stakeholders;
    const groupCompany = data?.groupCompany;
    const designatedContact = groupCompany?.keyPeople?.designatedContact;
    return {
      groupCompany,
      stakeholders,
      designatedContact
    }
  }

  const saveNewDesignatedContact = (context) => {
    const { SelectDesignatedContact } = context;
    return update(groupCompanyId,SelectDesignatedContact.stakeholder.id)
  }

  return {
    initialData,
    saveNewDesignatedContact,
    pincasData
  }
}

export default useServices;
