import React, { useState } from 'react';
import { clearAssistatText as clearAssistantText } from '../../../../generic/store/action';
import useModal from '../../../../generic/hooks/useModal';
import ModalAssistant from '../../../../generic/components/Modal/ModalAssistant';
import { StructureChartInitialData } from './types';
import Wizard from './wizard';

type Props = {
  open: boolean;
  initialValues: StructureChartInitialData;
};

/* To Call this modal execute: openModal( StructureChartModal, {},'MODAL_STRUCTURE_CHART' ); */
function StructureChartModal({ open: initialOpen = false, initialValues = {} }: Props) {
  const [open, setOpen] = useState(initialOpen);
  const { closeModal: onClose } = useModal();

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  const handleCancelMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  return (
    <ModalAssistant open={open} variant="assistance">
      <Wizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} />
    </ModalAssistant>
  );
}

export default StructureChartModal;
