import { useMemo } from "react";
import { Constants } from "src/v1/utils/constants";
import PayFormationBundleModal from "src/modules/startup/PayFormationBundleModal";
import StartRenewalModal from "../../renewal/cayman/modals/StartRenewalModal";
import StartRenewalProcessModal from "../../renewal/adgm/modals/StartRenewalProcessModal";
import useModal from "src/modules/generic/hooks/useModal";
import ModalSubmittedFormation from "src/modules/startup/GroupCompany/ADGM/SubmittedFormation/ModalSubmittedFormation";
import useSession from "src/modules/session/hooks/useSession";
import ModalSubmitApplication from "src/modules/clara/components/Accelerator/SubmitApplication";
import { useLD } from "src/providers/LDProvider";

const useDashboardActions = () => {
  const { openModal } = useModal();
  const { updateHasOverduePayment } = useSession();
  const { featureFlags } = useLD();

  const getProductCodeByJurisdictionType = (jurisdictionType) => {
    switch (jurisdictionType) {
      case "CAYMAN":
        return Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART;
      case "DELAWARE":
        return Constants.MODULES.CHECKOUT.PRODUCTS.DELAWARE_SHOPPING_CART;
      case "ADGM":
        return Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART;
      default:
        return null;
    }
  };

  const handleViewCart = (groupCompanyId, jurisdiction) => {
    openModal(PayFormationBundleModal, {
      initialValues: {
        groupCompanyId,
        productCode: getProductCodeByJurisdictionType(jurisdiction),
        needFirstContact: true,
        disabledPayCart: true,
        isPincasSubmit: false
      }
    })
  };

  const handlePayAndSubmitRenewal = (company, callback) => {
    const jurisdiction = company?.jurisdictionType;
    openModal(PayFormationBundleModal, {
      initialValues: {
        groupCompanyId: company?.id,
        productCode: getProductCodeByJurisdictionType(jurisdiction),
        needFirstContact: true,
        disabledPayCart: false,
        isPincasSubmit: false,
        callback
      }
    })
  };

  const handlePayAndSubmitFormation = (groupCompanyId, callback) => {
    openModal(
      PayFormationBundleModal,
      { initialValues: { groupCompanyId, callback } },
      "MODAL_PAY_FORMATION_BUNDLE"
    );
  };

  const handleSubmitFormation = (groupCompanyId, callback) => {
    openModal(ModalSubmittedFormation, {
      paramsMutation: { groupCompanyId, callback }
    })
  };

  const handleSubmitRenewal = (company, callback) => {
    const jurisdiction = company?.jurisdictionType;

    openModal(PayFormationBundleModal, {
      initialValues: {
        groupCompanyId: company?.id,
        productCode: getProductCodeByJurisdictionType(jurisdiction),
        needFirstContact: true,
        disabledPayCart: false,
        isPincasSubmit: !featureFlags['payment-process-removal-pincas-and-renewals'],
        isPincasSubmitPaymentRemoved: featureFlags['payment-process-removal-pincas-and-renewals'],
        callback
      }
    })
  };

  const handleSubmitStrikeOff = (company, callback) => {
    const jurisdiction = company?.jurisdictionType;

    openModal(PayFormationBundleModal, {
      initialValues: {
        groupCompanyId: company?.id,
        productCode: getProductCodeByJurisdictionType(jurisdiction),
        needFirstContact: true,
        disabledPayCart: false,
        isPincasSubmit: !featureFlags['payment-process-removal-pincas-and-renewals'],
        isPincasSubmitPaymentRemoved: featureFlags['payment-process-removal-pincas-and-renewals'],
        callback
      }
    }, 'MODAL_PAY_SUBMIT_STRIKE_OFF');
  };

  const handleSubmitApplication = (callback) => {
    openModal(
      ModalSubmitApplication, {
        initialValues: {
          callback
        }
      },
      "MODAL_TASK_ADD_SUBMIT_ACCELERATOR"
    );
  };

  const handlePincasPayAndSubmit = (
    groupCompanyId,
    jurisdiction,
    callback
  ) => {
    openModal(
      PayFormationBundleModal,
      {
        initialValues: {
          groupCompanyId,
          callback,
          productCode: getProductCodeByJurisdictionType(jurisdiction),
          needFirstContact: true,
          disabledPayCart: false,
          isPincasSubmit: false,
        },
      },
      "MODAL_PAY_AND_SUBMIT_CAYMAN_SHOPPING_CART"
    );
  };

  const handlePincasSubmit = (
    groupCompanyId,
    jurisdiction,
    callback
  ) => {
    openModal(
      PayFormationBundleModal,
      {
        initialValues: {
          groupCompanyId,
          callback,
          productCode: getProductCodeByJurisdictionType(jurisdiction),
          needFirstContact: true,
          disabledPayCart: false,
          isPincasSubmit: !featureFlags['payment-process-removal-pincas-and-renewals'],
          isPincasSubmitPaymentRemoved: featureFlags['payment-process-removal-pincas-and-renewals']
        },
      },
      "MODAL_SUBMIT_CAYMAN_SHOPPING_CART"
    );
  };

  const handleStartRenewalProcess = (company, callback) => {
    const jurisdiction = company?.jurisdictionType;

    if (jurisdiction === "ADGM") {
      openModal(
        StartRenewalProcessModal,
        {
          initialValues: {
            company: {
              id: company?.id,
              name: company?.name,
              licenceType: company?.companyType,
            },
            callback
          },
        },
        "MODAL_START_ADGM_RENEWAL"
      );
    } else if (jurisdiction === "CAYMAN") {
      openModal(
        StartRenewalModal,
        {
          initialValues: {
            company,
            callback
          },
        },
        "MODAL_START_RENEWAL"
      );
    }
  };

  const handlePayAndSubmitStrikeOff = (params, callback) => {
    openModal(PayFormationBundleModal, {
      initialValues: {
        groupCompanyId: params?.id,
        productCode: getProductCodeByJurisdictionType(params?.jurisdictionType),
        needFirstContact: true,
        disabledPayCart: false,
        isPincasSubmit: false,
        callback
      }
    }, 'MODAL_PAY_FORMATION_BUNDLE');
  };

  const updateHasOverduePaymentAction = (value) => {
    updateHasOverduePayment(value);
  };

  return useMemo(() => ({
    VIEW_CART: handleViewCart,
    PAY_AND_SUBMIT: handlePayAndSubmitFormation,
    SUBMIT_FORMATION: handleSubmitFormation,
    SUBMIT_RENEWAL: handleSubmitRenewal,
    SUBMIT_STRIKE_OFF: handleSubmitStrikeOff,
    SUBMIT_ACCELERATOR_APPLICATION: handleSubmitApplication,
    START_RENEWAL_PROCESS: handleStartRenewalProcess,
    PINCAS_PAY_AND_SUBMIT: handlePincasPayAndSubmit,
    PINCAS_SUBMIT: handlePincasSubmit,
    RENEWAL_PAY_AND_SUBMIT: handlePayAndSubmitRenewal,
    STRIKE_OFF_PAY_AND_SUBMIT: handlePayAndSubmitStrikeOff,
    UPDATE_HAS_OVERDUE_PAYMENT: updateHasOverduePaymentAction
  }), []);
};

export default useDashboardActions;
