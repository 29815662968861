const TEXTS = {
  BUTTON_NEXT: "Next",
  START_NOW: "Start Now",
  WIZARD_ADD_MORE_STEP_TITLE: "Do you have more companies to add?",
  WIZARD_ADD_MORE_STEP_SUBTITLE: "Company added!",
  WIZARD_INCORPORATED_TITLE: "Have you incorporated this company?",
  WIZARD_FORM_TITLE: "Add this company's basic info",
  WIZARD_FORM_SUBTITLE: "Add a company",
  LABEL_TOP_COMPANY: "Is this your top company (topco)?",
  LABEL_TOP_COMPANY_LINE1: "Is this your top",
  LABEL_TOP_COMPANY_LINE1_NOTFORMED: "Will this be your top",
  LABEL_TOP_COMPANY_LINE2: "company (topco)?",
  LABEL_TOP_COMPANY_LINE3: "Is this your topco?",
  LABEL_NAME: "Company name",
  LABEL_COUNTRY_INCORPORATION: "Country of incorporation",
  LABEL_JURISDICTION: "Jurisdiction",
  LABEL_INCORPORATION_DATE: "Incorporation date",
  LABEL_ANOTHER_NO: "No, it isn't",
  LABEL_ANOTHER_YES: "Yes, it is",
  LABEL_ANOTHER_NO_NOTFORMED: "No, it won't",
  LABEL_ANOTHER_YES_NOTFORMED: "Yes, it will",
  FIRST_STEP_TOOLTIPS:
    "Let’s get started. <b>Incorporating</b> (or forming) a company means you’ve registered your business as a legal entity in a jurisdiction.",
  FIRST_STEP_IS_EXIST_TOOLTIPS: "Good job. This is just the beginning…",
  FIRST_STEP_IS_NOT_EXIST_TOOLTIPS:
    "Did you know that you can form your company digitally with Clara? <a>Start Now</a>",
  SECOND_STEP_1_TOOLTIPS:
    "These details help build your map and activate handy features such as your cap table.",
  SECOND_STEP_2_TOOLTIPS:
    "Your <b>Topco</b>. is the parent company of your startup. All other companies added to your startup's profile should be subsidiaries of your topco",
  SECOND_STEP_IS_NOT_TOP_TOOLTIPS:
    "Once you've finished here, add your topco to create a more detailed map.",
  THIRD_STEP_NOT_ANOTHER: "Great. Now let's add a few more details. ",
  THIRD_STEP_ANOTHER: "Impressive. Let's add another.",
  ANOTHER: "Yes, there’s more where that came from",
  NOT_ANOTHER: "No, that’s enough for now",
  STEP1_HIT: "Add your incorporated companies or form a new one with Clara.",
  STEP1_HIT2:
    "An incorporated company is a business that has been registered as a legal entity in a jurisdiction.",
  STEP1_HIT3:
    "You'll be able to add all your incorporated companies one after the other. Once added, they will be shown on the map.",
  STEP2A_HIT:
    "Choose where to form this company. Clara will tailor the formation process accordingly.",
  STEP3A_HIT:
    "Need a legal term defined or some helpful information? Type your question below.",
  STEP4A_HIT:
    "ADGM has specific requirements for incorporations. Next, Clara will ask some questions to check that the new company will meet these requirements.",
  STEP5A_HIT:
    "Tell Clara who will own at least 25% of shares in the new company. Your choice here helps evaluate the company's eligibility for ADGM formation.",
  STEP6A_HIT:
    "<p>In ADGM, companies that act as your holding company or topco (like the one you'll form with Clara) usually take the form of a special purpose vehicle (SPV for short) and must follow specific rules.</p><p>&nbsp;</p><p>SPV's should only receive revenue from capital gains and or dividends. They should accept ADGM Model Articles of Association and form using a single class of shares. But don't worry, you can amend your articles or create different classes of shares after the entity has been formed. You'll also need to agree that this company will not issue visas, have a physical office, or hire any employees.</p><p>&nbsp;</p><p>If this sounds like the company being formed, select 'Agree, I'm forming an SPV'.</p>",
  STEP7B_HIT:
    "<p>The company hasn't met all the requirements for ADGM formation, so Clara recommends forming in the Cayman Islands instead.</p><p>&nbsp;</p><p>The Cayman Islands is the best alternative to ADGM. A popular, common law jurisdiction for new companies and venture capitalists, it has tax neutral status and a legal framework based on English law​.</p>",
  STEP6B_HIT:
    "GCC companies are based in Bahrain, Kuwait, Oman, Qatar, Saudi Arabia, and the United Arab Emirates.​",
  STEP8B_HIT:
    "<strong>GCC nationals and/or UAE residents</strong> hold a passport from Bahrain, Kuwait, Oman, Qatar, Saudi Arabia or UAE, or a residence visa for the UAE.​​",
  STEP7A_HIT:
    "<p>That's all the ADGM requirements met! From the answers given, Clara recommends an SPV formation for your company’s needs.</p><p>&nbsp;</p><p>This means you’ll be forming a passive holding company, established for the purpose of isolating financial and legal risk by ring-fencing certain assets and liabilities.</p>",
  STEP9B_HIT:
    "<p>The company hasn’t met all the requirements for ADGM digital formation, so Clara suggests some other options.</p><p>&nbsp;</p><p>The Cayman Islands is the best alternative to ADGM. A popular, common law jurisdiction for new companies and venture capitalists, it has tax neutral status and a legal framework based on English law​.</p><p>&nbsp;</p><p>Clara concierge service provides personal, tailored service to help create a formation suited to your company’s needs.</p><p>&nbsp;</p><p>Form anywhere in the world with Clara – simply choose another jurisdiction.</p>",
  STEP8A1_HIT:
    "Welcome to the first step of forming your new company - exciting!",
  STEP_CONCIERGE_HIT:
    "Don't worry, Clara can still help you forming your company. Tell us how you'd like to call your company.",
  STEP8A2_HIT:
    "Your topco is the parent company of your startup. All other companies added to your startup’s profile should be subsidiaries of your topco.",
  STEP6BB_HIT: "Start by adding the company’s details next.",
  STEP_POINTER_A_HIT:
    "Start your formation the right way – select ‘Next’ for key pointers to keep in mind.",
  STEP_POINTER_B_HIT:
    "Clara provides guidance throughout the journey, tailoring the formation to fit the new company’s needs.",
  STEP_POINTER_C_HIT:
    "No matter the company’s ambitions, plan online with Clara, with a range of specialist, goal-based tools.",
  STEP_POINTER_D_HIT:
    "A topco is the company that sits at the very top of your startup’s corporate structure. In most cases, it acts purely as a company to hold shares in the company or companies of your startup that actually carry out your business (these are sometimes referred to as operating companies or subsidiaries). You may have heard of this type of company being referred to as a holding company or holdco.",
  STEP_POINTER_E_HIT:
    "Need more insight on legal issues? Clara Academy contains helpful articles and definitions.",
  STEP_POINTER_F_HIT:
    "Clara helps companies prepare for investment. Once the company is formed, explore the full range of features.",
  STEP_POINTER_G_HIT:
    "Need a legal term defined or some helpful information? Type your question below.",
  CAYMAN_INFO_HIT: "Start by adding the company’s details next.",
  CONCIERGE_INFO_HIT: "Start by adding the company’s details next.",
  CONCIERGE_SUCCESS_HIT:
    "Your first task will be to add the individual and corporate stakeholders that will be the shareholders and directors of your new ADGM SPV company.",
  MAKE_COMPANY_INFO_HIT: "Start by adding the company’s details next.",
  TOOLTIP_ACCEPT_TERMS_CAYMAN_1:
    "What is a TopCo?<p>&nbsp;</p>A Topco is the company that sits at the very top of your startup's corporate structure. In the case of a Cayman Topco company, it will act purely as a company to hold shares in the company or companies of your startup that actual carry out your business (these are sometimes referred to as operating companies or subsidiaries).",
  TOOLTIP_ACCEPT_TERMS_CAYMAN_2:
    "What is an Exempted company?<p>&nbsp;</p>An Exempted Company is the most flexible and versatile company type recommended for venture-backed startups.",
  TOOLTIP_CREATE_CAYMAN_INFO: "Your first task will be to add the individual and corporate stakeholders that will be the shareholders and directors of your new company.​",
  TOOLTIP_REFERAL_CAYMAN:
    "What’s Clara Concierge?<p>&nbsp;</p>Clara’s concierge is an agent-led service. You’ll be personally guided through the process of forming your company in the Cayman Islands.​",
  TOOLTIP_REFERAL_DELAWARE:
    "What’s Clara Concierge?<p>&nbsp;</p>Clara’s concierge is an agent-led service. You’ll be personally guided through the process of forming your company in Delaware.​",
  TOOLTIP_CREATE_CAYMAN_1:
    "Welcome to the first step of forming your new company - exciting!​",
  TOOLTIP_CREATE_CAYMAN_2:
    "It is not required to include the word “Limited” or the abbreviation “Ltd.” after its name.",
  CHOOSE_JURISDICTION_HIT:
    "Select the jurisdiction in which you want to form your company.",
  CHOOSE_JURISDICTION_TOOLTIP_DELAWARE:
    "We currently only process formations in ADGM, the Cayman Islands and Delaware. We are planning to launch formations in England & Wales and Singapore. If you're interested in any of these 2 jurisdictions, please let us know and we will keep you updated.",
  CHOOSE_JURISDICTION_TOOLTIP:
    "We currently only process formations in ADGM and the Cayman Islands. We are planning to launch formations in Delaware, England & Wales and Singapore. If you're interested in any of these 3 jurisdictions, please let us know and we will keep you updated.",
  CHOOSE_JURISDICTION_ADGM: "Abu Dhabi Global Market (ADGM)",
  CHOOSE_JURISDICTION_CAYMAN: "Cayman Islands",
  CHOOSE_JURISDICTION_UE: "Delaware",
  CHOOSE_JURISDICTION_UK: "England & Wales",
  CHOOSE_JURISDICTION_SING: "Singapore",
  FORM_INC_CREATION_COMPANY_TOOLTIP1:
    "A topco is the company that sits at the very top of your startup’s corporate structure. In most cases, it acts purely as a company to hold shares in the company or companies of your startup that actually carry out your business (these are sometimes referred to as operating companies or subsidiaries). You may have heard of this type of company being referred to as a holding company or holdco.",
  FORM_INC_CREATION_COMPANY_TOOLTIP2:
    "<a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6385562-what-is-a-topco' >Click here</a> to learn more about topco.",
  FORM_INC_CREATION_COMPANY_TOOLTIP3:
    "Type the name of the jurisdiction in the Other jurisdiction field. If you don’t know the name or there is no jurisdiction, just type the country name.",
  FORM_INC_ADD_MORE_COMPANY: "Yes, I do",
  FORM_INC_NO_ADD_MORE_COMPANY: "No, I don’t",
  FORM_INC_ADD_ANOTHER_COMPANY_TOOLTIP:
    "In order to build a complete map of your business and use all Clara features, you should add all of your companies and stakeholders.",
  FORM_INC_ADD_ANOTHER_COMPANY_TOOLTIP_ACCELERATOR:
    "It is required to add all of your company as part of the program onboarding. Add all your companies here now, or use the add a company button on the map if you want to add them later.",
  ONBOARDING_STARTUP_NAME_STEP_NO_SAME_NAME:
    "This may not necessarily be the same as the legal name of your company or companies. Just tell us what you generally call your business or group.",
  ONBOARDING_STARTUP_NAME_STEP_TITLE:
    "<b>{{name}}</b>, meet <b>Clara.</b> ",
  ONBOARDING_STARTUP_NAME_STEP_LABEL:
    "Let’s get you set up. How would you like to name this<br>profile?",
  ONBOARDING_STARTUP_BUTTON_NEXT: "Next",
  ONBOARDING_DO_WANT_DO_LABEL: "What do you want to do?",
  ONBOARDING_GOAL_STEP_TITLE_GOAL_GOAL_FORMATION_ADGM_BUY_REFER:
    "Form a company",
  ONBOARDING_GOAL_STEP_GOAL_GOAL_FORMATION_ADGM_BUY_REFER:
    "If you're looking to incorporate a new company, you can do it here.",
  ONBOARDING_GOAL_STEP_TITLE_GOAL_GOAL_GENERATE_DOCUMENT: "Generate documents",
  ONBOARDING_GOAL_STEP_GOAL_GOAL_GENERATE_DOCUMENT:
    "Need a SAFE or Share Incentive Plan? Generate these and more, then sign them online.",
  ONBOARDING_GOAL_STEP_TITLE_GOAL_GOAL_GET_READY_FOR_INVESTMENT:
    "Build your cap table",
  ONBOARDING_GOAL_STEP_GOAL_GOAL_GET_READY_FOR_INVESTMENT:
    "Manage all your equity with our advanced cap table modelling.",
  ONBOARDING_GOAL_STEP_TITLE_GOAL_GOAL_ACCELERATOR:
    "Prepare for an accelerator program",
  ONBOARDING_GOAL_STEP_GOAL_GOAL_ACCELERATOR:
    "Satisfy the requirements to get onboarded into specific accelerator programs.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_FORMATION_NEW_USER: "Great! The new profile has been created and added to your account.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_FORMATION_NEW_USER2: "Access the profile and follow the task list on <strong>My Tasks</strong> to provide all the necessary information and documents to <strong>form your company</strong>. Once the application is paid and submitted, it will be reviewed right away to make your company official in no time.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_FORMATION_NEW_USER2_PAYMENTREMOVED: "Access the profile and follow the task list on <strong>My Tasks</strong> to provide all the necessary information and documents to <strong>form your company</strong>. Once the application is submitted, it will be reviewed right away to make your company official in no time.",
  ONBOARDING_FORMATION_READY_TO_START: "Follow the tasks on <strong>My Tasks</strong> page to form your company.",
  ONBOARDING_FORMATION_READY_TO_START2: "Once completed, you will be able to <strong>pay and submit</strong> your formation to Clara. You will be able to keep track of its status from the <strong>Dashboard</strong> and <strong>My Tasks page</strong>.",
  ONBOARDING_FORMATION_READY_TO_START3: "If you need help at any time, click on <strong>Clara Help</strong> in the bottom right corner to contact us.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_ACCELERATOR_NEW_USER: "Congratulations on your successful application to <strong>{{{accelerator}}}</strong>! Your profile has been created and it will be <strong>automatically shared</strong> with their team.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_ACCELERATOR_NEW_USER2: "Access the profile and follow the task list on <strong>My Tasks</strong> to get started. You can easily form a company if you don't have one yet.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_CAP_TABLE_NEW_USER: "Great! The new profile has been created and added to your account.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_CAP_TABLE_NEW_USER2: "Access the profile and follow the task list on <strong>My Tasks</strong> to <strong>set up your cap table</strong> by adding your companies, shareholders and all equity positions in each company.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_DOCUMENT_NEW_USER: "Great! The new profile has been created and added to your account.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_DOCUMENT_NEW_USER2: "Access the profile and follow the task list on <strong>My Tasks</strong> to generate and sign your documents through our integrated <strong>DocuSign</strong> feature. All documents will be automatically stored in your <strong>Data Room</strong.",
  ONBOARDING_ACCELERATOR_EXISTING_USER_TEXT: "Your profile will be <strong>automatically shared</strong> with <strong>Antler MENAP</strong>. Follow the tasks on the <strong>My Tasks page</strong> and complete them all to proceed.",
  ONBOARDING_ACCELERATOR_EXISTING_USER_TEXT2: "Please make sure you’ve added all companies and stakeholders in your startup before submitting your application. You can return to the <strong>Accelerators</strong> page to review it anytime.",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_FORMATION_ADGM_BUY_REFER:
    "<p>Follow the task list on the dashboard, or click the blue + button in the navigation to start forming your company.</p>",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_FORMATION_ADGM_BUY_REFER_2: "",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_GENERATE_DOCUMENT:
    "<p>Select the document you need then generate it with Clara. You’ll be able to sign them through our integrated DocuSign and all the documents will automatically store and categorise in the Dataroom.</p>",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_GET_READY_FOR_INVESTMENT:
    "<p>Start by adding all the <strong>companies</strong> and <strong>shareholders</strong> in your startup.</p><p>You can then start adding your equity positions and build your <strong>cap table</strong>.</p>",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_ACCELERATOR:
    "<p>Start by adding all the <b>companies</b> you have in your startup. If you don't have any, you can easily form one with Clara. Then add all the company <b>stakeholders</b>, their details and related documents.</p><p>Finally, follow the rest of the checklist on the right and submit your application. Your profile will be <b>automatically shared</b> with the {{accelerator}} accelerator team.</p>",
  ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TITLE: "What happens next",
  ONBOARDING_READY_TO_START_STEP_TITLE: "You're ready to start",
  ONBOARDING_ACCELERATOR_EXISTING_USER_STEP_TITLE: "Welcome to Antler MENAP",
  ONBOARDING_WHAT_EMPTY_ACCELERATORS_STEP_TITLE: "Select your accelerator",
  ONBOARDING_WHAT_EMPTY_ACCELERATORS_STEP_TEXT:
    "There are no programs available at the moment but more coming soon!",
  ONBOARDING_TELL_US_ABOUT_STEP_TEXT:
    "Tell us where your primary location is (the country where your main commercial activities are currently) and your website (if you have one).",
  ONBOARDING_TELL_US_ABOUT_STEP_TITLE: "Tell us about your business",
  ONBOARDING_TELL_US_PLACEHOLDER_PRIMARY_LOCATION: "Select country",
  ONBOARDING_TELL_US_LABEL_PRIMARY_LOCATION: "Primary location",
  ONBOARDING_TELL_US_LABEL_WEBSITE: "Website",
  ONBOARDING_ADD_FOUNDER_TITLE: "Who are the founders of<br>your profile?",
  ONBOARDING_ADD_FOUNDER_LABEL_FOUNDER: "Full legal name",
  ONBOARDING_ADD_FOUNDER_PLACEHOLDER_FOUNDER: "As it appears in their passport",
  ONBOARDING_ADD_FOUNDER_BUTTON_ADD: "Add",
  ONBOARDING_ACCELERATOR_TITLE: "Select your accelerator",
  ONBOARDING_ACCELERATOR_TEXT:
    "More accelerator programs coming soon!<br/> <br/> If you don’t see your program in here yet, use the ‘Previous’ button to go back and select a different goal.",
  ONBOARDING_NEW_ACCELERATOR_TITLE: "Accelerator program",
  ONBOARDING_NEW_ACCELERATOR_SUBTITLE:
    "Please enter the verification code you have received regarding your accelerator program onboarding.",
  ONBOARDING_NEW_ACCELERATOR_TEXT:
    "If you have not received a code, please click on Previous and select a different option.",
  ONBOARDING_PREVIOUS_BUTTON: "Previous",
  ONBOARDING_LAST_BUTTON: "Get started",
  TOOLTIP_ACCEPT_TERMS_DELAWARE_1:
    "What is a C-Corp?<p>&nbsp;</p>A C-Corp is a legal structure for a corporation in which the owners, or stockholders, are taxed separately from the corporation. Delaware C-Corps are the most popular form of corporations for venture-backed startups setting up in the US.<br/> <br/> You can only form through the platform using natural persons as stockholders and directors (i.e. no corporate entities). If you have corporate entities to add at incorporation, you will be re-directed to our offline concierge service.​<br/> <br/> The cost for incorporating a C-Corp is $499. This is inclusive of all filings fees.",
  TOOLTIP_ACCEPT_TERMS_DELAWARE_1_PAYMENTREMOVAL:
    "What is a C-Corp?<p>&nbsp;</p>A C-Corp is a legal structure for a corporation in which the owners, or stockholders, are taxed separately from the corporation. Delaware C-Corps are the most popular form of corporations for venture-backed startups setting up in the US.<br/> <br/> You can only form through the platform using natural persons as stockholders and directors (i.e. no corporate entities). If you have corporate entities to add at incorporation, you will be re-directed to our offline concierge service.",
  TOOLTIP_CREATE_DELAWARE_INFO: "Your first task will be to add the individual and corporate stakeholders that will be the shareholders and directors of your new company.​​",
  TOOLTIP_CREATE_DELAWARE_1:
    "Welcome to the first step of forming your new company - exciting!​",
  TOOLTIP_CREATE_DELAWARE_2: `It’s advisable to search if your proposed name is available. You can do so <a rel='noopener noreferrer' target='_blank' href="https://icis.corp.delaware.gov/ecorp/namereserv/namereservation.aspx" >here</a> (you don’t need to reserve it, just check that it’s available for reservation).`,
};

export default TEXTS;
