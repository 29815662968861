import * as Yup from "yup";

import React, { FC, useRef } from "react";

import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import Form from "../../../../components/forms/Form/FormL";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import SearchWithList from "src/modules/generic/components/Lists/SearchWithList";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { useLD } from "src/providers/LDProvider";
import { is } from "date-fns/locale";

const schema = Yup.object().shape({
  stakeholder: Yup.object().required("Required"),
});
interface Props extends FormProps {
  stakeholders: any[],
  dataProtectionContact: any
  showDataProtectionContact?: boolean
}

const FormSelectDataProtectionContact: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  stakeholders,
  dataProtectionContact,
  showDataProtectionContact
}: Props) => {

  const refForm = useRef();
  const { translate } = useTranslate();
  const { context } = useMachine();
  const [selectStakeholderOption, setSelectStakeholderOption] = React.useState(null);

  const ldProvider = useLD();
  const isPaymentProcessingRemoved = ldProvider.featureFlags['payment-process-removal-pincas-and-renewals'];

  const options: Array<CheckboxButtonOptions<boolean, string>> = stakeholders.map(st => ({
    value: st,
    label: st.fullName,
  }));

  return (
    <>
      <Form
        initialValues={{ stakeholder: dataProtectionContact }}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Save changes</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <SearchWithList
            controllerInputProps={{
              options,
              name: "stakeholder",
              onChange: setSelectStakeholderOption
            }}
            placeholderSearch={"Search stakeholder"}
            withBanner={selectStakeholderOption !== null}
            info={
              isPaymentProcessingRemoved ?
                translate('MODULES.RENEWALS_ADGM.COMMON.BANNER_1_PAYMENT_REMOVAL') :
              translate('MODULES.RENEWALS_ADGM.COMMON.BANNER_1', {
                currency: 'USD',
                price: context?.pincasData?.pincasData?.CHANGE_COMPANY_AMEND_DATA_PROTECTION_CONTACT?.value ?? 0,
                button: "Save changes",
              })
            }
          />
        </Template>
      </Form>
    </>
  );
};

export default FormSelectDataProtectionContact;
