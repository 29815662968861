import * as Yup from "yup";

import { Constants } from "src/v1/utils/constants";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
import { StakeholderBO } from "src/modules/startup/core/type/startup/stakeholder";
import { useMemo, useState } from "react";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { useLD } from "src/providers/LDProvider";
import { is } from "date-fns/locale";

// Define the type for the properties of each field
export type FieldConfig = {
  show: boolean;
  disabled: boolean;
  hasPincas: boolean;
  bannerTitle: string;
  bannerLogicType: "touched" | "values";
  isRequired: boolean;
  label: string;
  variant?: 'banner' | 'noTemplateLabel';
  onChange?: (value: any) => void;
};

type FieldAnalysisResult = {
  config: {
    [key: string]: FieldConfig;
  };
  schema: object;
};

function useStakeholderFieldsAnalizer(
  stakeholder: StakeholderBO,
  initialSchema = {},
  buttonText?: string
): FieldAnalysisResult {
  const { translate } = useTranslate();

  const defaultText = useMemo(() => {
    let result = "Save changes";
    if (buttonText) {
      result = buttonText;
    }
    return result;
  }, [buttonText]);

  const [hasNationalities, setHasNationalities] = useState(stakeholder?.hasNationalities);

  const ldProvider = useLD();
  const isPaymentProcessingRemoved =
    ldProvider.featureFlags["payment-process-removal-pincas-and-renewals"];

  const config = useMemo(() => {
    const stakeholderClass = new Stakeholder(stakeholder);

    return {
      title: {
        //  Corporate
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.CAYMAN,
          ]),
        bannerTitle: 
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." : 
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_TITLE"),
        schema: Yup.string()
          .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
          .nullable(),
      },
      fullName: {
        //  Corporate and Individaul
        show: true,
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
            Constants.JURISDICTIONS.CAYMAN,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: true,
        label: stakeholderClass.getIsCorporate()
          ? translate("MODULES.CLARA.ENTITY_NAME_LABEL")
          : translate("MODULES.CLARA.FORM_LEGAL_NAME"),
        schema: Yup.string().required(
          translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
        ),
      },
      arabicName: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: false,
        label: translate("MODULES.CLARA.FORM_ARABIC_NAME"),
        schema: Yup.string().nullable(),
      },
      previousName: {
        // individual && corporate
        show: true,
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: false,
        label: stakeholderClass.getIsCorporate()
          ? translate("VIEW_COMPANY_TAB_LABEL_DETAILS_PREVIOUS_COMPANY_NAME")
          : translate("MODULES.CLARA.FORM_FORMER_NAMES"),
        schema: Yup.string().nullable(),
      },
      nationality: {
        // individual && corporate (not yet implemented)
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_NATIONALITY"),
        schema: Yup.string().required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).nullable(),
      },
      companyType: {
        // corporate
        show: stakeholderClass.getIsCorporate(),
        disabled:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
          ]),
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
            Constants.JURISDICTIONS.CAYMAN,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: true,
        label: translate(
          "VIEW_COMPANY_TAB_LABEL_DETAILS_ENTITY_LEGAL_STRUCTURE"
        ),
        schema: Yup.string().required("This field is required.").nullable(),
      },
      dateOfBirth: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_DATE_OF_BIRTH"),
        schema: Yup.string()
          .matches(Constants.REGEXP.DATE, {
            message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
            excludeEmptyString: true,
          })
          .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
          .nullable(),
      },
      passportNumber: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas: false,
        bannerTitle: "No hay",
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_PASSPORT_NUMBER"),
        schema: Yup.string()
          .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
          .nullable(),
      },
      email: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas: false,
        bannerTitle: "No hay",
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_EMAIL"),
        schema: Yup.string()
          .lowercase()
          .matches(Constants.REGEXP.EMAIL, {
            message: translate("MODULES.CLARA.VALIDATION_ENTER_EMAIL"),
            excludeEmptyString: true,
          })
          .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
          .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      },
      phoneNumber: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_TELEPHONE_NUMBER"),
        schema: Yup.string()
          .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
          .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      },
      hasNationalities: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas: false,
        isRequired: true,
        onChange: (value) => {
          setHasNationalities(value)
        },
        label: "Do they have other nationalities (past or present)?",
        schema: Yup.boolean().required("This field is required").typeError("This field is required")
      },
      nationalities: {
        // individual
        show: !stakeholderClass.getIsCorporate() && hasNationalities,
        disabled: false,
        hasPincas: false,
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_OTHER_NATIONALITIES"),
        schema: Yup.mixed().when('hasNationalities', {
          is: true,
          then: Yup.array().of(Yup.string()).min(1, translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
          otherwise: Yup.array().of(Yup.string()).nullable(),
        }),
      },
      cityOfBirth: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas: false,
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_CITY_OF_BIRTH"),
        schema: Yup.string().required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).nullable(),
      },
      countryOfBirth: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas: false,
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_COUNTRY_OF_BIRTH"),
        schema: Yup.string().required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).nullable(),
      },
      profession: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("MODULES.CLARA.FORM_PROFESSION"),
        schema: Yup.string().required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      },
      linkedinURL: {
        // individual || corporate
        show: true,
        disabled: false,
        hasPincas: false,
        bannerTitle: "No hay",
        bannerLogicType: "touched",
        isRequired: false,
        label: translate("MODULES.CLARA.FORM_LINKEDIN_URL"),
        schema: Yup.string().matches(Constants.REGEXP.URL_PROTOCOL, {
          message: "Please enter a valid URL. eg:'https://...' or 'http://... '",
          excludeEmptyString: true,
        }).nullable(),
      },
      curriculumVitae: {
        // individual
        show: !stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas: false,
        bannerTitle: "No hay",
        bannerLogicType: "touched",
        isRequired: false,
        label: translate("MODULES.CLARA.FORM_CURRICULUM_VITAE"),
      },
      groundsOfBeneficialOwner: {
        // individual
        show: !stakeholderClass.getIsCorporate() && stakeholderClass.isUBO(),
        disabled: true,
        hasPincas: false,
        bannerTitle: "No hay",
        bannerLogicType: "touched",
        isRequired: true,
        label: translate(
          "MODULES.CLARA.FORM_GROUNDS_OF_BENEFICIAL_OWNER_APPOINTMENT"
        ),
        schema: Yup.string().required(
          translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
        ),
      },
      taxRegistrationNumber: {
        show: stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas: false,
        bannerTitle: null,
        bannerLogicType: "touched",
        isRequired: false,
        label: translate("MODULES.CLARA.COMPANY_TAX_REGISTRATION_NUMBER_LABEL"),
      },
      registeredNumber: {
        show: stakeholderClass.getIsCorporate(),
        disabled: false,
        hasPincas:
          stakeholderClass.getManagedByClara() &&
          stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
            Constants.JURISDICTIONS.ADGM,
          ]),
        bannerTitle:
        isPaymentProcessingRemoved ? 
        "Fees may apply for updating this value. When all changes are made, head to your Dashboard to submit your updates for Clara to review." :
          `Select "${defaultText}" to add this change request to your cart. Then go to the Dashboard and click "View cart" to pay and submit.`,
        bannerLogicType: "touched",
        isRequired: true,
        label: translate("MODULES.CLARA.COMPANY_NUMBER_LABEL"),
      },
    };
  }, [stakeholder, translate]);

  const schema = useMemo(() => {
    const s = initialSchema;
    Object.keys(config).forEach((key) => {
      if (config[key].show && !config[key].disabled && config[key].schema) {
        s[key] = config[key].schema;
      }
    });
    return Yup.object().shape(s);
  }, [config, initialSchema]);

  return { config: config as { [key: string]: FieldConfig }, schema };
}

export default useStakeholderFieldsAnalizer; // Define your Stakeholder type here
